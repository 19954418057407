import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Broadcast {
  private _eventSubject = new Subject<any>();

  event$ = this._eventSubject.asObservable();

  broadcastEvent(data: any) {
    this._eventSubject.next(data);
  }

  getBroadcast() {
    return this._eventSubject.asObservable();
  }
}
