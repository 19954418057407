<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<mat-card-header class="" style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="row col-md-12">
    <div class="col-10">
      <h1 class="titre-modale">Edition des Heures Supplémentaires</h1>
    </div>

    <div class="col-md-2 text-end mb-0 mr-0 ml-auto gx-0">
      <button mat-dialog-close class="py-0" mat-icon-button aria-label="Fermer la modale">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>

<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="formDirectionActif || formEvenementActif">
    <form [formGroup]="form_direction">
      <div class="row" style="padding-left: 0">
        <div class="col-6" style="margin-top: 0">
          <mat-form-field style="width: 100%">
            <mat-label>Agent</mat-label>
            <input type="text" matInput [readonly]="true" formControlName="agent" class="texte-agent" />
          </mat-form-field>
        </div>
      </div>

      <div class="row g-0 mt-0">
        <div class="col-md-12">
          <div class="div-champs-1">
            <div style="width: 100%">
              <mat-form-field style="width: 100%; min-height: 130px; margin: 0; padding: 0" floatLabel="always">
                <mat-label>Motif </mat-label>
                <textarea matInput formControlName="motif" class="champ-disabled" [readonly]="true"></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-3">
          <mat-form-field floatLabel="always" style="width: 100%; padding-right: 25px">
            <mat-label>Date </mat-label>
            <input
              type="text"
              formControlName="date"
              appearance="standard"
              matInput
              [matDatepicker]="picker"
              [min]="minDate"
              [max]="maxDate"
            />
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="form_direction.controls['date'].hasError('required')"> La date est obligatoire </mat-error>
            <mat-error *ngIf="form_direction.get('date')?.hasError('matDatepickerMax')"
              >La date maximum autorisée est le
              {{ form_direction.get('date')?.errors?.['matDatepickerMax']['max'] | date: 'dd/MM/yyyy' }}</mat-error
            >
            <mat-error *ngIf="form_direction.get('date')?.hasError('matDatepickerMin')"
              >La date minimum autorisée est le
              {{ form_direction.get('date')?.errors?.['matDatepickerMin']['min'] | date: 'dd/MM/yyyy' }}</mat-error
            >
          </mat-form-field>
        </div>

        <div class="col-3">
          <mat-form-field style="width: 100%; padding-right: 25px">
            <mat-label>Heure début</mat-label>
            <input type="time" matInput formControlName="heure_debut" (ngModelChange)="changedurationHs()" />

            <mat-error *ngIf="form_direction.controls['heure_debut'].hasError('required')"> L'heure de début is est obligatoire </mat-error>
          </mat-form-field>
        </div>

        <div class="col-3">
          <mat-form-field style="width: 100%; padding-right: 25px">
            <mat-label>Heure fin</mat-label>
            <input type="time" matInput formControlName="heure_fin" (ngModelChange)="changedurationHs()" />

            <mat-error *ngIf="form_direction.controls['heure_fin'].hasError('required')"> L'heure de fin est obligatoire </mat-error>
          </mat-form-field>
        </div>

        <div class="col-3">
          <mat-form-field style="width: 100%">
            <mat-label>Durée</mat-label>
            <input type="time" matInput [readonly]="true" formControlName="duree" />
            <mat-error *ngIf="form_direction.controls['duree'].hasError('required')"> La durée est obligatoire </mat-error>
          </mat-form-field>
        </div>

        <ng-container *ngIf="formEvenementActif">
          <div class="col-md-4">
            <mat-form-field class="example-full-width" floatLabel="always">
              <mat-label>Evènement </mat-label>
              <mat-select [(value)]="selectedEvenement" formControlName="evenement">
                <mat-option *ngFor="let item of evenements" [value]="item">{{ item.libelle }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>

        <ng-container *ngIf="formEvenementActif"> </ng-container>
      </div>
    </form>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions style="background-color: rgba(250, 250, 252, 1)" align="end">
  <button mat-stroked-button mat-dialog-close class="mx-3" color="primary">Annuler</button>
  <button [disabled]="form_direction.invalid" mat-flat-button color="primary" (click)="validerHSDirection()">Valider</button>
</mat-dialog-actions>
