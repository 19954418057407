import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsultationComponent } from 'src/app/features/consultation/consultation.component';
import { HomeComponent } from 'src/app/features/home-page/home.component';
import { BodyComponent } from './body/body.component';
import { HeaderComponent } from './header/header.component';
import { RouterOutlet } from '@angular/router';
import { RoleService } from '../services/role.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [CommonModule,  MatDatepickerModule, 
    MatNativeDateModule , RouterOutlet, ConsultationComponent, HomeComponent, BodyComponent, HeaderComponent, NgxSpinnerModule],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  userRoles: string[] = [];

  constructor(private service: RoleService) {}
  ngOnInit() {
    this.service.getRole().subscribe((role) => {
      this.userRoles = role;
    });
  }
}
