import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-ariane-nav',
  standalone: true,
  imports: [CommonModule, MatIconModule, RouterModule],
  templateUrl: './ariane-nav.component.html',
  styleUrls: ['./ariane-nav.component.css']
})
export class ArianeNavComponent {
  @Input() label: string = '';
  @Input() icon: string = '';
  @Input() arianeList: any = [];

  constructor(private router: Router) {}

  redirectHome() {
    this.router.navigate(['/', 'accueil']);
  }
}
