import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ArianeNavComponent } from 'src/app/layout/ariane-nav/ariane-nav.component';
import { MatDividerModule } from '@angular/material/divider';
import { JoursFeriesService } from 'src/app/services/jours-feries.service';
import { take } from 'rxjs';
import { DataTableComponent } from 'src/app/shared/data-table/data-table.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { environment } from 'src/environments/environment';
import { CommunicationService } from 'src/app/shared/communication.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { JoursFeriesModalComponent } from './jours-feries-modal/jours-feries-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Broadcast } from 'src/app/core/services/broadcast.service';
import { DeleteModalComponent } from 'src/app/shared/delete-modal/delete-modal.component';
import { ValidationModalComponent } from 'src/app/shared/validation-modal/validation-modal.component';
import { AlerteModalComponent } from 'src/app/shared/alerte-modal/alerte-modal.component';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomLuxonDateAdapter } from 'src/app/shared/utils/custom-luxon-date-adapter';
import { MY_FORMATS } from 'src/app/shared/utils/date-pickr-custom-luxon-format';

@Component({
  selector: 'app-admin-jours-feries',
  standalone: true,
  imports: [
    CommonModule,
    ArianeNavComponent,
    MatDividerModule,
    DataTableComponent,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './jours-feries.component.html',
  styleUrls: ['./jours-feries.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: DateAdapter, useClass: CustomLuxonDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class JoursFeriesComponent implements OnInit {
  arianeLabel: string = 'Jours Fériés';
  arianeIcon: string = 'celebration';
  pipe = new DatePipe('fr-FR'); // Use your own locale
  dataTable: any[] = [];
  rowData: any[] = [];
  keyChanges: any = {};
  untransformedData: any = [];
  dataLength: number;
  filterValue: string = '';
  arianeList = [
    {
      label: 'Administration',
      url: '/administration'
    },
    {
      label: 'Jours Fériés',
      url: '/jours-feries'
    }
  ];
  constructor(
    public joursFeriesService: JoursFeriesService,
    private refreshService: CommunicationService,
    public dialog: MatDialog,
    private detectorRef: ChangeDetectorRef,
    private broadcast: Broadcast
  ) {}
  joursFeriesDisplayedColumns: string[] = ['Libellé', 'Année', 'Date', 'Actions'];

  joursFeriesCellStyleCss: any = {
    Année: { 'text-align': 'center', 'padding-right': '30px' },
    Date: { 'text-align': 'center', 'padding-right': '30px' }
  };

  ngOnInit() {
    this.updatePagination(null);

    this.refreshService.refreshList$.subscribe((refresh) => {
      if (refresh) {
        this.updatePagination(null);
      }
    });
  }
  transformData(result: any) {
    console.log('dataJSON :', result);

    let data: any[] = [];

    for (let key in result) {
      let dataJ = result[key];
      let _data: any = {};
      _data['id'] = dataJ?.id;
      _data['Libellé'] = dataJ?.libelle;
      _data['Année'] = this.pipe.transform(dataJ?.dateJourFerier, 'yyyy');
      _data['Date'] = this.pipe.transform(dataJ?.dateJourFerier, 'shortDate');

      data.push(_data);
    }
    return data;
  }

  // modale de création
  openJourFerieDialog() {
    const dialogRef = this.dialog.open(JoursFeriesModalComponent, {
      width: '780px',
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data?.action) {
        this.updatePagination(null);
        this.detectorRef.markForCheck();

        this.broadcast.broadcastEvent({
          broadcastedevent: 'resetPagination'
        });
      }
    });
  }

  // modale de modification
  openJourFerieEdit(id: number) {
    this.searchDataID(this.untransformedData, id);

    const dialogRef = this.dialog.open(JoursFeriesModalComponent, {
      width: '780px',
      disableClose: true,
      data: { edition: true, ...this.rowData }
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data?.action) {
        this.updatePagination(null);
        this.detectorRef.markForCheck();
        this.broadcast.broadcastEvent({
          broadcastedevent: 'resetPagination'
        });
      }
    });
  }

  deleteJourFerie(id: number) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      width: '780px',
      data: {
        id: id,
        description: 'Voulez-vous supprimer ce jour férié ?',
        url: 'test'
      }
    });

    if (id != undefined && id != null) {
      dialogRef.afterClosed().subscribe((action) => {
        // lance la suppression
        if (action) {
          let data_id: number = id;

          this.joursFeriesService
            .supprimerJourFerie(data_id)
            .pipe(take(1))
            .subscribe({
              next: (result) => {
                this.dialog.open(ValidationModalComponent, {
                  width: '600px',
                  data: {
                    title: 'Confirmation',
                    content: 'Le jour férié a été supprimé avec succès.'
                  }
                });
                this.updatePagination(null);
                this.detectorRef.markForCheck();
              },
              error: (error) => {
                let erreur_texte = error.error.detail || error.error || error;

                this.dialog.open(AlerteModalComponent, {
                  width: '640px',
                  data: {
                    title: 'Alerte',
                    content: "Erreur lors de la suppression de l'enregistrement !",
                    status: 'STATUS: ' + error.status,
                    detail: 'DETAIL: ' + erreur_texte
                  }
                });
              }
            });
        }
      });
    }
  }

  // recherche la ligne data correspondant à l'id
  searchDataID(data: any, id: number) {
    let index = Object.keys(data).find((k) => data[k].id === id);

    if (index != null) {
      this.rowData = this.untransformedData[Number(index)];
    }
  }

  updateFiltre(value: any) {
    this.filterValue = value.filterValue;
    this.updatePagination(value);
  }

  updatePagination(event: any) {
    if (event) {
      this.joursFeriesService
        .getJoursFeriesWithParams(event.offset, event.limit, event.filterValue)
        .pipe(take(1))
        .subscribe({
          next: (result: any) => {
            this.untransformedData = result.results;

            this.dataTable = this.transformData(result.results);
            this.detectorRef.markForCheck();
            this.dataLength = result.totalRecords;
          },
          error: (error: any) => {
            console.log('erreur:', error);
          }
        });
    } else {
      this.joursFeriesService
        .getJoursFeriesWithParams(environment.pagination.offset, environment.pagination.limit)
        .pipe(take(1))
        .subscribe({
          next: (result: any) => {
            this.untransformedData = result.results;

            this.dataTable = this.transformData(result.results);
            this.detectorRef.markForCheck();
            this.dataLength = result.totalRecords;
          },
          error: (error: any) => {
            console.log('erreur:', error);
          }
        });
    }
  }
}
