import { Component, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ArianeNavComponent } from 'src/app/layout/ariane-nav/ariane-nav.component';
import { MatDividerModule } from '@angular/material/divider';
import { SuivieImportsService } from 'src/app/services/suivie-imports.service';
import { take } from 'rxjs';
import { DataTableComponent } from 'src/app/shared/data-table/data-table.component';
import { DetailModalComponent } from './detail-modal/detail-modal.component';
import { SoumettreFichierModalComponent } from './soumettre-fichier-modal/soumettre-fichier-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { environment } from 'src/environments/environment';
import { CommunicationService } from 'src/app/shared/communication.service';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
  selector: 'app-suivie-imports',
  standalone: true,
  imports: [
    CommonModule,
    ArianeNavComponent,
    MatDividerModule,
    DataTableComponent,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatTabsModule
  ],
  templateUrl: './suivie-imports.component.html',
  styleUrls: ['./suivie-imports.component.scss']
})
export class SuivieImportsComponent implements OnInit {
  arianeLabel: string = 'Suivi des imports et exports';
  arianeIcon: string = 'system_update_alt';
  pipe = new DatePipe('fr-FR'); // Use your own locale
  dataTable: any[] = [];
  dataTableExport: any[] = [];
  keyChanges: any = {};
  untransformedData: any = [];
  dataLength: number;
  exportDataLength: number;
  filterValue: string = '';
  arianeList = [
    {
      label: 'Administration',
      url: '/administration'
    },
    {
      label: 'Suivi des imports et exports',
      url: '/suivie-imports'
    }
  ];
  constructor(
    public suivieImportsService: SuivieImportsService,
    public dialog: MatDialog,
    private refreshService: CommunicationService
  ) {}
  SuivieImportsDisplayedColumns: string[] = ['Nom fichier', 'Date du traitement', 'Total', 'Statut', 'Actions'];

  ngOnInit() {
    this.updatePagination(null);
    this.updateExportPagination(null);
    this.refreshService.refreshList$.subscribe((refresh) => {
      if (refresh) {
        this.updatePagination(null);
        this.updateExportPagination(null);
      }
    });
  }
  transformData(result: any) {
    let data: any[] = [];

    for (let key in result) {
      let dataJ = result[key];
      let _data: any = {};
      _data['id'] = dataJ?.id;
      _data['Nom fichier'] = dataJ['nomFichier'];
      if (!dataJ['nomFichier']) {
        _data['Nom fichier'] = 'Non renseigné';
      }
      _data['Date du traitement'] = this.pipe.transform(dataJ?.dateDebut, 'd/MM/yyyy H:mm');

      _data['Total'] = dataJ?.totalAgents;
      if (dataJ?.statut == 'ECHEC') {
        _data['Statut'] = { class: 'erreur', text: 'Erreur' };
      }
      if (dataJ?.statut == 'TERMINE') {
        _data['Statut'] = { class: 'ended', text: 'Terminé' };
      }
      if (dataJ?.statut == 'EN_COURS') {
        _data['Statut'] = { class: 'in-proress', text: 'En cours' };
      }
      data.push(_data);
    }
    return data;
  }
  openDetailModal(event: string) {
    this.dialog.open(DetailModalComponent, {
      width: '960px',
      disableClose: true,
      autoFocus: false,
      data: [
        this.untransformedData.filter((elem: any) => {
          return elem.id == event;
        })
      ]
    });
  }
  /**
   * Objectif : présenter une modale pour soumettre en fichier d'agents à importer
   * par le back
   */
  importManuel() {
    const dialogRef = this.dialog.open(SoumettreFichierModalComponent, {
      width: '640px',
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('afterClosed result:', result);
    });
  }

  updatePagination(event: any) {
    if (event) {
      this.suivieImportsService
        .getAllSuivieImports(event.offset, event.limit, 'IMPORT', event.filterValue)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.untransformedData = [...this.untransformedData, ...result.results];
            this.dataLength = result.totalRecords;
            this.dataTable = this.transformData(result?.results);
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    } else {
      this.suivieImportsService
        .getAllSuivieImports(environment.pagination.offset, environment.pagination.limit, 'IMPORT')
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.untransformedData = [...this.untransformedData, ...result.results];
            this.dataLength = result.totalRecords;
            this.dataTable = this.transformData(result?.results);
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    }
  }

  updateExportPagination(event: any) {
    if (event) {
      this.suivieImportsService
        .getAllSuivieImports(event.offset, event.limit, ['EXPORT_EXCEL', 'EXPORT_PDF  '], event.filterValue)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.untransformedData = [...this.untransformedData, ...result.results];
            this.exportDataLength = result.totalRecords;
            this.dataTableExport = this.transformData(result?.results);
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    } else {
      this.suivieImportsService
        .getAllSuivieImports(environment.pagination.offset, environment.pagination.limit, ['EXPORT_EXCEL', 'EXPORT_PDF'])
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.untransformedData = [...this.untransformedData, ...result.results];
            this.exportDataLength = result.totalRecords;
            this.dataTableExport = this.transformData(result?.results);
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    }
  }
  updateFiltre(value: any) {
    if (value?.filterValue == 'erreur') {
      value.filterValue = 'ECHEC';
    }
    if (value?.filterValue == 'Terminé') {
      value.filterValue = 'TERMINE';
    }
    if (value?.filterValue == 'En cours') {
      value.filterValue = 'EN_COURS';
    }
    this.filterValue = value.filterValue;
    this.updatePagination(value);
  }
  updateFiltreExport(value: any) {
    if (value?.filterValue == 'erreur') {
      value.filterValue = 'ECHEC';
    }
    if (value?.filterValue == 'Terminé') {
      value.filterValue = 'TERMINE';
    }
    if (value?.filterValue == 'En cours') {
      value.filterValue = 'EN_COURS';
    }
    this.filterValue = value.filterValue;
    this.updateExportPagination(value);
  }
}
