<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<mat-card-header class="" style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="row col-md-12">
    <div class="col-6">
      <div class="titre-modale">Détails du nombre d'agents total</div>
    </div>

    <div class="col-md-6 text-end mb-2 mr-0 ml-auto gx-0">
      <button mat-dialog-close class="py-0" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>

<mat-dialog-content class="contenu">
  <div class="row">
    <div class="col" style="max-width: 340px">
      <mat-form-field class="mr-5" class="field-filtre">
        <mat-label>Filtrer</mat-label>
        <input matInput #input [formControl]="filterFormControl" />
      </mat-form-field>
    </div>
    <div class="col">
      <div class="description">
        <div class="table-row">
          <div class="cell-label">Direction :</div>
          <div class="cell-value">{{ libelleDirection }}</div>
        </div>
        <div class="table-row">
          <div class="cell-label">Total agents :</div>
          <div class="cell-value">{{ totalAgents }}</div>
        </div>
      </div>
    </div>
  </div>

  <mat-divider class="my-3"></mat-divider>

  <app-data-table
    #AgentDataTable
    [displayedColumns]="displayedColumns"
    [cellStyleCss]="cellStyleCss"
    [data]="rowData"
    [filter]="false"
    [formatter]="transformData"
    [hasPagination]="false"
  ></app-data-table>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close class="bouton-fermer">Fermer</button>
</mat-dialog-actions>
