import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { AgentsService } from 'src/app/services/agents.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { delay } from 'rxjs';
import { CommunicationService } from 'src/app/shared/communication.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSelectModule } from '@angular/material/select';
import { FormBuilder, FormsModule, ReactiveFormsModule, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-soumettre-fichier-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatCardModule,
    MatIconModule,
    MatSnackBarModule,
    DragDropModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './soumettre-fichier-modal.component.html',
  styleUrls: ['./soumettre-fichier-modal.component.scss']
})
export class SoumettreFichierModalComponent implements OnInit {
  /**
   * Le contenu du fichier à importer
   */
  fichierAgentsName: string;
  fileData: any;
  typeSelect: any = 'agents';

  formImport: FormGroup = this.formBuilder.group({
    type: ['agents', [Validators.required]]
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private agentsService: AgentsService,
    private spinner: NgxSpinnerService,
    private _snackBar: MatSnackBar,
    private notifyService: CommunicationService,
    public dialogRef: MatDialogRef<SoumettreFichierModalComponent>,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.fichierAgentsName = '';
    this.fileData = null;
  }

  onFileSelected() {
    let fileTypes = ['csv'];

    const inputNode: any = document.querySelector('#file');

    let fullPath = inputNode.value;
    if (fullPath) {
      let extension = fullPath.split('.').pop().toLowerCase(), //file extension from input file
        isSuccess = fileTypes.indexOf(extension) > -1; //is extension in acceptable types

      let fileNameMorceaux = fullPath.split('\\');

      if (!isSuccess) {
        alert('Vous devez choisir un fichier au format csv');
        return;
      }

      this.fichierAgentsName = fileNameMorceaux[fileNameMorceaux.length - 1];
      this.fileData = inputNode.files[0];
    } else {
      alert('Impossible de contrôler le nom du fichier choisi');
    }
  }

  validationModale() {
    this.spinner.show();

    /**
     * Appel au service d'import de fichier
     */

    // import des agents
    if (this.typeSelect == 'agents') {
      this.agentsService
        .importAgentFile(this.fileData, this.fichierAgentsName)
        .pipe(delay(1000))
        .subscribe({
          next: (result) => {
            console.log('fin import agent file');
            this.spinner.hide();
            this._snackBar.open('Fichier agent soumis avec succès', 'OK');
            this.dialogRef.close();
            this.notifyService.triggerRefresh();
          },
          error: (error) => {
            this.spinner.hide();
            this._snackBar.open('Erreur lors de la soumission du fichier agent', 'OK');
          }
        });

      // import des organisations
    } else if (this.typeSelect == 'organisations') {
      this.agentsService
        .importOrganisationFile(this.fileData, this.fichierAgentsName)
        .pipe(delay(1000))
        .subscribe({
          next: (result) => {
            console.log('fin import organisation file');
            this.spinner.hide();
            this._snackBar.open('Fichier organisation soumis avec succès', 'OK');
            this.dialogRef.close();
            this.notifyService.triggerRefresh();
          },
          error: (error) => {
            this.spinner.hide();
            this._snackBar.open('Erreur lors de la soumission du fichier organisation', 'OK');
          }
        });
    }
  }
}
