<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<mat-card-header class="" style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="row col-md-12">
    <div class="col-6">
      <h2 *ngIf="data.type == 'IMPORT'" class="titre-modale">Détails de l'import</h2>
      <h2 *ngIf="data.type != 'IMPORT'" class="titre-modale">Détails de l'export</h2>
    </div>
    <div class="col-md-6 text-end mb-2 mr-0 ml-auto gx-0">
      <button mat-dialog-close class="py-0" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>

<mat-dialog-content class="contenu">
  <div class="titre-modale text-black" *ngIf="data.type == 'IMPORT'">Propriétés de l'import</div>
  <div class="titre-modale text-black" *ngIf="data.type != 'IMPORT'">Propriétés de l'export</div>
  <mat-divider class="my-3"></mat-divider>
  <div class="row">
    <div class="col-3">Type :</div>
    <div class="col-9 text-black">{{ data.type }}</div>
  </div>
  <div class="row mt-3">
    <div class="col-3">Etat :</div>
    <div class="col-9 text-black erreur text-white" *ngIf="data.statut == 'ECHEC'">Erreur</div>
    <div class="col-9 text-black ended text-white" *ngIf="data.statut == 'TERMINE'">Terminé</div>
    <div class="col-9 text-black in-proress" *ngIf="data.statut == 'EN_COURS'">En cours</div>
  </div>

  <div class="row mt-3">
    <div class="col-3">Date début traitement :</div>
    <div class="col-9 text-black">{{ dateDebut }}</div>
  </div>
  <div class="row mt-3">
    <div class="col-3">Durée du traitement :</div>
    <div class="col-9 text-black">{{ dureeTraitement }}</div>
  </div>
  <div class="row mt-3">
    <div class="col-3">Compte rendu :</div>
    <div class="col-9 text-black code-comment">
      <code>{{ data.commentaire }}</code>
    </div>
  </div>
  <mat-divider class="my-3"></mat-divider>
  <div class="titre-modale mt-10 text-black">Fichier</div>

  <div class="row mt-3">
    <div class="col-3">Nom du fichier :</div>
    <div class="col-9 text-black">
      <div class="col-9 text-black">{{ data.nomFichier }}</div>
    </div>
  </div>
  <div class="row mt-3" *ngIf="data.nomFichier !== 'Non renseigné'">
    <div class="col-3">Télécharger le fichier :</div>
    <div *ngIf="data.statut == 'TERMINE'" class="col-9" style="color: #007fff; position: relative; cursor: pointer" (click)="download()">
      <mat-icon class="icon-helper" *ngIf="data.nomFichier == 'EXPORT_EXCEL' || data.nomFichier == 'EXPORT_PDF'">download</mat-icon>
      {{ data.nomFichier }}
    </div>
    <div class="col-9 text-black" *ngIf="data.statut == 'ECHEC' || data.statut == 'EN_COURS'">Aucun fichier à télécharger</div>
  </div>

  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary" mat-dialog-close class="bouton-fermer">Fermer</button>
  </mat-dialog-actions>
</mat-dialog-content>
