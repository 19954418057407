<div class="card my-3" class="div-agent">
  <div class="card-body pr-0">
    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4">
      <div class="col-3">
        <b class="text-red">Formulaire {{ currentIndex + 1 }}</b> / {{ nbFormulaires }}
      </div>
      <div class="col-2" style="padding-left: 0">
        <div>Total Astreinte : {{ totalJour }}</div>
      </div>
      <div class="col-3 div-parcourir">
        <button
          class="bt-precedent"
          matTooltip="Formulaire précédent"
          mat-icon-button
          color="primary"
          (click)="showPrevData()"
          [disabled]="currentIndex == 0"
        >
          <mat-icon>arrow_backward</mat-icon>
        </button>
        <div class="div-label" #label (focus)="label.blur()">Parcourir</div>
        <button
          class="bt-suivant"
          matTooltip="Formulaire suivant"
          mat-icon-button
          color="primary"
          (click)="showNextData()"
          [disabled]="currentIndex >= nbFormulaires - 1 || !actionsActives"
        >
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
      <div class="col-4 div-delete">
        <div class="d-flex div-delete border-r">
          <button
            mat-stroked-button
            color="primary"
            style="text-wrap: nowrap; border: none; padding: 13px"
            class="ml-auto"
            (click)="ajoutFormulaire()"
            [disabled]="!actionsActives"
          >
            <mat-icon>add</mat-icon>Ajouter un formulaire
          </button>
        </div>

        <div class="ml-auto">
          <button
            class="bt-ajouter"
            matTooltip="Supprimer ce formulaire"
            mat-icon-button
            color="primary"
            (click)="suppressionFormulaire()"
            [disabled]="nbFormulaires < 2"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
