<mat-card-header class="">
  <div class="row col-md-12">
    <div class="col-6">
      <div *ngIf="viewMode === 'edit' || viewMode === 'read'" class="titre-modale">
        Gérer les délégations <span>({{ firstName }} {{ lastname }})</span>
      </div>
    </div>
    <div class="col-md-6 text-end mb-2 mr-0 ml-auto gx-0">
      <button (click)="onBackToAgentList()" class="py-0" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="col-6" style="position: absolute">
      <div *ngIf="viewMode === 'user'" class="titre-modale">
        Gérer mes délégations <span>({{ userName }})</span>
      </div>
    </div>
  </div>
</mat-card-header>
<mat-dialog-content class="mat-typography">
  <mat-divider class="my-3"></mat-divider>

  <div class="div-contenu">
    <div class="row mt-3">
      <div class="col">
        <mat-form-field class="mr-5">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="appDataTable.applyFilter($event)" #input />
        </mat-form-field>
      </div>
      <div class="col flex-container end align-items-center">
        <div>
          <button
            *ngIf="viewMode === 'edit' || viewMode === 'user'"
            mat-raised-button
            class="bt-table-defaut bt-table-ajouter mr-5"
            (click)="openAgentDialog()"
          >
            <mat-icon>add</mat-icon> Ajouter une délégation
          </button>
        </div>
      </div>
    </div>

    <div class="mt-1" *ngIf="viewMode === 'edit'">
      <app-data-table
        #appDataTable
        [displayedColumns]="displayedColumns"
        [cellStyleCss]="cellStyleCss"
        [data]="delegationList"
        [expendable]="false"
        [hasView]="false"
        [editable]="true"
        [delatable]="true"
        [filter]="false"
        (deleteElement)="deleteDelegue($event, false)"
        (clicModalModifier)="modifyDelegate($event)"
        [length]="lenght"
        (updatePagination)="updatePagination($event)"
        (updateFiltre)="updateFiltre($event)"
        [customFilter]="true"
      ></app-data-table>
    </div>

    <div class="mt-1" *ngIf="viewMode === 'read'">
      <app-data-table
        #appDataTable
        [displayedColumns]="displayedViewColumns"
        [cellStyleCss]="cellStyleCss"
        [data]="delegationList"
        [expendable]="false"
        [hasView]="false"
        [editable]="false"
        [delatable]="false"
        [filter]="false"
        (deleteElement)="deleteDelegue($event, false)"
        [length]="lenght"
        (updatePagination)="updatePagination($event)"
        (updateFiltre)="updateFiltre($event)"
        [customFilter]="true"
      ></app-data-table>
    </div>
    <div class="mt-1" *ngIf="viewMode === 'user'">
      <app-data-table
        #appDataTable
        [displayedColumns]="displayedUserColumns"
        [cellStyleCss]="cellStyleCss"
        [data]="delegationList"
        [expendable]="false"
        [hasView]="false"
        [editable]="true"
        [delatable]="true"
        [filter]="false"
        (clicModalModifier)="modifyDelegate($event)"
        (deleteElement)="deleteDelegue($event, true)"
        [length]="lenght"
        (updatePagination)="updatePagination($event)"
        (updateFiltre)="updateFiltre($event)"
        [customFilter]="true"
      ></app-data-table>
    </div>
  </div>
</mat-dialog-content>
