<div class="d-flex alert-container">
  <mat-icon class="mr-5 icone-warning">warning</mat-icon>
  <span class="mt-2">
    Un filtre par campagne est appliqué par défaut sur
    <br />
    la liste des saisies HS et Astreintes. Vous pouvez le
    <br />
    désactiver depuis le bouton “Filtré”
  </span>
  <button mat-icon-button class="close-icon" (click)="closePopup($event)">
    <mat-icon>close</mat-icon>
  </button>
</div>
