import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { CampagneActuService } from './campagne-actuelle.service';
import { Observable, of, Subscription } from 'rxjs';
import { MatDividerModule } from '@angular/material/divider';
import { Broadcast } from 'src/app/core/services/broadcast.service';

@Component({
  selector: 'app-campagne-actuelle',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatIconModule, MatDividerModule],
  templateUrl: './campagne-actuelle.component.html',
  styleUrls: ['./campagne-actuelle.component.css']
})
export class CampagneActuelleComponent implements OnInit, OnDestroy {
  dataJSON: any[] = [];
  private subscription: Subscription;

  dateDebut_a$: Observable<any> = of('');
  dateFin_a$: Observable<any> = of('');
  nom_a$: Observable<any> = of('');
  type_a$: Observable<any> = of('');
  titre_a$: Observable<any> = of('');

  dateDebut_p$: Observable<any> = of('');
  dateFin_p$: Observable<any> = of('');
  nom_p$: Observable<any> = of('');
  type_p$: Observable<any> = of('');
  titre_p$: Observable<any> = of('');

  constructor(
    private campagneActuService: CampagneActuService,
    private broadcast: Broadcast
  ) {
    this.subscription = this.broadcast.event$.subscribe((data) => {
      this.getCompagne();
    });
  }

  ngOnInit() {
    this.getCompagne();
  }
  getCompagne() {
    this.campagneActuService.getCampagneActuelle();

    // campagne actuelle
    this.dateDebut_a$ = this.campagneActuService.dateDebut_a$;
    this.dateFin_a$ = this.campagneActuService.dateFin_a$;
    this.nom_a$ = this.campagneActuService.nom_a$;
    this.type_a$ = this.campagneActuService.type_a$;
    this.titre_a$ = this.campagneActuService.titre_a$;

    // campagne prochaine
    this.dateDebut_p$ = this.campagneActuService.dateDebut_p$;
    this.dateFin_p$ = this.campagneActuService.dateFin_p$;
    this.nom_p$ = this.campagneActuService.nom_p$;
    this.type_p$ = this.campagneActuService.type_p$;
    this.titre_p$ = this.campagneActuService.titre_p$;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
