<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<mat-card-header class="" style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="row col-md-12">
    <div class="col-6" style="padding-left: 35px; padding-top: 10px">
      <h2 class="titre-modale">Import de données</h2>
    </div>
    <div class="col-md-6 text-end mb-2 mr-0 ml-auto gx-0">
      <button mat-dialog-close class="py-0" mat-icon-button aria-label="Fermer la modale">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>

<mat-dialog-content class="contenu mt-1" style="padding-top: 0">
  <form [formGroup]="formImport">
    <div class="row">
      <mat-form-field class="col-6">
        <mat-label>Type de fichier </mat-label>
        <mat-select formControlName="type" [(value)]="typeSelect">
          <mat-option value="agents">Agents</mat-option>
          <mat-option value="organisations">Organisations</mat-option>
        </mat-select>
        <mat-error *ngIf="formImport.controls['type'].hasError('required')"> Le type est obligatoire </mat-error>
      </mat-form-field>
    </div>
    <div class="row mt-3">
      <div class="col-5">Fichier à importer :</div>
      <div class="col-7 text-black">
        <div class="col-9 text-black">
          <button type="button" mat-raised-button (click)="fileInput.click()">Choisir un fichier</button>
          <input hidden (change)="onFileSelected()" #fileInput type="file" id="file" />
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="fichierAgentsName">
      <div class="col-5">&nbsp;</div>
      <div class="col-7 text-black">
        {{ fichierAgentsName }}
      </div>
    </div>
    <div class="row mt-3">
      <h5>Le fichier doit être un fichier CSV au même format que celui qui fournit le référentiel des agents ou des organisations.</h5>
    </div>
  </form>
  <mat-dialog-actions align="end">
    <button mat-raised-button style="margin-right: 10px" mat-dialog-close class="bouton-fermer">Annuler</button>
    <button mat-raised-button color="primary" (click)="validationModale()" [disabled]="!this.fichierAgentsName">Valider</button>
  </mat-dialog-actions>
</mat-dialog-content>
