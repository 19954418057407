import { Component, Inject, AfterViewInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { take } from 'rxjs';
import { CampagnesService } from 'src/app/services/campagnes.service';
import { AlerteModalComponent } from 'src/app/shared/alerte-modal/alerte-modal.component';
import { MatSelectModule } from '@angular/material/select';
import { DateTime } from 'luxon';
import { Broadcast } from 'src/app/core/services/broadcast.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ValidationModalComponent } from 'src/app/shared/validation-modal/validation-modal.component';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';

import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { CustomLuxonDateAdapter } from 'src/app/shared/utils/custom-luxon-date-adapter';
import { MY_FORMATS } from 'src/app/shared/utils/date-pickr-custom-luxon-format';
import { DragDropModule } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-campagne-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatRadioModule,
    MatIconModule,
    MatCardModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatAutocompleteModule,
    ValidationModalComponent,
    ConfirmModalComponent,
    MatNativeDateModule,
    DragDropModule
  ],
  templateUrl: './campagne-modal.component.html',
  styleUrls: ['./campagne-modal.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: DateAdapter, useClass: CustomLuxonDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class CampagneModalComponent implements AfterViewInit {
  rowData: any = {};
  dateP = new DatePipe('fr-FR');
  action: string = '';
  titre_action: string = 'Ajouter';
  selectedType: string = '';
  dataCampagne: any = '';
  texte_erreur_date: string = '';
  minDateDebutSaisie: Date;
  minDateFinSaisie: Date;
  minDateDebutValidation: Date;
  minDateFinValidation: Date;
  minDateArchivage: Date;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CampagneModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dateAdapter: DateAdapter<any>,
    private campagneService: CampagnesService,
    private broadcast: Broadcast,
    private spinner: NgxSpinnerService
  ) {}

  form: FormGroup = this.formBuilder.group({
    nom: [this.data['nom'], [Validators.required]],
    date_debut_saisie: ['', [Validators.required]],
    date_fin_saisie: ['', [Validators.required]],
    date_debut_validation: ['', [Validators.required]],
    date_fin_validation: ['', [Validators.required]],
    date_archivage: ['', [Validators.required]],
    commentaire: [this.data['commentaire']]
  });

  ngOnInit() {
    this.dateAdapter.setLocale('fr-FR');
    this.rowData = this.data;
    this.loadCampagnesList();
    this.initForm();
  }

  ngAfterViewInit() {
    // initialise les vérification de champs après la création des champs
    this.form.controls['date_debut_saisie'].addValidators([verifDateSaisie(this)]);
    this.form.controls['date_fin_saisie'].addValidators([verifDateSaisie(this)]);
    this.form.controls['date_debut_validation'].addValidators([verifDateValidation(this)]);
    this.form.controls['date_fin_validation'].addValidators([verifDateValidation(this)]);
    this.form.controls['date_archivage'].addValidators([verifDateArchivage(this)]);
  }

  // actualise les messages d'erreur des champs date
  changeDate(event: any) {
    this.form.controls['date_debut_saisie'].updateValueAndValidity();
    this.form.controls['date_fin_saisie'].updateValueAndValidity();
    if (event.targetElement.id == 'date_fin_saisie') {
      this.dateFinSaisieAuto();
    }
    this.form.controls['date_debut_validation'].updateValueAndValidity();
    this.form.controls['date_fin_validation'].updateValueAndValidity();

    if (event.targetElement.id == 'date_fin_validation') {
      this.dateArchivageAuto();
    }
    this.form.controls['date_archivage'].updateValueAndValidity();

    this.desactiveJourCalendrier(event);
  }

  dateArchivageAuto() {
    let date_fin_validation = this.form.controls['date_fin_validation'].value;

    if (date_fin_validation) {
      console.log('called');

      let date = new Date(date_fin_validation);
      let dateFin_dt = DateTime.fromJSDate(date);
      let date_archivage_dt = dateFin_dt.plus({ days: 1 }).toFormat('yyyy-MM-dd');
      let date_archivage_val = new Date(date_archivage_dt).toISOString();
      this.form.controls['date_archivage'].setValue(date_archivage_val);
      this.form.controls['date_archivage'].updateValueAndValidity();
    }
  }

  // date de début validation suivant date de fin de saisie
  dateFinSaisieAuto() {
    let date_fin_validation = this.form.controls['date_fin_saisie'].value;
    if (date_fin_validation) {
      let date = new Date(date_fin_validation);
      let dateFin_dt = DateTime.fromJSDate(date);

      // si date de fin saisie, date de début validation = jour + 1
      let date_debut_dt = dateFin_dt.plus({ days: 1 }).toFormat('yyyy-MM-dd');
      this.form.controls['date_debut_validation'].setValue(date_debut_dt);
      this.form.controls['date_debut_validation'].updateValueAndValidity();

      let minDateFinValidation = dateFin_dt.plus({ days: 1 }).toFormat('yyyy-MM-dd');
      this.minDateFinValidation = new Date(minDateFinValidation);
    }
  }
  // chargement des dates dans les datepicker
  initForm() {
    let _data = this.data;
    console.log('initForm _data:', _data);

    // par défaut : en mode ajout
    this.action = 'ajouter';

    if (Object.keys(_data).length > 0) {
      if (_data['edition']) {
        this.titre_action = 'Modifier';
        this.action = 'modifier';
      } else {
        this.action = 'ajouter';
      }

      this.selectedType = this.data['type'];
      this.dateAdapter.setLocale('fr-FR');

      if (_data['dateDebutSaisie'] != null) {
        let date_d = _data['dateDebutSaisie'];
        let date_debut = new Date(date_d).toISOString();
        this.form.controls['date_debut_saisie'].setValue(date_debut);
      }

      if (this.data['dateFinSaisie'] != null) {
        let date_f = _data['dateFinSaisie'];
        let date_fin = new Date(date_f).toISOString();
        this.form.controls['date_fin_saisie'].setValue(date_fin);
      }

      if (_data['dateDebutValidation'] != null) {
        let date_d = _data['dateDebutValidation'];
        let date_debut = new Date(date_d).toISOString();
        this.form.controls['date_debut_validation'].setValue(date_debut);
      }

      if (this.data['dateFinValidation'] != null) {
        let date_f = _data['dateFinValidation'];
        let date_fin = new Date(date_f).toISOString();
        this.form.controls['date_fin_validation'].setValue(date_fin);
      }

      if (_data['dateArchivage'] != null) {
        let date_d = _data['dateArchivage'];
        let date_debut = new Date(date_d).toISOString();
        this.form.controls['date_archivage'].setValue(date_debut);
      }
    }

    this.desactiveDate();
    this.desactiveJourCalendrier();
  }

  // désactive les champs date
  desactiveDate() {
    let _data = this.data;
    let date_jour_s = DateTime.now().toLocaleString();
    let date_jour = DateTime.fromISO(date_jour_s);

    // modification
    if (_data['edition']) {
      let saisie_date_deb = DateTime.fromISO(_data['dateDebutSaisie']);
      if (saisie_date_deb.isValid && saisie_date_deb < date_jour) {
        this.form.controls['date_debut_saisie'].disable();
      }

      let saisie_date_fin = DateTime.fromISO(_data['dateFinSaisie']);
      if (saisie_date_deb.isValid && saisie_date_fin < date_jour) {
        this.form.controls['date_fin_saisie'].disable();
      }

      let valid_date_deb = DateTime.fromISO(_data['dateDebutValidation']);
      if (saisie_date_deb.isValid && valid_date_deb < date_jour) {
        this.form.controls['date_debut_validation'].disable();
      }

      let valid_date_fin = DateTime.fromISO(_data['dateFinValidation']);
      if (saisie_date_deb.isValid && valid_date_fin < date_jour) {
        this.form.controls['date_fin_validation'].disable();
      }

      let archivage_date = DateTime.fromISO(_data['dateArchivage']);
      if (saisie_date_deb.isValid && archivage_date < date_jour) {
        this.form.controls['date_archivage'].disable();
      }
    }
    // creation
    else {
      this.minDateDebutSaisie = new Date();
    }
  }

  // grise les jours du calendrier suivant les dates possibles
  desactiveJourCalendrier(event?: any) {
    let _data = this.data;
    let id, val;
    if (event != undefined) {
      id = event.targetElement.id;

      val = event.target.value;
    }

    let saisie_date_deb = event != undefined && id == 'date_debut_saisie' ? val : DateTime.fromISO(_data['dateDebutSaisie']);
    if (saisie_date_deb.isValid) {
      let minDateFinSaisie = saisie_date_deb.toFormat('yyyy-MM-dd');
      this.minDateFinSaisie = new Date(minDateFinSaisie);
    }

    let saisie_date_fin = event != undefined && id == 'date_fin_saisie' ? val : DateTime.fromISO(_data['dateFinSaisie']);
    if (saisie_date_fin.isValid) {
      let minDateDebutValidation = saisie_date_fin.plus({ days: 1 }).toFormat('yyyy-MM-dd');
      this.minDateDebutValidation = new Date(minDateDebutValidation);
    }

    let valid_date_deb = event != undefined && id == 'date_debut_validation' ? val : DateTime.fromISO(_data['dateDebutValidation']);
    if (valid_date_deb.isValid) {
      let minDateFinValidation = valid_date_deb.plus({ days: 0 }).toFormat('yyyy-MM-dd');
      this.minDateFinValidation = new Date(minDateFinValidation);
    }

    let valid_date_fin = event != undefined && id == 'date_fin_validation' ? val : DateTime.fromISO(_data['dateFinValidation']);
    if (valid_date_fin.isValid) {
      let minDateArchivage = valid_date_fin.plus({ days: 1 }).toFormat('yyyy-MM-dd');
      this.minDateArchivage = new Date(minDateArchivage);
    }
  }

  // liste pour le menu campagne associée
  loadCampagnesList() {
    this.campagneService
      .getCampagnesWithParams(environment.pagination.offset, environment.pagination.limit)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          console.log('getAllCampagnes result:', result);

          // campagne
          this.dataCampagne = result.results;
        },
        error: (error) => {
          console.log('erreur:', error);
        }
      });
  }

  fermerModale() {
    // modification
    if (this.data['edition']) {
      this.dialogRef.close({ action: false });
    }
    // création
    else {
      this.confirmQuitterModale();
    }
  }

  confirmQuitterModale() {
    // création uniquement
    if (!this.data['edition']) {
      if (this.form.pristine) {
        const dialogConfirm = this.dialog.open(ConfirmModalComponent, {
          width: '600px',
          data: {
            title: 'Confirmation',
            content1: 'Souhaitez vous abandonner la création de la campagne ?',
            content2: 'Les données que vous avez saisies ne seront pas enregistrées.'
          }
        });

        dialogConfirm.afterClosed().subscribe((data) => {
          if (data) {
            this.dialogRef.close({ action: false });
          }
        });
      }
    }
  }

  validerCampagne() {
    let campagneExistante = this.verifCampagneExistante();

    // aucune campagne existante sur la mêmep période
    if (!campagneExistante) {
      if (this.action == 'ajouter') {
        this.ajouterCampagne();
      } else {
        this.modifierCampagne();
      }
    }

    // campagne existante trouvée, affiche une erreur
    else {
      console.log('campagneExistante:', campagneExistante);

      this.dialog.open(AlerteModalComponent, {
        width: '640px',
        data: {
          title: 'Alerte',
          content: 'Il existe déjà une campagne pour cette période !'
        }
      });
    }
  }

  // Ajouter une campagne
  ajouterCampagne() {
    let _data: any = {};

    _data['nom'] = this.form.controls['nom'].value;

    let c_date_debut = this.form.controls['date_debut_saisie'].value;
    if (c_date_debut) {
      let date = new Date(c_date_debut);
      let date_dt = DateTime.fromJSDate(date);
      _data['dateDebutSaisie'] = date_dt.setLocale('fr').toFormat('yyyy-LL-dd');
    } else {
      _data['dateDebutSaisie'] = c_date_debut;
    }

    let c_date_fin = this.form.controls['date_fin_saisie'].value;
    if (c_date_fin) {
      let date = new Date(c_date_fin);
      let date_dt = DateTime.fromJSDate(date);
      _data['dateFinSaisie'] = date_dt.setLocale('fr').toFormat('yyyy-LL-dd');
    } else {
      _data['dateFinSaisie'] = c_date_fin;
    }

    let c_date_debut_valid = this.form.controls['date_debut_validation'].value;
    if (c_date_debut_valid) {
      let date = new Date(c_date_debut_valid);
      let date_dt = DateTime.fromJSDate(date);
      _data['dateDebutValidation'] = date_dt.setLocale('fr').toFormat('yyyy-LL-dd');
    } else {
      _data['dateDebutValidation'] = c_date_debut_valid;
    }

    let c_date_fin_valid = this.form.controls['date_fin_validation'].value;
    if (c_date_fin_valid) {
      let date = new Date(c_date_fin_valid);
      let date_dt = DateTime.fromJSDate(date);
      _data['dateFinValidation'] = date_dt.setLocale('fr').toFormat('yyyy-LL-dd');
    } else {
      _data['dateFinValidation'] = c_date_fin_valid;
    }

    let c_date_archivage = this.form.controls['date_archivage'].value;
    if (c_date_archivage) {
      let date = new Date(c_date_archivage);
      let date_dt = DateTime.fromJSDate(date);
      _data['dateArchivage'] = date_dt.setLocale('fr').toFormat('yyyy-LL-dd');
    } else {
      _data['dateArchivage'] = c_date_archivage;
    }

    // TEMP
    _data['statut'] = 'A_VENIR';

    this.broadcast.broadcastEvent({
      date_debut: _data['dateDebutSaisie'],
      date_fin: _data['dateFinSaisie']
    });

    _data['commentaire'] = this.form.controls['commentaire'].value;

    _data['statut'] = 'A_VENIR';

    console.log('ajouterCampagne _data:', _data);

    this.campagneService
      .ajouterCampagne(_data)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          console.log('result ok: ', result);
          this.spinner.hide();
          this.dialogRef.close({ action: true });
          this.dialog.open(ValidationModalComponent, {
            width: '600px',
            data: {
              title: 'Confirmation',
              content: 'La campagne a été créée avec succès.'
            }
          });
        },
        error: (error) => {
          console.log('error:', error);

          let erreur_texte = error.error.detail || error.error || error.statusText || error;

          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: 'Erreur lors de la création de la campagne !',
              status: 'STATUS: ' + error.status,
              detail: 'DETAIL: ' + erreur_texte
            }
          });
        }
      });
  }

  // Modifier une campagne
  modifierCampagne() {
    let _data: any = {};

    // dateCreation, id ,emetteur , lastModifiedDate, createdBy, lastModifiedBy,

    // champs système non modifiables
    _data['id'] = this.rowData.id;
    _data['dateCreation'] = this.rowData?.dateCreation;
    _data['emetteur'] = this.rowData?.emetteur;
    _data['lastModifiedDate'] = this.rowData?.lastModifiedDate;
    _data['createdBy'] = this.rowData?.createdBy;
    _data['lastModifiedBy'] = this.rowData?.lastModifiedBy;
    _data['statut'] = this.rowData?.statut;

    // champs de saisie modifiables
    _data['nom'] = this.form.controls['nom'].value;

    let c_date_debut = this.form.controls['date_debut_saisie'].value;
    if (c_date_debut) {
      let date = new Date(c_date_debut);
      let date_dt = DateTime.fromJSDate(date);
      _data['dateDebutSaisie'] = date_dt.setLocale('fr').toFormat('yyyy-LL-dd');
    } else {
      _data['dateDebutSaisie'] = c_date_debut;
    }

    let c_date_fin = this.form.controls['date_fin_saisie'].value;
    if (c_date_fin) {
      let date = new Date(c_date_fin);
      let date_dt = DateTime.fromJSDate(date);
      _data['dateFinSaisie'] = date_dt.setLocale('fr').toFormat('yyyy-LL-dd');
    } else {
      _data['dateFinSaisie'] = c_date_fin;
    }

    let c_date_debut_valid = this.form.controls['date_debut_validation'].value;
    if (c_date_debut_valid) {
      let date = new Date(c_date_debut_valid);
      let date_dt = DateTime.fromJSDate(date);
      _data['dateDebutValidation'] = date_dt.setLocale('fr').toFormat('yyyy-LL-dd');
    } else {
      _data['dateDebutValidation'] = c_date_debut_valid;
    }

    let c_date_fin_valid = this.form.controls['date_fin_validation'].value;
    if (c_date_fin_valid) {
      let date = new Date(c_date_fin_valid);
      let date_dt = DateTime.fromJSDate(date);
      _data['dateFinValidation'] = date_dt.setLocale('fr').toFormat('yyyy-LL-dd');
    } else {
      _data['dateFinValidation'] = c_date_fin_valid;
    }

    let c_date_archivage = this.form.controls['date_archivage'].value;
    if (c_date_archivage) {
      let date = new Date(c_date_archivage);
      let date_dt = DateTime.fromJSDate(date);
      _data['dateArchivage'] = date_dt.setLocale('fr').toFormat('yyyy-LL-dd');
    } else {
      _data['dateArchivage'] = c_date_archivage;
    }

    this.broadcast.broadcastEvent({
      date_debut: _data['dateDebut'],
      date_fin: _data['dateFin']
    });
    _data['commentaire'] = this.form.controls['commentaire'].value;

    console.log('modifierCampagne _data:', _data);

    this.campagneService
      .modifierCampagne(_data, _data['id'])
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          console.log('result ok: ', result);
          this.spinner.hide();
          this.dialogRef.close({ action: true });
          this.dialog.open(ValidationModalComponent, {
            width: '600px',
            data: {
              title: 'Confirmation',
              content: 'La campagne a été modifiée avec succès.'
            }
          });
        },
        error: (error) => {
          console.log('error: ', error);
          let erreur_texte = error.error.detail || error.error || error.statusText || error;

          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: 'Erreur lors de la modification de cette campagne !',
              status: 'STATUS: ' + error.status,
              detail: 'DETAIL: ' + erreur_texte
            }
          });
        }
      });
  }

  get hours(): any {
    return 1;
  }

  // vérification si les périodes ne se chevauchent pas
  // date de début existante < date de début en cours de saisie ET date de début en cours de saisie < date de fin existante
  // OU date de début existante < date de fin en cours de saisie ET date de fin en cours de saisie < date de fin existante
  // (dateDebutSaisie >= dateDebutSaisie Existante && dateDebutSaisie < dateFinValidation Existante) || (dateFinValidation <= dateFinValidation Existante && dateFinValidation > dateDebutSaisie Existante)
  verifCampagneExistante() {
    // champs de saisie date début
    let c_date_debut = this.form.controls['date_debut_saisie'].value;
    let date_d = new Date(c_date_debut);
    let dateDebut_c = DateTime.fromJSDate(date_d);

    // champs de saisie date fin validation
    let c_date_fin = this.form.controls['date_fin_validation'].value;
    let date_f = new Date(c_date_fin);
    let dateFinV_c = DateTime.fromJSDate(date_f);

    const _campagnes = this.dataCampagne;

    console.log('_campagnes:', _campagnes);

    let id_c = this.data['id'];

    let campageExistante = false;

    if (_campagnes.length > 0 && dateDebut_c.isValid && dateFinV_c.isValid) {
      _campagnes.every((ligne: any) => {
        let dateDebut = DateTime.fromISO(ligne?.dateDebutSaisie);
        let dateFinValidation = DateTime.fromISO(ligne?.dateFinValidation);
        let id = ligne.id;

        // vérification des intervales de date
        if (dateDebut.isValid && dateFinValidation.isValid && id != id_c) {
          /*
              (dateDebutSaisie >= dateDebutSaisie Existante && dateDebutSaisie < dateFinValidation Existante) 
              || (dateFinValidation <= dateFinValidation Existante && dateFinValidation > dateDebutSaisie Existante)
              */

          if (
            (dateDebut_c >= dateDebut && dateDebut_c < dateFinValidation) ||
            (dateFinV_c <= dateFinValidation && dateFinV_c > dateDebut)
          ) {
            campageExistante = true;
            return false;
          }
        }

        return true;
      });
    }

    return campageExistante;
  }

  /* 
  verifIntervaleDate1(control: AbstractControl): Observable<ValidationErrors | null> {

    console.log("VerifIntervaleDate control", control);
  
    if (control.value !== undefined) {

      let result = false;

        if (!result) {
        // erreur de validation
        return of({ 'erreurIntervaleDate': true, 'requiredValue': 10 });
      }
    }

    // aucune erreur
    return of(null);
  }
  */
}

// vérification des dates de saisie
export function verifDateSaisie(_this: any): ValidatorFn {
  return (control: AbstractControl): null | ValidationErrors => {
    let c_date_debut = _this.form.controls['date_debut_saisie'].value;
    let dateDebut: any = '';
    if (c_date_debut) {
      let date = new Date(c_date_debut);
      dateDebut = DateTime.fromJSDate(date).startOf('day');
    }

    let c_date_fin = _this.form.controls['date_fin_saisie'].value;
    let dateFin: any = '';
    if (c_date_fin) {
      let date = new Date(c_date_fin);
      dateFin = DateTime.fromJSDate(date).startOf('day');
    }

    // la date début de campagne doit être inférieure à la date fin de campagne
    // la date fin de campagne doit être supérieure à la date début de campagne
    if (dateDebut.isValid && dateFin.isValid) {
      if (dateDebut > dateFin) {
        return { erreurDateSaisie: control.value };
      }
    }

    return null;
  };
}

// vérification des dates de validation
export function verifDateValidation(_this: any): ValidatorFn {
  return (control: AbstractControl): null | ValidationErrors => {
    let c_date_debut = _this.form.controls['date_debut_validation'].value;
    let dateDebut: any = '';
    if (c_date_debut) {
      let date = new Date(c_date_debut);
      dateDebut = DateTime.fromJSDate(date).startOf('day');
    }

    let c_date_fin = _this.form.controls['date_fin_validation'].value;
    let dateFin: any = '';
    if (c_date_fin) {
      let date = new Date(c_date_fin);
      dateFin = DateTime.fromJSDate(date).startOf('day');
    }

    let c_date_fin_ss = _this.form.controls['date_fin_saisie'].value;
    let dateFin_ss: any = '';
    if (c_date_fin_ss) {
      let date = new Date(c_date_fin_ss);
      dateFin_ss = DateTime.fromJSDate(date).startOf('day');
    }

    // la campagne de validation doit démarrer après la fin de la campagne de saisie
    if (dateDebut <= dateFin_ss) {
      return { erreurDateDebutValid: control.value, erreurDateValidation: false };
    }

    // la date début de validation doit être inférieure à la date fin de validation
    // la date fin de validation doit être supérieure à la date début de validation
    else if (dateDebut.isValid && dateFin.isValid) {
      if (dateDebut > dateFin) {
        return { erreurDateValidation: control.value };
      }
    }

    return null;
  };
}

// vérification de la date d'archivage
export function verifDateArchivage(_this: any): ValidatorFn {
  return (control: AbstractControl): null | ValidationErrors => {
    let c_date_debut = _this.form.controls['date_archivage'].value;
    let dateDebut: any = '';
    if (c_date_debut) {
      let date = new Date(c_date_debut);
      dateDebut = DateTime.fromJSDate(date).startOf('day');
    }

    let c_date_fin_v = _this.form.controls['date_fin_validation'].value;
    let dateFin_v: any = '';
    if (c_date_fin_v) {
      let date = new Date(c_date_fin_v);
      dateFin_v = DateTime.fromJSDate(date).startOf('day');
    }

    // la campagne d’archivage doit démarrer après la fin de la campagne de validation.
    if (dateDebut <= dateFin_v) {
      return { erreurDateArchivage: control.value };
    }

    return null;
  };
}

// La campagne de validation doit démarrer après la fin de la campagne de saisie associée
// La campagne d’archivage doit démarrer après la fin de la campagne de validation associée
export function verifCampAssoc(_this: any, dateD: string): ValidatorFn {
  return (control: AbstractControl): null | ValidationErrors => {
    let id_camp = control?.value?.id;

    if (id_camp != null && id_camp != '') {
      // date de début de la campagne
      let ct_date_debut = _this.form.controls['date_debut'].value;
      let date = new Date(ct_date_debut);
      let dt_date_debut = DateTime.fromJSDate(date).startOf('day');

      // date de fin de la campagne associée
      let index = Object.keys(_this.campagneOptions).find((k: any) => _this.campagneOptions[k].id === id_camp);
      let CADate = _this.campagneOptions[Number(index)];
      let dateFin = CADate?.dateFin;
      let dt_date_fin = DateTime.fromFormat(dateFin, 'yyyy-LL-dd').startOf('day');

      let type = _this.form.controls['type'].value;

      if (type == 'VALIDATION') {
        _this.texte_erreur_date = 'La campagne de validation doit démarrer après la fin de la campagne de saisie associée';
      } else if (type == 'ARCHIVAGE') {
        _this.texte_erreur_date = 'La campagne d’archivage doit démarrer après la fin de la campagne de validation associée';
      }

      if (dt_date_debut < dt_date_fin) {
        return { erreurCampAssoc: control.value };
      }
    }

    return null;
  };
}
