<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<h2 class="mb-0" style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle mat-dialog-title>{{ title }}</h2>
<button mat-dialog-close class="btnClose" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
  <mat-icon>close</mat-icon>
</button>
<mat-dialog-content
  class="mat-typography pt-0 diag-content">
  <mat-divider></mat-divider>

  <div class="divContainer" style="width: 94%">
    <div style="margin-bottom: 25px">
      <mat-icon style="color: rgba(16, 46, 94, 0.982); transform: scale(2); vertical-align: middle; margin-right: 14px">help</mat-icon>
      <span style="margin-bottom: 20px"> {{ question }} </span>
    </div>
    <div style="width: 100%; margin-bottom: 5px" *ngIf="motifPostion == 'top'">
      <mat-form-field class="example-full-width w-100" floatLabel="always">
        <mat-label>Motif</mat-label>
        <textarea *ngIf="anyHasRefused" [(ngModel)]="motif_val" [formControl]="motif" matInput required (keyup)="verifMotif()">
          Saisissez votre texte
        </textarea>
        <textarea *ngIf="!anyHasRefused" [(ngModel)]="motif_val" [formControl]="motif" matInput (keyup)="verifMotif()">
          Saisissez votre texte
        </textarea>
        <mat-error *ngIf="motif.invalid">Veuillez saisir le motif.</mat-error>
      </mat-form-field>
    </div>
    <div class="detail">
      <div *ngFor="let item of data">
        <div>
          <div>Collaborateur</div>
          <div>: &nbsp;{{ item?.Collaborateur }}</div>
        </div>
        <div>
          <div *ngIf="this.type == 'hs'">Nombre d’HS</div>
          <div *ngIf="this.type == 'astrainte'">Nombre d'Astreintes</div>
          <div>: &nbsp;{{ item['Nombre d’heures totales'] }}</div>
        </div>
        <div *ngIf="this.type == 'hs'">
          <div>Durée totale</div>
          <div>: &nbsp;{{ item?.hours }}</div>
        </div>
        <div *ngIf="this.type == 'astrainte' && !item.Nombre">
          <div>Nombre de jours à valider</div>
          <div>: &nbsp;{{ item['Nombre de jours à valider'] }}</div>
        </div>
        <div *ngIf="this.type == 'astrainte' && item.Nombre">
          <div>Nombre de jours à valider</div>
          <div>: &nbsp;{{ item['Nombre'] }}</div>
        </div>
        <div *ngIf="item.hasRefused" style="display: flex; align-items: center; color: red; padding-top: 0.5rem">
          <mat-icon>warning</mat-icon>
          <div *ngIf="item['Nombre d’heures totales'] !== 1" style="padding-left: 0.5rem">
            Attention, cet agent a des {{ this.type == 'hs' ? 'HS' : 'astreintes' }} refusées par le directeur
          </div>
          <div *ngIf="item['Nombre d’heures totales'] == 1" style="padding-left: 0.5rem">
            Attention, cette {{ this.type == 'hs' ? 'HS' : 'astreinte' }} a été refusée par le directeur
          </div>
        </div>
      </div>
    </div>

    <div style="width: 100%; margin-top: 18px" *ngIf="motifPostion == 'bottom'">
      <mat-form-field class="example-full-width w-100" floatLabel="always">
        <mat-label>Motif</mat-label>
        <textarea *ngIf="anyHasRefused" [(ngModel)]="motif_val" [formControl]="motif" matInput required (keyup)="verifMotif()">
          Saisissez votre texte
        </textarea>
        <textarea *ngIf="!anyHasRefused" [(ngModel)]="motif_val" [formControl]="motif" matInput (keyup)="verifMotif()">
          Saisissez votre texte
        </textarea>
        <mat-error *ngIf="motif.invalid">Veuillez saisir le motif.</mat-error>
      </mat-form-field>
    </div>
  </div>

  <mat-divider [ngStyle]="{ marginTop: refuse ? '0px' : '0px' }"></mat-divider>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-stroked-button color="primary" mat-dialog-close>Annuler</button>
  <button
    mat-flat-button
    color="primary"
    style="width: 88px"
    *ngIf="!refuse"
    [mat-dialog-close]="['valider', this.motif_val, this.type]"
    cdkFocusInitial
    [disabled]="this.motif.invalid"
  >
    {{ texte_bouton }}
  </button>
  <button
    mat-flat-button
    color="primary"
    style="width: 88px"
    *ngIf="refuse"
    [mat-dialog-close]="['refuser', this.motif_val, this.type]"
    cdkFocusInitial
    [disabled]="bt_refuser_disabled"
  >
    Valider
  </button>
</mat-dialog-actions>
