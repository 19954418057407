import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectionService {
  private _data = new BehaviorSubject([]);

  get sharedData$(): Observable<any[]> {
    return this._data.asObservable();
  }

  // met à jour le subject
  stockData(_data: any) {
    this._data.next(_data);
  }

  // lit les données du subject
  get getData(): any[] {
    return this._data.getValue();
  }

  // ajoute un id à la sélection
  changeSelection(event: any, element: any, selKey: string) {
    let _data: any = this.getData;
    let _sel: any = _data[selKey] || [];
    let id = element['id'];
    // coche toutes les cases de subtable
    let _id_st: any[] = [];

    // liste heures supp ou liste astreintes, suppression
    if (selKey == 'saisieHS' || selKey == 'saisieAst') {
      // stocke les ID
      if (event.checked) {
        _sel.push(id);
      } else {
        _sel = _sel.filter((v: any) => v != id);
      }
    }

    // liste de validation
    else {
      // pour une ligne d'un agent
      if (element['subTable'] != undefined && element['subTable'] != null && element['subTable'].length > 0) {
        let _subTable = element['subTable'];
        _id_st = _subTable.map((ligne: any) => {
          if (!ligne?.valider_hidden) return ligne.id;
        });

        // stocke les ID
        if (event.checked) {
          _sel = [..._sel, ..._id_st];
        } else {
          _id_st.forEach((t) => {
            _sel = _sel.filter((v: any) => v != t);
          });
        }
      }
      // pour une seule ligne d'heures
      else {
        // stocke les ID
        if (event.checked) {
          _sel.push(id);
        } else {
          _sel = _sel.filter((v: any) => v != id);
        }
      }
    }

    _data[selKey] = _sel;
    this.stockData(_data);
  }

  cocheToutSelection(_tableData: any[], selKey: string) {
    console.log('cocheToutSelection');
    let _data: any = this.getData;
    delete _data[selKey];
    let _dataID: any = this.extractDataID(_tableData);
    _data[selKey] = _dataID;

    this.stockData(_data);
  }

  resetSelection(selKey?: any) {
    let _data: any = this.getData;
    delete _data[selKey];
    this.stockData(_data);
  }

  // recherche tous les ID
  extractDataID(_tableData: any[]) {
    let _data: any = [];
    if (_tableData.length > 0) {
      let _id: any = [];
      _tableData.forEach((_ligne: any) => {
        if (_ligne['subTable'] != undefined && _ligne['subTable'] != null && _ligne['subTable'].length > 0) {
          let _subTable = _ligne['subTable'];

          // si subtable contient des id (validation)
          _id = _subTable.map((li: any) => {
            if (!li?.valider_hidden && li?.id != undefined) {
              return li.id;

              // si subtable ne contient pas d'id on prend l'id de la ligne (saisie)
            } else {
              return _ligne.id;
            }
          });
        }

        _data = [..._data, ..._id];
      });
    }

    return _data;
  }
}
