import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { ValidationSaisiesService } from 'src/app/services/validation-saisies.service';
import { FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-confirm-validation-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatDividerModule,
    MatListModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    DragDropModule
  ],
  templateUrl: './confirm-suppression-modal.component.html',
  styleUrls: ['./confirm-suppression-modal.component.scss']
})
export class ConfirmSuppressionModalComponent {
  refuse = false;
  type: string;
  action: string = 'valider';
  motif_val: string = '';
  title: string = '';
  question: string = '';
  texte_bouton: string = 'oui';
  data: any[] = [];
  matDialogClose: any = [];
  @Output() onClickEmit = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private ValidationService: ValidationSaisiesService,
    @Inject(MAT_DIALOG_DATA) public dataDiag: any
  ) {}

  ngOnInit() {
    console.log('ngOnInit dataDiag;', this.dataDiag);
    this.type = this.dataDiag.type;

    let action = this.dataDiag.action;

    this.matDialogClose = ['supprimer', this.type];

    if (this.dataDiag.type == 'hs') {
      if (action == 'supprimer en masse') {
        this.refuse = false;
        this.title = 'Supprimer en masse';
        this.question = 'Voulez-vous vraiment supprimer en masse les HS ?';
        this.texte_bouton = 'Valider';
      } else if (action == 'lignes non supprimables') {
        this.title = 'Supprimer en masse';
        this.question = 'Vous ne pouvez pas supprimer en masse les HS qui ont déjà été validées.';
        this.texte_bouton = 'Fermer';
        this.matDialogClose = ['fermer', this.type];
      }
    } else if (this.dataDiag.type == 'astreinte') {
      if (action == 'supprimer en masse') {
        this.refuse = false;
        this.title = 'Supprimer en masse';
        this.question = 'Voulez-vous vraiment supprimer en masse les astreintes ?';
        this.texte_bouton = 'Valider';
      } else if (action == 'lignes non supprimables') {
        this.title = 'Supprimer en masse';
        this.question = 'Vous ne pouvez pas supprimer en masse les astreintes qui ont déjà été validées.';
        this.texte_bouton = 'Fermer';
        this.matDialogClose = ['fermer', this.type];
      }
    }

    this.data = this.dataDiag.data;
  }
}
