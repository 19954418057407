export const MY_FORMATS = {
  parse: {
    dateInput: 'dd/MM/yyyy' // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'dd/MM/yyyy', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM yyyy'
  }
};
