import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { ValidationSaisiesService } from 'src/app/services/validation-saisies.service';
import { take } from 'rxjs';
import { Broadcast } from 'src/app/core/services/broadcast.service';
import { DragDropModule } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-refresh-saisie-modal',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatIconModule, MatDialogModule, MatDividerModule, MatButtonModule, DragDropModule],
  templateUrl: './refresh-saisie-modal.component.html',
  styleUrls: ['./refresh-saisie-modal.component.scss']
})
export class RefreshSaisieModalComponent {
  constructor(
    public dialogRef: MatDialogRef<RefreshSaisieModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public validation_saisie: ValidationSaisiesService,
    private broadcast: Broadcast
  ) {}

  renouvelerSaisie() {
    if (this?.data?.type == 'hs') {
      this.renouvelerSaisieHs();
    } else if (this.data.type == 'astreinte') {
      this.renouvelerSaisieAstreinte();
    }
  }
  renouvelerSaisieHs() {
    this.validation_saisie
      .renouvelerHS(this.data.id)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.broadcast.broadcastEvent({
            broadcastedevent: 'renouvelerHs'
          });
        },
        error: (error) => {
          this.broadcast.broadcastEvent({
            broadcastedevent: 'renouvelerHsError',
            error: error
          });
        }
      });
  }

  renouvelerSaisieAstreinte() {
    this.validation_saisie
      .renouvelerAstreinte(this.data.id)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.broadcast.broadcastEvent({
            broadcastedevent: 'renouvelerAstreinte'
          });
        },
        error: (error) => {
          this.broadcast.broadcastEvent({
            broacateEvent: 'renouvelerAstreinteError',
            error: error
          });
        }
      });
  }
}
