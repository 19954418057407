<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<mat-card-header class="" style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="row col-md-12">
    <div class="col-10">
      <div class="titre-modale">{{ titre_action }} un jour férié</div>
    </div>

    <div class="col-md-2 text-end mb-0 mr-0 ml-auto gx-0">
      <button class="py-0" mat-icon-button aria-label="Fermer la modale" (click)="fermerModale()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>

<mat-dialog-content class="mat-typography">
  <!-- form -->
  <form [formGroup]="form" class="row">
    <div class="col-md-12 mt-2">
      <mat-form-field class="example-full-width" floatLabel="always" style="width: 100%">
        <mat-label>Libellé du jour férié </mat-label>
        <input class="pt-0" matInput class="form-control" id="libelle" formControlName="libelle" />
        <mat-error *ngIf="form.controls['libelle'].hasError('required')">Le libellé du jour férié.</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-4">
      <mat-form-field style="width: 100%" floatLabel="always">
        <mat-label>Date </mat-label>
        <input
          matInput
          type="text"
          appearance="standard"
          [matDatepicker]="pi_date"
          id="dateJourFerier"
          formControlName="dateJourFerier"
          placeholder="jj/mm/aaaa"
        />
        <mat-datepicker-toggle matIconSuffix [for]="pi_date"></mat-datepicker-toggle>
        <mat-datepicker #pi_date></mat-datepicker>
        <mat-error *ngIf="form.controls['dateJourFerier'].hasError('required')">La date est obligatoire.</mat-error>
        <mat-error *ngIf="form.controls['dateJourFerier'].hasError('erreurDateJour')"
          >la date doit être supérieure à la date du jour
        </mat-error>
      </mat-form-field>
    </div>
  </form>
  <mat-divider style="border-top-width: 1px" class="mt-4 mb-1"></mat-divider>
  <h5 class="text-footer">Les éléments marqués d'une étoile (*) sont obligatoires</h5>
</mat-dialog-content>
<mat-dialog-actions style="background-color: rgba(250, 250, 252, 1)" align="end">
  <button mat-stroked-button class="mx-3" color="primary" (click)="fermerModale()">Annuler</button>
  <button [disabled]="form.invalid" #bouttonValider mat-flat-button color="primary" (click)="validerForm()">Valider</button>
</mat-dialog-actions>
