import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ConfirmQuestionComponent } from '../../validation/confirm-question/confirm-question.component';
import { AgentSuivantComponent } from 'src/app/shared/agent-suivant/agent-suivant.component';
import { MatChipsModule } from '@angular/material/chips';
import { AstreinteFormComponent } from '../astreinte-form/astreinte-form.component';
import { AstreintesService } from 'src/app/services/astreintes.service';

import { HeureSupForm3Component } from '../heure-sup-form-3/heure-sup-form-3.component';
import { AstreinteSyntheseComponent } from '../astreinte-synthese/astreinte-synthese.component';

import { delay, take } from 'rxjs';
import { CommunicationService } from 'src/app/shared/communication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { FormulaireAstreinteModel, AgentHS, LigneAstreinte } from 'src/app/models/formulaire-astreinte.model';
import { ValidationModalComponent } from 'src/app/shared/validation-modal/validation-modal.component';
import { Broadcast } from 'src/app/core/services/broadcast.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AlerteModalComponent } from 'src/app/shared/alerte-modal/alerte-modal.component';

@Component({
  selector: 'app-astreinte-create-modal',
  standalone: true,
  imports: [
    AgentSuivantComponent,
    CommonModule,
    MatTableModule,
    MatRadioModule,
    MatIconModule,
    MatCardModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatChipsModule,
    AstreinteFormComponent,
    HeureSupForm3Component,
    AstreinteSyntheseComponent,
    MatSnackBarModule,
    DragDropModule
  ],
  templateUrl: './astreinte-create-modal.component.html',
  styleUrls: ['./astreinte-create-modal.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: true }
    }
  ]
})
export class AstreinteCreateModalComponent {
  //@ViewChild(HeureSupForm3Component) childForm!: HeureSupForm3Component;
  @ViewChild(AstreinteFormComponent) childFormEvt!: AstreinteFormComponent | null;
  @ViewChild(AstreinteSyntheseComponent) syntheseComponent!: AstreinteSyntheseComponent;

  agentsList: any;
  currentIndex = 0;
  selectedAgents: string[] = [];
  selectedOption: string = 'HS_POUR_DIRECTION_ORIGINE';
  selectedNbAgents: number = 1;

  infosFormulaires: FormulaireAstreinteModel[] = [];
  syntheseParAgent: any[];

  previousStepIndex = -1;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AstreinteCreateModalComponent>,
    //private HS_service: HeuresSupplémentairesService,
    private notifyService: CommunicationService,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private _snackBar: MatSnackBar,
    private astreintesService: AstreintesService,
    private broadcast: Broadcast
  ) {}

  /*onChangeAgentNbr(value: number = 1) {
    this.selectedNbAgents = value;
    this.notifyService.setNbAgent(value);
    this.cdr.detectChanges();
  }*/

  validate_1() {
    this.dialog
      .open(ConfirmQuestionComponent, {
        width: '460px'
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {});
  }
  cancelDialog() {
    this.dialogRef.close();
  }

  stepSelChange(event: any) {
    if (event) {
      if (this.childFormEvt) {
        this.infosFormulaires = this.childFormEvt.getCurentInfosFormulaires();
      }
      // Réaffichage du formulaire => on rétablit les données actuelles

      if (event.selectedIndex == 1 /* page 2 synthèse */) {
        // Recalcul de la synthèse par agent
        this.syntheseParAgent = this.getSyntheseByAgent();
      }

      this.previousStepIndex = event.selectedIndex;
    }
  }
  purgeData() {
    this.infosFormulaires = [];
    if (this.childFormEvt) {
      this.childFormEvt.infosFormulaires = [];
    }
  }

  // test HS et Hs bulk
  validerAstreinteModale() {
    let campagneEnCoursStr = sessionStorage.getItem('campagneActuelle');
    let campagneEnCoursId = -1;
    if (campagneEnCoursStr) {
      let campagneEnCours = JSON.parse(campagneEnCoursStr);
      if (campagneEnCours) {
        campagneEnCoursId = campagneEnCours.id;
      }
    }
    if (campagneEnCoursId < 0) {
      this._snackBar.open('Création impossible : pas de campagne en cours', 'OK');
    }

    this.spinner.show();

    const heurSuppBody: any = [];

    let nbAgents = 0;
    let nbHs = 0;

    // Parcours des formulaires
    this.infosFormulaires.forEach((currentForm: FormulaireAstreinteModel) => {
      // Parcours des agents
      currentForm.agents.forEach((agent: AgentHS) => {
        nbAgents++;
        const agentData = {
          type: currentForm.type,
          statut: 'A_VALIDER',
          agent: { id: agent.id },
          directionOrigine: { id: agent.direction.id },
          //directionOrganisatrice: { id: currentForm.directionOrganisatriceId },
          directionOrganisatrice: { id: agent.direction.id },
          serviceFonctionnel: { id: agent.serviceFonctionnel.id },
          campagne: { id: campagneEnCoursId }
        };

        currentForm.lignes.forEach((horaire: LigneAstreinte) => {
          nbHs++;
          let dateDebut_fm = horaire.date_debut.toFormat('yyyy-MM-dd');
          let dateFin_fm = horaire.date_fin.toFormat('yyyy-MM-dd');

          let heurSupp = {
            ...agentData,
            dateDebut: dateDebut_fm,
            dateFin: dateFin_fm,
            nombre: horaire.nombre
          };
          heurSuppBody.push(heurSupp);
        });
      });
    });

    this.astreintesService
      .createAstreinteBulk(heurSuppBody)
      .pipe(delay(1000))
      .subscribe({
        next: (result) => {
          this.dialogRef.close(true);
          this.spinner.hide();
          this._snackBar.open('Astreintes créées avec succès.', 'OK');

          this.dialog.open(ValidationModalComponent, {
            width: '600px',
            data: {
              title: 'Confirmation',
              content:
                'La saisie astreinte a été enregistrée avec succès  (' +
                heurSuppBody.length +
                ' demande(s) astreinte(s) créée(s) concernant ' +
                nbAgents +
                ' agent(s)).'
            }
          });

          //notify consultation
          this.broadcast.broadcastEvent({
            broadcastedevent: 'reloadHs'
          });
        },
        error: (error) => {
          this.spinner.hide();
          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Erreur',
              content: error.error.replace('Validation error:', '')
            }
          });
        }
      });
  }

  add_agent(selectedAgent: string) {
    if (selectedAgent && !this.selectedAgents.includes(selectedAgent)) this.selectedAgents.push(selectedAgent);
  }

  /**
   * Construit la vue des informations par agent et la passe à l'écran de synthèse
   */
  getSyntheseByAgent(): any[] {
    if (this.infosFormulaires && this.infosFormulaires.length > 0) {
      let agentMap: any = {};

      for (let currentForm of this.infosFormulaires) {
        if (currentForm.agents && currentForm.agents.length > 0) {
          let currentAgents = currentForm.agents;
          for (let currentAgent of currentAgents) {
            let agentId: string = currentAgent.id;
            let prevAgentInfos = agentMap[agentId];
            // agent pas déjà présent
            if (!prevAgentInfos) {
              agentMap[agentId] = {
                agent: currentAgent,
                infos_hs: []
              };
            }
            prevAgentInfos = agentMap[agentId];
            prevAgentInfos.infos_hs.push({
              type: currentForm.type,
              directionOrganisatriceId: currentForm?.directionOrganisatriceId,
              horaires: currentForm.lignes
            });
          }
        }
      }

      return Object.values(agentMap);
    } else {
      return [];
    }
  }
}
