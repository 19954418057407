import { Component, forwardRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { DataTableComponent } from 'src/app/shared/data-table/data-table.component';
import { HeuresSupplémentairesService } from 'src/app/services/heures-supplémentaires.service';
import { Duration } from 'luxon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-view-detail-modal',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatCardModule,
    MatIconModule,
    DragDropModule,
    forwardRef(() => DataTableComponent)
  ],
  templateUrl: './view-detail-modal.component.html',
  styleUrls: ['./view-detail-modal.component.scss']
})
export class ViewDetailModalComponent implements OnDestroy {
  rowData: any = {};
  dataJSON: any = {};
  libelleDirection: string = '';
  totalAgents: string = '';
  displayedColumns: string[] = [
    'Nom',
    'Prénom',
    'Service',
    'Total HS',
    'Heures totales jour',
    'Heures totales nuit',
    'Heures totales dimanches et jours fériés'
  ];

  cellStyleCss: any = {
    'Total HS': { 'text-align': 'center', 'padding-right': '30px' },
    'Heures totales jour': { 'text-align': 'center', 'padding-right': '30px' },
    'Heures totales nuit': { 'text-align': 'center', 'padding-right': '30px' },
    'Heures totales dimanches et jours fériés': { 'text-align': 'center', 'padding-right': '30px' }
  };

  filterFormControl = new FormControl();
  destroys$ = new Subject<void>();
  @ViewChild('AgentDataTable') AgentDataTable!: DataTableComponent;
  constructor(
    public dialog: MatDialog,
    private HS_service: HeuresSupplémentairesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.libelleDirection = this.data?.libelleDirection;
    this.totalAgents = this.data?.totalAgents;

    this.dataJSON = this.data?.mapAgents;
    this.rowData = this.transformData();
    this.filterFormControl.valueChanges.pipe(distinctUntilChanged(), debounceTime(200), takeUntil(this.destroys$)).subscribe((value) => {
      this.AgentDataTable.applyLocalFilter(value);
    });
  }

  ngOnDestroy(): void {
    this.destroys$.next();
    this.destroys$.complete();
  }

  transformData() {
    let dataAgent: any[] = [];

    console.log('modale rowData', this.dataJSON);

    for (let key in this.dataJSON) {
      let dataJ = this.dataJSON[key];
      let _data: any = {};

      _data['Nom'] = dataJ?.nom;
      _data['Prénom'] = dataJ?.prenom;
      _data['Service'] = dataJ?.libelleService;

      let jour = dataJ?.mapTypeTotalHS?.JOUR;
      let nuit = dataJ?.mapTypeTotalHS?.NUIT;
      let dim = dataJ?.mapTypeTotalHS?.FERIE;
      let _jour: any = jour != null ? jour.split(':', 2) : [0, 0];
      let _nuit: any = nuit != null ? nuit.split(':', 2) : [0, 0];
      let _dim: any = dim != null ? dim.split(':', 2) : [0, 0];
      let d_jour = Duration.fromObject({ hours: _jour[0], minutes: _jour[1] });
      let d_nuit = Duration.fromObject({ hours: Math.abs(_nuit[0]), minutes: Math.abs(_nuit[1]) });
      let d_dim = Duration.fromObject({ hours: _dim[0], minutes: _dim[1] });
      let d_tot = Duration.fromObject({ hours: 0, minutes: 0 });
      d_tot = d_tot.plus(d_jour).plus(d_nuit).plus(d_dim);

      _data['Heures totales jour'] = d_jour.valueOf() > 0 ? d_jour.toFormat('hh:mm') : '';
      _data['Heures totales nuit'] = d_nuit.valueOf() > 0 ? d_nuit.toFormat('hh:mm') : '';
      _data['Heures totales dimanches et jours fériés'] = d_dim.valueOf() > 0 ? d_dim.toFormat('hh:mm') : '';
      _data['Total HS'] = d_tot.valueOf() > 0 ? d_tot.toFormat('hh:mm') : '';

      dataAgent.push(_data);
    }

    console.log('dataAgent: ', dataAgent);

    return dataAgent;
  }
}
