import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ArianeNavComponent } from 'src/app/layout/ariane-nav/ariane-nav.component';
import { MatDividerModule } from '@angular/material/divider';
import { ParametresService } from 'src/app/services/parametres.service';
import { take } from 'rxjs';
import { DataTableComponent } from 'src/app/shared/data-table/data-table.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { CommunicationService } from 'src/app/shared/communication.service';
import { HistoriqueModificationsComponent } from './historique-modifications/historique-modifications.component';
import { ModalModifParametreComponent } from './modal-modif-parametre/modal-modif-parametre.component';
import { ImportParameterDataComponent } from './import-parameter-data/import-parameter-data.component';
import { Broadcast } from 'src/app/core/services/broadcast.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-parametres',
  standalone: true,
  imports: [
    CommonModule,
    ArianeNavComponent,
    MatDividerModule,
    DataTableComponent,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    DataTableComponent
  ],
  templateUrl: './parametres.component.html',
  styleUrls: ['./parametres.component.scss']
})
export class ParametresComponent implements OnInit {
  arianeLabel: string = 'Paramètres de configuration';
  arianeIcon: string = 'settings_applications';
  pipe = new DatePipe('fr-FR'); // Use your own locale
  dataTable: any[] = [];
  keyChanges: any = {};
  untransformedData: any = [];
  dataLength: number;
  rowData: any[] = [];
  dataJSON: any[] = [];
  filterValue: string = '';
  totalRecords_parameter: number = 0;
  arianeList = [
    {
      label: 'Administration',
      url: '/administration'
    },
    {
      label: 'Paramètres de configuration',
      url: '/parametres'
    }
  ];

  constructor(
    public parametresService: ParametresService,
    private refreshService: CommunicationService,
    public dialog: MatDialog,
    private detectorRef: ChangeDetectorRef,
    private broadcast: Broadcast,
    private excelService: ExcelService
  ) {}
  ParametresDisplayedColumns: string[] = ['Libellé', 'Propriété', 'Valeur', 'Description', 'Obligatoire', 'Actions'];

  ngOnInit() {
    //this.loadParameterList();
    this.updatePaginationParametres(null);
    this.refreshService.refreshList$.subscribe((refresh) => {
      if (refresh) {
        //this.loadParameterList();
        this.updatePaginationParametres(null);
      }
    });
  }
  retrieveHistory(id: number) {
    this.searchDataID(this.dataJSON, id);
    this.dialog.open(HistoriqueModificationsComponent, {
      width: '780px',
      data: {
        data: this.rowData
      }
    });
  }
  convertExcel(data: any, name: string) {
    const search = '/';
    const replaceWith = '-';
    const date = new Date().toLocaleDateString('en-GB').split(search).join(replaceWith);
    let fileName = `${name}${date}`;
    this.excelService.exportToExcel(data, `${fileName}.xlsx`, 'Sheet1');
  }
  exportParametre() {
    this.parametresService
      .getParametresWithParams(0, this.totalRecords_parameter)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.dataTable = this.transformData(result.results);
          const DataToexport = this.transformData(result.results);
          const _data_export: any[] = [];

          DataToexport.forEach((line: any) => {
            let copieddata = line;
            delete copieddata['id'];
            _data_export.push(copieddata);
          });

          this.convertExcel(_data_export, 'Export_Parametres_Configurations_');
        }
      });
  }

  searchDataID(data: any, id: number) {
    let index = Object.keys(data).find((k) => data[k].id === id);

    if (index != null) {
      this.rowData = this.dataJSON[Number(index)];
    }
  }

  modifParametre(id: number) {
    this.searchDataID(this.dataJSON, id);

    const dialogRef = this.dialog.open(ModalModifParametreComponent, {
      width: '640px',
      disableClose: true,
      data: {
        edition: true,
        data: this.rowData
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      //this.loadParameterList();
      this.broadcast.broadcastEvent({
        broadcastedevent: 'resetPagination'
      });
      // reset pagination re-récupère tous les éléments
      this.resetPagination();
    });
  }

  transformData(result: any) {
    let data: any[] = [];

    for (let key in result) {
      let dataJ = result[key];
      let _data: any = {};

      _data['id'] = dataJ?.id;
      _data['Libellé'] = dataJ?.libelle;
      _data['Propriété'] = dataJ?.code;
      _data['Valeur'] = dataJ?.valeur;
      _data['Description'] = dataJ?.description;
      _data['Obligatoire'] = dataJ?.mandatory ? 'Oui' : 'Non';

      data.push(_data);
    }
    return data;
  }

  importParameterData() {
    const dialogRef = this.dialog.open(ImportParameterDataComponent, {
      width: '640px',
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
  /*loadParameterList() {
    this.parametresService
      .getParametresWithParams(environment.pagination.offset, environment.pagination.limit)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.untransformedData = result;
          this.dataLength = result.length;

          this.dataJSON = result.results;
          console.log('all parameters', this.dataJSON);

          this.dataTable = this.transformData(result.results);
        },
        error: (error) => {
          console.log('erreur:', error);
        }
      });
  }*/
  updatePaginationParametres(event: any) {
    console.log('appel updatePaginationParametres', event);
    if (event) {
      this.parametresService.getParametresWithParams(event.offset, event.limit, event.filterValue).subscribe({
        next: (result: any) => {
          this.untransformedData = result.results;
          this.dataLength = result.totalRecords;

          this.dataTable = this.transformData(result.results);
          this.dataJSON = result.results;
        },
        error: (error: any) => {
          console.log('erreur:', error);
        }
      });
    } else {
      this.parametresService
        .getParametresWithParams(environment.pagination.offset, environment.pagination.limit)
        .pipe(take(1))
        .subscribe({
          next: (result: any) => {
            this.untransformedData = result;
            this.dataLength = result.totalRecords;

            this.dataTable = this.transformData(result.results);
            this.dataJSON = result.results;
          },
          error: (error: any) => {
            console.log('erreur:', error);
          }
        });
    }
  }
  resetPagination() {
    console.log('Réinitialisation de la pagination');
    this.parametresService
      .getParametresWithParams(environment.pagination.offset, environment.pagination.limit)
      .pipe(take(1))
      .subscribe({
        next: (result: any) => {
          this.untransformedData = result.results;
          this.dataLength = result.totalRecords;

          this.dataTable = this.transformData(result.results);
          this.dataJSON = result.results;
        },
        error: (error: any) => {
          console.log('erreur:', error);
        }
      });
  }
  updateFiltreParametre(value: any) {
    this.filterValue = value.filterValue;
    this.updatePaginationParametres(value);
  }
}
