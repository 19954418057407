

<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982); " class="col-12"></div>
 <h2 class="mb-0" mat-dialog-title>Confirmation</h2> 
<button mat-dialog-close class="btnClose" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
  <mat-icon>close</mat-icon>
</button> 
<mat-dialog-content class="mat-typography pt-0"> 
  <mat-divider></mat-divider>
    <div class="divContainer" >
      <mat-icon style="color: rgba(35, 163, 23, 0.982) ;transform: scale(2); vertical-align: middle; margin-right: 10px">check_circle</mat-icon>
      <span >
         La saisie HS a été validée avec succès.
      </span>
    </div>
    <mat-divider></mat-divider>
    
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-flat-button  color="primary" [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
</mat-dialog-actions>
