import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-bandeau-parcourir',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatTooltipModule],
  templateUrl: './bandeau-parcourir.component.html',
  styleUrls: ['./bandeau-parcourir.component.scss']
})
export class BandeauParcourirComponent {
  //@Input() EmployersTime: any[] = [];
  @Input() currentIndex: number = 1;
  @Input() actionsActives: boolean = true;
  //@Input() selectedAgent: string = '';
  @Input() totalTime: string = '00:00';
  //@Input() agentIndex: string = '';
  @Input() nbFormulaires: number = 1;
  @Output() showNext = new EventEmitter();
  @Output() showPrev = new EventEmitter();
  @Output() newForm = new EventEmitter();
  @Output() deleteForm = new EventEmitter();
  @Input() heurDifferror: boolean = false;
  showNextData() {
    this.showNext.emit();
  }

  showPrevData() {
    this.showPrev.emit();
  }

  ajoutFormulaire() {
    this.newForm.emit();
  }

  suppressionFormulaire() {
    this.deleteForm.emit();
  }
}
