import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SuivieImportsService {
  module = environment.webService.server + environment.webService.url + environment.webService.api;

  constructor(private http: HttpClient) {}

  getAllSuivieImports(offset: number, limit: number, filtre: any | null = null, filterGenerique: any | null = null) {
    filtre = filtre || '';
    let url = this.module + '/suivies-imports';
    if (filtre != '' && filtre !== undefined && filtre !== null && !Array.isArray(filtre)) {
      url = url + '?' + `type=${filtre}`;
    } else {
      url = url + '?type=' + filtre.join('&type=');
    }
    if (filterGenerique != '' && filterGenerique !== undefined && filterGenerique !== null) {
      filterGenerique = filterGenerique.toLowerCase();
      if (filterGenerique.includes('ter')) {
        filterGenerique = 'TERMINE';
      }
      if (filterGenerique.includes('en ')) {
        filterGenerique = 'EN_COURS';
      }
      if (filterGenerique.includes('err')) {
        filterGenerique = 'ECHEC';
      }
      url = url + '&' + `filterGenerique=${filterGenerique}`;
    }
    const params = new HttpParams().set('page', offset).set('size', limit);

    return this.http.get<any>(url, { params });
  }
  getAllSuivieExport(offset: number, limit: number, filtre: any | null = null, filterGenerique: any | null = null) {
    filtre = filtre || '';
    let url = this.module + '/suivies-imports';

    url = url + '?type=' + filtre.join('&type=');
    if (filterGenerique != '' && filterGenerique !== undefined && filterGenerique !== null) {
      filterGenerique = filterGenerique.toLowerCase();
      if (filterGenerique.includes('ter')) {
        filterGenerique = 'TERMINE';
      }
      if (filterGenerique.includes('en ')) {
        filterGenerique = 'EN_COURS';
      }
      if (filterGenerique.includes('err')) {
        filterGenerique = 'ECHEC';
      }
      url = url + '&' + `filterGenerique=${filterGenerique}`;
    }
    const params = new HttpParams().set('page', offset).set('size', limit);

    return this.http.get<any>(url, { params });
  }
  getFile(fileName: string, type: string) {
    /**
     * type = IMPORT ou EXPORT
     */
    /**
     * Le résultat n'est pas forcément convertible en JSON
     */
    console.log(this.module);
    if (type == 'IMPORT') {
      let HTTPOptions: Object = {
        responseType: 'text'
      };
      return this.http.get<any>(this.module + `/suivies-imports/download-file/${fileName}`, HTTPOptions);
    } else {
      let HTTPOptions: Object = {
        responseType: 'blob'
      };
      // EXPORT
      return this.http.get<any>(this.module + `/suivies-imports/download-export-file/${fileName}`, HTTPOptions);
    }
  }
}
