<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<mat-card-header class="" style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="row col-md-12">
    <div class="col-10">
      <div class="titre-modale">{{ titre_action }} un service</div>
    </div>

    <div class="col-md-2 text-end mb-0 mr-0 ml-auto gx-0">
      <button class="py-0" mat-icon-button aria-label="Fermer la modale" (click)="fermerModale()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>

<mat-dialog-content class="mat-typography">
  <!-- form -->
  <form [formGroup]="form" class="row">
    <div class="col-md-12 mt-2">
      <mat-form-field class="example-full-width" floatLabel="always" style="width: 100%">
        <mat-label>Libellé </mat-label>
        <input class="pt-0" matInput class="form-control champ-disabled" id="libelle" formControlName="libelle" [readonly]="true" />
        <mat-error *ngIf="form.controls['libelle'].hasError('required')">Le libellé.</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-12 mt-2">
      <mat-form-field class="field-filtre field-filtre-select" floatLabel="always" style="width: 100%">
        <mat-label>Responsable</mat-label>
        <input
          #autoCompleteResponsable
          type="text"
          placeholder="Responsable"
          aria-label="Responsable"
          matInput
          [matAutocomplete]="autoResponsable"
          formControlName="responsable"
          (input)="responsableFilter($event)"
          [(ngModel)]="val_responsable"
        />
        <mat-autocomplete cdkScrollable #autoResponsable="matAutocomplete" [displayWith]="responsableDisplayFn">
          <mat-option *ngFor="let item of agentList" [value]="item">{{ item.nom }} {{ item.prenom }}</mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="form.controls['responsable'].hasError('required')">Le responsable est obligatoire.</mat-error>
      </mat-form-field>
    </div>
  </form>
  <mat-divider style="border-top-width: 1px" class="mt-4 mb-1"></mat-divider>
  <h5 class="text-footer">Les éléments marqués d'une étoile (*) sont obligatoires</h5>
</mat-dialog-content>
<mat-dialog-actions style="background-color: rgba(250, 250, 252, 1)" align="end">
  <button mat-stroked-button class="mx-3" color="primary" (click)="fermerModale()">Annuler</button>
  <button [disabled]="form.invalid" #bouttonValider mat-flat-button color="primary" (click)="validerForm()">Valider</button>
</mat-dialog-actions>
