<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<mat-card-header class="" style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="row col-md-12">
    <div class="col-10">
      <div class="titre-modale">{{ titre_action }} une campagne</div>
    </div>

    <div class="col-md-2 text-end mb-0 mr-0 ml-auto gx-0">
      <button mat-dialog-close class="py-0" mat-icon-button aria-label="Fermer la modale" (click)="fermerModale()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>

<mat-dialog-content class="mat-typography">
  <!-- form -->
  <form [formGroup]="form" class="row">
    <div class="col-md-12 mt-2">
      <mat-form-field class="example-full-width" floatLabel="always" style="width: 100%">
        <mat-label>Nom de la campagne </mat-label>
        <input class="pt-0" matInput class="form-control" id="nom" formControlName="nom" />
        <mat-error *ngIf="form.controls['nom'].hasError('required')">Le nom est obligatoire.</mat-error>
      </mat-form-field>
    </div>

    <div class="row mt-0 mb-0 pb-0">
      <div class="col-md-3 d-flex align-items-end" style="font-size: 13px">Type de campagne *</div>
    </div>

    <div class="row mt-2 mb-0">
      <div class="col-md-3 d-flex align-items-center col-type">Saisie</div>

      <div class="col-md-4">
        <mat-form-field style="width: 100%" floatLabel="always">
          <mat-label>Date début de saisie </mat-label>
          <input
            matInput
            [matDatepicker]="pi_date_debut_saisie"
            id="date_debut_saisie"
            [min]="minDateDebutSaisie"
            formControlName="date_debut_saisie"
            (dateChange)="changeDate($event)"
            placeholder="dd/mm/yyyy"
          />
          <mat-datepicker-toggle matIconSuffix [for]="pi_date_debut_saisie"></mat-datepicker-toggle>
          <mat-datepicker #pi_date_debut_saisie></mat-datepicker>
          <mat-error *ngIf="form.controls['date_debut_saisie'].hasError('required')">La date début est obligatoire.</mat-error>
          <mat-error *ngIf="form.controls['date_debut_saisie'].hasError('erreurDateSaisie')"
            >La date de début ne peut être supérieure à la date de fin</mat-error
          >
          <mat-error *ngIf="form.get('date_debut_saisie')?.hasError('matDatepickerMin')"
            >La date minimum autorisée est le
            {{ form.get('date_debut_saisie')?.errors?.['matDatepickerMin']['min'] | date: 'dd/MM/yyyy' }}</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field style="width: 100%" floatLabel="always">
          <mat-label>Date fin de saisie </mat-label>
          <input
            matInput
            [matDatepicker]="pi_date_fin_saisie"
            id="date_fin_saisie"
            [min]="minDateFinSaisie"
            formControlName="date_fin_saisie"
            (dateChange)="changeDate($event)"
            placeholder="dd/mm/yyyy"
          />
          <mat-datepicker-toggle matIconSuffix [for]="pi_date_fin_saisie"></mat-datepicker-toggle>
          <mat-datepicker #pi_date_fin_saisie></mat-datepicker>
          <mat-error *ngIf="form.controls['date_fin_saisie'].hasError('required')">La date fin est obligatoire.</mat-error>
          <mat-error *ngIf="form.controls['date_fin_saisie'].hasError('erreurDateSaisie')"
            >La date de fin ne peut être inférieure à la date de début</mat-error
          >
          <mat-error *ngIf="form.get('date_fin_saisie')?.hasError('matDatepickerMin')"
            >La date minimum autorisée est le
            {{ form.get('date_fin_saisie')?.errors?.['matDatepickerMin']['min'] | date: 'dd/MM/yyyy' }}</mat-error
          >
        </mat-form-field>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-3 d-flex align-items-center col-type">Validation</div>

      <div class="col-md-4">
        <mat-form-field style="width: 100%" floatLabel="always">
          <mat-label>Date début de validation </mat-label>
          <input
            matInput
            [matDatepicker]="pi_date_debut_validation"
            id="date_debut_validation"
            [min]="minDateDebutValidation"
            formControlName="date_debut_validation"
            (dateChange)="changeDate($event)"
            placeholder="dd/mm/yyyy"
          />
          <mat-datepicker-toggle matIconSuffix [for]="pi_date_debut_validation"></mat-datepicker-toggle>
          <mat-datepicker #pi_date_debut_validation></mat-datepicker>
          <mat-error *ngIf="form.controls['date_debut_validation'].hasError('required')">La date début est obligatoire.</mat-error>
          <mat-error *ngIf="form.controls['date_debut_validation'].hasError('erreurDateValidation')"
            >La date début ne peut être supérieure à la date de fin</mat-error
          >
          <mat-error *ngIf="form.controls['date_debut_validation'].hasError('erreurDateDebutValid')"
            >La date début doit être > à la fin campagne de saisie</mat-error
          >
          <mat-error *ngIf="form.get('date_debut_validation')?.hasError('matDatepickerMin')"
            >La date minimum autorisée est le
            {{ form.get('date_debut_validation')?.errors?.['matDatepickerMin']['min'] | date: 'dd/MM/yyyy' }}</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field style="width: 100%" floatLabel="always">
          <mat-label>Date fin de validation </mat-label>
          <input
            matInput
            [matDatepicker]="pi_date_fin_validation"
            id="date_fin_validation"
            [min]="minDateFinValidation"
            formControlName="date_fin_validation"
            (dateChange)="changeDate($event)"
            placeholder="dd/mm/yyyy"
          />
          <mat-datepicker-toggle matIconSuffix [for]="pi_date_fin_validation"></mat-datepicker-toggle>
          <mat-datepicker #pi_date_fin_validation></mat-datepicker>
          <mat-error *ngIf="form.controls['date_fin_validation'].hasError('required')">La date fin est obligatoire.</mat-error>
          <mat-error *ngIf="form.controls['date_fin_validation'].hasError('erreurDateValidation')"
            >La date fin ne peut être inférieure à la date de début</mat-error
          >
          <mat-error *ngIf="form.get('date_fin_validation')?.hasError('matDatepickerMin')"
            >La date minimum autorisée est le
            {{ form.get('date_fin_validation')?.errors?.['matDatepickerMin']['min'] | date: 'dd/MM/yyyy' }}</mat-error
          >
        </mat-form-field>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-3 d-flex align-items-center col-type">Archivage</div>
      <div class="col-md-4">
        <mat-form-field style="width: 100%" floatLabel="always">
          <mat-label>Date d'archivage </mat-label>
          <input
            matInput
            [matDatepicker]="pi_date_archivage"
            id="date_archivage"
            [min]="minDateArchivage"
            formControlName="date_archivage"
            (dateChange)="changeDate($event)"
            placeholder="dd/mm/yyyy"
          />
          <mat-datepicker-toggle matIconSuffix [for]="pi_date_archivage"></mat-datepicker-toggle>
          <mat-datepicker #pi_date_archivage></mat-datepicker>
          <mat-error *ngIf="form.controls['date_archivage'].hasError('required')">La date d'archivage est obligatoire.</mat-error>
          <mat-error *ngIf="form.controls['date_archivage'].hasError('erreurDateArchivage')"
            >la campagne d’archivage doit démarrer après la fin de la campagne de validation.</mat-error
          >
          <mat-error *ngIf="form.get('date_archivage')?.hasError('matDatepickerMin')"
            >La date minimum autorisée est le
            {{ form.get('date_archivage')?.errors?.['matDatepickerMin']['min'] | date: 'dd/MM/yyyy' }}</mat-error
          >
        </mat-form-field>
      </div>
    </div>

    <div class="col-md-12 mt-2">
      <label for="exampleFormControlTextarea1" class="form-label" style="font-size: 12px">Commentaire</label>
      <mat-form-field style="width: 100%" floatLabel="always" class="textarea-mat">
        <textarea
          matInput
          class="form-control"
          id="commentaire"
          formControlName="commentaire"
          placeholder="Saisissez votre texte"
          rows="2"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
  <mat-divider style="border-top-width: 1px" class="mt-4 mb-1"></mat-divider>
  <h5 class="text-footer">Les éléments marqués d'une étoile (*) sont obligatoires</h5>
</mat-dialog-content>
<mat-dialog-actions style="background-color: rgba(250, 250, 252, 1)" align="end">
  <button mat-dialog-close mat-stroked-button class="mx-3" color="primary" (click)="fermerModale()">Annuler</button>
  <button [disabled]="form.invalid" #bouttonValider mat-flat-button color="primary" (click)="validerCampagne()">Valider</button>
</mat-dialog-actions>
