import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MotifsService {
  module = environment.webService.server + environment.webService.url + environment.webService.api;
  constructor(private http: HttpClient) {}

  // get all the motifs
  getAllMotifs() {
    return this.http.get<any>(this.module + '/motifs');
  }
}
