import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ParametresService } from 'src/app/services/parametres.service';
import { delay } from 'rxjs';
import { CommunicationService } from 'src/app/shared/communication.service';
import { AlerteModalComponent } from 'src/app/shared/alerte-modal/alerte-modal.component';

@Component({
  selector: 'app-import-parameter-data',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatIconModule, MatButtonModule, MatDialogModule, MatSnackBarModule],
  templateUrl: './import-parameter-data.component.html',
  styleUrls: ['./import-parameter-data.component.scss']
})
export class ImportParameterDataComponent implements OnInit {
  fileSelected: boolean = false;
  fileNameParameter: string;
  fileData: any;

  constructor(
    private spinner: NgxSpinnerService,
    private snackBar: MatSnackBar,
    private parameterService: ParametresService,
    public dialogRef: MatDialogRef<ImportParameterDataComponent>,
    private notifyService: CommunicationService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.fileNameParameter = '';
    this.fileData = null;
  }
  onFileSelected() {
    const fileTypes = ['xlsx'];
    console.log('selected file');

    const inputNode: any = document.querySelector('#file');

    const fullPath = inputNode.value;
    if (fullPath) {
      const extension = fullPath.split('.').pop().toLowerCase(),
        isSuccess = fileTypes.indexOf(extension) > -1;

      let fileNameMorceaux = fullPath.split('\\');

      if (!isSuccess) {
        alert('Vous devez choisir un fichier Excel');
        return;
      }

      this.fileNameParameter = fileNameMorceaux[fileNameMorceaux.length - 1];
      this.fileData = inputNode.files[0];
    } else {
      alert('Impossible de contrôler le nom du fichier choisi');
    }
  }
  validateData() {
    this.spinner.show();
    this.parameterService
      .importParameterFile(this.fileData, this.fileNameParameter)
      .pipe(delay(1000))
      .subscribe({
        next: (result) => {
          console.log('fin import');
          this.spinner.hide();
          this.snackBar.open('Fichier paramètre sousmis avec succès', 'OK');
          this.dialogRef.close();
          this.notifyService.triggerRefresh();
        },
        error: (error) => {
          this.spinner.hide();
           this.snackBar.open('Erreur lors de la soumission du fichier paramètre', 'OK');
          let erreur_texte = error.error.detail || error.error || error.statusText || error;
          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: "Erreur lors de l'import de paramètre !",
              status: 'STATUS: ' + error.status,
              detail: 'DETAIL: ' + erreur_texte
            }
          });
        }
      });
  }
}
