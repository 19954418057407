<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<mat-card-header class="" style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="row col-md-12">
    <div class="col-6">
      <div class="titre-modale" *ngIf="!this.data.modif">Ajouter une délégation</div>
      <div class="titre-modale" *ngIf="this.data.modif">Modifier une délégation</div>
    </div>

    <div class="col-md-6 text-end mb-2 mr-0 ml-auto gx-0">
      <button mat-dialog-close class="py-0" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>
<mat-dialog-content class="mat-typography">
  <!-- form -->
  <form [formGroup]="form" class="row">
    <div class="col-md-12 mt-2">
      <mat-form-field floatLabel="always" style="width: 480px">
        <mat-label>Agent</mat-label>

        <input
          type="text"
          placeholder="nom prénom"
          aria-label="Number"
          matInput
          name="item"
          [matAutocomplete]="autoDelegue"
          formControlName="agent"
          (input)="agentDelegueFilter($event)"
          required
        />
        <button *ngIf="val_del_agent" matSuffix mat-icon-button aria-label="Clear" (click)="effaceFiltreDelegue($event, 'agent')">
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete
          #autoDelegue="matAutocomplete"
          hideSingleSelectionIndicator
          requireSelection
          [displayWith]="agentDeleguedisplayFn"
        >
          <mat-option *ngFor="let agent of agentsList" [disabled]="this.data.modif === true" [value]="agent">
            {{ agent.nom }} {{ agent.prenom }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="form.get('agent')?.hasError('required')"> L'agent est <strong>obligatoire</strong> </mat-error>
        <mat-error *ngIf="form.get('agent')?.hasError('agentExist') && form.get('agent')?.value">
          Vous ne pouvez pas être votre propre délégué
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-4 mt-2">
      <mat-form-field style="width: 100%" floatLabel="always">
        <mat-label>Date de début</mat-label>
        <input
          matInput
          [matDatepicker]="pi_date_debut"
          id="date_debut"
          formControlName="date_debut"
          [readonly]="true"
          (click)="pi_date_debut.open()"
          [matDatepickerFilter]="FilterDebutDate"
          required
        />
        <mat-datepicker-toggle matIconSuffix [for]="pi_date_debut"></mat-datepicker-toggle>
        <mat-datepicker #pi_date_debut></mat-datepicker>
        <mat-error *ngIf="form.get('date_debut')?.hasError('required')">La date de début est <strong>obligatoire</strong> </mat-error>
        <mat-error *ngIf="form.get('date_debut')?.hasError('dates')">Date de début doit être inférieure ou égale à date de fin</mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-4 mt-2">
      <mat-form-field style="width: 100%" floatLabel="always">
        <mat-label>Date de fin</mat-label>
        <input
          matInput
          [matDatepicker]="pi_date_fin"
          id="date_fin"
          formControlName="date_fin"
          [readonly]="true"
          (click)="pi_date_fin.open()"
          required
        />
        <mat-datepicker-toggle matIconSuffix [for]="pi_date_fin"></mat-datepicker-toggle>
        <mat-datepicker #pi_date_fin></mat-datepicker>
        <mat-error *ngIf="form.get('date_fin')?.hasError('required')">La date de fin est <strong>obligatoire</strong> </mat-error>
        <mat-error *ngIf="form.get('date_fin')?.hasError('dates')">Date de fin doit être supérieure ou égale à date de début</mat-error>
      </mat-form-field>
    </div>
  </form>
  <mat-divider style="border-top-width: 1px" class="mt-4 mb-1"></mat-divider>
  <h5 class="text-footer">Les éléments marqués d'une étoile (*) sont obligatoires</h5>
</mat-dialog-content>
<mat-dialog-actions style="background-color: rgba(250, 250, 252, 1)" align="end">
  <button mat-stroked-button mat-dialog-close class="mx-3" color="primary">Annuler</button>
  <button mat-flat-button color="primary" [disabled]="form.invalid || agentMatriculeExist()" (click)="saveDelegue()">Valider</button>
</mat-dialog-actions>
