<mat-card-header class="">
  <div class="row col-md-12">
    <div class="col-6">
      <div class="titre-modale">Ajouter une alarme</div>
    </div>

    <div class="col-md-6 text-end mb-2 mr-0 ml-auto gx-0">
      <button mat-dialog-close class="py-0" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>
<mat-dialog-content class="mat-typography">
  <!-- form -->
  <form [formGroup]="form" class="row">

    <div class="col-md-12 mt-2">

      <mat-form-field floatLabel="always" style="width:480px;">
        <mat-label>Alarme *</mat-label>
        <mat-select formControlName="agent">
          <mat-option *ngFor="let agent of agentsList" [value]="agent.id">
            {{agent.nom}} {{agent.prenom}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <div class="col-md-4 mt-2">
 
      <mat-form-field style="width:100%;" floatLabel="always">
        <mat-label>Date de début *</mat-label>
        <input matInput [matDatepicker]="pi_date_debut" id="date_debut" formControlName="date_debut">
        <mat-datepicker-toggle matIconSuffix [for]="pi_date_debut"></mat-datepicker-toggle>
        <mat-datepicker #pi_date_debut></mat-datepicker>
      </mat-form-field>
  

    </div>
    <div class="col-md-4 mt-2">

      <mat-form-field style="width:100%;" floatLabel="always">
        <mat-label>Date de fin *</mat-label>
        <input matInput [matDatepicker]="pi_date_fin" id="date_fin" formControlName="date_fin">
        <mat-datepicker-toggle matIconSuffix [for]="pi_date_fin"></mat-datepicker-toggle>
        <mat-datepicker #pi_date_fin></mat-datepicker>
      </mat-form-field>

  </div>

  </form>
  <mat-divider style="border-top-width: 1px" class="mt-4 mb-1"></mat-divider>
  <h5 class="text-footer">Les éléments marqués d'une étoile (*) sont obligatoires</h5>
</mat-dialog-content>
<mat-dialog-actions style="background-color: rgba(250, 250, 252, 1)" align="end">
  <button mat-stroked-button mat-dialog-close class="mx-3" color="primary">Annuler</button>
  <button mat-flat-button mat-dialog-close color="primary" matStepperNext>Valider</button>
</mat-dialog-actions>
