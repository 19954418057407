import { Component, forwardRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { DataTableComponent } from 'src/app/shared/data-table/data-table.component';
import { HeuresSupplémentairesService } from 'src/app/services/heures-supplémentaires.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject, distinctUntilChanged, debounceTime, takeUntil } from 'rxjs';

@Component({
  selector: 'app-view-detail-astrainte-modal',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatCardModule,
    MatIconModule,
    DragDropModule,
    forwardRef(() => DataTableComponent)
  ],
  templateUrl: './view-astrainte-detail-modal.component.html',
  styleUrls: ['./view-astrainte-detail-modal.component.scss']
})
export class ViewAstrainteDetailModalComponent implements OnDestroy {
  rowData: any = {};
  dataJSON: any = {};
  libelleDirection: string = '';
  totalAgents: string = '';
  displayedColumns: string[] = [
    'Nom',
    'Prénom',
    'Service',
    'Total',
    'Jour',
    'Nuit',
    'Semaine',
    'Samedi',
    'Dim. / jours fériés',
    'Week-end',
    'Crèche'
  ];

  cellStyleCss: any = {
    'Total HS': { 'text-align': 'center', 'padding-right': '30px' },
    'Heures totales jour': { 'text-align': 'center', 'padding-right': '30px' },
    'Heures totales nuit': { 'text-align': 'center', 'padding-right': '30px' },
    'Heures totales dimanches et jours fériés': { 'text-align': 'center', 'padding-right': '30px' }
  };
  filterFormControl = new FormControl();
  destroys$ = new Subject<void>();
  @ViewChild('AgentDataTable') AgentDataTable!: DataTableComponent;

  constructor(
    public dialog: MatDialog,
    private HS_service: HeuresSupplémentairesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.libelleDirection = this.data?.libelleDirection;
    this.totalAgents = this.data?.totalAgents;

    this.dataJSON = this.data?.mapAgents;
    this.rowData = this.transformData();
    this.filterFormControl.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(200), takeUntil(this.destroys$))
      .subscribe((value: string) => {
        this.AgentDataTable.applyLocalFilter(value);
      });
  }

  ngOnDestroy(): void {
    this.destroys$.next();
    this.destroys$.complete();
  }

  transformData() {
    let dataAgent: any[] = [];

    console.log('modale rowData', this.dataJSON);

    for (let key in this.dataJSON) {
      let dataJ = this.dataJSON[key];
      let _data: any = {};

      _data['Nom'] = dataJ?.nom;
      _data['Prénom'] = dataJ?.prenom;
      _data['Service'] = dataJ?.libelleService;
      _data['Total'] = this.sumOfValues(dataJ?.mapTypeTotalAstreintes);
      let jour = dataJ?.mapTypeTotalAstreintes?.JOUR;
      let nuit = dataJ?.mapTypeTotalAstreintes?.NUIT;
      let dim = dataJ?.mapTypeTotalAstreintes?.DIMANCHE_FERIE;

      _data['Jour'] = jour;
      _data['Nuit'] = nuit;
      _data['Dim. / jours fériés'] = dim;
      _data['Week-end'] = dataJ.mapTypeTotalAstreintes?.WEEKEND;
      _data['Crèche'] = dataJ.mapTypeTotalAstreintes?.CRECHE;
      _data['Semaine'] = dataJ.mapTypeTotalAstreintes?.SEMAINE;
      _data['Samedi'] = dataJ.mapTypeTotalAstreintes?.SAMEDI;

      dataAgent.push(_data);
    }

    console.log('dataAgent: ', dataAgent);

    return dataAgent;
  }

  sumOfValues(obj: any) {
    let sum = 0;
    for (let key in obj) {
      if (typeof obj[key] === 'string' && !isNaN(obj[key])) {
        sum += Number(obj[key]);
      }
    }
    return sum;
  }
}
