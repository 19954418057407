import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { SuivieImportsService } from 'src/app/services/suivie-imports.service';
import { take } from 'rxjs';
import { CsvService } from 'src/app/core/services/csv.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AlerteModalComponent } from 'src/app/shared/alerte-modal/alerte-modal.component';

@Component({
  selector: 'app-detail-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatCardModule,
    MatIconModule,
    DragDropModule
  ],
  templateUrl: './detail-modal.component.html',
  styleUrls: ['./detail-modal.component.scss']
})
export class DetailModalComponent implements OnInit {
  dureeTraitement = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public suivieImportsService: SuivieImportsService,
    public csvService: CsvService,
    public dialog: MatDialog
  ) {}
  dateDebut = '';

  ngOnInit() {
    console.log(this.data);

    this.data = this.data[0][0];
    this.dureeTraitement = 'n.a.';
    if (this.data) {
      if (!this.data.dateDebut.includes('à')) {
        if (this.data.dateDebut && this.data.dateFin) {
          const date1 = new Date(this.data.dateDebut).getTime();
          const date2 = new Date(this.data.dateFin).getTime();
          const diffTime = Math.abs(date2 - date1);
          this.dureeTraitement = diffTime / 1000 + ' secondes';
        }
        this.dateDebut = this.formatDateString(this.data.dateDebut);
      }

      if (!this.data.nomFichier) {
        this.data.nomFichier = 'Non renseigné';
      }
    }
  }

  formatDateString(inputDateString: string) {
    if (!inputDateString.includes('à')) {
      // Parse the input date string
      const inputDate = new Date(inputDateString);

      // Check if the date is valid
      if (isNaN(inputDate.getTime())) {
        return 'Invalid Date';
      }

      // Format the date components
      const day = String(inputDate.getDate()).padStart(2, '0');
      const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
      const year = String(inputDate.getFullYear());
      const hours = String(inputDate.getHours()).padStart(2, '0');
      const minutes = String(inputDate.getMinutes()).padStart(2, '0');

      // Construct the formatted string
      const formattedString = `${day}/${month}/${year} à ${hours}:${minutes}`;

      return formattedString;
    } else {
      return inputDateString;
    }
  }
  download() {
    console.log('called');
    this.suivieImportsService
      .getFile(this.data.nomFichier, this.data.type)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          const link = document.createElement('a');

          if (this.data.nomFichier.endsWith('xlsx')) {
            const blob = new Blob([result], { type: 'application/vnd.ms-excel' });
            link.href = window.URL.createObjectURL(blob);
          } else if (this.data.nomFichier.endsWith('pdf')) {
            const blob = new Blob([result], { type: 'application/pdf' });
            link.href = window.URL.createObjectURL(blob);
          } else {
            // fichier csv
            const blob = new Blob([result], { type: 'text/csv' });
            link.href = window.URL.createObjectURL(blob);
          }

          link.download = this.data.nomFichier;

          // Append the link to the document
          document.body.appendChild(link);

          // Trigger the click event to start the download
          link.click();

          // Remove the link from the document
          document.body.removeChild(link);
        },
        error: (error) => {
          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: "Le ficher n'existe pas "
            }
          });
        }
      });
  }
}
