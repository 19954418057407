<app-ariane-nav [label]="arianeLabel" [icon]="arianeIcon" [arianeList]="this.arianeList"></app-ariane-nav>

<mat-divider class="my-3"></mat-divider>

<mat-tab-group>
  <mat-tab style="overflow-x: hidden" label="IMPORTS">
    <div class="row mt-3">
      <div class="col flex-container end align-items-center">
        <div>
          <button mat-raised-button class="bt-table-defaut bt-table-ajouter mr-5" (click)="importManuel()">
            <mat-icon class="text-danger">file</mat-icon> Import de données
          </button>
        </div>
      </div>
    </div>
    <app-data-table
      #appDataTable
      [displayedColumns]="SuivieImportsDisplayedColumns"
      [data]="dataTable"
      [expendable]="false"
      [hasView]="true"
      [editable]="false"
      [delatable]="false"
      (showDetail)="openDetailModal($event)"
      [length]="dataLength"
      (updatePagination)="updatePagination($event)"
      (updateFiltre)="updateFiltre($event)"
      [customFilter]="true"
    ></app-data-table>
  </mat-tab>
  <mat-tab style="overflow-x: hidden" label="EXPORTS">
    <app-data-table
      #appDataTable
      [displayedColumns]="SuivieImportsDisplayedColumns"
      [data]="dataTableExport"
      [expendable]="false"
      [hasView]="true"
      [editable]="false"
      [delatable]="false"
      (showDetail)="openDetailModal($event)"
      [length]="exportDataLength"
      (updatePagination)="updateExportPagination($event)"
      (updateFiltre)="updateFiltreExport($event)"
      [customFilter]="true"
    ></app-data-table>
  </mat-tab>
</mat-tab-group>
