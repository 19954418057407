import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CreateHSModel } from '../models/heures-supplémantaires.model';
import { Broadcast } from 'src/app/core/services/broadcast.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeuresSupplémentairesService {
  module = environment.webService.server + environment.webService.url + environment.webService.api;
  private loggedAgentSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private broadcast: Broadcast
  ) {}

  //get heuresSupplémaentaires
  getHeuresSupplémentairesWithParams(offset: number, limit: number, filtre: string | null) {
    filtre = filtre || '';
    const params = new HttpParams().set('page', offset).set('size', limit);
    let url = this.module + '/heures-supplementaires';
    if (filtre != '') {
      url = url + '?' + filtre;
    }
    return this.http.get<any>(url, { params });
  }

  // Heures supplémentaires archivées + filtres
  getArchiveHSWithParams(offset: number, limit: number, filtre: string | null) {
    filtre = filtre || '';
    const params = new HttpParams().set('page', offset).set('size', limit);
    let url = this.module + '/heures-supplementaires/archived';
    //let url = this.module + '/heures-supplementaires';
    if (filtre != '') {
      url = url + '?' + filtre;
    }
    return this.http.get<any>(url, { params });
  }

  getAllHeuresSupplémentaires() {
    return this.http.get<any>(this.module + '/heures-supplementaires');
  }

  // creation HS
  createHS(data: CreateHSModel) {
    let obj = this.http.post<any>(this.module + '/heures-supplementaires', data);
    setTimeout(() => {
      this.broadcast.broadcastEvent('reloadNotification');
    }, 1000);
    return obj;
  }

  // Bulk creation
  createHSBulk(data: CreateHSModel[]) {
    let obj = this.http.post<any>(this.module + '/heures-supplementaires/bulk', data);
    setTimeout(() => {
      setTimeout(() => {
        this.broadcast.broadcastEvent('reloadNotification');
      }, 1000);
    }, 1000);
    return obj;
  }

  // modification HS
  modifierHS(data: any, id: Number) {
    console.log('dataaa', data);

    return this.http.put<any>(this.module + '/heures-supplementaires/' + id, data);
  }

  // Delete HS
  DeleteHS(id: number) {
    let obj = this.http.delete(this.module + '/heures-supplementaires/' + id);
    setTimeout(() => {
      this.broadcast.broadcastEvent('reloadNotification');
    }, 1000);
    return obj;
  }

  // supprimer plusieurs lignes à la fois
  deleteHSBulk(data: any) {
    let obj = this.http.delete<any>(this.module + '/heures-supplementaires/bulk', { body: data });
    setTimeout(() => {
      this.broadcast.broadcastEvent('reloadNotification');
    }, 1000);
    return obj;
  }

  // Totaux Heures Supplémentaire
  getTotauxHeuresSupplémentaires() {
    return this.http.get<any>(this.module + '/heures-supplementaires/totalHs-par-direction');
  }

  // Agent Direction
  getAgentDirection() {
    return this.http.get<any>(this.module + '/heures-supplementaires/liste-agents-par-direction');
  }

  // Get PDF HS
  getPdf(id: number) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.get(this.module + '/heures-supplementaires/' + id + '/pdf', httpOptions);
  }

  // ********* graphiques vue globale *********

  // liste des 3 dernières campagnes
  getCampagneDerniere() {
    return this.http.get<any>(this.module + '/campagnes/dernieres?size=3');
  }

  // statistiques campagne
  getCampagneStat(campId: any, dirID: string) {
    let statsDirection = dirID == 'mes_directions' ? 'statsMesDirections' : 'statsDirection/' + dirID;
    return this.http.post<any>(this.module + '/campagnes/' + statsDirection, campId);
    //return this.http.get<any>(this.module + '/campagnes/' + campId + '/statsDirection/' + dirID);
  }

  // infos de l'agent connecté
  getAgentConnecte() {
    return this.http.get<any>(this.module + '/agents/moi');
  }

  get loggedAgent(): Observable<any> {
    if (!this.loggedAgentSubject.value) {
      this.fetchLoadedAgent();
    }
    return this.loggedAgentSubject.asObservable();
  }

  fetchLoadedAgent(): void {
    this.getAgentConnecte().subscribe((data) => {
      this.loggedAgentSubject.next(data);
    });
  }
}
