<div class="mt-1">
    <app-data-table
        [filter]="false"
        [displayedColumns]="displayedColumns"
        [cellStyleCss]="hourCellStyleCss"
        [headerStyleCssSub]="hourHeaderStyleCssSub"
        [cellStyleCssSub]="hourCellStyleCssSub"
        [data]="dataSource"
        [expendable]="true"
        [hasPagination]="false"
        [subTableDisplayedColumns]="subTableDisplayedColumns"
    ></app-data-table>
</div>

  