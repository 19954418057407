export const constant = {
  mois: [
    { id: 1, value: 'janvier' },
    { id: 2, value: 'février' },
    { id: 3, value: 'mars' },
    { id: 4, value: 'avril' },
    { id: 5, value: 'mai' },
    { id: 6, value: 'juin' },
    { id: 7, value: 'juillet' },
    { id: 8, value: 'août' },
    { id: 9, value: 'septembre' },
    { id: 10, value: 'octobre' },
    { id: 11, value: 'novembre' },
    { id: 12, value: 'décembre' }
  ],
  exportFormats: [
    { value: 'excel', id: 1 },
    { value: 'csv', id: 2 },
    { value: 'pdf', id: 2 }
  ]
  
};
