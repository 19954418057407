import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar-filtre-campagne',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './snack-bar-filtre-campagne.component.html',
  styleUrls: ['./snack-bar-filtre-campagne.component.scss']
})
export class SnackBarFiltreCampagneComponent {
  constructor(private snackRef: MatSnackBarRef<SnackBarFiltreCampagneComponent>) {}

  closePopup(event: Event) {
    this.snackRef.dismiss();
    event?.preventDefault();
  }
}
