<h1 mat-dialog-title>Exporter</h1>
<div mat-dialog-content>
  <div class="row mb-20">
    <mat-form-field class="col-6">
      <mat-label>Format</mat-label>
      <mat-select>
        <mat-option *ngFor="let format of formats" [value]="format.value">
          {{ format.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row">
    <div class="col-md-6">
      <mat-form-field>
        <mat-label>Date fin</mat-label>
        <input matInput [matDatepicker]="picker" />
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <mat-form-field>
        <mat-label>Date fin</mat-label>
        <input matInput [matDatepicker]="picker_1" />
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker_1"></mat-datepicker-toggle>
        <mat-datepicker #picker_1></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <h6 class="mt-4">Les éléments marqués d'une étoile (*) sont obligatoires</h6>
</div>

<mat-dialog-actions align="end">
  <button mat-stroked-button class="mr-5" [mat-dialog-close]="true" cdkFocusInitial>Annuler</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Valider</button>
</mat-dialog-actions>
