<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<div class="row col-md-12" style="padding: 10px" style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="col-6">
    <h2 class="titre-modale d-flex">Archivage manuel</h2>
  </div>
  <div class="col-md-6 text-end mb-2 mr-0 ml-auto gx-0">
    <button mat-dialog-close class="py-0" mat-icon-button aria-label="Fermer la modale">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content class="mat-typography pt-0 diag-content">
  <mat-divider></mat-divider>
  <div class="container" style="width: 90%">
    <div style="margin-bottom: 40px">
      <mat-icon
        style="
          color: rgba(16, 46, 94, 0.982);
          transform: scale(2);
          vertical-align: middle;
          margin-right: 14px;
          position: relative;
          top: 23px;
          bottom: 13px;
          right: 42px;
        "
        >help</mat-icon
      >
      <h4 style="margin-bottom: 20px; color: rgba(16, 46, 94, 0.982)">Voulez-vous déclencher un archivage manuel de cette campagne ?</h4>
    </div>
    <div class="row mt-3">
      <h4>
        Campagne : <span>{{ nameCamp }}</span> <br />
      </h4>
      <h5>
        Un fichier Excel sera généré pour chaque campagne archivée contenant les saisies validées.<br />
        Un fichier PDF par agent sera également archivé dans Neoged.<br />
        Le détail des traitements effectués sera visible dans le "Suivi des imports".
      </h5>
    </div>
  </div>
  <mat-divider></mat-divider>

  <mat-dialog-actions align="center">
    <button mat-raised-button style="margin-right: 10px" mat-dialog-close class="bouton-fermer">Annuler</button>
    <button mat-raised-button color="primary" (click)="validationModale()">Valider</button>
  </mat-dialog-actions>
</mat-dialog-content>
