<div class="div-conteneur">
  <div class="list-group pt-3">
    <div class="list-group-item list-group-item-action" aria-current="true">
      <app-campagne-actuelle></app-campagne-actuelle>
    </div>
  </div>

  <ng-container *ngIf="!(chartActif$ | async)">
    <div class="list-group-item list-group-item-action div-group-card">
      <div class="div-card-row">
        <ng-container *ngFor="let item of cards">
          <div class="div-card" *ngIf="item.show">
            <app-card (onClickEmit)="navigateToLink(item)" [item]="item"></app-card>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="chartActif$ | async">
    <div class="list-group-item list-group-item-action div-group-card">
      <div class="div-chart" [formGroup]="form_chart">
        <div>
          <mat-toolbar class="toolbar-chart">
            <div>
              <span class="material-symbols-outlined icon">show_chart</span>
              <span>VUE GLOBALE</span>
            </div>
            <div>
              <button mat-flat-button matStepperNext color="primary" class="bouton-redirect" (click)="redirect_vue_globale()">
                Accéder
              </button>
            </div>
          </mat-toolbar>
        </div>
        <div class="div-filtre">
          <div class="div-radio">
            <mat-radio-group
              class="mb-10 mb-20"
              aria-label="Choisir une option"
              [(ngModel)]="isHs"
              style="position: relative"
              (change)="radioChange($event)"
              [ngModelOptions]="{ standalone: true }"
            >
              <mat-radio-button class="custom-radio-button" [value]="true" [ngClass]="{ active: isHs }" [checked]="isHs"
                ><span>HS</span></mat-radio-button
              >
              <mat-radio-button [value]="false" class="custom-radio-button" [ngClass]="{ active: !isHs }"
                ><span>ASTREINTES</span></mat-radio-button
              >
            </mat-radio-group>
          </div>
          <div class="div-menu">
            <div>
              <mat-form-field class="field-filtre" floatLabel="always">
                <mat-label>Vue par direction</mat-label>
                <input
                  #autoCompleteDirection
                  type="text"
                  placeholder="Direction"
                  aria-label="Direction"
                  matInput
                  [matAutocomplete]="autoDirection"
                  formControlName="HSdirection"
                  (input)="directionFilter($event)"
                  [(ngModel)]="val_HS_direction"
                />
                <mat-autocomplete
                  cdkScrollable
                  #autoDirection="matAutocomplete"
                  [displayWith]="directionOriginedisplayFn"
                  (optionSelected)="changeFiltreChart($event)"
                >
                  <mat-option *ngFor="let item of directionList" [value]="item">{{ item.libelle }}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field floatLabel="always" class="field-filtre">
                <mat-label>Vue par campagne</mat-label>
                <mat-select
                  [formControl]="HScampagne"
                  [(ngModel)]="val_HS_campagne"
                  (selectionChange)="changeFiltreChart($event)"
                  placeholder="Campagne"
                  multiple
                >
                  <mat-select-trigger>
                    <span *ngIf="(HScampagne.value?.length || 0) > 0" class="additional-selection">
                      {{ HScampagne.value?.length || '' }}
                      {{ HScampagne.value?.length === 1 ? 'campagne sélectionnée' : 'campagnes sélectionnées' }}
                    </span>
                  </mat-select-trigger>
                  <div style="font-size: 11px; padding-left: 16px; color: #818181">Vous pouvez sélectionner maximum 3 campagnes</div>

                  <mat-option *ngFor="let campagne of campagneList" [value]="campagne" [disabled]="campOptionDisabled(campagne)">{{
                    campagne.nom
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
              <button
                mat-stroked-button
                type="button"
                class="bt-effacer-filtre"
                (click)="effaceCampagneFiltre($event, 'campagne')"
                matTooltip="Effacer le filtre campagne"
              >
                <mat-icon class="icon-effacer">delete</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div class="div-graph" *ngIf="isHs">
          <div id="div-graph-1" style="margin-top: 0px">
            <div echarts [options]="chartHS1_options" [merge]="chartHS1_data" class="graph-1" *ngIf="chartHS1_data"></div>
          </div>
          <div *ngIf="!chartHS1_data" class="chart-message" (chartInit)="onChartInit($event, 'chartHS1')">
            Aucune donnée pour les campagnes sélectionnées
          </div>
          <div>
            <hr />
          </div>
          <div id="div-graph-2" style="margin-top: 0px">
            <div
              echarts
              *ngIf="chartHS1_data"
              [options]="chartHS2_options"
              [merge]="chartHS2_data"
              class="graph-2"
              (chartInit)="onChartInit($event, 'chartHS2')"
            ></div>
          </div>
        </div>

        <div class="div-graph" *ngIf="!isHs">
          <div *ngIf="!chartAst2_data" class="chart-message">Aucune donnée pour les campagnes sélectionnées</div>
          <div>
            <hr />
          </div>
          <div id="div-graph-2" style="margin-top: 0px">
            <div
              echarts
              *ngIf="chartAst2_data"
              [options]="chartAst2_options"
              [merge]="chartAst2_data"
              class="graph-2"
              (chartInit)="onChartInit($event, 'chartAst2')"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
