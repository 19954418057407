import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { FormBuilder, FormsModule, ReactiveFormsModule, FormGroup, FormControl } from '@angular/forms';
import { DataTableComponent } from 'src/app/shared/data-table/data-table.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarModule, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

import { CreateHsModalComponent } from './create-hs-modal/create-hs-modal.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule, DateAdapter } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { AstreinteCreateModalComponent } from './astreinte-create-modal/astreinte-create-modal.component';

import { AstreinteEditionComponent } from './astreinte-edition/astreinte-edition.component';
import { AstreintesService } from 'src/app/services/astreintes.service';

import { HeureSupEditionComponent } from './heure-sup-edition/heure-sup-edition.component';
import { MatDividerModule } from '@angular/material/divider';
import { ArianeNavComponent } from 'src/app/layout/ariane-nav/ariane-nav.component';
import localeFr from '@angular/common/locales/fr';
import { DeleteModalComponent } from 'src/app/shared/delete-modal/delete-modal.component';
import { ViewDetailModalComponent } from './view-detail-modal/view-detail-modal.component';
import { RoleService } from 'src/app/services/role.service';
import { RestrictedPageComponent } from 'src/app/shared/restricted-page/restricted-page.component';
import { HeuresSupplémentairesService } from 'src/app/services/heures-supplémentaires.service';
import { Subscription, take, Observable, of } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { CommunicationService } from 'src/app/shared/communication.service';
import { Duration, DateTime } from 'luxon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from 'src/environments/environment';
import { AlerteModalComponent } from 'src/app/shared/alerte-modal/alerte-modal.component';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { AgentsService } from 'src/app/services/agents.service';
import { DirectionService } from 'src/app/services/direction.service';
import { Broadcast } from 'src/app/core/services/broadcast.service';
import { ValidationModalComponent } from 'src/app/shared/validation-modal/validation-modal.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { TabService } from 'src/app/core/services/tab.service';
import { CampagnesService } from 'src/app/services/campagnes.service';
import { SelectionService } from 'src/app/shared/data-table/data-selection.service';
import { ConfirmSuppressionModalComponent } from './confirm-suppression-modal/confirm-suppression-modal.component';
import { MatBadgeModule } from '@angular/material/badge';

import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';

registerLocaleData(localeFr);
import { ExcelService } from 'src/app/core/services/excel.service';

import { ViewAstrainteDetailModalComponent } from './view-astrainte-detail-modal/view-astrainte-detail-modal.component';
import { CustomLuxonDateAdapter } from 'src/app/shared/utils/custom-luxon-date-adapter';
import { MY_FORMATS } from 'src/app/shared/utils/date-pickr-custom-luxon-format';
import { RefreshSaisieModalComponent } from 'src/app/shared/refresh-saisie-modal/refresh-saisie-modal.component';
import { SnackBarFiltreCampagneComponent } from 'src/app/shared/snack-bar-filtre-campagne/snack-bar-filtre-campagne.component';
import { SnackAlertService } from 'src/app/services/snack-alert.service';

@Component({
  selector: 'app-consultation',
  standalone: true,
  imports: [
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    MatTableModule,
    CommonModule,
    DataTableComponent,
    MatExpansionModule,
    MatSelectModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDividerModule,
    ArianeNavComponent,
    RestrictedPageComponent,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    NgxMaterialTimepickerModule,
    MatRadioModule,
    MatSnackBarModule
  ],
  templateUrl: './consultation.component.html',
  styleUrls: ['./consultation.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: DateAdapter, useClass: CustomLuxonDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' }
    }
  ]
})
export class ConsultationComponent implements OnInit, OnDestroy {
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  durationTime = 30000;
  showSpinner = false;
  pipe = new DatePipe('fr-FR');
  arianeLabel: string = 'Saisies et consultation HS / Astreintes';
  arianeIcon: string = 'format_align_left';
  userRoles: string[] = [];
  userProfile: any = {};
  dataSource: any;
  total: number | undefined;
  page_size: number | undefined;
  loading = false;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  btSupprMasseActif: boolean = false;

  // heures supplémentaires
  rowHeureSup: any = {};
  dataJSON_HS: any[] = [];
  dataTable_HS: any[] = [];
  hsPaginationUrl = environment.webService.server + environment.webService.url + environment.webService.api + '/heures-supplementaires';
  hslenght: number;
  totalRecords_HS: number = 0;
  panelOpenState: boolean = false;

  // astreintes
  rowAstreinte: any = {};
  dataJSON_Ast: any[] = [];
  dataTable_Ast: any[] = [];
  astPaginationUrl = environment.webService.server + environment.webService.url + environment.webService.api + '/astreintes';
  astlenght: number;
  totalRecords_Ast: number = 0;
  panelOpenStateAst: boolean = false;

  // vue globale
  dataJSON_VG: any[] = [];
  dataTable_VG: any[] = [];
  dataTable_astrainte: any[] = [];
  dataJSON_ast_list: any[] = [];
  dataJSON_VG_agent: any[] = [];
  dataJSON_astrainte_agent: any[] = [];
  filtreActif_VG: boolean = false;

  form_filtre_VG: FormGroup = this.formBuilder.group({
    direction: ['']
  });

  @ViewChild('autoCompleteVGDirection', { read: MatAutocompleteTrigger })
  autoCompleteVGDirection: MatAutocompleteTrigger;
  @ViewChild('appdataTableVG') appdataTableVG!: DataTableComponent;
  @ViewChild('appdataTableVGAstreinte') appdataTableVGAstreinte!: DataTableComponent;

  // heures supplémentaires
  boutonSaisirActif: boolean = false;
  boutonSaisirAstreinteActif: boolean = false;
  campagneEnCours: boolean = false;
  agentFormControl = new FormControl();
  filteredOptions: any = [];
  val_ft_agent: any = null;
  val_ft_typeHS: any = null;
  val_ft_duree: any = null;
  val_ft_emetteur: any = null;
  val_ft_directionOrigine: any = null;
  val_ft_directionOrganisatrice: any = null;
  val_ft_statut: any = null;
  val_ft_campagne: any = null;
  val_ft_serviceFonctionnel: any = null;
  val_ft_motif: any = null;
  agentsList: any = [];
  directionList: any[] = [];
  campagneList: any = [];
  directionEventList: any[] = [];
  listDirectionOrigine: any = [];
  listCampagne: any = [];
  FiltreHSActif: boolean = false;
  filtrerHS_libelle$: Observable<any> = of('Filtres avancés');
  default_compagne = true;
  default_compagne_astreinte = true;
  serviceFonctionnelList: any[] = [];
  listService: any = [];
  selectedTabIndex: number;

  form_filtre_HS: FormGroup = this.formBuilder.group({
    agent: [''],
    typeHS: [''],
    dateRealisation: [''],
    emetteur: [''],
    campagne: [''],
    directionOrigine: [''],
    directionOrganisatrice: [''],
    serviceFonctionnel: [''],
    statut: [''],
    dateCreation: [''],
    motif: [''],
    heure_debut: [''],
    heure_fin: [''],
    duree: ['']
  });
  isHs: any = true;

  @ViewChild('autoCompleteDirection', { read: MatAutocompleteTrigger })
  autoCompleteDirection: MatAutocompleteTrigger;
  @ViewChild('autoCompleteAgent', { read: MatAutocompleteTrigger })
  autoCompAgent: MatAutocompleteTrigger;
  @ViewChild('autoCompleteEvent', { read: MatAutocompleteTrigger })
  autoCompleteEvent: MatAutocompleteTrigger;
  @ViewChild('autoCompleteCamp', { read: MatAutocompleteTrigger })
  autoCompleteCamp: MatAutocompleteTrigger;
  @ViewChild('autoCompleteService', { read: MatAutocompleteTrigger })
  autoCompleteService: MatAutocompleteTrigger;

  // astreintes
  filteredOptionsAst: any = [];
  val_ft_ast_agent: any = null;
  val_ft_ast_type: any = null;
  val_ft_ast_nombre: any = null;
  val_ft_ast_emetteur: any = null;
  val_ft_ast_directionOrigine: any = null;
  val_ft_ast_directionOrganisatrice: any = null;
  val_ft_ast_statut: any = null;
  val_ft_ast_campagne: any = null;
  val_ft_ast_serviceFonctionnel: any = null;
  agentsListAst: any = [];
  directionListAst: any[] = [];
  campagneListAst: any = [];
  directionEventListAst: any[] = [];
  listDirectionOrigineAst: any = [];
  listCampagneAst: any = [];
  FiltreAstActif: boolean = false;
  filtrerAst_libelle$: Observable<any> = of('Filtres avancés');

  form_filtre_Ast: FormGroup = this.formBuilder.group({
    agent: [''],
    type: [''],
    emetteur: [''],
    directionOrigine: [''],
    directionOrganisatrice: [''],
    statut: [''],
    dateCreation: [''],
    dateDebut: [''],
    dateFin: [''],
    nombre: [''],
    campagne: [''],
    serviceFonctionnel: ['']
  });

  @ViewChild('autoCompleteAstDirection', { read: MatAutocompleteTrigger })
  autoCompleteAstDirection: MatAutocompleteTrigger;
  @ViewChild('autoCompleteAstAgent', { read: MatAutocompleteTrigger })
  autoCompleteAstAgent: MatAutocompleteTrigger;
  @ViewChild('autoCompleteAstEvent', { read: MatAutocompleteTrigger })
  autoCompleteAstEvent: MatAutocompleteTrigger;
  @ViewChild('autoCompleteAstCampagne', { read: MatAutocompleteTrigger })
  autoCompleteAstCampagne: MatAutocompleteTrigger;
  @ViewChild('autoCompleteAstService', { read: MatAutocompleteTrigger })
  autoCompleteAstService: MatAutocompleteTrigger;

  heureFinerror: boolean = false;
  private subscription: Subscription;

  /*     @ViewChild('autoCompleteInput', {read : MatAutocompleteTrigger})
  autoComplete:QueryList<MatAutocompleteTrigger>  */

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private roleService: RoleService,
    private HS_service: HeuresSupplémentairesService,
    private astreintesService: AstreintesService,
    private refreshService: CommunicationService,
    private detectorRef: ChangeDetectorRef,
    private agentsService: AgentsService,
    private directionService: DirectionService,
    private campagneServie: CampagnesService,
    private broadcast: Broadcast,
    private tabService: TabService,
    private excelService: ExcelService,
    private selectionService: SelectionService,
    private snackBar: MatSnackBar,
    private snackBarService: SnackAlertService
  ) {
    this.subscription = this.broadcast.event$.subscribe((data) => {
      if (data.broadcastedevent === 'deleteHs') {
        this.dialog.open(ValidationModalComponent, {
          width: '600px',
          data: {
            title: 'Confirmation',
            content: 'La saisie HS a été supprimée avec succès.'
          }
        });
        this.getListe_HS();
      }
      if (data.broadcastedevent === 'deleteHsError') {
        this.dialog.open(AlerteModalComponent, {
          width: '640px',
          data: {
            title: 'Alerte',
            content: "une erreur s'est produite lors de la suppression"
          }
        });
      }

      if (data.broadcastedevent === 'deleteAstreinte') {
        this.dialog.open(ValidationModalComponent, {
          width: '600px',
          data: {
            title: 'Confirmation',
            content: 'La saisie astreinte a été supprimée avec succès.'
          }
        });
        this.getListe_astreinte();
      }
      if (data.broadcastedevent === 'deleteAstreinteError') {
        this.dialog.open(AlerteModalComponent, {
          width: '640px',
          data: {
            title: 'Alerte',
            content: "une erreur s'est produite lors de la suppression"
          }
        });
      }
    });

    this.getUserProfile();
    this.subscription = this.broadcast.event$.subscribe((data) => {
      if (data.broadcastedevent === 'renouvelerHs') {
        this.dialog.open(ValidationModalComponent, {
          width: '600px',
          data: {
            title: 'Confirmation',
            content: 'La saisie HS a été renouvelée avec succès.'
          }
        });
        this.getListe_HS();
      }
      if (data.broadcastedevent === 'renouvelerHsError') {
        this.dialog.open(AlerteModalComponent, {
          width: '640px',
          data: {
            title: 'Alerte',
            content: "Une erreur s'est produite lors de renouvellement : " + data.error?.error
          }
        });
      }
      if (data.broadcastedevent === 'renouvelerAstreinte') {
        this.dialog.open(ValidationModalComponent, {
          width: '600px',
          data: {
            title: 'Confirmation',
            content: 'La saisie astreinte a été renouvelée avec succès.'
          }
        });
        this.getListe_Astreinte_Agent_VG();
      }
      if (data.broacateEvent === 'renouvelerAstreinteError') {
        this.dialog.open(AlerteModalComponent, {
          width: '640px',
          data: {
            title: 'Alerte',
            content: "une erreur s'est produite lors de renouvellement : " + data.error?.error
          }
        });
      }
    });
  }

  ngOnInit() {
    if (this.snackBarService.Isdisplayed()) {
      this.snackBar.openFromComponent(SnackBarFiltreCampagneComponent, {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: this.durationTime,
        panelClass: 'snack-bar-filtre-campagne'
      });
    }

    this.dataSource = new MatTableDataSource();
    this.selectedTabIndex = this.tabService.getSelectedTabIndex();

    this.verifCampagne();

    this.roleService.getRole().subscribe((roles) => {
      this.userRoles = roles;

      // activation du bouton saisir heures sup
      let index = Object.keys(roles).find(
        (val: any) => roles[val] === 'dhesa-admin' || roles[val] === 'dhesa-assistant-cds' || roles[val] === 'dhesa-assistant-responsable'
      );

      if (this.campagneEnCours) {
        this.boutonSaisirActif = index != undefined ? true : false;
        this.boutonSaisirAstreinteActif = index != undefined ? true : false;
      }

      // affichage du bouton supprimer en masse
      if (roles.includes('dhesa-admin')) {
        this.btSupprMasseActif = true;
      }

      // affichage du filtre de la vue globale
      let index_VG = Object.keys(roles).find(
        (val: any) => roles[val] === 'dhesa-admin' || roles[val] === 'dhesa-dg' || roles[val] === 'dhesa-gestionnaire-paie'
      );
      this.filtreActif_VG = index_VG != undefined ? true : false;
    });

    // initialise les listes
    this.getListe_VG1();
    this.getListe_HS();
    this.getListe_astreinte();
    this.getListe_Astreinte_Agent_VG(); // VG
    //to test after notification from form modal

    this.refreshService.refreshList$.subscribe((refresh) => {
      if (refresh) {
        this.getListe_VG1();
        this.getListe_HS();
        this.getListe_astreinte();
        this.getListe_Astreinte_Agent_VG();
      }
    });

    this.loadAgents();
    this.loadDirectionList();
    this.loadserviceFonctionnel();
    this.loadCampagneList();
  }

  scrollEvent = (event: any): void => {
    if (this.autoCompleteDirection.panelOpen) {
      this.autoCompleteDirection.updatePosition();
    }
    if (this.autoCompAgent.panelOpen) {
      this.autoCompAgent.updatePosition();
    }
    if (this.autoCompleteEvent.panelOpen) {
      this.autoCompleteEvent.updatePosition();
    }

    if (this.autoCompleteCamp.panelOpen) {
      this.autoCompleteCamp.updatePosition();
    }
    if (this.autoCompleteService.panelOpen) {
      this.autoCompleteService.updatePosition();
    }
  };
  // focusinmethod(){
  //   let b = document.body;
  //   b.style.overflow = "hidden";
  // }
  // focusoutmethod(){
  //   let b = document.body;
  //   b.style.overflow = "auto";
  // }

  // vérifie si une campagne est en cours
  verifCampagne() {
    let campagneActuelle: any = sessionStorage.getItem('campagneActuelle');
    let _campagneActuelle = JSON.parse(campagneActuelle);
    this.campagneEnCours = _campagneActuelle?.type == 'SAISIE_EN_COURS' ? true : false;

    // activation des boutons
    this.boutonSaisirActif = this.campagneEnCours;
    this.boutonSaisirAstreinteActif = this.campagneEnCours;
  }

  // récupération du profil utilisateur dans la session
  getUserProfile() {
    const ss_userProfile: any = sessionStorage.getItem('userProfile');
    this.userProfile = JSON.parse(ss_userProfile);
  }

  /*********************** VUE GLOBALE ***********************/

  globalDisplayedColumns: string[] = [
    'Direction',
    "Nombre d'agents",
    'Total HS',
    'Heures totales jour',
    'Heures totales nuit',
    'Heures totales dimanches et jours fériés',
    'Actions'
  ];

  astraintesDisplayedColumns: string[] = [
    'Direction',
    "Nombre d'agents",
    'Total',
    'Jour',
    'Nuit',
    'Semaine',
    'Samedi',
    'Dim. / jours fériés',
    'Week-end',
    'Crèche',
    'Actions'
  ];

  globalCellStyleCss: any = {
    "Nombre d'agents": { 'text-align': 'center', 'padding-right': '30px' },
    'Total HS': { 'text-align': 'center', 'padding-right': '30px' },
    'Heures totales jour': { 'text-align': 'center', 'padding-right': '30px' },
    'Heures totales nuit': { 'text-align': 'center', 'padding-right': '30px' },
    'Heures totales dimanches et jours fériés': { 'text-align': 'center', 'padding-right': '30px' }
  };

  //getAgentDirection
  getListe_VG1() {
    this.showSpinner = true;
    this.HS_service.getAgentDirection()
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.dataJSON_VG_agent = result.mapDirectionListeAgents;
          this.getListe_VG2();
        },
        error: (error) => {
          console.log('erreur:', error);
        }
      });
  }

  // lecture des données pour la vue globale
  getListe_VG2() {
    this.showSpinner = true;
    this.HS_service.getTotauxHeuresSupplémentaires()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.dataJSON_VG = result.map;
          this.dataTable_VG = this.transformData_VG();
          this.detectorRef.markForCheck();
        }
      });
  }

  //getAgentDirection
  getListe_Astreinte_Agent_VG() {
    this.showSpinner = true;
    this.astreintesService
      .getAgentDirection()
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.dataJSON_astrainte_agent = result.mapDirectionListeAgents;
          this.getListe_Astrainte();
        },
        error: (error) => {
          console.log('erreur:', error);
        }
      });
  }

  // lecture des données pour la vue globale astrainte
  getListe_Astrainte() {
    this.showSpinner = true;
    this.astreintesService
      .getTotauxAstrainte()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.dataJSON_ast_list = result.map;
          this.dataTable_astrainte = this.transformAstraintesData();
          this.detectorRef.markForCheck();
        }
      });
  }
  transformAstraintesData() {
    let data: any[] = [];
    for (let key in this.dataJSON_ast_list) {
      let dataJ = this.dataJSON_ast_list[key];
      let _data: any = {};
      _data['id'] = key;
      _data['Direction'] = dataJ.libelleDirection;
      _data["Nombre d'agents"] = this.dataJSON_astrainte_agent[key]?.totalAgents;
      _data['Total'] = this.sumOfValues(dataJ?.mapTypeTotalAstreintes);
      _data['Jour'] = dataJ.mapTypeTotalAstreintes.JOUR;
      _data['Nuit'] = dataJ.mapTypeTotalAstreintes.NUIT;
      _data['Semaine'] = dataJ.mapTypeTotalAstreintes.SEMAINE;
      _data['Samedi'] = dataJ.mapTypeTotalAstreintes.SAMEDI;
      _data['Dim. / jours fériés'] = dataJ.mapTypeTotalAstreintes.DIMANCHE_FERIE;
      _data['Week-end'] = dataJ.mapTypeTotalAstreintes.WEEKEND;
      _data['Crèche'] = dataJ.mapTypeTotalAstreintes.CRECHE;

      data.push(_data);
    }
    return data;
  }

  sumOfValues(obj: any) {
    let sum = 0;
    for (let key in obj) {
      if (typeof obj[key] === 'string' && !isNaN(obj[key])) {
        sum += Number(obj[key]);
      }
    }
    return sum;
  }

  transformData_VG() {
    let dataVG: any[] = [];

    for (let key in this.dataJSON_VG) {
      let dataJ = this.dataJSON_VG[key];
      let _data: any = {};

      _data['id'] = key;
      _data['Direction'] = dataJ?.libelleDirection;
      _data["Nombre d'agents"] = this.dataJSON_VG_agent[key]?.totalAgents;

      let _jour = dataJ?.mapTypeTotalHS?.JOUR;
      let _nuit = dataJ?.mapTypeTotalHS?.NUIT;
      let _dim = dataJ?.mapTypeTotalHS?.FERIE;
      _jour = _jour != null ? _jour.split(':', 2) : [0, 0];
      _nuit = _nuit != null ? _nuit.split(':', 2) : [0, 0];
      _dim = _dim != null ? _dim.split(':', 2) : [0, 0];
      let d_jour = Duration.fromObject({ hours: _jour[0], minutes: _jour[1] });
      let d_nuit = Duration.fromObject({ hours: Math.abs(_nuit[0]), minutes: Math.abs(_nuit[1]) });
      let d_dim = Duration.fromObject({ hours: _dim[0], minutes: _dim[1] });
      let d_tot = Duration.fromObject({ hours: 0, minutes: 0 });
      d_tot = d_tot.plus(d_jour).plus(d_nuit).plus(d_dim);

      _data['Heures totales jour'] = d_jour.valueOf() > 0 ? d_jour.toFormat('hh:mm') : '';
      _data['Heures totales nuit'] = d_nuit.valueOf() > 0 ? d_nuit.toFormat('hh:mm') : '';
      _data['Heures totales dimanches et jours fériés'] = d_dim.valueOf() > 0 ? d_dim.toFormat('hh:mm') : '';
      _data['Total HS'] = d_tot.valueOf() > 0 ? d_tot.toFormat('hh:mm') : '';

      dataVG.push(_data);
    }

    return dataVG;
  }

  showDetail_VG(id: any) {
    if (id) {
      let data = this.dataJSON_VG_agent[id];

      this.dialog.open(ViewDetailModalComponent, {
        data: data,
        width: '1200px',
        autoFocus: false
      });
    }
  }
  showDetail_Ast(id: any) {
    if (id != undefined && id != '') {
      let data = this.dataJSON_astrainte_agent[id];
      this.dialog.open(ViewAstrainteDetailModalComponent, {
        data: data,
        width: '1200px',
        autoFocus: false
      });
    }
  }

  recherche_VG(event: MatAutocompleteSelectedEvent) {
    let directionLibelle = event.option.value.libelle;
    this.appdataTableVG.applyLocalFilter(directionLibelle);
    this.appdataTableVGAstreinte.applyLocalFilter(directionLibelle);
  }

  effaceDirectionFiltre_VG() {
    this.form_filtre_VG.get('direction')?.setValue('');
    this.loadDirectionList();
    this.getListe_VG1();
    this.getListe_Astreinte_Agent_VG();
  }
  /*********************** HEURES SUPPLEMENTAIRES ***********************/

  hourDisplayedColumns: string[] = [
    /**
     * Dans la sous-liste plutôt
     */
    //'Campagne',
    'Sélectionner',
    'Agent',
    'Type',
    'Date création',
    'Date HS',
    'Heure début',
    'Heure fin',
    'Durée',
    'Emetteur',
    'Direction origine',
    'Direction évènement',
    'Motif',
    'Statut',
    'Actions'
  ];

  subTableDisplayedColumns: string[] = ['Campagne', 'Service origine'];

  hourCellStyleCss: any = {
    Statut: { 'text-align': 'center' },
    'Date HS': { 'text-align': 'center' },
    'Heure début': { 'text-align': 'center' },
    'Heure fin': { 'text-align': 'center' }
  };

  hourHeaderStyleCssSub: any = {
    Date: { 'text-align': 'center' },
    'Date création': { 'text-align': 'center' },
    'Heure début': { 'text-align': 'center' },
    'Heure fin': { 'text-align': 'center' },
    Durée: { 'text-align': 'center' }
  };

  hourCellStyleCssSub: any = {
    'Date HS': { 'text-align': 'center' }
  };

  // lecture des données pour les HS
  getListe_HS() {
    this.showSpinner = true;

    this.HS_service.getHeuresSupplémentairesWithParams(
      environment.pagination.offset,
      environment.pagination.limit,
      this.filtrerRecherche_HS()
    )
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          if (result) {
            this.totalRecords_HS = result.totalRecords;
            this.dataJSON_HS = result.results;
            this.hslenght = result.totalRecords;
            this.dataTable_HS = this.transformData_HS();
            this.detectorRef.markForCheck();
          }
        },
        error: (error) => {
          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: 'Le compte utilisé est mal configuré'
            }
          });
        }
      });
  }

  recherche_HS() {
    this.getListe_HS();
  }

  // concaténation des filtres de recherche
  filtrerRecherche_HS() {
    let _filtre: any = {};

    let agent = this.form_filtre_HS.controls['agent'].value;
    if (agent?.id != undefined) {
      _filtre['agent.nom'] = agent?.nom;
      _filtre['agent.nom'] += '&agent.prenom=' + agent?.prenom;
    }

    _filtre['typeHS'] = this.form_filtre_HS.controls['typeHS'].value;

    let dateCreation = this.form_filtre_HS.controls['dateCreation'].value;
    let dateCreation_dt = DateTime.fromJSDate(dateCreation).isValid ? DateTime.fromJSDate(dateCreation) : DateTime.fromISO(dateCreation);
    if (dateCreation_dt.isValid) {
      _filtre['dateCreation'] = dateCreation_dt.toFormat('yyyy-MM-dd') + 'T00:00:00';
      _filtre['dateCreation'] += '&dateCreation=' + dateCreation_dt.toFormat('yyyy-MM-dd') + 'T23:59:59'; // 2ème filtre pour rechercher l'égalité de la date
    }

    let dateRealisation = this.form_filtre_HS.controls['dateRealisation'].value;
    let dateRealisation_dt = DateTime.fromJSDate(dateRealisation).isValid
      ? DateTime.fromJSDate(dateRealisation)
      : DateTime.fromISO(dateRealisation);
    if (dateRealisation_dt.isValid) {
      _filtre['dateRealisation'] = dateRealisation_dt.toFormat('yyyy-MM-dd');
      _filtre['dateRealisation'] += '&dateRealisation=' + dateRealisation_dt.toFormat('yyyy-MM-dd'); // 2ème filtre pour rechercher l'égalité de la date
    }

    let heureDebut = this.form_filtre_HS.controls['heure_debut'].value;
    _filtre['heureDebut'] = heureDebut;

    let heureFin = this.form_filtre_HS.controls['heure_fin'].value;
    _filtre['heureFin'] = heureFin;

    let duree_val = this.form_filtre_HS.controls['duree'].value;
    if (duree_val != '' && duree_val != null) {
      let _duree = duree_val.split(':').map((e: string) => {
        return parseInt(e);
      });
      _filtre['duree'] = _duree.join(':');
    }

    _filtre['searchByEmetteur'] = this.form_filtre_HS.controls['emetteur'].value;

    let campagne = this.form_filtre_HS.controls['campagne'].value;

    // to test filtre campagne par défaut hs
    if (this.default_compagne) {
      let campagneEnSaisie: any = sessionStorage.getItem('campagneActuelle');
      let _campagneEnSaisie = JSON.parse(campagneEnSaisie);
      _filtre['campagne.id'] = _campagneEnSaisie.id;
      this.val_ft_campagne = { id: _campagneEnSaisie?.id, nom: _campagneEnSaisie?.nom };
      this.default_compagne = false;
    } else {
      _filtre['campagne.id'] = campagne?.id != undefined ? campagne?.id : '';
    }

    let directionOrigine = this.form_filtre_HS.controls['directionOrigine'].value;
    _filtre['directionOrigine'] = directionOrigine?.id != undefined ? directionOrigine?.id : '';

    let directionOrganisatrice = this.form_filtre_HS.controls['directionOrganisatrice'].value;
    _filtre['directionOrganisatrice'] = directionOrganisatrice?.id != undefined ? directionOrganisatrice?.id : '';

    let serviceFonctionnel = this.form_filtre_HS.controls['serviceFonctionnel'].value;
    _filtre['serviceFonctionnel'] = serviceFonctionnel?.id != undefined ? serviceFonctionnel?.id : '';

    _filtre['motif'] = this.form_filtre_HS.controls['motif'].value;
    _filtre['statut'] = this.form_filtre_HS.controls['statut'].value;

    let _arg: any = [];

    for (let key in _filtre) {
      if (_filtre[key] != undefined && _filtre[key] != '') {
        _arg.push(key + '=' + _filtre[key]);
      }
    }

    let filtres = _arg.join('&');

    let filtre_actif = Object.keys(_filtre).filter((k) => _filtre[k] != '' && _filtre[k] != null);
    let nb_filtre_actif = filtre_actif.length;
    if (nb_filtre_actif > 0) {
      this.FiltreHSActif = true;
      this.filtrerHS_libelle$ = of('Filtré (' + nb_filtre_actif + ')');
    } else {
      this.FiltreHSActif = false;
      this.filtrerHS_libelle$ = of('Filtres avancés');
    }

    return filtres;
  }

  // efface le filtre avec la croix
  effaceFiltre(event: any, controlName: string) {
    event.stopPropagation();

    if (controlName != undefined && controlName != '') {
      this.form_filtre_HS.controls[controlName].setValue('');
      this.loadserviceFonctionnel();
    }
  }
  effaceAgentFiltre(event: any, controlName: string) {
    event.stopPropagation();

    if (controlName != undefined && controlName != '') {
      this.form_filtre_HS.controls[controlName].setValue('');
      this.loadAgents();
    }
  }
  effaceDirectionFiltre(event: any, controlName: string) {
    event.stopPropagation();
    this.val_ft_directionOrigine = '';
    if (controlName != undefined && controlName != '') {
      this.form_filtre_HS.controls[controlName].setValue('');
      this.loadDirectionList();
    }
  }
  effaceCampagneFiltre(event: any, controlName: string) {
    event.stopPropagation();

    if (controlName != undefined && controlName != '') {
      this.form_filtre_HS.controls[controlName].setValue('');
      this.loadCampagneList();
    }
  }

  effaceEventFiltre(event: any, controlName: string) {
    event.stopPropagation();

    if (controlName != undefined && controlName != '') {
      this.form_filtre_HS.controls[controlName].setValue('');
      this.loadDirectionList();
    }
  }

  agentdisplayFn(agent: any): string {
    return agent ? `${agent.nom} ${agent.prenom}` : '';
  }

  // /*   directionOriginedisplayFn(item: any): any {
  //   item ? `${item.libelle}` : '';
  // } */
  /*   directionEvenementdisplayFn(item: any): any {
    item ? `${item.libelle}` : '';
  } */
  /*  servicedisplayFn(element: any): any {
    element ? `${element.libelle}` : '';
  } */

  agentFilter(event: any): void {
    const value = event.target.value;
    if (typeof value !== 'string') {
      this.filteredOptions = [];
      return;
    }
    const filterValue = value.toLowerCase().split(' ').join('');
    this.filteredOptions = this.agentsList.filter((agent: any) =>
      (agent.nom + agent.prenom).toLowerCase().split(' ').join('').includes(filterValue)
    );
  }
  directionOrigineFilter(event: any): void {
    // efface la liste de recherche pour le filtre de l'onglet vue globale lorsqu'on commence à filtrer
    // ne s'applique que si l'onglet vue globale est activé, dans les autres onglets les objets dataTable concernés sont indéfinis
    if (this.appdataTableVG && this.appdataTableVGAstreinte) {
      this.appdataTableVG.applyLocalFilter('');
      this.appdataTableVGAstreinte.applyLocalFilter('');
    }

    const value = event.target.value;
    if (typeof value !== 'string') {
      this.directionList = [];
      return;
    }
    const filterValue = value.toLowerCase().split(' ').join('');
    this.directionList = this.listDirectionOrigine.filter((item: any) =>
      item.libelle.toLowerCase().split(' ').join('').includes(filterValue)
    );
  }
  campagneFilter(event: any): void {
    this.default_compagne = false;
    this.default_compagne_astreinte = false;
    const value = event.target.value;
    if (typeof value !== 'string') {
      this.campagneList = [];
      return;
    }
    const filterValue = value.toLowerCase().split(' ').join('');
    this.campagneList = this.listCampagne.filter((campagne: any) => campagne?.nom.toLowerCase().split(' ').join('').includes(filterValue));
  }

  directionEvenementFilter(event: any): void {
    const value = event.target.value;
    if (typeof value !== 'string') {
      this.directionEventList = [];
      return;
    }
    const filterValue = value.toLowerCase().split(' ').join('');
    this.directionEventList = this.listDirectionOrigine.filter((item: any) =>
      item.libelle.toLowerCase().split(' ').join('').includes(filterValue)
    );
  }
  serviceFilter(event: any): void {
    const value = event.target.value;
    if (typeof value !== 'string') {
      this.serviceFonctionnelList = [];
      return;
    }
    const filterValue = value.toLowerCase().split(' ').join('');
    this.serviceFonctionnelList = this.listService.filter((element: any) =>
      element.libelle.toLowerCase().split(' ').join('').includes(filterValue)
    );
  }
  changedurationfilter() {
    const commonDate = DateTime.now();
    const startDateTime = DateTime.fromFormat(
      `${commonDate.toISODate()}T${this.form_filtre_HS.get('heure_debut')?.value}`,
      "yyyy-MM-dd'T'HH:mm"
    );
    let endDateTime = DateTime.fromFormat(`${commonDate.toISODate()}T${this.form_filtre_HS.get('heure_fin')?.value}`, "yyyy-MM-dd'T'HH:mm");
    if (startDateTime.isValid && endDateTime.isValid) {
      // Si l'heure de fin est antérieure à l'heure de début, ajoute un jour à l'heure de fin
      if (endDateTime <= startDateTime) {
        this.heureFinerror = true;
      } else {
        this.heureFinerror = false;
      }
    }
  }

  loadAgents() {
    this.agentsService
      .getAllAgents()
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          this.agentsList = data;
          this.filteredOptions = [...this.agentsList];
        }
      });
  }

  loadDirectionList() {
    this.directionService
      .getAllDirections()
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          this.listDirectionOrigine = data.filter((o: any) => {
            if (o.libelle !== '') {
              return o;
            }
          });
          console.warn(this.listDirectionOrigine);

          this.directionList = [...this.listDirectionOrigine];
          this.directionEventList = [...this.listDirectionOrigine];
        }
      });
  }
  loadCampagneList() {
    this.campagneServie
      .getAllCampagnes()
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          this.listCampagne = data.results;
          this.campagneList = [...this.listCampagne];
        }
      });
  }
  directionOriginFilter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.directionList.filter((direction) => direction.libelle.toLowerCase().includes(filterValue));
  }
  directionOriginedisplayFn(direction?: any): string {
    return direction ? direction.libelle : undefined;
  }
  // campagnedisplayFn(value: string): any[] {
  //   const filterValue = value.toLowerCase();
  //   return this.campagneList.filter((campagne) => campagne?.nom.toLowerCase().includes(filterValue));
  // }
  campagnedisplayFn(campagne?: any): string {
    return campagne ? campagne?.nom : undefined;
  }

  directionEvenementdisplayFn(event?: any): any {
    return event ? event.libelle : undefined;
  }
  servicedisplayFn(element?: any): any {
    return element ? element.libelle : undefined;
  }
  onDirectionOrigineselected(event: MatAutocompleteSelectedEvent): void {
    this.form_filtre_HS.get('directionOrganisatrice')?.setValue(event.option.value.libelle);
  }

  loadserviceFonctionnel() {
    this.directionService
      .getAllServices()
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          this.listService = data.filter((o: any) => {
            if (o.libelle !== '') {
              return o;
            }
          });

          this.serviceFonctionnelList = this.listService;
        }
      });
  }

  transformData_HS(data = null, data2?: any) {
    if (data) {
      this.dataJSON_HS = data;
    }

    // data pour l'export
    let dataJSON = data2 ? data2 : this.dataJSON_HS;

    let dataHS: any[] = [];
    let roles = this.userRoles;
    let profil_admin = roles.includes('dhesa-admin');
    let profil_assistant_cds = roles.includes('dhesa-assistant-cds');
    let profil_assistant_responsable = roles.includes('dhesa-assistant-responsable');
    let username = this.userProfile?.username;

    for (let key in dataJSON) {
      let dataJ = dataJSON[key];
      let agent = dataJ?.agent;
      let emetteur = '';
      if (dataJ?.emetteur?.nom && dataJ?.emetteur?.prenom) {
        emetteur = dataJ?.emetteur?.nom + ' ' + dataJ?.emetteur?.prenom;
      }
      let _data: any = {};

      _data['id'] = dataJ?.id;
      _data['Agent'] = agent?.nom + ' ' + agent?.prenom;

      let typeCode = dataJ?.typeHS;
      let typeText = '';
      if (typeCode == 'FERIE') {
        typeText = 'Dim./Férié';
      } else if (typeCode == 'NUIT') {
        typeText = 'Nuit';
      } else if (typeCode == 'JOUR') {
        typeText = 'Jour';
      }
      _data['Type'] = typeText;
      if (!data) {
        _data['Date création'] = this.pipe.transform(dataJ?.dateCreation, 'shortDate');
        _data['Date HS'] = this.pipe.transform(dataJ?.dateRealisation, 'shortDate');
      }

      _data['Heure début'] = dataJ?.heureDebut.slice(0, -3);

      // arrondit les secondes en minutes
      // dataJ.heureFin = '23:59:59'; // pour test
      let _heure_fin = dataJ?.heureFin.split(':').map((a: any) => Number(a));

      if (_heure_fin[2] != undefined && _heure_fin[2] > 30) {
        _heure_fin[2] = 0;
        if (_heure_fin[1] == 59) {
          _heure_fin[1] = 0;
          _heure_fin[0] = _heure_fin[0] == 23 ? 0 : _heure_fin[0] + 1;
        } else {
          _heure_fin[1] += 1;
        }
      }
      let d_heure_fin = Duration.fromObject({ hours: _heure_fin[0], minutes: _heure_fin[1], seconds: _heure_fin[2] });
      _data['Heure fin'] = d_heure_fin.toFormat('hh:mm');

      let _duree: any = dataJ?.duree.split(':');
      let hr = _duree[0].toString().length == 1 ? '0' + _duree[0] : _duree[0];
      let min = _duree[1].toString().length == 1 ? '0' + _duree[1] : _duree[1];
      _data['Durée'] = hr + ':' + min;

      _data['Emetteur'] = emetteur;
      _data['Direction origine'] = dataJ?.directionOrigine?.libelle;
      _data['Direction évènement'] = dataJ?.directionOrganisatrice?.libelle;
      _data['Motif'] = dataJ?.motif;

      let status_code = dataJ?.statut;
      let status_text = '';
      let status_class = '';

      if (status_code == 'A_VALIDER') {
        status_class = 'badge-gray';
        status_text = 'Avis non renseigné';
      } else if (status_code == 'VALIDEES') {
        status_class = 'badge-green-directeur';
        status_text = 'Avis favorable - Directeur';
      } else if (status_code == 'REFUSEES') {
        status_class = 'badge-red';
        status_text = 'Avis défavorable - Directeur';
      } else if (status_code == 'VALIDEES_N2') {
        status_class = 'badge-green-dga';
        status_text = 'Avis favorable - DG';
      } else if (status_code == 'REFUSEES_N2') {
        status_class = 'badge-red';
        status_text = 'Avis défavorable - DG';
      } else if (status_code == 'SAISIE_A_RENOUVELER') {
        status_class = 'badge-gray';
        status_text = 'Saisie à renouveler';
      } else if (status_code == 'A_VALIDER_RESPONSABLE') {
        status_class = 'badge-gray';
        status_text = 'Avis non renseigné (responsable)';
      } else if (status_code == 'VALIDEES_RESPONSABLE') {
        status_class = 'badge-green-directeur';
        status_text = 'Avis favorable - Responsable';
      } else if (status_code == 'REFUSEES_RESPONSABLE') {
        status_class = 'badge-red';
        status_text = 'Avis défavorable - Responsable';
      }

      _data['Statut'] = { class: status_class, text: status_text, object: true };
      _data['status_code'] = status_code;
      _data['Statut_export'] = status_text; // champ pour la fonction export

      _data['editer_hidden'] = true;
      _data['delete_hidden'] = true;
      _data['refresh_hidden'] = true;

      // actions pour statut A_VALIDER
      // Admin ou créateur a accès aux actions
      if (
        (profil_admin ||
          ((profil_assistant_cds || profil_assistant_responsable) && dataJ?.emetteur?.login?.toUpperCase() == username?.toUpperCase())) &&
        this.campagneEnCours &&
        (dataJ.statut == 'A_VALIDER' || dataJ.statut == 'A_VALIDER_RESPONSABLE')
      ) {
        _data['editer_hidden'] = false;
        _data['delete_hidden'] = false;
      }

      // actions pour statut SAISIE_A_RENOUVELER
      // Admin ou créateur a accès aux actions
      if (
        (profil_admin ||
          ((profil_assistant_cds || profil_assistant_responsable) && dataJ?.emetteur?.login?.toUpperCase() == username?.toUpperCase())) &&
        this.campagneEnCours &&
        dataJ.statut == 'SAISIE_A_RENOUVELER'
      ) {
        _data['editer_hidden'] = true;
        _data['delete_hidden'] = false;
        _data['refresh_hidden'] = false;
      }

      // désactivation du bouton supprimer si pas de campagne de type SAISIE
      if (this.campagneEnCours) {
        _data['delete_hidden'] = true;
      }

      _data['subTable'] = [];
      let _subTable: any = {};

      _subTable['Campagne'] = dataJ?.campagne?.nom;
      _subTable['Service origine'] = dataJ?.serviceFonctionnel?.libelle;

      _data['subTable'].push(_subTable);
      dataHS.push(_data);
    }

    return dataHS;
  }

  DeleteHSElement(element: any) {
    this.HS_service.DeleteHS(element.id)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.getListe_HS();
        },
        error: (error) => {
          console.log('erreur:', error);

          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: "une erreur s'est produite lors de la suppression de masse"
            }
          });
        }
      });
  }

  DeleteHSBulk(data: any) {
    this.HS_service.deleteHSBulk(data)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.modaleConfirmSuppressionHS('hs', Number(result));
          this.getListe_HS();
          this.getListe_VG1(); // VG
        },
        error: (error) => {
          console.log('erreur:', error);
          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: "Une erreur s'est produite lors de la suppression de masse."
            }
          });
        }
      });
  }

  modaleConfirmSuppressionHS(type: string, nb: number) {
    let content = '';
    let content2 = '';

    if (type == 'hs') {
      content = 'La saisie HS a été supprimée avec succès ';
      content2 = `(${nb} HS supprimée(s)).`; // concernant x agent(s)
    } else {
      content = 'La saisie Astreinte a été supprimée avec succès ';
      content2 = `(${nb} astreinte(s) supprimée(s)).`; // concernant x agent(s)
    }

    this.dialog.open(ValidationModalComponent, {
      width: '540px',
      data: {
        title: 'Confirmation',
        content: content,
        content2: content2
      }
    });
  }

  // modale de création HS
  openDialog() {
    const dialogRef = this.dialog.open(CreateHsModalComponent, {
      width: '960px',
      disableClose: true,
      autoFocus: false,
      data: []
    });
    dialogRef.afterClosed().subscribe((action) => {
      if (action) {
        this.getListe_HS();
        this.getListe_VG1();
      }
      this.broadcast.broadcastEvent({
        broadcastedevent: 'resetPagination'
      });
    });
  }

  /*openCreateHS() {
    this.dialog.open(CreateHsDialogComponent, {
      width: '960px',
      disableClose: true,
      data: []
    });
  }*/

  // modale d'édition HS
  openHeureSupEdit(id: number) {
    this.heureSupDataID(this.dataJSON_HS, id);

    const dialogRef = this.dialog.open(HeureSupEditionComponent, {
      width: '960px',
      data: this.rowHeureSup
    });

    dialogRef.afterClosed().subscribe((action) => {
      if (action) {
        this.getListe_HS();
        this.getListe_VG1();
      }
      this.broadcast.broadcastEvent({
        broadcastedevent: 'resetPagination'
      });
    });
  }

  deleteHs(event: any) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      width: '600px',
      data: {
        id: event,
        description: 'Voulez-vous supprimer la saisie de cette heure supplémentaire ?',
        type: 'hs'
      }
    });
    dialogRef.afterClosed().subscribe((action) => {
      if (action) {
        this.getListe_VG1(); // VG
      }

      this.broadcast.broadcastEvent({
        broadcastedevent: 'resetPagination'
      });
    });
  }

  renouvelerSaisieHs(event: any) {
    const dialogRef = this.dialog.open(RefreshSaisieModalComponent, {
      width: '600px',
      data: {
        id: event,
        description: 'Voulez-vous renouveler la saisie de cette heure supplémentaire ?',
        type: 'hs'
      }
    });
    dialogRef.afterClosed().subscribe((action) => {
      this.broadcast.broadcastEvent({
        broadcastedevent: 'resetPagination'
      });
    });
  }

  // suppression de toutes les cases cochées dans la liste
  suppressionEnMasseHS(action: string, select_key: string) {
    // retrouve les data pour l'affichage du détail dans la moadale
    let _data: any[] = [];
    let _selection: any = this.selectionService.getData;
    let ligne_valide = 0;
    if (select_key && _selection[select_key] != undefined) {
      let _dataTable = select_key == 'saisieHS' ? [...this.dataTable_HS] : [...this.dataTable_Ast];
      // filtre les lignes d'après le statut, uniquement les lignes avec un status A_VALIDER ou SAISIE_A_RENOUVELER sont supprimables
      _dataTable.forEach((data_a: any) => {
        if (_selection[select_key].includes(data_a.id)) {
          if (
            data_a.status_code == 'A_VALIDER' ||
            data_a.status_code == 'A_VALIDER_RESPONSABLE' ||
            data_a.status_code == 'SAISIE_A_RENOUVELER'
          ) {
            _data.push(data_a);
          } else {
            ligne_valide++;
          }
        }
      });
    }

    // lignes validées qui ne sont plus supprimables
    if (ligne_valide > 0 && _data.length == 0) {
      action = 'lignes non supprimables';
    }

    // aucune sélection on ne fait rien
    if (_data.length <= 0 && ligne_valide == 0) {
      this.dialog.open(AlerteModalComponent, {
        width: '640px',
        data: {
          title: 'Alerte',
          content: `Vous devez faire une sélection avant de supprimer en masse !`
        }
      });
    } else {
      let _data_id = _data.map((d: any) => d.id);
      let type = select_key == 'saisieHS' ? 'hs' : 'astreinte';

      const dialogRef = this.dialog.open(ConfirmSuppressionModalComponent, {
        width: '640px',
        data: {
          type: type,
          action: action,
          data: _data
        }
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (Array.isArray(result)) {
          // demande la suppression
          if (result[0] == 'supprimer') {
            if (type == 'hs') {
              this.DeleteHSBulk(_data_id);
            } else if (type == 'astreinte') {
              this.DeleteAstreinteBulk(_data_id);
            }

            this.broadcast.broadcastEvent({
              broadcastedevent: 'resetPagination'
            });
          }
        }
      });
    }
  }

  // recherche la ligne des heures sup correspondant à l'id
  heureSupDataID(data: any, id: number) {
    let index = Object.keys(data).find((k) => data[k].id === id);

    if (index != null) {
      this.rowHeureSup = data[Number(index)];
    }
  }

  // exportation des lignes heures supp en CSV
  export_HS() {
    this.HS_service.getHeuresSupplémentairesWithParams(0 /*page*/, this.totalRecords_HS /*size*/, this.filtrerRecherche_HS())
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          let dataTable_HS = this.transformData_HS(null, result.results);

          let _data_export: any[] = [];

          dataTable_HS.forEach((_ligne: any) => {
            let _data = _ligne;
            if (_ligne['subTable'] != undefined) {
              _data = { ..._data, ..._ligne['subTable'][0] };
              delete _data['subTable'];
            }
            delete _data['id'];
            delete _data['delete_hidden'];
            delete _data['editer_hidden'];
            _data['Statut'] = _data['Statut_export'];
            delete _data['Statut_export'];

            _data_export.push(_data);
          });

          this.convertExcel(_data_export, 'export_donnees_saisies_HS_');
        },
        error: (error) => {
          console.log('error:', error);
          let erreur_texte = error.statusText || error.error.detail || error.error || error;

          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: "Une erreur est survenue lors de l'export des heures supplémentaires.",
              status: 'STATUS: ' + error.status,
              detail: 'DETAIL: ' + erreur_texte
            }
          });
        }
      });
  }

  exporterLigne_HS(id: any) {
    let index = Object.keys(this.dataJSON_HS).find((k) => this.dataJSON_HS[Number(k)].id === id);

    if (index != null) {
      let result = this.dataJSON_HS[Number(index)];
      let _ligne: any = this.transformData_HS(null, [result])[0];
      let _data = _ligne;
      let _data_export: any[] = [];

      if (_ligne['subTable'] != undefined) {
        _data = { ..._data, ..._ligne['subTable'][0] };
        delete _data['subTable'];
      }
      delete _data['id'];
      delete _data['delete_hidden'];
      delete _data['editer_hidden'];
      _data['Statut'] = _data['Statut_export'];
      delete _data['Statut_export'];

      _data_export.push(_data);
      let agent = '';
      if (result?.agent?.nom != undefined) {
        agent = result?.agent?.nom + ' ' + result?.agent?.prenom;
        agent = agent.replace(' ', '_').toUpperCase() + '_';
      }

      this.convertExcel(_data_export, 'export_donnees_saisies_HS_' + agent);
    }
  }

  convertExcel(data: any, name: string) {
    const search = '/';
    const replaceWith = '-';
    const date = new Date().toLocaleDateString('en-GB').split(search).join(replaceWith);
    let fileName = `${name}${date}`;
    this.excelService.exportToExcel(data, `${fileName}.xlsx`, 'Sheet1');
  }

  exportPdf_HS(id: any) {
    this.HS_service.getPdf(id)
      .pipe(take(1))
      .subscribe({
        next: (result: any) => {
          const blob = new Blob([result], { type: 'application/pdf' });

          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          const search = '/';
          const replaceWith = '-';
          const date = new Date().toLocaleDateString('en-GB').split(search).join(replaceWith);
          let fileName = `hs_${date}`;
          link.download = fileName;

          // Append the link to the document
          document.body.appendChild(link);

          // Trigger the click event to start the download
          link.click();

          // Remove the link from the document
          document.body.removeChild(link);
        },
        error: (error) => {}
      });
  }

  /*********************** ASTREINTES ***********************/

  // lecture des données pour les astreintes
  getListe_astreinte() {
    this.showSpinner = true;

    this.astreintesService
      .getAstreintesWithParams(environment.pagination.offset, environment.pagination.limit, this.filtrerRecherche_astreinte())
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.totalRecords_Ast = result.totalRecords;
          this.dataJSON_Ast = result.results;
          this.astlenght = result.totalRecords;
          this.dataTable_Ast = this.transformData_astreintes();
          this.detectorRef.markForCheck();
        }
      });
  }

  transformData_astreintes(data = null, data2?: any) {
    if (data) {
      this.dataJSON_Ast = data;
    }

    // data pour l'export
    let dataJSON = data2 ? data2 : this.dataJSON_Ast;

    let dataHS: any[] = [];
    let roles = this.userRoles;
    let profil_admin = roles.includes('dhesa-admin');
    let profil_assistant_cds = roles.includes('dhesa-assistant-cds');
    let profil_assistant_responsable = roles.includes('dhesa-assistant-responsable');
    let username = this.userProfile?.username;
    for (let key in dataJSON) {
      let dataJ = dataJSON[key];
      let agent = dataJ?.agent;
      let emetteur = '';
      if (dataJ?.emetteur?.nom && dataJ?.emetteur?.prenom) {
        emetteur = dataJ?.emetteur?.nom + ' ' + dataJ?.emetteur?.prenom;
      }
      let _data: any = {};

      _data['id'] = dataJ?.id;
      _data['Agent'] = agent?.nom + ' ' + agent?.prenom;

      let typeCode = dataJ?.type;
      let typeText = '';
      if (typeCode == 'JOUR') {
        typeText = 'Jour';
      } else if (typeCode == 'NUIT') {
        typeText = 'Nuit';
      } else if (typeCode == 'SEMAINE') {
        typeText = 'Semaine';
      } else if (typeCode == 'DIMANCHE_FERIE') {
        typeText = 'Dimanche/J.F';
      } else if (typeCode == 'CRECHE') {
        typeText = 'Crèche';
      } else if (typeCode == 'SAMEDI') {
        typeText = 'Samedi';
      } else if (typeCode == 'WEEKEND') {
        typeText = 'Week-end';
      }

      _data['Type'] = typeText;

      if (!data) {
        _data['Date création'] = this.pipe.transform(dataJ?.dateCreation, 'shortDate');
        _data['Date début'] = this.pipe.transform(dataJ?.dateDebut, 'shortDate');
        _data['Date fin'] = this.pipe.transform(dataJ?.dateFin, 'shortDate');
      }

      _data['Nombre'] = dataJ?.nombre;
      _data['Emetteur'] = emetteur;
      _data['Direction origine'] = dataJ?.directionOrigine?.libelle;

      let status_code = dataJ?.statut;
      let status_text: { class: string; text: string; object: boolean } | null = null;

      if (status_code == 'A_VALIDER') {
        status_text = { class: 'badge-gray', text: 'Avis non renseigné', object: true };
      } else if (status_code == 'VALIDEES') {
        status_text = { class: 'badge-green-directeur', text: 'Avis favorable - Directeur', object: true };
      } else if (status_code == 'REFUSEES') {
        status_text = { class: 'badge-red', text: 'Avis défavorable - Directeur', object: true };
      } else if (status_code == 'VALIDEES_N2') {
        status_text = { class: 'badge-green-dga', text: 'Avis favorable - DG', object: true };
      } else if (status_code == 'REFUSEES_N2') {
        status_text = { class: 'badge-red', text: 'Avis défavorable - DG', object: true };
      } else if (status_code == 'SAISIE_A_RENOUVELER') {
        status_text = { class: 'badge-gray', text: 'Saisie à renouveler', object: true };
      } else if (status_code == 'A_VALIDER_RESPONSABLE') {
        status_text = { class: 'badge-gray', text: 'Avis non renseigné (responsable)', object: true };
      } else if (status_code == 'VALIDEES_RESPONSABLE') {
        status_text = { class: 'badge-green-directeur', text: 'Avis favorable - Responsable', object: true };
      } else if (status_code == 'REFUSEES_RESPONSABLE') {
        status_text = { class: 'badge-red', text: 'Avis défavorable - Responsable', object: true };
      }
      _data['Statut'] = status_text;
      _data['status_code'] = status_code;
      _data['Statut_export'] = status_text?.text;

      // activation du bouton éditer
      _data['editer_hidden'] = true;
      _data['delete_hidden'] = true;
      _data['refresh_hidden'] = true;

      // actions pour statut A_VALIDER
      // Admin ou créateur a accès aux actions
      if (
        (profil_admin ||
          ((profil_assistant_cds || profil_assistant_responsable) && dataJ?.emetteur?.login?.toUpperCase() == username?.toUpperCase())) &&
        this.campagneEnCours &&
        (dataJ.statut == 'A_VALIDER' || dataJ.statut == 'A_VALIDER_RESPONSABLE')
      ) {
        _data['editer_hidden'] = false;
        _data['delete_hidden'] = false;
      }

      // actions pour statut SAISIE_A_RENOUVELER
      // Admin ou créateur a accès aux actions
      if (
        (profil_admin ||
          ((profil_assistant_cds || profil_assistant_responsable) && dataJ?.emetteur?.login?.toUpperCase() == username?.toUpperCase())) &&
        this.campagneEnCours &&
        dataJ.statut == 'SAISIE_A_RENOUVELER'
      ) {
        _data['editer_hidden'] = true;
        _data['delete_hidden'] = false;
        _data['refresh_hidden'] = false;
      }

      // désactivation du bouton supprimer si pas de campagne de type SAISIE
      if (this.campagneEnCours) {
        _data['delete_hidden'] = true;
      }

      _data['subTable'] = [];
      let _subTable: any = {};

      _subTable['Campagne'] = dataJ?.campagne?.nom;
      _subTable['Service origine'] = dataJ?.serviceFonctionnel?.libelle;
      _subTable['Motif'] = dataJ?.motif;

      _data['subTable'].push(_subTable);
      dataHS.push(_data);
    }

    return dataHS;
  }

  openAstreintsDialog() {
    const dialogRef = this.dialog.open(AstreinteCreateModalComponent, {
      width: '960px',
      disableClose: true,
      autoFocus: false,
      data: []
    });

    dialogRef.afterClosed().subscribe((action) => {
      if (action) {
        this.getListe_astreinte();
        this.getListe_Astreinte_Agent_VG(); // VG
      }
      this.broadcast.broadcastEvent({
        broadcastedevent: 'resetPagination'
      });
    });
  }

  openAstreinteEdit(id: number) {
    this.astreinteDataID(this.dataJSON_Ast, id);

    const dialogRef = this.dialog.open(AstreinteEditionComponent, {
      width: '1020px',
      data: this.rowAstreinte
    });

    dialogRef.afterClosed().subscribe((action) => {
      if (action) {
        this.getListe_astreinte();
        this.getListe_Astreinte_Agent_VG(); // VG
      }
      this.broadcast.broadcastEvent({
        broadcastedevent: 'resetPagination'
      });
    });
  }
  exportPdf_astreinte(id: any) {
    this.astreintesService
      .getPdfAstreinte(id)
      .pipe(take(1))
      .subscribe({
        next: (result: any) => {
          const blob = new Blob([result], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          const search = '/';
          const replaceWith = '-';
          const date = new Date().toLocaleDateString('en-GB').split(search).join(replaceWith);
          let fileName = `astreinte_${date}`;
          link.download = fileName;

          // Append the link to the document
          document.body.appendChild(link);

          // Trigger the click event to start the download
          link.click();

          // Remove the link from the document
          document.body.removeChild(link);
        },
        error: (error) => {}
      });
  }

  // recherche la ligne des astreintes correspondant à l'id
  astreinteDataID(data: any, id: number) {
    let index = Object.keys(data).find((k) => data[k].id === id);

    if (index != null) {
      this.rowAstreinte = this.dataJSON_Ast[Number(index)];
    }
  }

  deleteAstr(event: any) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      width: '600px',
      data: {
        id: event,
        description: 'Voulez-vous supprimer la saisie de cette astreinte ?',
        type: 'astreinte'
      }
    });
    dialogRef.afterClosed().subscribe((action) => {
      if (action) {
        this.getListe_astreinte();
        this.getListe_Astreinte_Agent_VG(); // VG
      }
      this.broadcast.broadcastEvent({
        broadcastedevent: 'resetPagination'
      });
    });
  }
  renouvelerSaisieAstreinte(event: any) {
    const dialogRef = this.dialog.open(RefreshSaisieModalComponent, {
      width: '600px',
      data: {
        id: event,
        description: 'Voulez-vous renouveler la saisie de cette astreinte ?',
        type: 'astreinte'
      }
    });
    dialogRef.afterClosed().subscribe((action) => {
      if (action) {
        this.getListe_astreinte();
      }
      this.broadcast.broadcastEvent({
        broadcastedevent: 'resetPagination'
      });
    });
  }

  DeleteAstreinteBulk(data: any) {
    this.astreintesService
      .deleteAstreinteBulk(data)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.modaleConfirmSuppressionHS('astreinte', Number(result));
          this.getListe_astreinte();
        },
        error: (error) => {
          console.log('erreur:', error);

          const dialogRef = this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: "Une erreur s'est produite lors de la suppression de masse."
            }
          });
          dialogRef.afterClosed().subscribe((action) => {
            if (action) {
              this.getListe_astreinte();
              this.getListe_Astreinte_Agent_VG(); // VG
            }
            this.broadcast.broadcastEvent({
              broadcastedevent: 'resetPagination'
            });
          });
        }
      });
  }

  updatePaginationAstreinte(event: any) {
    if (event) {
      this.astreintesService
        .getAstreintesWithParams(event.offset, event.limit, this.filtrerRecherche_astreinte())
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            this.dataJSON_Ast = result.results;
            this.astlenght = result.totalRecords;
            this.dataTable_Ast = this.transformData_astreintes();
            this.detectorRef.markForCheck();
          }
        });
    } else {
      this.astreintesService
        .getAstreintesWithParams(environment.pagination.offset, environment.pagination.limit, this.filtrerRecherche_astreinte())
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            this.dataJSON_Ast = result.results;
            this.astlenght = result.totalRecords;
            this.dataTable_Ast = this.transformData_astreintes();
            this.detectorRef.markForCheck();
          }
        });
    }
  }

  recherche_astreinte() {
    this.getListe_astreinte();
  }

  // concaténation des filtres de recherche
  filtrerRecherche_astreinte() {
    let _filtre: any = {};

    let agent = this.form_filtre_Ast.controls['agent'].value;
    if (agent?.id != undefined) {
      _filtre['agent.nom'] = agent?.nom;
      _filtre['agent.nom'] += '&agent.prenom=' + agent?.prenom;
    }

    _filtre['type'] = this.form_filtre_Ast.controls['type'].value;

    let dateCreation = this.form_filtre_Ast.controls['dateCreation'].value;
    let dateCreation_dt = DateTime.fromJSDate(dateCreation).isValid ? DateTime.fromJSDate(dateCreation) : DateTime.fromISO(dateCreation);
    if (dateCreation_dt.isValid) {
      _filtre['dateCreation'] = dateCreation_dt.toFormat('yyyy-MM-dd') + 'T00:00:00';
      _filtre['dateCreation'] += '&dateCreation=' + dateCreation_dt.toFormat('yyyy-MM-dd') + 'T23:59:59'; // 2ème filtre pour rechercher l'égalité de la date
    }

    let dateDebut = this.form_filtre_Ast.controls['dateDebut'].value;
    let dateDebut_dt = DateTime.fromJSDate(dateDebut).isValid ? DateTime.fromJSDate(dateDebut) : DateTime.fromISO(dateDebut);
    let dateFin = this.form_filtre_Ast.controls['dateFin'].value;
    let dateFin_dt = DateTime.fromJSDate(dateFin).isValid ? DateTime.fromJSDate(dateFin) : DateTime.fromISO(dateFin);

    if (dateDebut_dt.isValid && !dateFin_dt.isValid) {
      _filtre['dateDebut'] = dateDebut_dt.toFormat('yyyy-MM-dd') + '&dateDebut=' + dateDebut_dt.toFormat('yyyy-MM-dd');
    } else if (dateFin_dt.isValid && !dateDebut_dt.isValid) {
      _filtre['dateFin'] = dateFin_dt.toFormat('yyyy-MM-dd') + '&dateFin=' + dateFin_dt.toFormat('yyyy-MM-dd');
    } else if (dateDebut_dt.isValid && dateFin_dt.isValid) {
      _filtre['dateDebut'] = dateDebut_dt.toFormat('yyyy-MM-dd');
      _filtre['dateFin'] = dateDebut_dt.toFormat('yyyy-MM-dd') + '&dateFin=' + dateFin_dt.toFormat('yyyy-MM-dd');
    }

    _filtre['nombre'] = this.form_filtre_Ast.controls['nombre'].value;
    _filtre['searchByEmetteur'] = this.form_filtre_Ast.controls['emetteur'].value;

    let campagne = this.form_filtre_Ast.controls['campagne'].value;

    //to test filtre campagne par défaut astreintes

    if (this.default_compagne_astreinte) {
      let campagneEnSaisie: any = sessionStorage.getItem('campagneActuelle');
      let _campagneEnSaisie = JSON.parse(campagneEnSaisie);
      _filtre['campagne.id'] = _campagneEnSaisie?.id;
      this.val_ft_ast_campagne = { id: _campagneEnSaisie?.id, nom: _campagneEnSaisie?.nom };

      this.default_compagne_astreinte = false;
    } else {
      _filtre['campagne.id'] = campagne?.id != undefined ? campagne?.id : '';
    }

    let directionOrigine = this.form_filtre_Ast.controls['directionOrigine'].value;
    _filtre['directionOrigine'] = directionOrigine?.id != undefined ? directionOrigine?.id : '';

    _filtre['statut'] = this.form_filtre_Ast.controls['statut'].value;

    let serviceFonctionnel = this.form_filtre_Ast.controls['serviceFonctionnel'].value;
    _filtre['serviceFonctionnel'] = serviceFonctionnel?.id != undefined ? serviceFonctionnel?.id : '';
    let _arg: any = [];

    for (let key in _filtre) {
      if (_filtre[key] != undefined && _filtre[key] != '') {
        _arg.push(key + '=' + _filtre[key]);
      }
    }

    let filtres = _arg.join('&');

    let filtre_actif = Object.keys(_filtre).filter((k) => _filtre[k] != '' && _filtre[k] != null);
    let nb_filtre_actif = filtre_actif.length;
    if (nb_filtre_actif > 0) {
      this.FiltreAstActif = true;
      this.filtrerAst_libelle$ = of('Filtré (' + nb_filtre_actif + ')');
    } else {
      this.FiltreAstActif = false;
      this.filtrerAst_libelle$ = of('Filtres avancés');
    }

    return filtres;
  }

  // efface le filtre avec la croix
  effaceFiltreAst(event: any, controlName: string) {
    event.stopPropagation();
    if (controlName != undefined && controlName != '') {
      this.form_filtre_Ast.controls[controlName].setValue('');
    }
  }

  effaceFiltreStatut(event: any, controlName: string) {
    event.stopPropagation();
    this.val_ft_statut = '';
    if (controlName != undefined && controlName != '') {
      this.form_filtre_Ast.controls[controlName].setValue('');
    }
  }

  effaceAgentFiltreAst(event: any, controlName: string) {
    event.stopPropagation();

    if (controlName != undefined && controlName != '') {
      this.form_filtre_Ast.controls[controlName].setValue('');
      this.loadAgents();
    }
  }
  effaceDirectionFiltreAst(event: any, controlName: string) {
    event.stopPropagation();

    if (controlName != undefined && controlName != '') {
      this.form_filtre_Ast.controls[controlName].setValue('');
      this.loadDirectionList();
    }
  }
  effaceCampagneFiltreAst(event: any, controlName: string) {
    event.stopPropagation();

    if (controlName != undefined && controlName != '') {
      this.form_filtre_Ast.controls[controlName].setValue('');
      this.loadCampagneList();
    }
  }
  effaceServiceFiltreAst(event: any, controlName: string) {
    event.stopPropagation();

    if (controlName != undefined && controlName != '') {
      this.form_filtre_Ast.controls[controlName].setValue('');
      this.loadserviceFonctionnel();
    }
  }

  effaceEventFiltreAst(event: any, controlName: string) {
    event.stopPropagation();

    if (controlName != undefined && controlName != '') {
      this.form_filtre_Ast.controls[controlName].setValue('');
      this.loadDirectionList();
    }
  }

  astreintesDisplayedColumns: string[] = [
    'Sélectionner',
    'Agent',
    'Type',
    'Date création',
    'Date début',
    'Date fin',
    'Nombre',
    'Emetteur',
    'Direction origine',
    'Statut',
    'Actions'
  ];

  astSubTableDisplayedColumns: string[] = ['Campagne', 'Service origine'];

  astCellStyleCss: any = {
    Statut: { 'text-align': 'center' },
    Nombre: { 'text-align': 'center' },
    'Date début': { 'text-align': 'center' },
    'Date fin': { 'text-align': 'center' }
  };

  astHeaderStyleCss: any = {
    Nombre: { 'text-align': 'center' },
    'Date début': { 'text-align': 'center' },
    'Date fin': { 'text-align': 'center' }
  };

  astCellStyleCssSub: any = {
    'Date HS': { 'text-align': 'center' }
  };

  subTablePenalitiesColumns: string[] = ['Date début', 'Date fin'];

  /*********************** AUTRES FONCTIONS ***********************/

  togglePanel() {
    this.panelOpenState = !this.panelOpenState;
  }

  togglePanelAstreinte() {
    this.panelOpenStateAst = !this.panelOpenStateAst;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  updatePaginationHs(event: any) {
    if (event) {
      this.HS_service.getHeuresSupplémentairesWithParams(event.offset, event.limit, this.filtrerRecherche_HS())
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            this.dataJSON_HS = result.results;
            this.hslenght = result.totalRecords;
            this.dataTable_HS = this.transformData_HS();
            this.detectorRef.markForCheck();
          }
        });
    } else {
      this.HS_service.getHeuresSupplémentairesWithParams(
        environment.pagination.offset,
        environment.pagination.limit,
        this.filtrerRecherche_HS()
      )
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            this.dataJSON_HS = result.results;
            this.hslenght = result.totalRecords;
            this.dataTable_HS = this.transformData_HS();
            this.detectorRef.markForCheck();
          }
        });
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  exportAstrainte() {
    this.astreintesService
      .getAllAstreintes()
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          let exportData = this.transformData_astreintes_to_export(result.results);

          this.convertExcel(exportData, 'export_donnees_saisies_Astreintes_');
        },
        error: (error) => {
          console.log('error:', error);
        }
      });
  }
  transformData_astreintes_to_export(data: any) {
    // data pour l'export
    let dataJSON = data;
    let dataHS: any[] = [];

    for (let key in dataJSON) {
      let dataJ = dataJSON[key];
      let agent = dataJ?.agent;
      let emetteur = '';
      if (dataJ?.emetteur?.nom && dataJ?.emetteur?.prenom) {
        emetteur = dataJ?.emetteur?.nom + ' ' + dataJ?.emetteur?.prenom;
      }
      let _data: any = {};
      _data['Agent'] = agent?.nom + ' ' + agent?.prenom;

      let typeCode = dataJ?.type;
      let typeText = '';
      if (typeCode == 'JOUR') {
        typeText = 'Jour';
      } else if (typeCode == 'NUIT') {
        typeText = 'Nuit';
      } else if (typeCode == 'SEMAINE') {
        typeText = 'Semaine';
      } else if (typeCode == 'DIMANCHE_FERIE') {
        typeText = 'Dimanche/J.F';
      } else if (typeCode == 'CRECHE') {
        typeText = 'Crèche';
      } else if (typeCode == 'SAMEDI') {
        typeText = 'Samedi';
      } else if (typeCode == 'WEEKEND') {
        typeText = 'Week-end';
      }
      _data['Type'] = typeText;

      _data['Date création'] = this.pipe.transform(dataJ?.dateCreation, 'shortDate');
      _data['Date début'] = this.pipe.transform(dataJ?.dateDebut, 'shortDate');
      _data['Date fin'] = this.pipe.transform(dataJ?.dateFin, 'shortDate');

      _data['Nombre'] = dataJ?.nombre;
      _data['Emetteur'] = emetteur;
      _data['Direction origine'] = dataJ?.directionOrigine?.libelle;

      let status_code = dataJ?.statut;
      let status_text = {};
      if (status_code == 'A_VALIDER') {
        status_text = { class: 'badge-gray', text: 'Avis non renseigné', object: true };
      } else if (status_code == 'VALIDEES') {
        status_text = { class: 'badge-green-directeur', text: 'Avis favorable - Directeur', object: true };
      } else if (status_code == 'REFUSEES') {
        status_text = { class: 'badge-red', text: 'Avis défavorable - Directeur', object: true };
      } else if (status_code == 'VALIDEES_N2') {
        status_text = { class: 'badge-green-dga', text: 'Avis favorable - DG', object: true };
      } else if (status_code == 'REFUSEES_N2') {
        status_text = { class: 'badge-red', text: 'Avis défavorable - DG', object: true };
      } else if (status_code == 'SAISIE_A_RENOUVELER') {
        status_text = { class: 'badge-gray', text: 'Saisie à renouveler', object: true };
      } else if (status_code == 'A_VALIDER_RESPONSABLE') {
        status_text = { class: 'badge-gray', text: 'Avis non renseigné (responsable)', object: true };
      } else if (status_code == 'VALIDEES_RESPONSABLE') {
        status_text = { class: 'badge-green-directeur', text: 'Avis favorable - Responsable', object: true };
      } else if (status_code == 'REFUSEES_RESPONSABLE') {
        status_text = { class: 'badge-red', text: 'Avis défavorable - Responsable', object: true };
      }
      //@ts-ignore
      _data['Statut'] = status_text.text;

      _data['Campagne'] = dataJ?.campagne?.nom;
      _data['Service origine'] = dataJ?.serviceFonctionnel?.libelle;
      dataHS.push(_data);
    }

    return dataHS;
  }
  radioChange(event: any) {
    this.effaceDirectionFiltre_VG();
    this.isHs = event.value;
  }
}
