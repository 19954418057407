<app-ariane-nav [label]="arianeLabel" [icon]="arianeIcon" [arianeList]="this.arianeList"></app-ariane-nav>
<mat-divider class="my-3"></mat-divider>
<div class="row mt-3">
  <div class="col">
    <mat-form-field class="mr-5" class="field-filtre">
      <mat-label>Filtrer</mat-label>
      <input matInput #input (keyup)="appDataTable.applyFilter($event)" />
    </mat-form-field>
  </div>
  <div class="col flex-container end align-items-center">
    <button mat-raised-button class="bt-table-defaut bt-table-ajouter mr-5" (click)="openJourFerieDialog()">
      <mat-icon>add</mat-icon> Ajouter un jour férié
    </button>
  </div>
</div>
<app-data-table
  #appDataTable
  [displayedColumns]="joursFeriesDisplayedColumns"
  [cellStyleCss]="joursFeriesCellStyleCss"
  [data]="dataTable"
  [expendable]="false"
  [hasView]="false"
  [editable]="true"
  [delatable]="true"
  [filter]="false"
  [length]="dataLength"
  (deleteElement)="deleteJourFerie($event)"
  (clicModalModifier)="openJourFerieEdit($event)"
  (updatePagination)="updatePagination($event)"
  (updateFiltre)="updateFiltre($event)"
  [customFilter]="true"
></app-data-table>
