<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<h2 style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mb-0" mat-dialog-title>{{ title }}</h2>
<button mat-dialog-close class="btnClose" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
  <mat-icon>close</mat-icon>
</button>
<mat-dialog-content class="mat-typography pt-0 diag-content">
  <mat-divider></mat-divider>

  <div class="divContainer" style="width: 94%">
    <div class="div-content">
      <div class="div-content-haut">
        <div class="div-icon">
          <mat-icon style="color: rgba(16, 46, 94, 0.982); transform: scale(2); vertical-align: middle; margin-right: 14px">help</mat-icon>
        </div>
        <div style="margin-bottom: 0px">{{ question }}</div>
      </div>

      <div class="div-texte" *ngIf="data.length > 0">
        <div class="detail" *ngIf="this.type == 'hs'">
          <div *ngFor="let item of data">
            <div>
              <div>Collaborateur</div>
              <div>: &nbsp;{{ item?.Agent }}</div>
            </div>
            <div>
              <div>Type</div>
              <div>: &nbsp;{{ item?.Type }}</div>
            </div>
            <div>
              <div>Durée</div>
              <div>: &nbsp;{{ item['Durée'] }}</div>
            </div>
          </div>
        </div>

        <div class="detail" *ngIf="this.type == 'astreinte'">
          <div *ngFor="let item of data">
            <div>
              <div>Collaborateur</div>
              <div>: &nbsp;{{ item?.Agent }}</div>
            </div>
            <div>
              <div>Type</div>
              <div>: &nbsp;{{ item?.Type }}</div>
            </div>
            <div>
              <div>Nombre</div>
              <div>: &nbsp;{{ item['Nombre'] }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <mat-divider [ngStyle]="{ marginTop: refuse ? '0px' : '0px' }"></mat-divider>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-stroked-button color="primary" *ngIf="data.length > 0" mat-dialog-close>Annuler</button>
  <button mat-flat-button color="primary" class="bouton-valider" [mat-dialog-close]="this.matDialogClose" cdkFocusInitial>
    {{ texte_bouton }}
  </button>
</mat-dialog-actions>
