<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<h2
  class="mb-0"
  style="cursor: grabbing"
  mat-dialog-title
  style="cursor: grabbing"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  {{ data.title }}
</h2>
<button mat-dialog-close class="btnClose" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
  <mat-icon>close</mat-icon>
</button>
<mat-dialog-content
  style="cursor: grabbing"
  class="mat-typography pt-0"
  style="cursor: grabbing"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <mat-divider></mat-divider>
  <div class="divContainer">
    <div class="div-content">
      <div class="div-icon" style="padding-right: 15px; padding-top: 8px">
        <mat-icon style="color: #dd0000; transform: scale(2); margin-right: 15px">error_outline</mat-icon>
      </div>
      <div class="div-texte">
        {{ data.content }}
      </div>
    </div>
    <div *ngIf="data.erreur" class="div-erreur">
      {{ data.erreur }}
    </div>
    <div *ngIf="data.status" class="div-detail">
      {{ data.status }}
    </div>
    <div *ngIf="data.detail" class="div-status">
      {{ data.detail }}
    </div>
  </div>
  <mat-divider></mat-divider>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Fermer</button>
</mat-dialog-actions>
