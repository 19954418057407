import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JoursFeriesService {
  module = environment.webService.server + environment.webService.url + environment.webService.api;
  constructor(private http: HttpClient) {}

  // lire tous les jours fériés
  getAllJoursFeries() {
    return this.http.get<any>(this.module + '/jours-feries/all');
  }

  getJoursFeriesWithParams(offset: number, limit: number, filtre: string | null = null) {
    filtre = filtre ?? '';
    let url = this.module + '/jours-feries';
    if (filtre != '' && filtre !== undefined && filtre !== null) {
      url = url + '?' + `filterGenerique=${filtre}`;
    }
    const params = new HttpParams().set('page', offset).set('size', limit);
    return this.http.get<any>(url, { params });
  }

  // création jour férié
  ajouterJourFerie(data: any) {
    return this.http.post<any>(this.module + '/jours-feries', data);
  }

  // Modification jour férié
  modifierJourFerie(data: any, id: number) {
    return this.http.put<any>(this.module + '/jours-feries/' + id, data);
  }

  // Suppression jour férié
  supprimerJourFerie(id: number) {
    return this.http.delete<any>(this.module + '/jours-feries/' + id);
  }
}
