<mat-card-header class="">
  <div class="row col-md-12">
    <div class="col-6">
      <div class="titre-modale">Configurer mes alarmes</div>
    </div>
    <div class="col-md-6 text-end mb-2 mr-0 ml-auto gx-0">
      <button mat-dialog-close class="py-0" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>
<mat-dialog-content class="mat-typography">


<mat-divider class="my-3"></mat-divider>

<div class="div-contenu">

<div class="row mt-3">
  <div class="col">
    <mat-form-field class="mr-5">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="appDataTable.applyFilter($event)" #input />
    </mat-form-field>
  </div>  
  <div class="col flex-container end align-items-center">
    <div>
      <button mat-raised-button class="bt-table-defaut bt-table-ajouter mr-5" (click)="openEditionDialog()">
        <mat-icon>add</mat-icon> Ajouter une alarme
      </button>
    </div>
  </div>
</div>

<div class="mt-0">
  <mat-expansion-panel [expanded]="panelOpenState">
    <h6 class="mt-4">Vous pouvez filtrer sur un ou plusieurs critères</h6>

    <div class="flex-container">
      <div class="">
        <mat-form-field>
          <mat-label>Emetteur</mat-label>
          <mat-select>
            <mat-option>None</mat-option>
            <mat-option value="option1">Option 1</mat-option>
            <mat-option value="option2">Option 2</mat-option>
            <mat-option value="option3">Option 3</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="">
        <mat-form-field>
          <mat-label>Date début</mat-label>
          <input matInput [matDatepicker]="picker_3" />
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker_3"></mat-datepicker-toggle>
          <mat-datepicker #picker_3></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="">
        <mat-form-field>
          <mat-label>Date fin</mat-label>
          <input matInput [matDatepicker]="picker_4" />
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker_4"></mat-datepicker-toggle>
          <mat-datepicker #picker_4></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="">
        <mat-form-field>
          <mat-label>Commentaire</mat-label>
          <input matInput placeholder="" />
        </mat-form-field>
      </div>
    </div>
    <div class="justify-flex">
      <div>
        <button mat-stroked-button class="mr-5" (click)="togglePanel()" style="height: 48px">Fermer</button>
      </div>
      <div>
        <button mat-stroked-button class="mr-5" style="height: 48px">
          <mat-icon>trash</mat-icon>
          Effacer tous les filtres
        </button>

        <button mat-raised-button color="primary" class="mr-5" style="height: 48px">Filtrer</button>
      </div>
    </div></mat-expansion-panel
  >
</div>
<div class="mt-1">
  <app-data-table
    #appDataTable
    [displayedColumns]="displayedColumns"
    [cellStyleCss]="cellStyleCss"
    [data]="mock"
    [expendable]="false"
    [hasView]="false"
    [editable]="false"
    [delatable]="true"
    [filter]="false"
    (deleteElement)="deleteAgent($event)"
  ></app-data-table>
</div>
</div>

</mat-dialog-content>
