import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeleguesService {
  module = environment.webService.server + environment.webService.url + environment.webService.api;
  constructor(private http: HttpClient) {}

  getDeleguesAgent(id: number) {
    const params = new HttpParams().set('remplace.id', '' + id);
    return this.http.get<any>(this.module + '/agents/delegues', { params });
  }
  //renvoie la liste de tous mes délégués
  getMesDelegues() {
    return this.http.get<any>(this.module + '/agents/mes-delegues');
  }
  getDeleguesWithParams(offset: number, limit: number, id: number, filtre: string | null = null) {
    filtre = filtre ?? '';
    let url = this.module + '/agents/delegues';
    if (filtre != '' && filtre !== undefined && filtre !== null) {
      url = url + '?' + `filterGenerique=${filtre}`;
    }

    const params = new HttpParams().set('page', offset).set('size', limit);

    if (id) {
      params.set('remplace.id', '' + id);
    }
    return this.http.get<any>(url, { params });
  }

  supprimerDelegue(id: number) {
    return this.http.delete<any>(this.module + '/agents/delegues/' + id);
  }

  deleteDelegue(data: any): Observable<any[]> {
    return this.http.delete<any>(this.module + '/agents/delegues/' + data);
  }
  supprimerMonDelegue(id: number) {
    return this.http.delete<any>(this.module + '/agents/mes-delegues/' + id);
  }

  AjoutDelegue(model: any) {
    return this.http.post<any>(this.module + '/agents/delegues', model);
  }
  AjoutMonDelegue(model: any) {
    return this.http.post<any>(this.module + '/agents/mes-delegues', model);
  }
  modifierDelegue(data: any, id: number) {
    return this.http.put<any>(this.module + '/agents/delegues/' + id, data);
  }
  modifierMonDelegue(data: any, id: number) {
    return this.http.put<any>(this.module + '/agents/mes-delegues/' + id, data);
  }
}
