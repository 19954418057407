import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {  MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';
@Component({
  selector: 'app-confirm-question',
  standalone: true,
  imports: [CommonModule,MatButtonModule, MatDialogModule,MatIconModule , MatDividerModule],
  templateUrl: './confirm-question.component.html',
  styleUrls: ['./confirm-question.component.scss']
})
export class ConfirmQuestionComponent {

}
