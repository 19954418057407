<!-- form 2 -->
<form [formGroup]="formHsEvenements">
  <div class="row g-3">
    <div class="col-md-6" *ngIf="this.typeHS == 'HS_EVENEMENT'">
      <mat-form-field floatLabel="always" style="width: 100%">
        <mat-label>Evènement </mat-label>
        <input
          type="text"
          placeholder="Evènement"
          aria-label="Number"
          matInput
          name="item"
          [matAutocomplete]="auto"
          [formControl]="eventFormControl"
          (input)="filterEvent($event)"
        />
        <mat-autocomplete
          (optionSelected)="updateDirectionFromEvt($event)"
          #auto="matAutocomplete"
          hideSingleSelectionIndicator
          requireSelection
          [displayWith]="displayFnEvent"
        >
          <mat-option *ngFor="let item of filteredOptionsEvent" [value]="item">{{ item.libelle }}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="col-md-6" *ngIf="this.typeHS == 'HS_EVENEMENT'">
      <mat-form-field floatLabel="always" style="width: 100%">
        <mat-label>Direction organisatrice </mat-label>
        <mat-select formControlName="directionId">
          <mat-option *ngFor="let item of directionList$ | async" [value]="item.id">{{ item.libelle }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row" *ngIf="this.typeHS == 'HS_POUR_DIRECTION_ORIGINE'">
    <div class="col-md-12">
      <mat-form-field style="width: 100%" floatLabel="always">
        <mat-label>Motif</mat-label>
        <textarea matInput formControlName="motif"></textarea>
        <mat-error *ngIf="formHsEvenements.get('motif')?.hasError('required')"> Le motif est <strong>obligatoire</strong> </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row g-3">
    <div class="col-md-8">
      <mat-form-field floatLabel="always" style="width: 100%">
        <mat-label>Agent</mat-label>
        <input
          type="text"
          placeholder="Nom/Prénom"
          aria-label="Number"
          matInput
          name="item"
          [matAutocomplete]="auto"
          [formControl]="agentFormControl"
          (input)="filter($event)"
          [required]="selectedAgents.length == 0"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          hideSingleSelectionIndicator
          requireSelection
          (optionSelected)="add_agent($event)"
          [displayWith]="displayFn"
        >
          <mat-option [value]="agent" *ngFor="let agent of filteredOptions">
            <br />
            <b>{{ agent.nom }} {{ agent.prenom }}</b> <br />
            {{ agent.direction?.libelle }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="erreurChoixAgentCadre || agentFormControl.hasError('erreurChoixAgentCadre')"
          >Vous ne pouvez pas ajouter un Agent Cadre à un évènement autre que ELECTIONS</mat-error
        >
        <mat-error *ngIf="erreurAgentsNonEligibles || agentFormControl.hasError('erreurAgentsNonEligibles')"
          >Certains agents ont été supprimés car non éligibles à cet évènement</mat-error
        >
      </mat-form-field>
    </div>
  </div>
  <!-- card for chips -->
  <mat-card [hidden]="selectedAgents.length == 0">
    <mat-card-header
      ><mat-card-title [class.invalidAgents]="selectedAgents.length == 0"
        >Agents sélectionnés ({{ selectedAgents.length }})</mat-card-title
      ></mat-card-header
    >
    <mat-card-content>
      <mat-chip class="m-10 agent-chip" *ngFor="let agentSelected of selectedAgents" (removed)="delete_agent(agentSelected)">
        <strong>{{ agentSelected.nom + ' ' + agentSelected.prenom }}</strong> <br />
        {{ agentSelected.direction?.libelle + ' / ' + agentSelected.serviceFonctionnel?.libelle }}
        <button matChipRemove [attr.area-label]="'remove' + agentSelected">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
    </mat-card-content>
  </mat-card>
  <mat-divider style="border-top-width: 3px" class="my-3"></mat-divider>
  <!-- form  -->
  <div class="row g-3">
    <ng-container formArrayName="hoursFormArray" *ngFor="let hour of hours.controls; index as i">
      <ng-container [formGroupName]="i">
        <div class="d-flex">
          <mat-form-field floatLabel="always">
            <mat-label>Date</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              formControlName="date"
              placeholder="JJ/MM/AAAA"
              [matDatepickerFilter]="rangeFilter"
              [min]="minDate"
              [max]="maxDate"
            />
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="hour.get('date')?.hasError('required')"> Date est <strong>obligatoire</strong> </mat-error>
            <mat-error *ngIf="hour.get('date')?.hasError('matDatepickerMax')"
              >La date maximum autorisée est le {{ hour.get('date')?.errors?.['matDatepickerMax']['max'] | date: 'dd/MM/yyyy' }}</mat-error
            >
            <mat-error *ngIf="hour.get('date')?.hasError('matDatepickerMin')"
              >La date minimum autorisée est le {{ hour.get('date')?.errors?.['matDatepickerMin']['min'] | date: 'dd/MM/yyyy' }}</mat-error
            >
          </mat-form-field>

          <div class="ml-15 mr-15">
            <mat-form-field>
              <mat-label>Heure début</mat-label>
              <input type="time" matInput (ngModelChange)="updateDuration(i)" formControlName="heure_debut" />
            </mat-form-field>
          </div>
          <div class="ml-15 mr-15">
            <mat-form-field>
              <mat-label>Heure fin</mat-label>
              <input type="time" matInput (ngModelChange)="updateDuration(i)" formControlName="heure_fin" />
            </mat-form-field>
          </div>

          <mat-form-field style="width: 22%; margin-left: 10px">
            <mat-label>Durée</mat-label>
            <input type="time" matInput [readonly]="true" formControlName="duree" />
          </mat-form-field>

          <a mat-icon-button color="primary" class="small form-control ml-3" (click)="remove_inputs(i)" title="Supprimer la date">
            <mat-icon>delete</mat-icon>
          </a>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <!-- btn add -->
  <div class="w-100" class="bottom-ajouter">
    <div style="display: inline-flexbox; background-color: #ffffff; padding-bottom: 10px">
      <button
        mat-stroked-button
        color="primary"
        style="text-wrap: nowrap; background: #ffffff; padding: 13px"
        class="ml-auto"
        (click)="add_inputs()"
      >
        <mat-icon>add</mat-icon>Ajouter une date
      </button>
    </div>
  </div>
  <div class="bottom-spacer"></div>
  <!-- zone fixe en bas de la modale -->
  <div class="bottom-fixed">
    <mat-divider style="border-top-width: 3px" class="my-1"></mat-divider>

    <div class="row gx-0 d-flex mt-3 div-agent">
      <app-bandeau-parcourir
        [currentIndex]="this.currentIndex"
        [nbFormulaires]="this.infosFormulaires.length"
        [actionsActives]="isFormulaireValid()"
        [totalTime]="totaldurationHs()"
        (showNext)="formulaireSuivant()"
        (showPrev)="formulairePrecedent()"
        (newForm)="formulaireNew()"
        (deleteForm)="formulaireDelete()"
      ></app-bandeau-parcourir>
    </div>
    <h5 style="margin-bottom: 0px">Les éléments marqués d'une étoile(*) sont obligatoires.</h5>
  </div>
</form>
