<div class="contenu">
  <ng-container *ngIf="dateFin_a$ | async">
    <div>
      <span class="material-symbols-outlined icon icon-calendar"> calendar_month </span>
      <div>
        <div class="ligne-titre">
          <h2 class="mb-0" style="color: rgb(48, 50, 121)">{{ titre_a$ | async }} est ouverte jusqu'au</h2>
        </div>
        <div class="ligne-nom">
          <h2 class="mb-0" style="color: rgb(48, 50, 121)">
            <span class="badge bg-danger">{{ dateFin_a$ | async }}</span>
          </h2>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!(dateDebut_a$ | async)">
    <div class="message">Pas de campagne actuellement</div>
  </ng-container>

  <mat-divider [vertical]="true" style="margin-right: 20px"></mat-divider>

  <ng-container *ngIf="dateDebut_p$ | async">
    <div>
      <span class="material-symbols-outlined icon icon-calendar"> calendar_month </span>
      <div>
        <div class="ligne-titre" style="border-radius: 5px">
          <h2 class="mb-0" style="color: rgb(48, 50, 121); padding-left: 5px; padding-right: 5px">{{ titre_p$ | async }} débutera le</h2>
        </div>
        <div class="ligne-nom">
          <h2 class="mb-0" style="color: rgb(48, 50, 121)">
            <span class="badge bg-warning">{{ dateDebut_p$ | async }}</span>
          </h2>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!(dateDebut_p$ | async)">
    <div class="message">Pas de prochaine campagne</div>
  </ng-container>
</div>
