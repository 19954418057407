import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ArianeNavComponent } from 'src/app/layout/ariane-nav/ariane-nav.component';
import { MatDividerModule } from '@angular/material/divider';
import { DirectionService } from 'src/app/services/direction.service';
import { take } from 'rxjs';
import { DataTableComponent } from 'src/app/shared/data-table/data-table.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommunicationService } from 'src/app/shared/communication.service';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Broadcast } from 'src/app/core/services/broadcast.service';
import { ServicesModalComponent } from './services-modal/services-modal.component';

@Component({
  selector: 'app-admin-services',
  standalone: true,
  imports: [CommonModule, ArianeNavComponent, MatDividerModule, DataTableComponent, MatFormFieldModule, MatInputModule],
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  arianeLabel: string = 'Services';
  arianeIcon: string = 'view_list';
  pipe = new DatePipe('fr-FR'); // Use your own locale
  dataTable: any[] = [];
  rowData: any[] = [];
  keyChanges: any = {};
  untransformedData: any = [];
  dataLength: number;
  filterValue: string = '';
  arianeList = [
    {
      label: 'Administration',
      url: '/administration'
    },
    {
      label: 'Services',
      url: '/services'
    }
  ];
  constructor(
    public directionService: DirectionService,
    private refreshService: CommunicationService,
    public dialog: MatDialog,
    private detectorRef: ChangeDetectorRef,
    private broadcast: Broadcast
  ) {}
  ServicesDisplayedColumns: string[] = ['Libellé', 'Responsable', 'Actions'];

  ngOnInit() {
    this.updatePagination(null);

    this.refreshService.refreshList$.subscribe((refresh) => {
      if (refresh) {
        this.updatePagination(null);
      }
    });
  }
  transformData(result: any) {
    console.log('untransformedData :', this.untransformedData);

    let data: any[] = [];

    for (let key in result) {
      let dataJ = result[key];
      let _data: any = {};
      _data['id'] = dataJ?.id;
      _data['Libellé'] = dataJ?.libelle;
      if (dataJ?.responsable) {
        _data['Responsable'] = dataJ?.responsable.nom + ' ' + dataJ?.responsable.prenom;
      } else {
        _data['Responsable'] = '';
      }

      data.push(_data);
    }
    return data;
  }

  updatePagination(event: any) {
    if (event) {
      this.directionService
        .getServicesWithParams(event.offset, event.limit, event.filterValue)
        .pipe(take(1))
        .subscribe({
          next: (result: any) => {
            this.untransformedData = result.results;
            this.dataLength = result.totalRecords;
            //console.log("result 1", result);
            //result.forEach((element: any) => {
            this.dataTable = this.transformData(result.results);
            //});
          },
          error: (error: any) => {
            console.log('erreur:', error);
          }
        });
    } else {
      this.directionService
        .getServicesWithParams(environment.pagination.offset, environment.pagination.limit)
        .pipe(take(1))
        .subscribe({
          next: (result: any) => {
            this.untransformedData = result.results;
            this.dataLength = result.totalRecords;
            //console.log("result 2", result.results);
            //result.forEach((element: any) => {
            this.dataTable = this.transformData(result.results);
            //});
          },
          error: (error: any) => {
            console.log('erreur:', error);
          }
        });
    }
  }
  updateFiltre(value: any) {
    this.filterValue = value.filterValue;
    this.updatePagination(value);
  }

  // modale de modification
  openServiceEdit(id: number) {
    this.searchDataID(this.untransformedData, id);

    const dialogRef = this.dialog.open(ServicesModalComponent, {
      width: '780px',
      disableClose: true,
      data: { edition: true, ...this.rowData }
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data?.action) {
        this.updatePagination(null);
        this.detectorRef.markForCheck();
        this.broadcast.broadcastEvent({
          broadcastedevent: 'resetPagination'
        });
      }
    });
  }

  // recherche la ligne data correspondant à l'id
  searchDataID(data: any, id: number) {
    let index = Object.keys(data).find((k) => data[k].id === id);

    if (index != null) {
      this.rowData = this.untransformedData[Number(index)];
    }
  }
}
