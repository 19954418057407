<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<mat-card-header class="" style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="row col-md-12">
    <div class="col-10">
      <div class="titre-modale">{{ titre_action }} une direction</div>
    </div>

    <div class="col-md-2 text-end mb-0 mr-0 ml-auto gx-0">
      <button class="py-0" mat-icon-button aria-label="Fermer la modale" (click)="fermerModale()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>

<mat-dialog-content class="mat-typography">
  <!-- form -->
  <form [formGroup]="form" class="row">
    <div class="col-md-12 mt-2">
      <mat-form-field class="example-full-width" floatLabel="always" style="width: 100%">
        <mat-label>Libellé </mat-label>
        <input class="pt-0" matInput class="form-control champ-disabled" id="libelle" formControlName="libelle" [readonly]="true" />
        <mat-error *ngIf="form.controls['libelle'].hasError('required')">Le libellé.</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-12 mt-2">
      <mat-form-field class="example-full-width" floatLabel="always" style="width: 100%">
        <mat-label>Structure de validation </mat-label>
        <input
          class="pt-0"
          matInput
          class="form-control champ-disabled"
          id="structValidation"
          formControlName="structValidation"
          [readonly]="true"
        />
        <mat-error *ngIf="form.controls['structValidation'].hasError('required')">La structure de validation.</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-12 mt-2">
      <mat-form-field class="example-full-width" floatLabel="always" style="width: 100%">
        <mat-label>Type de workflow </mat-label>
        <mat-select [(value)]="selTypeWorkflow" formControlName="typeWorkflow">
          <mat-option *ngFor="let item of possibleWorkflows" [value]="item.value">{{ item.libelle }}</mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls['typeWorkflow'].hasError('required')">Le type de workflow.</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <div style="margin-top: 20px">
        <mat-checkbox formControlName="flagDepassementInterne"
          >Autorisation de dépassement des 25h pour les agents de cette Direction qui interviennent pour cette Direction</mat-checkbox
        >
      </div>
    </div>

    <div class="col-md-12">
      <div style="margin-top: 20px">
        <mat-checkbox formControlName="flagDepassementExterne"
          >Autorisation de dépassement des 25h pour les agents des autres Directions qui interviennent pour cette Direction</mat-checkbox
        >
      </div>
    </div>
  </form>
  <mat-divider style="border-top-width: 1px" class="mt-4 mb-1"></mat-divider>
  <h5 class="text-footer">Les éléments marqués d'une étoile (*) sont obligatoires</h5>
</mat-dialog-content>
<mat-dialog-actions style="background-color: rgba(250, 250, 252, 1)" align="end">
  <button mat-stroked-button class="mx-3" color="primary" (click)="fermerModale()">Annuler</button>
  <button [disabled]="form.invalid" #bouttonValider mat-flat-button color="primary" (click)="validerForm()">Valider</button>
</mat-dialog-actions>
