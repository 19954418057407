import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { FormControl, ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MaterialDateAdapter, MY_DATE_FORMATS } from 'src/app/core/services/material-date-adapter.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { ExcelService } from 'src/app/core/services/excel.service';
import { ExportService } from 'src/app/services/export.service';
import { Observable, map, startWith, take } from 'rxjs';
import { PdfExportService } from 'src/app/core/services/pdf.service';
import { CsvService } from 'src/app/core/services/csv.service';
import { MatCardModule } from '@angular/material/card';
import { constant } from 'src/constant';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CampagnesService } from 'src/app/services/campagnes.service';

function validateSecondExportGreaterThanFirstExport(control: FormGroup) {
  //@ts-ignore
  const yearStat = control.get('anneeDebut').value;
  //@ts-ignore
  const yearEnd = control.get('anneeFin').value;
  //@ts-ignore
  const monthEnd = control.get('moisFin').value;
  //@ts-ignore
  const monthStart = control.get('moisDebut').value;

  // Check if secondNumber is greater than firstNumber
  if (yearStat > yearEnd || (yearStat === yearEnd && monthStart > monthEnd)) {
    return { validateSecondExportGreaterThanFirstExport: true };
  }
  return null;
}
let currentDate = new Date();

// Get the current month as a number (zero-based)
let currentMonth = currentDate.getMonth();

// Add 1 to get the month as a number starting from 1
currentMonth = currentMonth + 1;
let currentYear = currentDate.getFullYear();
@Component({
  selector: 'app-export-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCardModule,
    MatAutocompleteModule,
    DragDropModule
  ],
  templateUrl: './export-data-modal.component.html',
  styleUrls: ['./export-data-modal.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MaterialDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_DATE_FORMATS
    }
  ]
})
export class ExportDataModalComponent implements OnInit {
  // colonnes pour le format PDF
  columns = [
    { header: 'NUM_LIGNE', dataKey: 'numLigne' },
    { header: 'ORGANISME', dataKey: 'agentCodeOrganisme' },
    { header: 'IDENTIFIANT', dataKey: 'agentMatricule' },
    //{ header: 'CAMPAGNE', dataKey: 'campagneNom' },
    { header: 'NUM_EMPLOI', dataKey: 'codeAffectation' },
    { header: 'CODE_RUBRIQUE', dataKey: 'codeRubriquePaye' },
    { header: 'VAL_1', dataKey: 'no_value' },
    { header: 'VAL_2', dataKey: 'no_value' },
    { header: 'VAL_3', dataKey: 'no_value' },
    { header: 'DATE_de_debut', dataKey: 'exportDateDebut' },
    { header: 'date_de_fin', dataKey: 'exportDateFin' },
    { header: 'Montant', dataKey: 'no_value' },
    { header: 'Nombre', dataKey: 'totalHeures' },
    { header: 'taux', dataKey: 'no_value' },
    { header: 'code_elt', dataKey: 'code' },
    { header: 'date_deb_ref', dataKey: 'realisationDateDebut' },
    { header: 'date_fin_ref', dataKey: 'realisationDateFin' },
    { header: 'idf_Cle', dataKey: 'no_value' },
    { header: 'cod_vent', dataKey: 'no_value' },
    { header: 'typ_vent', dataKey: 'no_value' },
    { header: 'num_paie', dataKey: 'no_value' },
    { header: 'ind_origine', dataKey: 'no_value' },
    { header: 'dat_maj', dataKey: 'no_value' },
    { header: 'heu_maj', dataKey: 'no_value' },
    { header: 'num_crit', dataKey: 'no_value' }
  ];
  exportData: any;
  selectedDate: any = null;
  campagneList: any = [];
  listCampagne: any = [];
  val_ft_campagne: any = null;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ExportDataModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDiag: any,
    private excelService: ExcelService,
    private exportService: ExportService,
    private pdfExportService: PdfExportService,
    private csvService: CsvService,
    private campagneService: CampagnesService
  ) {
    this.filteredOptions = this.moisDebut.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
    this.filteredOptionsFin = this.moisFin.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
  }
  form: any;
  pipe = new DatePipe('fr-FR');

  formats: any[] = constant.exportFormats;
  mois: any[] = constant.mois;
  monthNumbersArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  filteredOptions: Observable<any[]> | undefined;
  filteredOptionsFin: Observable<any[]> | undefined;
  moisDebut = new FormControl(this.getObjectById(this.mois, currentMonth));
  moisFin = new FormControl(this.getObjectById(this.mois, currentMonth));
  start: any;
  end: any;
  showCloseEnd: boolean = true;
  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        format: ['', [Validators.required]],
        campagne: [null, [Validators.required]],
        moisDebut: [currentMonth],
        anneeDebut: [currentYear],
        moisFin: [currentMonth],
        anneeFin: [currentYear]
      },
      { validator: validateSecondExportGreaterThanFirstExport }
    );

    this.loadCampagneList();
  }

  onSubmit() {
    if (this.form.valid) {
      const toFormatDebut = this.monthNumbersArray.includes(this.form.get('moisDebut').value);
      const toFormatEnd = this.monthNumbersArray.includes(this.form.get('moisFin').value);

      if (toFormatDebut) {
        this.form.get('moisDebut').value = '0' + this.form.get('moisDebut').value;
      }
      if (toFormatEnd) {
        this.form.get('moisFin').value = '0' + this.form.get('moisFin').value;
      }

      let campagne = this.form.controls['campagne'].value;

      let query = {
        campagneId: campagne.id
      };
      this.exportService
        .exportwithFilter(query /*, 0, 20000, null, this.dataDiag?.generiqueFiltre*/)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.exportData = this.transformData(result.results);
            const search = '/';
            const replaceWith = '-';
            const date = new Date().toLocaleDateString('en-GB').split(search).join(replaceWith);
            let nomCampagne = campagne?.nom != undefined && campagne?.nom != null ? campagne.nom.toLowerCase().replace(/\W/g, '_') : '';
            let fileName = `export_donnees-${nomCampagne}-${date}`;

            if (this.form.get('format').value == 'csv') {
              let header = [
                {
                  NUM_LIGNE: '',
                  ORGANISME: '',
                  IDENTIFIANT: '',
                  //CAMPAGNE: '',
                  NUM_EMPLOI: '',
                  CODE_RUBRIQUE: '',
                  VAL_1: '',
                  VAL_2: '',
                  VAL_3: '',
                  DATE_de_debut: '',
                  date_de_fin: '',
                  Montant: '',
                  Nombre: '',
                  taux: '',
                  code_elt: '',
                  date_deb_ref: '',
                  date_fin_ref: '',
                  idf_Cle: '',
                  cod_vent: '',
                  typ_vent: '',
                  num_paie: '',
                  ind_origine: '',
                  dat_maj: '',
                  heu_maj: '',
                  num_crit: ''
                }
              ];
              // Header n'est utilisé que quand il n'y a pas de données
              this.csvService.exportToCsv(this.exportData, fileName, header);
            }
            if (this.form.get('format').value == 'excel') {
              const headers = [
                'NUM_LIGNE',
                'ORGANISME',
                'IDENTIFIANT',
                //'CAMPAGNE',
                'NUM_EMPLOI',
                'CODE_RUBRIQUE',
                'VAL_1',
                'VAL_2',
                'VAL_3',
                'DATE_de_debut',
                'date_de_fin',
                'Montant',
                'Nombre',
                'taux',
                'code_elt',
                'date_deb_ref',
                'date_fin_ref',
                'idf_Cle',
                'cod_vent',
                'typ_vent',
                'num_paie',
                'ind_origine',
                'dat_maj',
                'heu_maj',
                'num_crit'
              ];

              this.excelService.exportToExcel(this.exportData, `${fileName}.xlsx`, 'Sheet1', headers);
            }
            if (this.form.get('format').value == 'pdf') {
              let data: any = [];
              result.results.forEach((element: any) => {
                element.exportDateDebut = this.pipe.transform(element.exportDateDebut, 'dd/MM/yyyy');
                element.exportDateFin = this.pipe.transform(element.exportDateFin, 'dd/MM/yyyy');
                element.realisationDateDebut = this.pipe.transform(element.realisationDateDebut, 'dd/MM/yyyy');
                element.realisationDateFin = this.pipe.transform(element.realisationDateFin, 'dd/MM/yyyy');
                element.no_value = '';
                data.push(element);
              });
              this.pdfExportService.exportToPdf(data, this.columns, `${fileName}.pdf`);
            }
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    }
  }
  transformData(result: any) {
    let data: any[] = [];

    for (let key in result) {
      let dataJ = result[key];
      let _data: any = {};
      _data['NUM_LIGNE'] = dataJ['numLigne'];
      _data['ORGANISME'] = dataJ['agentCodeOrganisme'];
      _data['IDENTIFIANT'] = dataJ['agentMatricule'];

      _data['NUM_EMPLOI'] = dataJ['codeAffectation'];
      _data['CODE_RUBRIQUE'] = dataJ['codeRubriquePaye'];
      _data['VAL_1'] = '';
      _data['VAL_2'] = '';
      _data['VAL_3'] = '';
      _data['DATE_de_debut'] = this.pipe.transform(dataJ?.exportDateDebut, 'dd/MM/yyyy');
      _data['date_de_fin'] = this.pipe.transform(dataJ?.exportDateFin, 'dd/MM/yyyy');

      _data['Montant'] = '';
      _data['Nombre'] = dataJ?.totalHeures;
      _data['taux'] = '';
      _data['code_elt'] = dataJ?.code;
      _data['date_deb_ref'] = this.pipe.transform(dataJ?.realisationDateDebut, 'dd/MM/yyyy');
      _data['date_fin_ref'] = this.pipe.transform(dataJ?.realisationDateFin, 'dd/MM/yyyy');
      _data['idf_Cle'] = '';
      _data['cod_vent'] = '';
      _data['typ_vent'] = '';
      _data['num_paie'] = '';
      _data['ind_origine'] = '';
      _data['dat_maj'] = '';
      _data['heu_maj'] = '';
      _data['num_crit'] = '';
      data.push(_data);
    }
    return data;
  }

  cancelDialog() {
    this.dialogRef.close();
  }

  isControlDirty(controlName: string): boolean {
    const control = this.form.get(controlName);
    return control ? control.dirty : false;
  }

  // Step 5: Filter function for options
  private _filter(value: string | { value: string }): any[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : value.value.toLowerCase();
    return this.mois.filter((option) => option.value.toLowerCase().includes(filterValue));
  }

  // Step 5: Custom display function to display option names
  displayFn(option: any): string {
    return option ? option.value : '';
  }
  getObjectById(array: any[], id: number) {
    return array.find((obj) => obj.id === id);
  }
  updateMoisDebut() {
    this.form.get('moisDebut').setValue(this.moisDebut.getRawValue().id);
  }
  updateMoisFin() {
    this.form.get('moisFin').setValue(this.moisFin.getRawValue().id);
    if (this.form.get('format').value !== '') {
      this.form.setErrors(null);
    }
  }
  updateValidity() {
    let yearstart = this.form.get('anneeDebut').value;
    let yearEnd = this.form.get('anneeDebut').value;
    let monthStart = this.form.get('moisDebut').value;
    let monthEnd = this.form.get('moisFin').value;
    if (yearstart > yearEnd || (yearstart === yearEnd && monthStart > monthEnd)) {
      return this.form.setErrors(null);
    }
  }
  effacer(event: any, controlName: string, model: string) {
    if (controlName != undefined && controlName != '') {
      if (controlName == 'moisFin') {
        this.moisFin.setValue('');
      }
      if (controlName == 'moisDebut') {
        this.moisDebut.setValue('');
      }
      this.form.get(controlName).setValue('');
    }
  }

  loadCampagneList() {
    this.campagneService
      .getAllCampagnes()
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          this.listCampagne = data.results;
          this.campagneList = [...this.listCampagne];
        }
      });
  }

  campagneFilter(event: any): void {
    const value = event.target.value;
    if (typeof value !== 'string') {
      this.campagneList = [];
      return;
    }
    const filterValue = value.toLowerCase().split(' ').join('');
    this.campagneList = this.listCampagne.filter((campagne: any) => campagne?.nom.toLowerCase().split(' ').join('').includes(filterValue));
  }

  campagnedisplayFn(campagne?: any): string {
    return campagne ? campagne?.nom : undefined;
  }
}
