import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { CommonModule, DatePipe, NgFor, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { DataTableComponent } from 'src/app/shared/data-table/data-table.component';
import { MatStepperModule } from '@angular/material/stepper';
import { HeuresSupplémentairesService } from 'src/app/services/heures-supplémentaires.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommunicationService } from 'src/app/shared/communication.service';
@Component({
  selector: 'app-synthese-creation',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatDialogModule,
    MatTableModule,
    DataTableComponent,
    NgFor,
    NgIf,
    MatStepperModule
  ],
  templateUrl: './synthese-creation.component.html',
  styleUrls: ['./synthese-creation.component.scss'],
  providers: [DatePipe]
})
export class SyntheseCreationComponent {
  dialogRef: any;
  dataTable_HS: any[] = [];
  dataSource: any;
  showSpinner: boolean | undefined;
  dataJSON_HS: any[] = [];
  pipe: any;
  @Input() data: any;
  @Output() closeModal = new EventEmitter();
  constructor(
    private datePipe: DatePipe,
    private HS_service: HeuresSupplémentairesService,
    private spinner: NgxSpinnerService,
    private _snackBar: MatSnackBar,
    private notifyService: CommunicationService,
    private detectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.data) {
      this.dataSource = this.remapData(this.data);
      this.detectorRef.markForCheck();
    }
  }

  displayedColumns: string[] = ['Nom', 'Prénom', 'Direction origine', 'Service origine', 'Total', 'Actions'];

  subTableDisplayedColumns: string[] = ['Motif', 'Date', 'Heure début', 'Heure fin', 'Durée'];

  hourCellStyleCss: any = {
    Statut: { 'text-align': 'center' },
    'Date saisie': { 'text-align': 'center' }
  };
  hourHeaderStyleCssSub: any = {
    Date: { 'text-align': 'center' },
    'Heure début': { 'text-align': 'center' },
    'Heure fin': { 'text-align': 'center' },
    Durée: { 'text-align': 'center' }
  };

  hourCellStyleCssSub: any = {
    Date: { 'text-align': 'center' },
    'Heure début': { 'text-align': 'center' },
    'Heure fin': { 'text-align': 'center' },
    Durée: { 'text-align': 'center' }
  };

  remapData(rawData: any[]): any[] {
    let res: any[] = [];

    rawData.forEach((elem: any) => {
      let horairesList: any[] = [];
      let dureeTotaleAgent: number = 0;
      // On liste tous les horaires avec le motif (1 motif pour N horaires)
      elem.infos_hs?.forEach((elemHS: any) => {
        elemHS.horaires?.forEach((elemHoraire: any) => {
          let dateFormat = elemHoraire.dateRealisation.toFormat('dd/MM/yyyy');
          let currentHoraireLine = {
            Motif: elemHS.motif,
            Date: dateFormat,
            'Heure début': elemHoraire.heureDebut,
            'Heure fin': elemHoraire.heureFin,
            Durée: elemHoraire.duree
          };
          let dureeNumberMinutes = 0;
          // du style XX:XX
          if (elemHoraire.duree && elemHoraire.duree.length >= 5) {
            let morceaux = elemHoraire.duree.split(':');
            dureeNumberMinutes = Number(morceaux[0]) * 60 + Number(morceaux[1]);
          }
          dureeTotaleAgent += dureeNumberMinutes;
          horairesList.push(currentHoraireLine);
        });
      });

      let dureeHours = Math.floor(dureeTotaleAgent / 60);
      let dureeMinutes = dureeTotaleAgent % 60;
      // Padding sur 2 nombres
      let dureeHoursStr: string = '' + dureeHours;
      while (dureeHoursStr.length < 2) {
        dureeHoursStr = '0' + dureeHoursStr;
      }
      let dureeMinutesStr: string = '' + dureeMinutes;
      while (dureeMinutesStr.length < 2) {
        dureeMinutesStr = '0' + dureeMinutesStr;
      }

      let currentAgentLine = {
        'Direction origine': elem.agent.direction.libelle,
        'Service origine': elem.agent.serviceFonctionnel.libelle,
        Nom: elem.agent.nom,
        Prénom: elem.agent.prenom,
        Total: dureeHoursStr + ':' + dureeMinutesStr,
        subTable: horairesList
      };

      res.push(currentAgentLine);
    });

    return res;
  }
}
