import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { DataTableComponent } from 'src/app/shared/data-table/data-table.component';
import { AgentsService } from 'src/app/services/agents.service';
import { take } from 'rxjs';
import { ArianeNavComponent } from 'src/app/layout/ariane-nav/ariane-nav.component';
import { MatDividerModule } from '@angular/material/divider';
import { environment } from 'src/environments/environment';
import { RoleService } from 'src/app/services/role.service';
import { MatDialog } from '@angular/material/dialog';
import { DelegationModalComponent } from '../../modales-utilisateur/delegation-modal/delegation-modal.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DeleguesService } from 'src/app/services/delegues.service';
import { CommunicationService } from 'src/app/shared/communication.service';
import { EditAgentComponent } from './edit-agent/edit-agent.component';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-gestion-utilisateurs',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    DataTableComponent,
    ArianeNavComponent,
    MatDividerModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  templateUrl: './gestion-utilisateurs.component.html',
  styleUrls: ['./gestion-utilisateurs.component.scss']
})
export class GestionUtilisateursComponent implements OnInit {
  agentRow: any[] = [];
  currentDisplayedIndex: number = -1;
  dataSource: any;
  Agent: any[] = [];
  dataTable: any[] = [];
  dataJSON: any[] = [];
  lenght: number;
  arianeLabel: string = 'Gestion des délégations';
  arianeIcon: string = 'person';
  arianeList = [
    {
      label: 'Administration',
      url: '/administration'
    },
    {
      label: 'Gestion des délégations',
      url: '/gestion-des-utilisateurs'
    }
  ];
  dataJSON_listeAgents: any[] = [];
  dataTable_listeAgents: any[] = [];
  userRoles: string[] = [];
  rowAgent: any = {};
  filtre: string;
  previousValue: any;
  filterValue: string = '';
  constructor(
    private agentService: AgentsService,
    private delegueservice: DeleguesService,
    private detectorRef: ChangeDetectorRef,
    private roleService: RoleService,
    public agentDialog: MatDialog,
    public dialog: MatDialog,
    private refreshService: CommunicationService
  ) {}
  ngOnInit() {
    this.dataSource = new MatTableDataSource();
    this.roleService.getRole().subscribe((roles) => {
      this.userRoles = roles;
    });
    this.refreshService.refreshList$.subscribe((refresh) => {
      /**
       * On utilise ce service pour rafraichir le nombre de délégué de l'agent
       * quand on sort de la modale des délégués
       */
      if (refresh) {
        if (this.currentDisplayedIndex >= 0) {
          this.dataTable_listeAgents[this.currentDisplayedIndex]['Nombre de délégués'] = this.refreshService.getNbAgent();
          this.detectorRef.markForCheck();
        }
      }
    });
    this.listeAgentsLoading();
  }
  agentDisplayedColumns: string[] = [
    'Nom',
    'Prénom',
    'Email',
    'Login',
    'Dernière connexion',
    'Collectivité',
    'Structure',
    'DG',
    'Profil',
    'Matricule',
    'Direction',
    'Service',
    'Nombre de délégués',
    'Etat',
    'Statut',
    'Fonction',
    'Notif. Directeur',
    'Code Grade',
    'Actions'
  ];

  agentCellStyleCss: any = {
    Matricule: { 'text-align': 'center', 'padding-right': '30px' },
    Direction: { 'text-align': 'center', 'padding-right': '30px' },
    Service: { 'text-align': 'center', 'padding-right': '30px' },
    Etat: { 'text-align': 'center' },
    'Nombre de délégués': { 'text-align': 'center' },
    Statut: { 'text-align': 'center' },
    'Notif. Directeur': { 'text-align': 'center' },
    DG: { 'text-align': 'center' }
  };
  formatDate(date: string) {
    const ma_date = DateTime.fromISO(date);
    return ma_date.isValid ? ma_date.toFormat("dd/MM/yyyy  HH':'mm") : '';
  }

  listeAgentsLoading(filtre = null) {
    this.agentService
      .getAgentWithParams(environment.pagination.offset, environment.pagination.limit, filtre)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          console.log('result', result);

          this.dataJSON = result.results;

          this.dataTable_listeAgents = this.transformDataListeAgent();
          this.detectorRef.markForCheck();
          this.lenght = result.totalRecords;
        },
        error: (error) => {
          console.log('erreur:', error);
        }
      });
  }
  fitlerAgentsLoading(offset: number, limit: number, filtre = null) {
    this.agentService
      .getAgentWithParams(offset, limit, filtre)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          console.log('result', result);

          this.dataJSON = result.results;
          this.dataTable_listeAgents = this.transformDataListeAgent();
          this.detectorRef.markForCheck();
          this.lenght = result.totalRecords;
        },
        error: (error) => {
          console.log('erreur:', error);
        }
      });
  }
  transformDataListeAgent() {
    let dataAgent: any[] = [];

    for (let key in this.dataJSON) {
      let dataJ = this.dataJSON[key];

      let _data: any = {};
      _data['id'] = dataJ?.id;
      _data['Nom'] = dataJ['nom'];
      _data['Prénom'] = dataJ['prenom'];
      _data['Matricule'] = dataJ['matricule'];
      _data['Email'] = dataJ['email'];
      _data['Login'] = dataJ['login'];
      _data['Dernière connexion'] = this.formatDate(dataJ['dateDerniereConnexion']);
      _data['Collectivité'] = dataJ['collectivite'];
      _data['Structure'] = dataJ['structure'];
      _data['Profil'] = dataJ['profil']; //
      _data['Direction'] = dataJ['direction']?.libelle;
      _data['Service'] = dataJ['serviceFonctionnel']?.libelle;

      _data['Nombre de délégués'] = '...';
      this.delegueservice.getDeleguesAgent(dataJ?.id).subscribe((total) => {
        _data['Nombre de délégués'] = total.totalRecords;
      });

      let status_code = dataJ['actif'];
      let status_text = '';
      let status_class = '';

      if (status_code === true) {
        status_class = 'badge-green-actif';
        status_text = 'Actif';
      } else if (status_code === false) {
        status_class = 'badge-gray-inactif';
        status_text = 'Inactif';
        _data['editUserInactif'] = false;
        _data['addUserInactif'] = true;
      }

      _data['Etat'] = { class: status_class, text: status_text };

      _data['Fonction'] = dataJ['fonction'];
      _data['Notif. Directeur'] = dataJ['flagANotifier'];
      _data['DG'] = dataJ['flagDG'] ? 'DG' : 'NON DG';

      _data['Statut'] = dataJ['statut'];
      let status = dataJ['flagANotifier'];
      let label = '';
      let styleclass = '';

      if (status === true) {
        styleclass = 'badge-green-statut';
        label = 'True';
      } else if (status === false) {
        styleclass = 'badge-red-statut';
        label = 'False';
      }
      _data['Notif. Directeur'] = { class: styleclass, text: label };
      _data['Code Grade'] = dataJ['codeGrade'];

      dataAgent.push(_data);
    }

    return dataAgent;
  }

  updatePagination(event: any) {
    if (event) {
      this.agentService
        .getAgentWithParams(event.offset, event.limit, event.filterValue)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.dataJSON = result.results;
            this.dataTable_listeAgents = this.transformDataListeAgent();
            this.detectorRef.markForCheck();
            this.lenght = result.totalRecords;
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    } else {
      this.agentService
        .getAgentWithParams(environment.pagination.offset, environment.pagination.limit)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.dataJSON = result.results;
            this.dataTable_listeAgents = this.transformDataListeAgent();
            this.detectorRef.markForCheck();
            this.lenght = result.totalRecords;
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    }
  }
  displayDetailsDelegate(id: any) {
    /**
     * Mémorisation rang ligne sélectionnée
     */
    let idxAgent = -1;
    this.dataJSON.forEach((agent) => {
      idxAgent++;
      if (agent.id === id) {
        this.currentDisplayedIndex = idxAgent;
      }
    });

    this.searchDataID(this.dataJSON, id);
    this.agentDialog.open(DelegationModalComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        agentId: id,
        agentName: this.rowAgent.nom,
        agentPrenom: this.rowAgent.prenom,
        viewMode: 'read'
      }
    });
  }

  onViewAgentDelegate(id: number) {
    /**
     * Mémorisation rang ligne sélectionnée
     */
    let idxAgent = -1;
    this.dataJSON.forEach((agent) => {
      idxAgent++;
      if (agent.id === id) {
        this.currentDisplayedIndex = idxAgent;
      }
    });

    this.searchDataID(this.dataJSON, id);
    this.agentDialog
      .open(DelegationModalComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        data: {
          agentId: id,
          agentName: this.rowAgent.nom,
          agentPrenom: this.rowAgent.prenom,
          agentMatricule: this.rowAgent.matricule,
          viewMode: 'edit'
        }
      })
      .afterClosed()
      .subscribe((action) => {
        if (action) {
          this.listeAgentsLoading();

          this.detectorRef.markForCheck();
        }
      });
  }
  modifierDelegateAgent(rowCheck: number) {
    console.log('id of agent ', rowCheck);

    console.log('All data', this.dataJSON);
    const rowdata = this.dataJSON.find((agent) => agent.id === rowCheck);
    console.log('Agent Data', rowdata);
    this.dialog
      .open(EditAgentComponent, {
        width: '780px',
        data: {
          id: rowCheck,
          rowElement: rowdata
        }
      })
      .afterClosed()
      .subscribe((result) => {
        console.log(`Dialog result: ${result}`);
        this.listeAgentsLoading();
      });
  }

  searchDataID(data: any, id: number) {
    let index = Object.keys(data).find((k) => data[k].id === id);

    if (index != null) {
      this.rowAgent = this.dataJSON[Number(index)];
    }
  }
  updateFiltre(value: any) {
    this.filterValue = value.filterValue;
    this.updatePagination(value);
  }
}
