import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormBuilder, FormsModule, ReactiveFormsModule, FormGroup, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule, DateAdapter } from '@angular/material/core';

import { AgentSuivantComponent } from 'src/app/shared/agent-suivant/agent-suivant.component';
import { MatChipsModule } from '@angular/material/chips';
import { ValidationModalComponent } from 'src/app/shared/validation-modal/validation-modal.component';
import { HeureSupDirectionOrigineFormComponent } from '../heure-sup-direction-origine-form/heure-sup-direction-origine-form.component';
import { HeureSupForm3Component } from '../heure-sup-form-3/heure-sup-form-3.component';

import { delay, take } from 'rxjs';
import { DirectionService } from 'src/app/services/direction.service';
import { HeuresSupplémentairesService } from 'src/app/services/heures-supplémentaires.service';
import { AlerteModalComponent } from 'src/app/shared/alerte-modal/alerte-modal.component';
import { EvenementsService } from 'src/app/services/evenements.service';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DateTime, Duration } from 'luxon';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateCampagneRangeService } from 'src/app/core/services/date-campagne-range.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CustomLuxonDateAdapter } from 'src/app/shared/utils/custom-luxon-date-adapter';
import { MY_FORMATS } from 'src/app/shared/utils/date-pickr-custom-luxon-format';

@Component({
  selector: 'app-heure-sup-edition',
  standalone: true,
  imports: [
    AgentSuivantComponent,
    CommonModule,
    MatTableModule,
    MatRadioModule,
    MatIconModule,
    MatCardModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    HeureSupDirectionOrigineFormComponent,
    HeureSupForm3Component,
    NgxMaterialTimepickerModule,
    DragDropModule
  ],
  templateUrl: './heure-sup-edition.component.html',
  styleUrls: ['./heure-sup-edition.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: DateAdapter, useClass: CustomLuxonDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class HeureSupEditionComponent {
  Agent: any[] = [];
  evenements: any[] = [];
  directionList: any[] = [];
  selectedEvenement: string = '';
  titre_detail: string = '';
  formDirectionActif: boolean = true;
  formEvenementActif: boolean = false;
  rowData: any = [];
  minDate: DateTime | null;
  maxDate: DateTime | null;

  form_direction: FormGroup = this.formBuilder.group({
    motif: [{ value: '', disabled: true }],
    agent: [{ value: '', disabled: true }],
    direction_origine: [{ value: '', disabled: true }],
    service: [{ value: '', disabled: true }],
    date: ['', [Validators.required]],
    heure_debut: ['', [Validators.required]],
    heure_fin: ['', [Validators.required]],
    duree: [{ value: '', disabled: true }],
    evenement: ['', [Validators.required]]
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<HeureSupForm3Component>,
    private directionService: DirectionService,
    private HS_service: HeuresSupplémentairesService,
    private eventsService: EvenementsService,
    private spinner: NgxSpinnerService,
    private dateCampagneRangeService: DateCampagneRangeService
  ) {}

  ngOnInit() {
    this.rowData = this.data;
    this.initForm();
    this.setDatePicker();
    this.menuEvenements();
  }

  initForm() {
    console.log('initForm rowData:', this.rowData);

    // active le formulaire suivant le type ("HS_POUR_DIRECTION_ORIGINE")

    // force le type
    const type = 'HS_POUR_DIRECTION_ORIGINE';

    // active le form2 : HS direction origine
    if (type != undefined && type != null && type == 'HS_POUR_DIRECTION_ORIGINE') {
      this.formDirectionActif = true;
      this.formEvenementActif = false;
      this.form_direction.removeControl('evenement');
      this.form_direction.controls['direction_origine'].setValue(this.rowData?.directionOrigine?.libelle);
      this.titre_detail = " Direction d'origine";
    }
    // active le form2 : HS évènement
    else {
      this.formDirectionActif = false;
      this.formEvenementActif = true;
      this.form_direction.removeControl('direction_origine');
      this.titre_detail = ' Evènement';
    }

    // rempli les deux types de formulaire
    this.form_direction.controls['motif'].setValue(this.rowData?.motif);
    this.form_direction.controls['date'].setValue(this.rowData?.dateRealisation);
    this.form_direction.controls['heure_debut'].setValue(this.formatTime(this.rowData?.heureDebut));
    this.form_direction.controls['heure_fin'].setValue(this.formatDureeArrondi(this.rowData?.heureFin));
    this.form_direction.controls['duree'].setValue(this.formatTime(this.rowData?.duree, 'H:m:s'));
    this.form_direction.controls['agent'].setValue(`${this.rowData?.agent?.nom} ${this.rowData?.agent?.prenom}`);
    this.form_direction.controls['service'].setValue(this.rowData?.serviceFonctionnel?.libelle);
  }

  formatTime(time: string, format = 'HH:mm:ss') {
    const luxonTime = DateTime.fromFormat(time, format);
    if (luxonTime.isValid) {
      return luxonTime.toFormat('HH:mm');
    }
    return '';
  }

  // arrondit les secondes en minutes
  formatDureeArrondi(heure: string) {
    let _heure_fin = heure.split(':').map((a: any) => Number(a));
    if (_heure_fin[2] != undefined && _heure_fin[2] > 30) {
      _heure_fin[2] = 0;
      if (_heure_fin[1] == 59) {
        _heure_fin[1] = 0;
        _heure_fin[0] = _heure_fin[0] == 23 ? 0 : _heure_fin[0] + 1;
      } else {
        _heure_fin[1] += 1;
      }
    }
    let d_heure_fin = Duration.fromObject({ hours: _heure_fin[0], minutes: _heure_fin[1], seconds: _heure_fin[2] });

    if (Duration.isDuration(d_heure_fin) && d_heure_fin.isValid) {
      return d_heure_fin.toFormat('hh:mm');
    }

    return '';
  }

  changeSelcetionMotif(event: any) {
    if (event) {
      this.form_direction.controls['motif_libelle'].setValue(event.value.libelle);
    }
  }

  menuEvenements() {
    this.eventsService
      .getAllEvenements()
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          this.evenements = data;
          // sélection de l'option dans le menu
          this.evenements.forEach((_even: any) => {
            if (_even['id'] == this.rowData?.evenement?.id) {
              this.selectedEvenement = _even;
            }
          });
        }
      });
  }

  loadDirectionList() {
    this.directionService
      .getAllDirections()
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          this.directionList = data;
        }
      });
  }

  validerHSDirection() {
    this.spinner.show();
    let _data: any = {};
    // champs système non modifiables
    _data['id'] = this.rowData.id;
    _data['dateCreation'] = this.rowData?.dateCreation;
    _data['lastModifiedDate'] = this.rowData?.lastModifiedDate;
    _data['createdBy'] = this.rowData?.createdBy;
    _data['lastModifiedBy'] = this.rowData?.lastModifiedBy;
    _data['type'] = this.rowData?.type;
    _data['typeHS'] = this.rowData?.typeHS;
    _data['statut'] = this.rowData?.statut;
    _data['motif'] = this.rowData?.motif;

    // champs de saisie modifiables

    let dateRealisation = this.form_direction.controls['date'].value;
    let dateRealisation_dt = DateTime.fromJSDate(dateRealisation).isValid
      ? DateTime.fromJSDate(dateRealisation)
      : DateTime.fromISO(dateRealisation);
    if (dateRealisation_dt.isValid) {
      _data['dateRealisation'] = dateRealisation_dt.toFormat('yyyy-MM-dd') + 'T00:00:00';
    }

    _data['heureDebut'] = this.form_direction.controls['heure_debut'].value;

    _data['heureFin'] = this.form_direction.controls['heure_fin'].value;
    _data['agent'] = { id: this.rowData?.agent?.id };
    _data['directionOrganisatrice'] = { id: this.rowData?.directionOrganisatrice?.id };
    _data['serviceFonctionnel'] = { id: this.rowData?.serviceFonctionnel?.id };
    _data['campagne'] = { id: this.rowData?.campagne?.id };

    // form direction
    if (this.form_direction.get('direction_origine') && this.rowData?.directionOrigine?.id != undefined) {
      _data['directionOrigine'] = { id: this.rowData?.directionOrigine?.id };
    }
    // form évènement
    else if (this.form_direction.get('evenement') && this.rowData?.evenement?.id != undefined) {
      let evenement = this.form_direction.controls['evenement'].value;
      _data['evenement'] = { id: evenement.id };
    }
    let nbAgents = 0;
    let nbHs = 0;
    this.HS_service.modifierHS(_data, this.rowData.id)
      .pipe(delay(1000))
      .subscribe({
        next: (result) => {
          console.log(result);
          this.spinner.hide();

          this.dialog.open(ValidationModalComponent, {
            width: '600px',
            data: {
              title: 'Confirmation',
              content: 'La saisie HS a été modifiée avec succès.'
            }
          });
          this.dialogRef.close(true);
        },
        error: (error) => {
          this.spinner.hide();
          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Erreur',
              content: error.error.replace('Validation error:', '')
            }
          });
        }
      });
  }

  changedurationHs() {
    const commonDate = DateTime.now();
    const startDateTime = DateTime.fromFormat(
      `${commonDate.toISODate()}T${this.form_direction.get('heure_debut')?.value}`,
      "yyyy-MM-dd'T'HH:mm"
    );
    let endDateTime = DateTime.fromFormat(`${commonDate.toISODate()}T${this.form_direction.get('heure_fin')?.value}`, "yyyy-MM-dd'T'HH:mm");
    if (startDateTime.isValid && endDateTime.isValid) {
      // Si l'heure de fin est antérieure à l'heure de début, ajoute un jour à l'heure de fin
      if (endDateTime < startDateTime) {
        endDateTime = endDateTime.plus({ days: 1 });
      }
      // Calcule la différence de temps
      const diff = endDateTime.diff(startDateTime, ['hours', 'minutes']);

      const hours = diff.hours;
      const minutes = diff.minutes;
      console.log(`Différence de temps : ${hours} heures, ${minutes} minutes`);
      this.form_direction.get('duree')?.setValue(diff.toFormat('hh:mm'));
    }
  }
  rangeFilter = (d: DateTime | null): boolean => {
    return this.dateCampagneRangeService.rangeHandler(d);
  };

  // date min et max du date picker
  setDatePicker() {
    this.minDate = this.dateCampagneRangeService.minDate();
    this.maxDate = this.dateCampagneRangeService.maxDate();
  }
}
