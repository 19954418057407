<app-ariane-nav [label]="arianeLabel" [icon]="arianeIcon" [arianeList]="this.arianeList"></app-ariane-nav>
<mat-divider class="my-3"></mat-divider>

<div>
  <app-data-table
    #appDataTable
    [displayedColumns]="agentDisplayedColumns"
    [cellStyleCss]="agentCellStyleCss"
    [data]="dataTable_listeAgents"
    [expendable]="false"
    [hasView]="true"
    [editable]="true"
    [addable]="true"
    [delatable]="false"
    [filter]="true"
    [length]="lenght"
    (clicModalEdit)="onViewAgentDelegate($event)"
    (clicModalModifier)="modifierDelegateAgent($event)"
    (showDetail)="displayDetailsDelegate($event)"
    (updatePagination)="updatePagination($event)"
    (updateFiltre)="updateFiltre($event)"
    [customFilter]="true"
  ></app-data-table>
</div>
