<div container>
  <div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>

  <h2
    class="mb-0"
    style="padding-left: 15px; padding-bottom: 10px"
    style="cursor: grabbing"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    mat-dialog-title
  >
    Détails de la validation des saisies
  </h2>
  <button (click)="closepopup()" mat-dialog-close class="btnClose" mat-icon-button aria-label="Fermer la fenêtre">
    <mat-icon>close</mat-icon>
  </button>

  <ng-container *ngIf="afficheTable == true && afficheTable != null">
    <table mat-table [dataSource]="dataSource" aria-describedby="tableDescription">
      <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef>Date validation</th>
        <td mat-cell *matCellDef="let element">{{ element.dateCreation }}</td>
      </ng-container>
      <ng-container matColumnDef="Heure de validation">
        <th mat-header-cell *matHeaderCellDef>Heure validation</th>
        <td mat-cell *matCellDef="let element">{{ element.heurValidation }}</td>
      </ng-container>
      <ng-container matColumnDef="Avis">
        <th mat-header-cell *matHeaderCellDef>Avis</th>
        <td mat-cell *matCellDef="let element">{{ element.statut }}</td>
      </ng-container>

      <ng-container matColumnDef="Valideur">
        <th mat-header-cell *matHeaderCellDef>Valideur</th>
        <td mat-cell *matCellDef="let element">{{ element.valideur }}</td>
      </ng-container>

      <ng-container matColumnDef="motif">
        <th mat-header-cell *matHeaderCellDef>Motif</th>
        <td mat-cell *matCellDef="let element">{{ element.motif }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
    </table>
  </ng-container>

  <ng-container *ngIf="afficheTable == false && afficheTable != null">
    <div class="message-erreur">
      <div [hidden]="type == 'astreinte'">Il n'y a pas de ligne d'historique pour cette validation HS.</div>
      <div [hidden]="type == 'hs'">Il n'y a pas de ligne d'historique pour cette validation astreinte.</div>
    </div>
  </ng-container>

  <mat-divider></mat-divider>
  <div>
    <button (click)="fermerdialog()" mat-dialog-close class="btnFermer" mat-raised-button color="primary">Fermer</button>
  </div>
</div>
