// pdf-export.service.ts
import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';

import 'jspdf-autotable';

@Injectable({
  providedIn: 'root'
})
export class PdfExportService {
  exportToPdf(data: any[], columns: { header: string; dataKey: string }[], fileName: string): void {
    //@ts-ignore
    let pdf = new jsPDF('landscape');
    //@ts-ignore
    pdf.autoTable({
      head: [columns.map((column) => column.header)],
      body: data.map((item) => columns.map((column) => item[column.dataKey])),
      horizontalPageBreak: true
    });
    pdf.save(fileName);
  }
}
