<div class="col-lg-12 col-xs-12 pt-125 mt-20">
  <div class="sticky" style="width: 100%; z-index: 10">
    <nav mat-tab-nav-bar [tabPanel]="tabPanel">
      <ng-container *ngFor="let link of links; let i = index">
        <a
          mat-tab-link
          *ngIf="canAccessLink(link)"
          [routerLink]="['/' + Routerlinks[i]]"
          routerLinkActive
          #rla="routerLinkActive"
          [active]="rla.isActive"
        >
        <ng-container *ngIf="link=='ACCUEIL'"><mat-icon class="material-symbols-outlined icon-home" style="margin-right: 5px; font-size: 20px;"> home </mat-icon> </ng-container>
        
          
          {{ link }}
        </a>
      </ng-container>
    </nav>
  </div>
  <mat-tab-nav-panel #tabPanel>
    <div class="m-25">
      <div class="col-12 table-container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-tab-nav-panel>
</div>
