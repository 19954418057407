import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
private selectedNbAgents: number =1
 private refreshSubject = new BehaviorSubject<boolean>(false);
 refreshList$ = this.refreshSubject.asObservable();

 triggerRefresh(){
  this.refreshSubject.next(true)
 }

 setNbAgent(value:number){
  this.selectedNbAgents = value;
 }
 getNbAgent(){
  return this.selectedNbAgents;
 }
}
