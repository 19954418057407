import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, take } from 'rxjs';
import { DatePipe } from '@angular/common';
import { CampagnesService } from 'src/app/services/campagnes.service';

interface Campagne {
  dateDebut: string;
  dateFin: string;
}

@Injectable({
  providedIn: 'root'
})
export class CampagneActuService {
  dateP = new DatePipe('fr-FR');

  // data campagne actuelle
  private _data_a = new BehaviorSubject<any>({});

  get sharedData_a$(): Observable<any> {
    return this._data_a.asObservable();
  }

  constructor(private campagneService: CampagnesService) {}

  // met à jour le subject
  stockData_a(_data: any) {
    this._data_a.next(_data);
  }

  // lit les données du subject
  get getData_a(): Campagne {
    return this._data_a.getValue();
  }

  // Données séparées du tableau _data
  public dateDebut_a$ = this.sharedData_a$.pipe(
    map((data) => {
      return data.dateDebut;
    })
  );
  public dateFin_a$ = this.sharedData_a$.pipe(
    map((data) => {
      return data.dateFin;
    })
  );
  public nom_a$ = this.sharedData_a$.pipe(
    map((data) => {
      return data.nom;
    })
  );
  public commentaire_a$ = this.sharedData_a$.pipe(
    map((data) => {
      return data.commentaire;
    })
  );
  public type_a$ = this.sharedData_a$.pipe(
    map((data) => {
      return data.type;
    })
  );
  public titre_a$ = this.sharedData_a$.pipe(
    map((data) => {
      return data.titre;
    })
  );

  // campagne actuelle active
  public active_a$ = this.sharedData_a$.pipe(
    map((data) => {
      return data?.active;
    })
  );

  // data campagne prochaine
  private _data_p = new BehaviorSubject<any>({});

  get sharedData_p$(): Observable<any> {
    return this._data_p.asObservable();
  }

  // met à jour le subject
  stockData_p(_data: any) {
    this._data_p.next(_data);
  }

  // lit les données du subject
  get getData_p(): Campagne {
    return this._data_p.getValue();
  }

  // Données séparées du tableau _data
  public dateDebut_p$ = this.sharedData_p$.pipe(
    map((data) => {
      return data.dateDebut;
    })
  );
  public dateFin_p$ = this.sharedData_p$.pipe(
    map((data) => {
      return data.dateFin;
    })
  );
  public nom_p$ = this.sharedData_p$.pipe(
    map((data) => {
      return data.nom;
    })
  );
  public commentaire_p$ = this.sharedData_p$.pipe(
    map((data) => {
      return data.commentaire;
    })
  );
  public type_p$ = this.sharedData_p$.pipe(
    map((data) => {
      return data.type;
    })
  );
  public titre_p$ = this.sharedData_p$.pipe(
    map((data) => {
      return data.titre;
    })
  );

  // si les données sont vide, cherche les données une seule fois
  getCampagneActuelle(): Observable<any> {
    let _data_a: any = this.getData_a;

    // vérifie si la requête a déjà été lancée
    this.reqCampagneActuelle();
    this.reqCampagneProchaine();

    return this._data_a.asObservable();
  }

  // se connecte au service de l'API pour chercher la denière compagne
  reqCampagneActuelle() {
    let _data: any = this.getData_a;

    this.campagneService
      .getCampagneActuelleSync()
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          let dateDebut = this.dateP.transform(result['dateDebutSaisie'], 'shortDate');
          localStorage.setItem('dateDebutSaisie', result['dateDebutSaisie']);
          let dateFin = this.dateP.transform(result['dateFinSaisie'], 'shortDate');
          localStorage.setItem('dateFinSaisie', result['dateFinSaisie']);

          _data['id'] = result['id'];
          _data['nom'] = result['nom'];
          _data['commentaire'] = result['commentaire'];
          _data['type'] = result['type'];

          _data['titre'] = '';
          if (_data['type'] == 'SAISIE_EN_COURS') {
            _data['titre'] = 'La Campagne de saisie';
            dateDebut = this.dateP.transform(result['dateDebutSaisie'], 'shortDate');
            dateFin = this.dateP.transform(result['dateFinSaisie'], 'shortDate');
          } else if (_data['type'] == 'VALIDATION_EN_COURS') {
            _data['titre'] = 'La Campagne de validation';
            dateDebut = this.dateP.transform(result['dateDebutValidation'], 'shortDate');
            dateFin = this.dateP.transform(result['dateFinValidation'], 'shortDate');
          }
          /*else if (_data["type"]=="TERMINEE"){
            _data["titre"] = "La Campagne d'archivage"; 
            dateDebut = this.dateP.transform(result["dateArchivage"], 'shortDate');
            dateFin = this.dateP.transform(result["dateArchivage"], 'shortDate');
          }*/

          _data['dateDebut'] = dateDebut;
          _data['dateFin'] = dateFin;

          // désactive la campagne
          _data['active'] = _data['type'] != 'SAISIE_EN_COURS' && _data['type'] != 'VALIDATION_EN_COURS' ? false : true;

          this.stockData_a(_data);
          console.log('Stockage campagne ' + JSON.stringify(_data));
          sessionStorage.setItem('campagneActuelle', JSON.stringify(_data));
        },
        error: (error) => {
          console.log('error:', error);
          console.log('Stockage campagne erreur : on supprime la campagne stockée');
          sessionStorage.setItem('campagneActuelle', JSON.stringify({}));

          // erreur ou ne trouve pas de résultat
          let _data: any = this.getData_a;
          _data['active'] = false;
          this.stockData_a(_data);
        }
      });
  }

  reqCampagneProchaine() {
    let _data: any = this.getData_p;

    this.campagneService
      .getCampagneProchaine()
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          let dateDebut = this.dateP.transform(result['dateDebutSaisie'], 'shortDate');
          let dateFin = this.dateP.transform(result['dateFinSaisie'], 'shortDate');

          _data['id'] = result['id'];
          _data['nom'] = result['nom'];
          _data['commentaire'] = result['commentaire'];
          _data['type'] = result['type'];

          _data['titre'] = '';
          /**
           * Une campagne à venir est toujours une campagne de saisie ???
           */
          if (_data['type'] == 'SAISIE_EN_COURS') {
            _data['titre'] = 'La Campagne de saisie';
            dateDebut = this.dateP.transform(result['dateDebutSaisie'], 'shortDate');
            dateFin = this.dateP.transform(result['dateFinSaisie'], 'shortDate');
          } else if (_data['type'] == 'VALIDATION_EN_COURS') {
            _data['titre'] = 'La Campagne de validation';
            dateDebut = this.dateP.transform(result['dateDebutValidation'], 'shortDate');
            dateFin = this.dateP.transform(result['dateFinValidation'], 'shortDate');
          }
          /*else if (_data["type"]=="TERMINEE"){
            _data["titre"] = "La Campagne d'archivage"; 
            dateDebut = this.dateP.transform(result["dateArchivage"], 'shortDate');
            dateFin = this.dateP.transform(result["dateArchivage"], 'shortDate');
          }*/

          _data['dateDebut'] = dateDebut;
          _data['dateFin'] = dateFin;

          this.stockData_p(_data);
          console.log('Stockage campagne prochaine ' + JSON.stringify(_data));
          sessionStorage.setItem('campagneProchaine', JSON.stringify(_data));
        },
        error: (error) => {
          console.log('error:', error);
        }
      });
  }
}
