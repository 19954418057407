import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ParametresService } from 'src/app/services/parametres.service';
import { take } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ValidationModalComponent } from 'src/app/shared/validation-modal/validation-modal.component';
import { AlerteModalComponent } from 'src/app/shared/alerte-modal/alerte-modal.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-modal-modif-parametre',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule
  ],
  templateUrl: './modal-modif-parametre.component.html',
  styleUrls: ['./modal-modif-parametre.component.scss']
})
export class ModalModifParametreComponent implements OnInit {
  rowData: any = {};
  listParameter: any = [];
  ParameterList: any[] = [];
  parametreModifForm: any;
  savedFormData: any;
  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalModifParametreComponent>,
    private parametresService: ParametresService,
    private spinner: NgxSpinnerService
  ) {
    if (this.data?.data) {
      this.savedFormData = this.data.data;
      this.parametreModifForm = this.formBuilder.group({
        code: [this.data.data['code'], [Validators.required]],
        description: [this.data.data['description'], [Validators.required]],
        libelle: [this.data.data['libelle'], [Validators.required]],
        valeur: [this.data.data['valeur'], [Validators.required]]
      });
    }
  }

  ngOnInit() {
    this.rowData = this.data.data;
  }

  modifierParametre() {
    if (this.parametreModifForm.invalid) {
      return;
    }
    let _data: any = {
      code: this.parametreModifForm.controls['code'].value,
      description: this.parametreModifForm.controls['description'].value,
      id: this.data.data.id,
      libelle: this.parametreModifForm.controls['libelle'].value,
      valeur: this.parametreModifForm.controls['valeur'].value
    };

    this.parametresService
      .modifierParametre(_data, _data['id'])
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          console.log('result ok: ', result);
          this.spinner.hide();
          this.dialogRef.close({ action: true });
          this.dialog.open(ValidationModalComponent, {
            width: '600px',
            data: {
              title: 'Confirmation',
              content: 'Les paramètres ont été modifiés avec succès.'
            }
          });
        },
        error: (error) => {
          console.error('error:', error);
          let erreur_texte = error.error.detail || error.error || error.statusText || error;
          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: 'Erreur lors de la modification de paramètre !',
              status: 'STATUS: ' + error.status,
              detail: 'DETAIL: ' + erreur_texte
            }
          });
        }
      });
  }
}
