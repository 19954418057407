<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<mat-card-header style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="row col-md-12">
    <div class="col-6">
      <mat-card-title><h2 class="pl-20">Saisies des astreintes</h2></mat-card-title>
    </div>

    <div class="col-md-6 text-end mb-2 mr-0 ml-auto gx-0">
      <button (click)="cancelDialog()" class="py-0" mat-icon-button aria-label="Fermer la modale">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>
<mat-stepper labelPosition="bottom" linear #stepper class="stepper-modal" (selectionChange)="stepSelChange($event)">
  <ng-template matStepperIcon="edit"><mat-icon>done</mat-icon></ng-template>
  <ng-template matStepperIcon="done"><mat-icon>done</mat-icon></ng-template>

  <!-- second form - D.Origine -->
  <mat-step state="step1" editable="true">
    <mat-dialog-content class="mat-typography">
      <h3 class="h3-etape1"><b>Étape 1</b> : Sélectionner les Motif / Agents / Dates et heures</h3>

      <app-astreinte-form
        #childFormEvt
        *ngIf="stepper.selectedIndex === 0"
        [currentIndex]="this.currentIndex"
        [typeHS]="this.selectedOption"
        [infosFormulaires]="this.infosFormulaires"
      ></app-astreinte-form>
    </mat-dialog-content>

    <mat-dialog-actions *ngIf="stepper.selectedIndex === 0" style="background-color: rgba(250, 250, 252, 1)" align="end">
      <button mat-stroked-button mat-dialog-close class="mx-3" color="primary">Annuler</button>
      <button [disabled]="!this.childFormEvt?.formAstreinte?.valid" mat-flat-button matStepperNext color="primary">Suivant</button>
    </mat-dialog-actions>
  </mat-step>

  <!-- Third step: Synthese -->
  <mat-step state="step2" editable="true" completed="false">
    <mat-dialog-content class="mat-typography">
      <h3 class="h3-etape2"><b>Étape 2</b> : Synthèse des données</h3>

      <app-astreinte-synthese #syntheseComponent *ngIf="stepper.selectedIndex === 1" [data]="this.syntheseParAgent">
      </app-astreinte-synthese>
    </mat-dialog-content>

    <mat-dialog-actions style="background-color: rgba(250, 250, 252, 1)" align="end">
      <button mat-stroked-button mat-dialog-close class="mx-3" color="primary">Annuler</button>
      <button mat-stroked-button matStepperPrevious color="primary" class="mx-3" (change)="purgeData()">Précédent</button>
      <button mat-flat-button (click)="validerAstreinteModale()" color="primary">Valider</button>
    </mat-dialog-actions>
  </mat-step>

  <!-- third form - HS-event -->
</mat-stepper>
