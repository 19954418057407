<app-ariane-nav [label]="arianeLabel" [icon]="arianeIcon"></app-ariane-nav>
<mat-divider class="my-3"></mat-divider>

<mat-tab-group [(selectedIndex)]="selectedTabIndex">
  <mat-tab style="overflow-x: hidden" label="HEURES SUPPLÉMENTAIRES">
    <ng-template matTabContent>
      <div class="row mt-3">
        <div class="col flex-container end align-items-center">
          <div>
            <button mat-stroked-button class="bt-table-defaut mr-5" (click)="export_HS()"><mat-icon>download</mat-icon>Exporter</button>
          </div>

          <div *ngIf="btSupprMasseActif">
            <button mat-stroked-button class="bt-table-defaut mr-5" (click)="suppressionEnMasseHS('supprimer en masse', 'saisieHS')">
              <mat-icon>delete</mat-icon> Supprimer en masse
            </button>
          </div>

          <div>
            <button
              mat-stroked-button
              class="bt-table-defaut mr-5"
              (click)="togglePanel()"
              [style]="panelOpenState ? 'border: 2px solid #405d9c;' : ''"
            >
              <mat-icon *ngIf="!panelOpenState">filter_alt</mat-icon>
              <mat-icon *ngIf="!panelOpenState && FiltreHSActif" class="icon-filtre-check">check_circle</mat-icon>
              <mat-icon *ngIf="panelOpenState">filter_alt</mat-icon>
              <span *ngIf="!panelOpenState"> {{ filtrerHS_libelle$ | async }}</span>
              <span *ngIf="panelOpenState"> Liste des filtres </span>
              <mat-icon iconPositionEnd *ngIf="!panelOpenState">keyboard_arrow_down</mat-icon>
              <mat-icon iconPositionEnd *ngIf="panelOpenState">keyboard_arrow_up</mat-icon>
            </button>
          </div>
          <div *ngIf="boutonSaisirActif">
            <button mat-raised-button class="bt-table-defaut bt-table-ajouter mr-5" (click)="openDialog()">
              <mat-icon>add</mat-icon> Saisir des HS
            </button>
          </div>
        </div>
      </div>

      <mat-expansion-panel class="panel-filtre" [expanded]="panelOpenState" style="width: 100%">
        <form [formGroup]="form_filtre_HS" (reset)="recherche_HS()">
          <div class="div-champ-filtre">
            <div class="div-field">
              <mat-form-field floatLabel="always" class="field-filtre field-filtre-text">
                <mat-label>Agent </mat-label>
                <input
                  #autoCompleteAgent
                  type="text"
                  placeholder="Nom Prénom"
                  aria-label="Nom Prénom"
                  matInput
                  name="item"
                  [matAutocomplete]="auto"
                  formControlName="agent"
                  (input)="agentFilter($event)"
                  [(ngModel)]="val_ft_agent"
                />

                <button *ngIf="val_ft_agent" matSuffix mat-icon-button aria-label="Clear" (click)="effaceAgentFiltre($event, 'agent')">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete #auto="matAutocomplete" hideSingleSelectionIndicator requireSelection [displayWith]="agentdisplayFn">
                  <mat-option [value]="agent" *ngFor="let agent of filteredOptions"> {{ agent.nom }} {{ agent.prenom }} </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div class="div-field">
              <mat-form-field class="field-filtre field-filtre-select" floatLabel="always">
                <mat-label>Type d'heure</mat-label>
                <mat-select formControlName="typeHS" [(ngModel)]="val_ft_typeHS">
                  <mat-option value="JOUR">Jour</mat-option>
                  <mat-option value="NUIT">Nuit</mat-option>
                  <mat-option value="FERIE">Dimanche/JF</mat-option>
                </mat-select>
                <button *ngIf="val_ft_typeHS" matSuffix mat-icon-button aria-label="Clear" (click)="effaceFiltre($event, 'typeHS')">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div class="div-field">
              <mat-form-field class="field-filtre" floatLabel="always">
                <mat-label>Date création</mat-label>
                <input
                  type="text"
                  formControlName="dateCreation"
                  appearance="standard"
                  matInput
                  [matDatepicker]="pickerDCrea"
                  placeholder="dd/mm/yyyyy"
                />
                <mat-datepicker-toggle matIconSuffix [for]="pickerDCrea"></mat-datepicker-toggle>
                <mat-datepicker #pickerDCrea></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="div-field">
              <mat-form-field class="field-filtre" floatLabel="always">
                <mat-label>Date HS</mat-label>
                <input
                  type="text"
                  formControlName="dateRealisation"
                  appearance="standard"
                  matInput
                  [matDatepicker]="pickerDHS"
                  placeholder="dd/mm/yyyyy"
                />
                <mat-datepicker-toggle matIconSuffix [for]="pickerDHS"></mat-datepicker-toggle>
                <mat-datepicker #pickerDHS></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="div-field">
              <mat-form-field class="field-filtre" floatLabel="always">
                <mat-label style="height: 20px; border: none">Heure début</mat-label>

                <input type="time" matInput formControlName="heure_debut" (ngModelChange)="changedurationfilter()" />
              </mat-form-field>
            </div>
            <div class="div-field">
              <mat-form-field class="field-filtre" floatLabel="always">
                <mat-label>Heure fin</mat-label>

                <input type="time" matInput (ngModelChange)="changedurationfilter()" formControlName="heure_fin" />
              </mat-form-field>
            </div>

            <div class="div-field">
              <mat-form-field class="field-filtre" style="width: 100%">
                <mat-label>Durée</mat-label>
                <input type="time" matInput formControlName="duree" [(ngModel)]="val_ft_duree" />
                <button *ngIf="val_ft_duree" matSuffix mat-icon-button aria-label="Clear" (click)="effaceFiltre($event, 'duree')">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div class="div-field">
              <mat-form-field class="field-filtre" floatLabel="always">
                <mat-label>Emetteur</mat-label>
                <input formControlName="emetteur" matInput [(ngModel)]="val_ft_emetteur" />
                <button *ngIf="val_ft_emetteur" matSuffix mat-icon-button aria-label="Clear" (click)="effaceFiltre($event, 'emetteur')">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div class="div-field">
              <mat-form-field class="field-filtre field-filtre-select" floatLabel="always">
                <mat-label>Direction origine</mat-label>

                <input
                  #autoCompleteDirection
                  type="text"
                  placeholder="Direction Origine"
                  aria-label="Direction origine"
                  matInput
                  [matAutocomplete]="autoDirectionHS"
                  formControlName="directionOrigine"
                  (input)="directionOrigineFilter($event)"
                  [(ngModel)]="val_ft_directionOrigine"
                />
                <button
                  *ngIf="val_ft_directionOrigine"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="effaceDirectionFiltre($event, 'directionOrigine')"
                >
                  <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete cdkScrollable #autoDirectionHS="matAutocomplete" [displayWith]="directionOriginedisplayFn">
                  <mat-option *ngFor="let item of directionList" [value]="item">{{ item.libelle }}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div class="div-field">
              <mat-form-field class="field-filtre field-filtre-select" floatLabel="always">
                <mat-label>Direction évènement</mat-label>
                <input
                  #autoCompleteEvent
                  type="text"
                  placeholder="Direction Evènement"
                  aria-label="Direction Evènement"
                  matInput
                  [matAutocomplete]="autoEvenement"
                  formControlName="directionOrganisatrice"
                  (input)="directionEvenementFilter($event)"
                  [(ngModel)]="val_ft_directionOrganisatrice"
                />
                <button
                  *ngIf="val_ft_directionOrganisatrice"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="effaceEventFiltre($event, 'directionOrganisatrice')"
                >
                  <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete #autoEvenement="matAutocomplete" [displayWith]="directionEvenementdisplayFn">
                  <mat-option *ngFor="let event of directionEventList" [value]="event"> {{ event.libelle }} </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div class="div-field">
              <mat-form-field class="field-filtre field-filtre-select" floatLabel="always">
                <mat-label>Statut</mat-label>
                <mat-select formControlName="statut" [(ngModel)]="val_ft_statut">
                  <mat-option value="A_VALIDER">Avis non renseigné</mat-option>
                  <mat-option value="A_VALIDER_RESPONSABLE">Avis non renseigné (responsable)</mat-option>
                  <mat-option value="SAISIE_A_RENOUVELER">Saisie à renouveler</mat-option>
                  <mat-option value="VALIDEES_RESPONSABLE">Avis favorable - Responsable</mat-option>
                  <mat-option value="REFUSEES_RESPONSABLE">Avis défavorable - Responsable</mat-option>
                  <mat-option value="VALIDEES">Avis favorable - Directeur</mat-option>
                  <mat-option value="REFUSEES">Avis défavorable - Directeur</mat-option>
                  <mat-option value="VALIDEES_N2">Avis favorable - DG</mat-option>
                  <mat-option value="REFUSEES_N2">Avis défavorable - DG</mat-option>
                </mat-select>
                <button *ngIf="val_ft_statut" matSuffix mat-icon-button aria-label="Clear" (click)="effaceFiltreStatut($event, 'statut')">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div class="div-field">
              <mat-form-field floatLabel="always" class="field-filtre field-filtre-text">
                <mat-label>Campagne</mat-label>
                <input
                  #autoCompleteCamp
                  type="text"
                  placeholder="Campagne"
                  aria-label="Campagne"
                  matInput
                  name="item"
                  [matAutocomplete]="autoCampagne"
                  formControlName="campagne"
                  (input)="campagneFilter($event)"
                  [(ngModel)]="val_ft_campagne"
                />

                <button
                  *ngIf="val_ft_campagne"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="effaceCampagneFiltre($event, 'campagne')"
                >
                  <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete
                  #autoCampagne="matAutocomplete"
                  hideSingleSelectionIndicator
                  requireSelection
                  [displayWith]="campagnedisplayFn"
                >
                  <mat-option *ngFor="let campagne of campagneList" [value]="campagne">{{ campagne.nom }}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div class="div-field">
              <mat-form-field class="field-filtre" floatLabel="always">
                <mat-label>Service Origine</mat-label>

                <input
                  #autoCompleteService
                  type="text"
                  placeholder="Service Origine"
                  aria-label="Service Origine"
                  matInput
                  [matAutocomplete]="autoService"
                  formControlName="serviceFonctionnel"
                  (input)="serviceFilter($event)"
                  [(ngModel)]="val_ft_serviceFonctionnel"
                />
                <button
                  *ngIf="val_ft_serviceFonctionnel"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="effaceFiltre($event, 'serviceFonctionnel')"
                >
                  <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete
                  #autoService="matAutocomplete"
                  hideSingleSelectionIndicator
                  requireSelection
                  [displayWith]="servicedisplayFn"
                >
                  <mat-option *ngFor="let element of serviceFonctionnelList" [value]="element">{{ element.libelle }}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div class="div-field">
              <mat-form-field floatLabel="always" class="field-filtre field-filtre-text">
                <mat-label>Motif</mat-label>
                <input formControlName="motif" matInput [(ngModel)]="val_ft_motif" />
                <button *ngIf="val_ft_motif" matSuffix mat-icon-button aria-label="Clear" (click)="effaceFiltre($event, 'motif')">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div class="div-filtre-bouton" style="margin-left: 10px">
              <button mat-stroked-button type="reset" class="mr-5 bt-table-effacer" (click)="recherche_HS()" style="height: 40px">
                <mat-icon>delete</mat-icon> Effacer tous les filtres
              </button>
              <button mat-raised-button class="mr-5 bt-table-filtrer" (click)="recherche_HS()" style="height: 40px; width: 100px">
                Filtrer
              </button>
            </div>
          </div>
        </form>
      </mat-expansion-panel>

      <div class="mt-1">
        <app-data-table
          #hourDataTable
          [displayedColumns]="hourDisplayedColumns"
          [cellStyleCss]="hourCellStyleCss"
          [headerStyleCssSub]="hourHeaderStyleCssSub"
          [cellStyleCssSub]="hourCellStyleCssSub"
          [data]="dataTable_HS"
          [expendable]="true"
          [subTableDisplayedColumns]="subTableDisplayedColumns"
          [editable]="true"
          [delatable]="true"
          [refreshable]="true"
          [selectable]="true"
          selectionKey="saisieHS"
          [export]="true"
          (exporterLigne)="exportPdf_HS($event)"
          [filter]="false"
          [length]="hslenght"
          (deleteElement)="deleteHs($event)"
          (refreshSaisie)="renouvelerSaisieHs($event)"
          (clicModalModifier)="openHeureSupEdit($event)"
          (updatePagination)="updatePaginationHs($event)"
        ></app-data-table>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab label="ASTREINTES" [disabled]="false">
    <ng-template matTabContent>
      <div class="row mt-3">
        <div class="col flex-container end align-items-center">
          <div>
            <button (click)="exportAstrainte()" mat-stroked-button class="bt-table-defaut mr-5">
              <mat-icon>download</mat-icon>Exporter
            </button>
          </div>

          <div *ngIf="btSupprMasseActif">
            <button mat-stroked-button class="bt-table-defaut mr-5" (click)="suppressionEnMasseHS('supprimer en masse', 'saisieAst')">
              <mat-icon>delete</mat-icon> Supprimer en masse
            </button>
          </div>

          <div>
            <button
              mat-stroked-button
              class="bt-table-defaut mr-5"
              (click)="togglePanelAstreinte()"
              [style]="panelOpenStateAst ? 'border: 2px solid #405d9c;' : ''"
            >
              <mat-icon *ngIf="!panelOpenStateAst">filter_alt</mat-icon>
              <mat-icon *ngIf="!panelOpenStateAst && FiltreAstActif" class="icon-filtre-check">check_circle</mat-icon>
              <mat-icon *ngIf="panelOpenStateAst">filter_alt</mat-icon>
              <span *ngIf="!panelOpenStateAst"> {{ filtrerAst_libelle$ | async }}</span>
              <span *ngIf="panelOpenStateAst"> Liste des filtres </span>
              <mat-icon iconPositionEnd *ngIf="!panelOpenStateAst">keyboard_arrow_down</mat-icon>
              <mat-icon iconPositionEnd *ngIf="panelOpenStateAst">keyboard_arrow_up</mat-icon>
            </button>
          </div>

          <div>
            <button
              *ngIf="boutonSaisirAstreinteActif"
              mat-raised-button
              class="bt-table-defaut bt-table-ajouter mr-5"
              (click)="openAstreintsDialog()"
            >
              <mat-icon>add</mat-icon> Saisir des Astreintes
            </button>
          </div>
        </div>
      </div>
      <div class="mt-1">
        <mat-expansion-panel class="panel-filtre" [expanded]="panelOpenStateAst" style="width: 100%">
          <form [formGroup]="form_filtre_Ast" (reset)="recherche_astreinte()">
            <div class="div-champ-filtre">
              <div class="div-field">
                <mat-form-field floatLabel="always" class="field-filtre field-filtre-text">
                  <mat-label>Agent </mat-label>
                  <input
                    #autoCompleteAstAgent
                    type="text"
                    placeholder="Nom Prénom"
                    aria-label="Nom Prénom"
                    matInput
                    name="item"
                    [matAutocomplete]="auto"
                    formControlName="agent"
                    (input)="agentFilter($event)"
                    [(ngModel)]="val_ft_ast_agent"
                  />

                  <button
                    *ngIf="val_ft_ast_agent"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="effaceAgentFiltreAst($event, 'agent')"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-autocomplete #auto="matAutocomplete" hideSingleSelectionIndicator requireSelection [displayWith]="agentdisplayFn">
                    <mat-option [value]="agent" *ngFor="let agent of filteredOptions"> {{ agent.nom }} {{ agent.prenom }} </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

              <div class="div-field">
                <mat-form-field class="field-filtre field-filtre-select" floatLabel="always">
                  <mat-label>Type</mat-label>
                  <mat-select formControlName="type" [(ngModel)]="val_ft_ast_type">
                    <mat-option value="JOUR">Jour</mat-option>
                    <mat-option value="NUIT">Nuit</mat-option>
                    <mat-option value="SEMAINE">Semaine</mat-option>
                    <mat-option value="WEEKEND">Week-end</mat-option>
                    <mat-option value="SAMEDI">Samedi</mat-option>
                    <mat-option value="DIMANCHE_FERIE">Dimanche / jour férié</mat-option>
                    <mat-option value="CRECHE">Crèche</mat-option>
                  </mat-select>
                  <button *ngIf="val_ft_ast_type" matSuffix mat-icon-button aria-label="Clear" (click)="effaceFiltreAst($event, 'type')">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>

              <div class="div-field">
                <mat-form-field class="field-filtre" floatLabel="always">
                  <mat-label>Date création</mat-label>
                  <input
                    type="text"
                    formControlName="dateCreation"
                    appearance="standard"
                    matInput
                    [matDatepicker]="pickerDCreaAst"
                    placeholder="jj/mm/aaaa"
                  />
                  <mat-datepicker-toggle matIconSuffix [for]="pickerDCreaAst"></mat-datepicker-toggle>
                  <mat-datepicker #pickerDCreaAst></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="div-field">
                <mat-form-field class="field-filtre" floatLabel="always">
                  <mat-label>Date début</mat-label>
                  <input
                    type="text"
                    formControlName="dateDebut"
                    appearance="standard"
                    matInput
                    [matDatepicker]="pickerDDAst"
                    placeholder="jj/mm/aaaa"
                  />
                  <mat-datepicker-toggle matIconSuffix [for]="pickerDDAst"></mat-datepicker-toggle>
                  <mat-datepicker #pickerDDAst></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="div-field">
                <mat-form-field class="field-filtre" floatLabel="always">
                  <mat-label>Date fin</mat-label>
                  <input
                    type="text"
                    formControlName="dateFin"
                    appearance="standard"
                    matInput
                    [matDatepicker]="pickerDFAst"
                    placeholder="jj/mm/aaaa"
                  />
                  <mat-datepicker-toggle matIconSuffix [for]="pickerDFAst"></mat-datepicker-toggle>
                  <mat-datepicker #pickerDFAst></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="div-field">
                <mat-form-field class="field-filtre" floatLabel="always">
                  <mat-label>Nombre</mat-label>
                  <input type="text" matInput formControlName="nombre" [(ngModel)]="val_ft_ast_nombre" />
                  <button
                    *ngIf="val_ft_ast_nombre"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="effaceFiltreAst($event, 'nombre')"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>

              <div class="div-field">
                <mat-form-field class="field-filtre" floatLabel="always">
                  <mat-label>Emetteur</mat-label>
                  <input formControlName="emetteur" matInput [(ngModel)]="val_ft_ast_emetteur" />
                  <button
                    *ngIf="val_ft_ast_emetteur"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="effaceFiltreAst($event, 'emetteur')"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>

              <div class="div-field">
                <mat-form-field class="field-filtre field-filtre-select" floatLabel="always">
                  <mat-label>Direction origine</mat-label>
                  <input
                    #autoCompleteAstDirection
                    type="text"
                    placeholder="Direction Origine"
                    aria-label="Direction origine"
                    matInput
                    [matAutocomplete]="autoDirectionAst"
                    formControlName="directionOrigine"
                    (input)="directionOrigineFilter($event)"
                    [(ngModel)]="val_ft_ast_directionOrigine"
                  />
                  <button
                    *ngIf="val_ft_ast_directionOrigine"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="effaceDirectionFiltreAst($event, 'directionOrigine')"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-autocomplete cdkScrollable #autoDirectionAst="matAutocomplete" [displayWith]="directionOriginedisplayFn">
                    <mat-option *ngFor="let item of directionList" [value]="item">{{ item.libelle }}</mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

              <div class="div-field">
                <mat-form-field class="field-filtre field-filtre-select" floatLabel="always">
                  <mat-label>Statut</mat-label>
                  <mat-select formControlName="statut" [(ngModel)]="val_ft_ast_statut">
                    <mat-option value="A_VALIDER">Avis non renseigné</mat-option>
                    <mat-option value="A_VALIDER_RESPONSABLE">Avis non renseigné (responsable)</mat-option>
                    <mat-option value="SAISIE_A_RENOUVELER">Saisie à renouveler</mat-option>
                    <mat-option value="VALIDEES_RESPONSABLE">Avis favorable - Responsable</mat-option>
                    <mat-option value="REFUSEES_RESPONSABLE">Avis défavorable - Responsable</mat-option>
                    <mat-option value="VALIDEES">Avis favorable - Directeur</mat-option>
                    <mat-option value="REFUSEES">Avis défavorable - Directeur</mat-option>
                    <mat-option value="VALIDEES_N2">Avis favorable - DG</mat-option>
                    <mat-option value="REFUSEES_N2">Avis défavorable - DG</mat-option>
                  </mat-select>
                  <button
                    *ngIf="val_ft_ast_statut"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="effaceFiltreAst($event, 'statut')"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>

              <div class="div-field">
                <mat-form-field floatLabel="always" class="field-filtre field-filtre-text">
                  <mat-label>Campagne</mat-label>
                  <input
                    #autoCompleteAstCampagne
                    type="text"
                    placeholder="Campagne"
                    aria-label="Campagne"
                    matInput
                    name="item"
                    [matAutocomplete]="autoCampagne"
                    formControlName="campagne"
                    (input)="campagneFilter($event)"
                    [(ngModel)]="val_ft_ast_campagne"
                  />

                  <button
                    *ngIf="val_ft_ast_campagne"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="effaceCampagneFiltreAst($event, 'campagne')"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-autocomplete
                    #autoCampagne="matAutocomplete"
                    hideSingleSelectionIndicator
                    requireSelection
                    [displayWith]="campagnedisplayFn"
                  >
                    <mat-option *ngFor="let campagne of campagneList" [value]="campagne">{{ campagne.nom }}</mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

              <div class="div-field">
                <mat-form-field class="field-filtre" floatLabel="always">
                  <mat-label>Service Origine</mat-label>
                  <input
                    #autoCompleteAstService
                    type="text"
                    placeholder="Service Origine"
                    aria-label="Service Origine"
                    matInput
                    [matAutocomplete]="autoService"
                    formControlName="serviceFonctionnel"
                    (input)="serviceFilter($event)"
                    [(ngModel)]="val_ft_ast_serviceFonctionnel"
                  />
                  <button
                    *ngIf="val_ft_ast_serviceFonctionnel"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="effaceServiceFiltreAst($event, 'serviceFonctionnel')"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-autocomplete
                    #autoService="matAutocomplete"
                    hideSingleSelectionIndicator
                    requireSelection
                    [displayWith]="servicedisplayFn"
                  >
                    <mat-option *ngFor="let element of serviceFonctionnelList" [value]="element">{{ element.libelle }}</mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

              <div class="div-filtre-bouton" style="margin-left: 10px">
                <button mat-stroked-button type="reset" class="mr-5 bt-table-effacer" (click)="recherche_astreinte()" style="height: 40px">
                  <mat-icon>delete</mat-icon> Effacer tous les filtres
                </button>
                <button mat-raised-button class="mr-5 bt-table-filtrer" (click)="recherche_astreinte()" style="height: 40px; width: 100px">
                  Filtrer
                </button>
              </div>
            </div>
          </form>
        </mat-expansion-panel>
      </div>

      <app-data-table
        #penalitiesDataTable
        [displayedColumns]="astreintesDisplayedColumns"
        [cellStyleCss]="astCellStyleCss"
        [data]="dataTable_Ast"
        [expendable]="true"
        [subTableDisplayedColumns]="astSubTableDisplayedColumns"
        [editable]="true"
        [delatable]="true"
        [refreshable]="true"
        [selectable]="true"
        selectionKey="saisieAst"
        [filter]="false"
        [length]="astlenght"
        [export]="true"
        (exporterLigne)="exportPdf_astreinte($event)"
        (deleteElement)="deleteAstr($event)"
        (refreshSaisie)="renouvelerSaisieAstreinte($event)"
        (clicModalModifier)="openAstreinteEdit($event)"
        (updatePagination)="updatePaginationAstreinte($event)"
      ></app-data-table>
    </ng-template>
  </mat-tab>
  <mat-tab label="VUE GLOBALE">
    <ng-template matTabContent>
      <div class="row mt-3 mb-0" style="min-height: 40px">
        <div class="col flex-container align-items-center">
          <form [formGroup]="form_filtre_VG">
            <div style="display: flex; flex-direction: row">
              <div class="div-field" *ngIf="filtreActif_VG" style="width: 400px !important; margin-top: -15px; margin-right: 15px">
                <mat-form-field
                  class="field-filtre-simple field-filtre-select"
                  style="width: 400px !important; margin-bottom: -1.25em"
                  floatLabel="always"
                >
                  <mat-label>Direction</mat-label>
                  <input
                    #autoCompleteVGDirection
                    type="text"
                    placeholder="Filtrer par direction"
                    aria-label="Direction"
                    matInput
                    [matAutocomplete]="autoDirectionVG"
                    formControlName="direction"
                    (input)="directionOrigineFilter($event)"
                  />
                  <button
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    *ngIf="this.form_filtre_VG.get('direction')?.value"
                    (click)="effaceDirectionFiltre_VG()"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-autocomplete
                    ngDefaultControl
                    cdkScrollable
                    #autoDirectionVG="matAutocomplete"
                    (optionSelected)="recherche_VG($event)"
                    [displayWith]="directionOriginedisplayFn"
                    hideSingleSelectionIndicator="true"
                  >
                    <mat-option *ngFor="let item of directionList" [value]="item">
                      {{ item.libelle }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <mat-radio-group
                class="mb-10 mb-20"
                aria-label="Choisir une option"
                [(ngModel)]="isHs"
                style="position: relative"
                (change)="radioChange($event)"
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-radio-button class="custom-radio-button" [value]="true" [ngClass]="{ active: isHs }" [checked]="isHs"
                  ><span style="cursor: pointer">HS</span></mat-radio-button
                >
                <mat-radio-button [value]="false" class="custom-radio-button" [ngClass]="{ active: !isHs }"
                  ><span style="cursor: pointer"> ASTREINTES </span></mat-radio-button
                >
              </mat-radio-group>
            </div>
          </form>
        </div>
      </div>

      <app-data-table
        [style.display]="isHs ? 'block' : 'none'"
        #appdataTableVG
        [displayedColumns]="globalDisplayedColumns"
        [cellStyleCss]="globalCellStyleCss"
        [data]="dataTable_VG"
        [hasView]="true"
        [filter]="false"
        [expendable]="false"
        zoomTooltip="Voir détails agents"
        [subTableDisplayedColumns]="subTableDisplayedColumns"
        (showDetail)="showDetail_VG($event)"
        [hasPagination]="false"
      >
      </app-data-table>
      <app-data-table
        [style.display]="!isHs ? 'block' : 'none'"
        #appdataTableVGAstreinte
        [displayedColumns]="astraintesDisplayedColumns"
        [cellStyleCss]="globalCellStyleCss"
        [data]="dataTable_astrainte"
        [hasView]="true"
        [filter]="false"
        [expendable]="false"
        zoomTooltip="Voir détails agents"
        [subTableDisplayedColumns]="subTableDisplayedColumns"
        (showDetail)="showDetail_Ast($event)"
        [hasPagination]="false"
      >
      </app-data-table>
    </ng-template>
  </mat-tab>
</mat-tab-group>
