import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParametresService {
  module = environment.webService.server + environment.webService.url + environment.webService.api;
  constructor(private http: HttpClient) {}

  // get all the parametres
  getAllParametres() {
    return this.http.get<any>(this.module + '/parametres');
  }
  getParametresWithParams(offset: number, limit: number, filtre: string | null = null) {
    console.log('filtre param', filtre);
    filtre = filtre ?? '';
    let url = this.module + '/parametres';
    if (filtre != ' ' && filtre !== undefined && filtre !== null) {
      url = url + '?' + `filterGenerique=${filtre}`;
    }
    const params = new HttpParams().set('page', offset).set('size', limit);
    return this.http.get<any>(url, { params });
  }
  modifierParametre(data: any, id: number) {
    return this.http.put<any>(this.module + '/parametres/' + id, data);
  }
  getHistorique(id: number) {
    return this.http.get<any>(this.module + '/historique/filter-object-id/' + id + '/filter-entity-name/PARAMETRE');
  }
  importParameterFile(file: any, fileName: string) {
    const fd = new FormData();
    fd.append('file', file, fileName);

    return this.http.post<any>(this.module + '/parametres/import', fd, {});
  }
}
