<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<mat-card-header class="" style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="row col-md-12">
    <div class="col-6" style="padding-left: 27px">
      <h2 class="titre-modale">Modification des paramètres</h2>
    </div>
    <div class="col-md-6 text-end mb-2 mr-0 ml-auto gx-0">
      <button mat-dialog-close class="py-0" mat-icon-button aria-label="Fermer la modale">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="parametreModifForm" class="row">
    <div class="col-md-12 mt-2">
      <mat-form-field class="example-full-width" floatLabel="always" style="width: 70%">
        <mat-label>Valeur </mat-label>
        <input type="text" placeholder="Valeur" matInput formControlName="valeur" required="valeur" />
        <mat-error *ngIf="parametreModifForm.controls['valeur'].hasError('required')">La valeur est obligatoire.</mat-error>
      </mat-form-field>
      <a mat-icon-button class="small" #tooltip="matTooltip" [matTooltip]="this.rowData.description" matTooltipPosition="below">
        <mat-icon color="primary">help</mat-icon>
      </a>
    </div>
    <div class="aide-saisie">
      <mat-label>{{ this.data.data.valeur }} </mat-label>
    </div>

    <div class="col-md-12 mt-2">
      <label for="description" id="description" class="form-label" style="font-size: 12px">Description *</label>
      <mat-form-field style="width: 100%" floatLabel="always" class="textarea-mat">
        <textarea
          matInput
          class="form-control"
          id="description"
          formControlName="description"
          placeholder="Saisissez votre texte"
          required
        ></textarea>
        <mat-error *ngIf="parametreModifForm.controls['description'].hasError('required')">La description est obligatoire.</mat-error>
      </mat-form-field>
    </div>
  </form>
  <mat-dialog-actions align="end">
    <button mat-raised-button style="margin-right: 10px" mat-dialog-close class="bouton-fermer">Annuler</button>
    <button mat-raised-button color="primary" [disabled]="parametreModifForm.invalid" #bouttonValider (click)="modifierParametre()">
      Valider
    </button>
  </mat-dialog-actions>
</mat-dialog-content>
