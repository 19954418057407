import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { ExportService } from 'src/app/services/export.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { delay } from 'rxjs';
import { CommunicationService } from 'src/app/shared/communication.service';
import { DragDropModule } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-archivage-manuel-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatCardModule,
    MatIconModule,
    MatSnackBarModule,
    DragDropModule
  ],
  templateUrl: './archivage-manuel-modal.component.html',
  styleUrls: ['./archivage-manuel-modal.component.scss']
})
export class ArchivageManuelModalComponent implements OnInit {
  nameCamp: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private exportService: ExportService,
    private spinner: NgxSpinnerService,
    private _snackBar: MatSnackBar,
    private notifyService: CommunicationService,
    public dialogRef: MatDialogRef<ArchivageManuelModalComponent>
  ) {}

  ngOnInit() {
    this.nameCamp = this.data.nomCampagne;
    console.log('nameCamp', this.nameCamp);
  }

  validationModale() {
    this.spinner.show();

    /**
     * Appel au service de déclenchement du job d'export
     */
    this.exportService
      .launchJob()
      .pipe(delay(1000))
      .subscribe({
        next: (result) => {
          console.log('fin launch job export');
          this.spinner.hide();
          this._snackBar.open('Archivage terminé avec succès', 'OK');
          this.dialogRef.close();
          this.notifyService.triggerRefresh();
        },
        error: (error) => {
          this.spinner.hide();
          this._snackBar.open("Erreur lors de l'archivage", 'OK');
        }
      });
  }
}
