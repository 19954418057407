import { Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './authentication/login/login.component';
import { AccesInterditComponent } from './authentication/acces-interdit/acces-interdit.component';
import { AuthGuard } from './guard/auth.guard';
import { ConsultationComponent } from './features/consultation/consultation.component';
import { ValidationComponent } from './features/validation/validation.component';
import { ExportDataComponent } from './features/export-data/export-data.component';
import { GestionCampagneComponent } from './features/gestion-campagne/gestion-campagne.component';
import { SectionAdministrationComponent } from './features/section-administration/section-administration.component';
import { HomeComponent } from './features/home-page/home.component';
import { GestionUtilisateursComponent } from './features/section-administration/gestion-utilisateurs/gestion-utilisateurs.component';
import { SuivieImportsComponent } from './features/suivie-imports/suivie-imports.component';
import { ParametresComponent } from './features/section-administration/parametres/parametres.component';
import { EvenementsComponent } from './features/section-administration/evenements/evenements.component';
import { ServicesComponent } from './features/section-administration/services/services.component';
import { DirectionsComponent } from './features/section-administration/directions/directions.component';
import { ArchivesSaisiesComponent } from './features/section-administration/archives-saisies/archives-saisies.component';
import { JoursFeriesComponent } from './features/section-administration/jours-feries/jours-feries.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],

    children: [
      {
        path: '',
        redirectTo: 'accueil',
        pathMatch: 'full'
      },
      {
        path: 'accueil',
        component: HomeComponent
      },
      {
        path: 'saisie-et-consultation',
        component: ConsultationComponent
      },
      {
        path: 'validation-des-saisies',
        component: ValidationComponent
      },
      {
        path: 'export-des-donnees',
        component: ExportDataComponent
      },
      {
        path: 'administration/gestion-des-campagnes',
        component: GestionCampagneComponent
      },
      {
        path: 'administration/gestion-delegations',
        component: GestionUtilisateursComponent
      },
      {
        path: 'administration',
        component: SectionAdministrationComponent
      },
      {
        path: 'administration/suivie-imports',
        component: SuivieImportsComponent
      },
      {
        path: 'administration/parametres_de_configurations',
        component: ParametresComponent
      },
      {
        path: 'administration/evenements',
        component: EvenementsComponent
      },
      {
        path: 'administration/services',
        component: ServicesComponent
      },
      {
        path: 'administration/directions',
        component: DirectionsComponent
      },
      {
        path: 'administration/archives-saisies',
        component: ArchivesSaisiesComponent
      },
      {
        path: 'administration/jours-feries',
        component: JoursFeriesComponent
      }
    ]
  },
  {
    path: 'acces-interdit',
    component: AccesInterditComponent
  },
  {
    path: '',
    component: LoginComponent
  }
];
