<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<mat-card-header class="" style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="row col-md-12">
    <div class="col-6">
      <div class="titre-modale">Suppression</div>
    </div>

    <div class="col-md-6 text-end mb-2 mr-0 ml-auto gx-0">
      <button (click)="close()" class="py-0" mat-icon-button aria-label="Boutton supression">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>
<mat-dialog-content class="mat-typography pt-0">
  <mat-divider></mat-divider>
  <div class="divContainer">
    {{ data.description }}
  </div>
  <mat-divider></mat-divider>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Annuler</button>
  <button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial (click)="delete()">Valider</button>
</mat-dialog-actions>
