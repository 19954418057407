import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatButtonModule, MatIconModule],
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() item = {
    icon: '',
    title: '',
    body: '',
    link: ''
  };

  @Input() toggle: boolean = false;
  @Output() onClickEmit = new EventEmitter();

  onClick() {
    this.toggle = !this.toggle;
    this.onClickEmit.emit(this.toggle);
  }
}
