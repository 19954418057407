import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'dd/MM/yyyy'
  },
  display: {
    dateInput: 'dd/MM/yyyy',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM yyyy'
  }
};
@Injectable({
  providedIn: 'root'
})
export class MaterialDateAdapter extends NativeDateAdapter {
  override format(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  override getFirstDayOfWeek(): number {
    return 1;
  }
}
