import { Component, OnInit, Inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { DataTableComponent } from 'src/app/shared/data-table/data-table.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { Alarmes } from 'src/app/interfaces/alarmes';

import { EditionAlarmModalComponent } from './edition-alarm-modal/edition-alarm-modal.component';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { ArianeNavComponent } from 'src/app/layout/ariane-nav/ariane-nav.component';
import { DeleteModalComponent } from 'src/app/shared/delete-modal/delete-modal.component';
import localeFr from '@angular/common/locales/fr';
import { RoleService } from 'src/app/services/role.service';
import { RestrictedPageComponent } from 'src/app/shared/restricted-page/restricted-page.component';
registerLocaleData(localeFr);

@Component({
  selector: 'app-alarm-config-modal',
  standalone: true,
  imports: [
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    CommonModule,
    DataTableComponent,
    MatExpansionModule,
    MatSelectModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerModule,
    MatDividerModule,
    ArianeNavComponent,
    RestrictedPageComponent,
    MatCardModule,
    EditionAlarmModalComponent
  ],
  templateUrl: './alarm-config-modal.component.html',
  styleUrls: ['./alarm-config-modal.component.scss']
})
export class AlarmConfigModalComponent implements OnInit {
  panelOpenState: boolean = false;
  pipe = new DatePipe('fr-FR'); // Use your own locale
  date_deb = this.pipe.transform(new Date(), 'short');
  date_fin = this.pipe.transform(new Date(), 'short');
  rowCampagne: any = {};

  togglePanel() {
    console.log('clicked');
    this.panelOpenState = !this.panelOpenState;
  }

  userRoles: string[] = [];

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private roleService: RoleService
  ) {}

  ngOnInit() {
    this.roleService.getRole().subscribe((roles) => {
      this.userRoles = roles;
      console.log('Role', roles);
    });
  }

  // recherche la ligne data correspondant à l'id
  searchDataID(data: any, id: number) {
    let index = Object.keys(data).find((k) => data[k].id === id);

    if (index != null) {
      this.rowCampagne = this.mock[Number(index)];
    }
  }

  displayedColumns: string[] = ["Nom de l'alarme", 'Description', 'Date de début', 'Date de fin', 'Actions'];

  cellStyleCss: any = {};

  mock: Alarmes[] = [
    {
      id: 1,
      "Nom de l'alarme": 'Alarme 1',
      Description: 'Description 1',
      'Date de début': this.date_deb,
      'Date de fin': this.date_deb
    },
    {
      id: 2,
      "Nom de l'alarme": 'Alarme 2',
      Description: 'Description 2',
      'Date de début': this.date_deb,
      'Date de fin': this.date_deb
    },
    {
      id: 3,
      "Nom de l'alarme": 'Alarme 3',
      Description: 'Description 3',
      'Date de début': this.date_deb,
      'Date de fin': this.date_deb
    },
    {
      id: 4,
      "Nom de l'alarme": 'Alarme 4',
      Description: 'Description 4',
      'Date de début': this.date_deb,
      'Date de fin': this.date_deb
    },
    {
      id: 5,
      "Nom de l'alarme": 'Alarme 5',
      Description: 'Description 5',
      'Date de début': this.date_deb,
      'Date de fin': this.date_deb
    }
  ];

  openEditionDialog() {
    this.dialog.open(EditionAlarmModalComponent, {
      width: '780px',
      hasBackdrop: true,
      data: {}
    });
  }

  deleteAgent(event: any) {
    this.dialog.open(DeleteModalComponent, {
      width: '780px',
      data: {
        id: event,
        description: 'Voulez-vous supprimer cet agent ?',
        url: 'test'
      }
    });
  }
}
