import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SnackAlertService {
  displayed: boolean = false;

  public Isdisplayed(): boolean {
    if (!this.displayed) {
      this.displayed = true;
      return true;
    }
    return false;
  }
}
