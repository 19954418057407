import { NativeDateAdapter } from '@angular/material/core';

/**
 * Pour démarrer la semaine le Lundi sur les date pickers
 */
export class CustomDateAdapter extends NativeDateAdapter {
    override getFirstDayOfWeek(): number {
        return 1;
    }
}
