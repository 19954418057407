import {
  AfterViewInit,
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { MatPaginator, MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { CommonModule, KeyValue } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule, MatSort } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ViewDetailModalComponent } from '../../features/consultation/view-detail-modal/view-detail-modal.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { Pagination } from 'src/app/core/services/pagination.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RoleService } from 'src/app/services/role.service';

import { SelectionService } from './data-selection.service';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Broadcast } from 'src/app/core/services/broadcast.service';

@Component({
  selector: 'app-data-table',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatTableModule,
    MatInputModule,
    MatSortModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatCheckboxModule,
    FormsModule,
    MatTooltipModule
  ],
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useClass: Pagination }],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('350ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class DataTableComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() displayedColumns: string[] = [];
  @Input() data: any[] = [];
  @Input() subTableData: any[] = [];
  @Input() subTableDisplayedColumns: string[] = [];
  @Input() limit: number = environment.pagination.limit;
  @Input() offset: number = environment.pagination.offset;
  //@Input() api: string = '';
  @Input() actions: string[] = [];
  @Input() expendable: boolean = false;
  @Input() hasView: boolean = false;
  @Input() hasViewDisabled: boolean = false;

  /**
   * Si hasPagination = false alors pas de paginateur
   */
  @Input() hasPagination: boolean = true;
  @Input() zoomTooltip: string = 'Voir détails';
  @Input() zoomTooltipDisabled: string = 'à venir';
  @Input() editable: boolean = false;
  @Input() addable: boolean = false;
  @Input() editRole: string[] = [];
  @Input() delatable: boolean = false;
  @Input() refreshable: boolean = false;
  @Input() historical: boolean = false;
  @Input() validable: boolean = false;
  @Input() refusable: boolean = false;
  @Input() selectable: boolean = false;
  @Input() toggle: boolean = false;
  @Input() subTableSelectable: boolean = false;
  @Input() disabledEdit: boolean = false;
  @Input() disabledAdd: boolean = false;
  @Input() filter: boolean = true;
  @Input() zoom: boolean = false;
  @Input() export: boolean = false;
  @Input() creerPDF: boolean = false;
  @Input() exportTooltip: boolean = false;
  @Input() cellStyleCss: any = {};
  @Input() cellStyleCssSub: any = {};
  @Input() headerStyleCss: any = {};
  @Input() headerStyleCssSub: any = {};
  @Input() selectionKey: string = 'liste';
  @Input() length: number;
  @Input() customFilter: boolean;

  @Output() onClickEmit = new EventEmitter();
  @Output() onClickEmitRefSingle = new EventEmitter();
  @Output() onClickEmitSingle = new EventEmitter();
  @Output() onClickEmitRef = new EventEmitter();
  @Output() clicModalEdit = new EventEmitter();
  @Output() clicModalModifier = new EventEmitter();

  @Output() deleteElement = new EventEmitter();
  @Output() refreshSaisie = new EventEmitter();
  @Output() fetchData = new EventEmitter();

  @Output() showDetail = new EventEmitter();
  @Output() showDetailSub = new EventEmitter();
  @Output() updatePagination = new EventEmitter();
  @Output() partialSelection = new EventEmitter();
  @Output() updateFiltre = new EventEmitter();
  @Output() exporterLigne = new EventEmitter();
  @Output() creerPDFLigne = new EventEmitter();
  @Input() formatter: (data: any) => any;

  dataSource!: MatTableDataSource<any>;
  subTableDataSource!: MatTableDataSource<any>;
  @ViewChild('empTbSort') empTbSort = new MatSort();
  @ViewChild(MatPaginator, { read: true }) paginator: MatPaginator;

  columnsToDisplay = this.displayedColumns;
  //expandedElement!: [] | null;
  allSelected: boolean = false;
  subTableAllSelected: boolean = false;
  //ajouter selection
  selection = new SelectionModel<any>(true, []);
  selectionST = new SelectionModel<any>(true, []);
  allselectedActif: boolean = false;
  userRoles: string[] = [];
  IDaValider: any[] = [];
  filterValue: string;
  _selection!: Observable<any[]>;
  showAllSubTable: boolean = false;
  private subscription: Subscription;

  constructor(
    public dialog: MatDialog,
    private roleService: RoleService,
    private selectionService: SelectionService,
    private cdr: ChangeDetectorRef,
    private broadcast: Broadcast
  ) {
    this.formatter = () => {};
    this.subscription = this.broadcast.event$.subscribe((data) => {
      if (data.broadcastedevent === 'resetPagination') {
        this.onPageChange({}, true);
      }
    });
  }

  ngOnInit() {
    this.roleService.getRole().subscribe((roles) => {
      this.userRoles = roles;
    });
    this.dataSource = new MatTableDataSource(this.data);
    this.subTableDataSource = new MatTableDataSource(this.subTableData);
    this.selectionService.resetSelection(this.selectionKey);
    this.allselectedActif = false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.selectionService.resetSelection(this.selectionKey);
    this.allselectedActif = false;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.empTbSort;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] != undefined && changes['data'] != null) {
      this.dataSource = new MatTableDataSource(changes['data'].currentValue);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.empTbSort;
      this.data = changes['data'].currentValue;
      this.selection.clear();
      this.selectionService.resetSelection(this.selectionKey);
      this.allselectedActif = false;
    }
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.onPageChange({}, true);
    if (this.filterValue && this.filterValue.length >= 0 && this.filterValue !== '') {
      this.updateFiltre.emit({ filterValue: this.filterValue, limit: environment.pagination.limit, offset: environment.pagination.offset });
    } else {
      this.updateFiltre.emit({ filterValue: this.filterValue, limit: environment.pagination.limit, offset: environment.pagination.offset });
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  applyLocalFilter(value = '') {
    this.dataSource.filter = value.trim().toLowerCase();
  }

  isObjectType(value: any): boolean {
    return typeof value === 'object' && value !== null && !(value instanceof Date);
  }

  viewDetails(id: number) {
    this.dialog.open(ViewDetailModalComponent, {
      data: {}
    });
  }

  edit(id: number) {
    this.clicModalModifier.emit(id);
  }
  add(id: number) {
    this.clicModalEdit.emit(id);
  }

  delete(id: number) {
    this.deleteElement.emit(id);
  }
  renouveler(id: number) {
    this.refreshSaisie.emit(id);
  }
  fetchHistoricalData(id: number) {
    this.fetchData.emit(id);
  }

  showTableDetail(id: number) {
    this.showDetail.emit(id);
  }

  showTableDetailSub(id: number) {
    this.showDetailSub.emit(id);
  }

  onClickValider(element: any) {
    this.toggle = !this.toggle; //!=Not
    let _data: any[] = [];
    let _dataAst: any[] = [];
    //@ts-ignore
    _data = this.selectionService.getData.listeAvalider;
    //@ts-ignore

    _dataAst = this.selectionService.getData.listeAstAvalider;
    if ((_data && _data.length > 0) || (_dataAst && _dataAst.length > 0)) {
      this.onClickEmit.emit(this.selectionService.getData);
    } else {
      this.onClickEmit.emit(element);
    }
  }
  validateSingleItem(element: any) {
    this.onClickEmitSingle.emit(element);
  }
  refuseSingleItem(element: any) {
    this.onClickEmitRefSingle.emit(element);
  }

  onClickRefuser(element: any) {
    this.toggle = !this.toggle; //!=Not
    let _data: any[] = [];
    let _dataAst: any[] = [];
    //@ts-ignore
    _data = this.selectionService.getData.listeAvalider;
    //@ts-ignore

    _dataAst = this.selectionService.getData.listeAstAvalider;
    if ((_data && _data.length > 0) || (_dataAst && _dataAst.length > 0)) {
      this.onClickEmitRef.emit(this.selectionService.getData);
    } else {
      this.onClickEmitRef.emit(element);
    }
  }

  // id simple ou tableau d'id
  onClickExporter(id?: number, exportData?: any) {
    if (exportData == undefined || exportData == null) {
      this.exporterLigne.emit(id);
    } else {
      this.exporterLigne.emit(exportData);
    }
  }

  onClickCreerPDF(data: any) {
    this.creerPDFLigne.emit(data);
  }

  updateCheck(event: any, item: any, element?: any) {
    this.selectionService.changeSelection(event, item, this.selectionKey);
    this.partialSelection.emit(this.selectionService.getData);

    this.allselectedActif = true;
  }

  updateCheckAll() {
    // tout sélectionner
    if (this.isAllSelected('s1')) {
      this.allselectedActif = true;
      this.selectionService.cocheToutSelection(this.data, this.selectionKey);
    }
    // tout effacer
    else {
      this.allselectedActif = false;
      this.selectionService.resetSelection(this.selectionKey);
    }
  }

  selectAll() {
    this.allSelected = !this.allSelected;
    this.data.forEach((t) => (t.checked = this.allSelected));
  }

  selectAllSubTable() {
    this.subTableAllSelected = !this.subTableAllSelected;
    this.subTableData.forEach((t) => (t.checked = this.subTableAllSelected));
  }

  originalOrder = (a: KeyValue<any, any>, b: KeyValue<any, any>): number => {
    return 0;
  };

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(serie: string) {
    let numSelected;
    let numRows;

    if (serie == 's1') {
      numSelected = this.selection.selected.length;
      numRows = this.dataSource.data.length;
    }

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected('s1')) {
      this.selection.clear();
      this.selectionService.resetSelection(this.selectionKey);
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any, serie = 's1'): string {
    if (!row) {
      return `${this.isAllSelected('s1') ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  // vérifie toutes les lignes de subtable si au moins une ligne peut-être validée
  verifSubtableDisabled(subTable: any) {
    let valider_disabled = true;

    if (subTable != undefined && subTable.length > 0) {
      subTable.every((_ligne: any) => {
        if (_ligne?.valider_hidden === false) {
          valider_disabled = false;
          return false;
        }
        return true;
      });
    }
    return valider_disabled;
  }

  onPageChange(event: any, gotoFirst = false) {
    this.allselectedActif = false;
    if (gotoFirst) {
      this.limit = environment.pagination.limit;
      this.offset = environment.pagination.offset;
    } else {
      const lastPageIndex = Math.ceil(this.length / event.pageSize) - 1;
      if (event.pageSize !== this.limit) {
        this.limit = event.pageSize;
        this.offset = 1;
      }
      if (event.previousPageIndex < event.pageIndex) {
        this.offset++;
      } else {
        this.offset--;
      }
      if (event.pageIndex == 0) {
        this.offset = 0;
      } else if (event.pageIndex == lastPageIndex) {
        this.offset = lastPageIndex;
      }
      this.updatePagination.emit({ filterValue: this.filterValue, limit: this.limit, offset: this.offset });
    }
  }
  toggleSubTable() {
    this.showAllSubTable = !this.showAllSubTable;
    if (this.showAllSubTable) {
      this.dataSource.data.forEach((element: any) => {
        element.isExpanded = true;
      });
    } else {
      this.dataSource.data.forEach((element: any) => {
        element.isExpanded = false;
      });
    }
  }
  toggleExpandedElement(element: any, event: Event): void {
    // Iterate over all elements
    if (!this.showAllSubTable) {
      this.dataSource.data.forEach((item: any) => {
        // Check if the current item is not the same as the element passed as a parameter
        if (item !== element) {
          // Set isExpanded to false for all other elements
          item.isExpanded = false;
        }
      });
    }
    element.isExpanded = !element.isExpanded;

    // Stop event propagation
    event.stopPropagation();
  }
}
