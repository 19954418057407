<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<mat-card-header class="" style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="row col-md-12">
    <div class="col-10">
      <div class="titre-modale">Historique des modifications</div>
    </div>

    <div class="col-md-2 text-end mb-0 mr-0 ml-auto gx-0">
      <button class="py-0" mat-icon-button mat-dialog-close aria-label="Fermer la modale">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>
<mat-dialog-content class="mat-typography">
  <div class="history-modal">
    <div *ngIf="dataJSON && dataJSON.length > 0; else noHistory">
      <div
        class="history-item ng-star-inserted"
        style="margin-bottom: 15px; padding: 10px; background-color: #f2f2f2; border-radius: 5px"
        *ngFor="let item of dataJSON"
      >
        <div style="display: flex; margin-bottom: 5px; color: black; font-size: 14px">
          <div style="flex: 1; color: #000000de; font-size: 14px">
            <span style="font-weight: 500"> Par : </span>
            <span> {{ item.agentNomPrenom }}</span>
          </div>
          <div class="history-item-date">le {{ item.date | date: 'dd/MM/yyyy à HH:mm' }}</div>
        </div>
        <div style="color: #000000de; font-size: 14px">
          <span style="font-weight: 500">Commentaire : </span>
          <span>{{ item.description }}</span>
        </div>
      </div>
    </div>
    <ng-template #noHistory>
      <div style="text-align: center; padding: 20px; color: black">Il n'y a pas d'historique de modification pour ce paramètre.</div>
    </ng-template>
  </div>
</mat-dialog-content>
<mat-divider style="border-top-width: 1px" class="mt-4 mb-1"></mat-divider>
<mat-dialog-actions style="background-color: rgba(250, 250, 252, 1)" align="end">
  <button mat-dialog-close mat-flat-button color="primary">Fermer</button>
</mat-dialog-actions>
