// excel.service.ts
import { Injectable } from '@angular/core';
import { Papa as PaParse } from 'ngx-papaparse';

@Injectable({
  providedIn: 'root'
})
export class CsvService {
  constructor(private paParse: PaParse) {}
  exportToCsv(data: any[], fileName: string, headers: any = null): void {
    console.log(data);
    let options = {
      delimiter: ';',
      quotes: true,
      encoding: 'UTF-8',
      escapeFormulae: true
    };
    let ContenuCSV;

    if (data.length === 0) {
      ContenuCSV = this.paParse.unparse(headers, options);
    } else {
      ContenuCSV = this.paParse.unparse(data, options);
    }
    const a = document.createElement('a');
    a.href = 'data:text/csv;charset=utf-8,' + ContenuCSV;
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
