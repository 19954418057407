import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DirectionService {
  module = environment.webService.server + environment.webService.url + environment.webService.api;
  constructor(private http: HttpClient) {}

  // get directions
  getAllDirections() {
    return this.http.get<any>(this.module + '/directions/all');
  }

  // get mes directions (pour les stats)
  getMesDirections() {
    return this.http.get<any>(this.module + '/directions/mes');
  }

  getDirectionsWithParams(offset: number, limit: number, filtre: string | null = null) {
    filtre = filtre ?? '';
    let url = this.module + '/directions';
    if (filtre != '' && filtre !== undefined && filtre !== null) {
      url = url + '?' + `filterGenerique=${filtre}`;
    }
    const params = new HttpParams().set('page', offset).set('size', limit);
    return this.http.get<any>(url, { params });
  }

  // get services
  getAllServices() {
    return this.http.get<any>(this.module + '/services-fonctionnels/all');
  }

  getServicesWithParams(offset: number, limit: number, filtre: string | null = null) {
    filtre = filtre ?? '';
    let url = this.module + '/services-fonctionnels';
    if (filtre != '' && filtre !== undefined && filtre !== null) {
      url = url + '?' + `filterGenerique=${filtre}`;
    }
    const params = new HttpParams().set('page', offset).set('size', limit);
    return this.http.get<any>(url, { params });
  }

  // Modification direction
  modifierDirection(data: any, id: number) {
    return this.http.put<any>(this.module + '/directions/' + id, data);
  }
}
