import { LuxonDateAdapter } from '@angular/material-luxon-adapter';

/**
 * Pour démarrer la semaine le Lundi sur les date pickers
 */
export class CustomLuxonDateAdapter extends LuxonDateAdapter {
  override getFirstDayOfWeek(): number {
    return 1;
  }
}
