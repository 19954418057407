import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private userRolesSubject = new BehaviorSubject<string[]>([]);
  constructor(private keycloak: KeycloakService) {}
  getRole(): Observable<string[]> {
    if (!this.userRolesSubject.getValue().length) {
      const userRoles = this.keycloak.getUserRoles();
      this.updateUserRole(userRoles);
    }
    return this.userRolesSubject.asObservable();
  }
  updateUserRole(roles: string[]) {
    this.userRolesSubject.next(roles);
  }
}
