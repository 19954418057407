import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CampagnesService {
  module = environment.webService.server + environment.webService.url + environment.webService.api;
  constructor(private http: HttpClient) {}

  // liste toutes les campagnes
  getAllCampagnes() {
    const params = new HttpParams().set('page', 0).set('size', 500);
    return this.http.get<any>(this.module + '/campagnes', { params });
  }

  getCampagnesWithParams(offset: number, limit: number, filtre: string | null = null) {
    filtre = filtre ?? '';
    let url = this.module + '/campagnes';
    if (filtre != '' && filtre !== undefined && filtre !== null) {
      url = url + '?' + `filterGenerique=${filtre}`;
    }
    const params = new HttpParams().set('page', offset).set('size', limit);
    return this.http.get<any>(url, { params });
  }

  getCampagneActuelleSync() {
    return this.http.get<any>(this.module + '/campagnes/en-cours');
  }

  getCampagneProchaine() {
    return this.http.get<any>(this.module + '/campagnes/prochaine');
  }

  // validation HS
  ajouterCampagne(data: any) {
    return this.http.post<any>(this.module + '/campagnes', data);
  }

  // Modification campagne
  modifierCampagne(data: any, id: number) {
    return this.http.put<any>(this.module + '/campagnes/' + id, data);
  }

  // Suppression campagne
  supprimerCampagne(id: number) {
    return this.http.delete<any>(this.module + '/campagnes/' + id);
  }
}
