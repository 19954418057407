<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<h2 class="mb-0" mat-dialog-title style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{ data.title }}</h2>
<button mat-dialog-close class="btnClose" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
  <mat-icon>close</mat-icon>
</button>

<mat-dialog-content class="mat-typography pt-0" style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div style="margin-top: 5%">
    <mat-divider></mat-divider>
    <div class="divContainer">
      <div class="div-content">
    <div class="div-icon" style="padding-right: 15px; padding-top: 8px;">
      <mat-icon style="color: #326fc9; transform: scale(2); margin-right: 15px">help</mat-icon>
    </div>
    <div class="div-texte">
      <div *ngIf="data.content1">
        {{ data.content1 }}
      </div>
      <div *ngIf="data.content2">
        {{ data.content2 }}
      </div>
    </div>
      </div>
    </div>
    <mat-divider></mat-divider>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-stroked-button color="primary" [mat-dialog-close]="false" style="margin-right: 15px; width: 80px;">non</button>
  <button mat-flat-button color="primary" [mat-dialog-close]="true" style="width: 80px;" cdkFocusInitial>Oui</button>
</mat-dialog-actions>
