import { Component, Inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatDialogModule, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { delay, take } from 'rxjs';
import { AgentsService } from 'src/app/services/agents.service';
import { RoleService } from 'src/app/services/role.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ValidationModalComponent } from 'src/app/shared/validation-modal/validation-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomDateAdapter } from 'src/app/shared/utils/custom-date-adapter';
import { DeleguesService } from 'src/app/services/delegues.service';
import { AlerteModalComponent } from 'src/app/shared/alerte-modal/alerte-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-agent-del-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatRadioModule,
    MatIconModule,
    MatCardModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    DragDropModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: DateAdapter, useClass: CustomDateAdapter }
  ],
  templateUrl: './agent-del-modal.component.html',
  styleUrls: ['./agent-del-modal.component.scss']
})
export class AgentDelModalComponent {
  rowData: any = {};
  dateP = new DatePipe('fr-FR');
  agentsList: any[] = [];
  listOfAgent: any = [];
  delegationList: any = [];
  userRoles: string[] = [];
  val_del_agent: any = null;
  datesDisabled: string[] = [];
  viewMode: any;
  delegationAgent: any = [];
  matricule: string;
  userId: any;

  userName: string;
  minDateFin: Date;
  delegue: any = [];

  modifier: boolean;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dateAdapter: DateAdapter<any>,
    private agentsService: AgentsService,
    private delegueService: DeleguesService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AgentDelModalComponent>,
    private roleService: RoleService,
    private spinner: NgxSpinnerService
  ) {}

  form: FormGroup = this.formBuilder.group({
    agent: new FormControl('', [Validators.required, this.agentMatricule(this.data.nameUser, this.data.agentId)]),
    date_debut: new FormControl('', [Validators.required, this.dateLessThan('date_debut', 'date_fin')]),
    date_fin: new FormControl('', [Validators.required, this.dateLessThan('date_debut', 'date_fin')])
  });

  dateLessThan(start: string, end: string) {
    return (group: AbstractControl): { [key: string]: any } => {
      if (!this.form) {
        return {};
      }
      let f = this.form.controls[start];
      let t = this.form.controls[end];
      if (f && t && f.value && t.value && f.value > t.value) {
        return {
          dates: 'Date de début doit être inférieure ou égale à date de fin'
        };
      }
      return {};
    };
  }
  ngOnInit() {
    this.roleService.getRole().subscribe((role) => {
      this.userRoles = role;
    });
    this.form.controls['date_fin'].valueChanges.subscribe((value) => {
      console.log('testtt', value);

      this.form.controls['date_debut'].updateValueAndValidity({ onlySelf: false, emitEvent: false });
    });
    this.form.controls['date_debut'].valueChanges.subscribe((value) => {
      this.form.controls['date_fin'].updateValueAndValidity({ onlySelf: false, emitEvent: false });
    });
    if (this.data.modif === true) {
      this.delegue = this.data.delegue;
      this.initFormModif();
    } else {
      this.loadAgents();
      this.datesDisabled = this.data.Dates;
      this.delegationAgent = this.data.DelegationAgent;
      this.matricule = this.data.agentMatricule;
      this.viewMode = this.data.view;

      console.log('matricule comes from delegation cp', this.matricule);
    }
  }

  initFormModif() {
    console.log('initFormModif rowData:', this.delegue);

    const agentName = this.delegue?.remplacant;
    console.log('value agent', agentName);
    this.form.controls['agent'].setValue(agentName);
    this.form.controls['date_debut'].setValue(new Date(this.delegue?.dateDebut));
    console.log('dat debut', this.delegue?.dateDebut);

    this.form.controls['date_fin'].setValue(new Date(this.delegue?.dateFin));
    this.form.get('agent')?.disable();
  }
  verifAcces() {
    throw new Error('Method not implemented.');
  }

  effaceFiltreDelegue(event: any, controlName: string) {
    event.stopPropagation();

    if (controlName != undefined && controlName != '') {
      this.form.controls[controlName].setValue('');
    }
  }

  FilterDebutDate = (date: Date | null): boolean => {
    if (!date) {
      return false;
    }
    const day = this.testFormatAdvanced(date);

    return !this.datesDisabled?.includes(day); // && day !== 6;
  };

  formatDate(date: Date): string {
    const day = date.getDate() + 1;
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
  }

  testFormatAdvanced(date: Date): string {
    const formatDays = this.dateP.transform(date, 'dd/MM/YYYY');
    return formatDays ?? '';
  }

  agentDelegueFilter(event: any): void {
    const value = event.target.value;
    if (typeof value !== 'string' || !value) {
      this.form.get('agent')?.setErrors({ required: true });
      this.agentsList = [];
      return;
    }

    const filterValue = value.toLowerCase().split(' ').join('');
    this.agentsList = this.listOfAgent.filter((agent: any) =>
      (agent.nom + agent.prenom).toLowerCase().split(' ').join('').includes(filterValue)
    );
  }
  agentDeleguedisplayFn(agent: any): string {
    console.log('dispalyed', agent);
    return agent ? `${agent.nom} ${agent.prenom}` : '';
  }

  loadAgents() {
    let agentId = this.data.agentId;

    this.agentsService
      .getDelegueAgents(agentId)
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          this.agentsList = data /*.results*/;
          this.listOfAgent = [...this.agentsList];
        } else {
          console.log('error');
        }
      });
  }
  agentMatriculeExist(): boolean {
    const selectedAgent = this.form.get('agent')?.value;
    return selectedAgent && selectedAgent.matricule === this.matricule;
  }

  agentMatricule(username: string, id: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const selectedAgent = control.value;
      if (selectedAgent?.id === id || selectedAgent?.login?.toUpperCase() === username?.toUpperCase()) {
        return { agentExist: true };
      }
      return null;
    };
  }

  agentSelected(event: any): void {
    this.form.get('agent')?.updateValueAndValidity();
  }
  saveDelegue() {
    let role = this.roleService.getRole();
    console.log('roleee', role);

    const formdata = this.form.value;
    console.log('bind', formdata);

    const DebutDate = new Date(formdata.date_debut);

    DebutDate.setDate(DebutDate.getDate() + 1);

    const FinDate = new Date(formdata.date_fin);

    FinDate.setDate(FinDate.getDate() + 1);
    const data = {
      //  dateDebut: formdata.date_debut,
      dateDebut: DebutDate,
      //dateFin: formdata.date_fin,
      dateFin: FinDate,
      remplacant: {
        id: formdata.agent?.id

        // id: this.form.get('agent')?.value.id
      },
      remplace: {
        id: this.data.remplaceId
      }
    };
    console.log('data', data);

    if (this.data.view !== 'user' && this.data.modif !== true) {
      console.log('api 1 ajout delegue ');

      this.delegueService
        .AjoutDelegue(data)
        .pipe(take(1))
        .subscribe((results) => {
          if (results) {
            console.log('result ok: ', results);

            this.dialog
              .open(ValidationModalComponent, {
                width: '600px',
                data: {
                  title: 'Confirmation',
                  content: 'La délégation a été créée avec succès.'
                }
              })
              .afterClosed()
              .subscribe((action) => {
                if (action) {
                  this.dialogRef.close();
                }
              });
          }
        });
    } else if (this.data.view === 'user' && this.data.modif !== true) {
      console.log('api 2 ajout monDelegue ');

      this.delegueService
        .AjoutMonDelegue(data)
        .pipe(take(1))
        .subscribe((results) => {
          if (results) {
            this.dialog
              .open(ValidationModalComponent, {
                width: '600px',
                data: {
                  title: 'Confirmation',
                  content: 'La délégation a été créée avec succès.'
                }
              })
              .afterClosed()
              .subscribe((action) => {
                if (action) {
                  this.dialogRef.close();
                }
              });
          }
        });
    } else if (this.data.modif === true && this.data.view !== 'user') {
      console.log('api 3 edit delegue ');
      let _data: any = {};
      let delegationId: any;
      _data = this.delegue;
      console.log('_dataaaaaa', _data);
      delegationId = this.delegue.id;
      console.log('idddd delegation', delegationId);
      _data.agent = this.form.get('agent')?.value;
      const formdata = this.form.value;
      console.log('bind', formdata);

      const DebutDate = new Date(formdata.date_debut);

      DebutDate.setDate(DebutDate.getDate() + 1);

      const FinDate = new Date(formdata.date_fin);

      FinDate.setDate(FinDate.getDate() + 1);
      const deleguedata = {
        //  dateDebut: formdata.date_debut,
        dateDebut: DebutDate,
        //dateFin: formdata.date_fin,
        dateFin: FinDate,
        id: delegationId,
        remplacant: {
          id: this.delegue.remplacant.id

          // id: this.form.get('agent')?.value.id
        },
        remplace: {
          id: this.data.remplaceId
        }
      };
      console.log('data', data);
      this.delegueService
        .modifierDelegue(deleguedata, delegationId)
        .pipe(delay(1000))
        .subscribe({
          next: (result) => {
            console.log(result);
            this.dialogRef.close();
            this.dialog.open(ValidationModalComponent, {
              width: '600px',
              data: {
                title: 'Confirmation',
                content: 'La délégtaion a été modifiée avec succès.'
              }
            });
          },
          error: (error) => {
            this.spinner.hide();
            this.dialog.open(AlerteModalComponent, {
              width: '640px',
              data: {
                title: 'Erreur',
                content: error.error.replace('Validation error:', '')
              }
            });
          }
        });
    } else {
      console.log('api 4 edit monDelegue');
      let _data: any = {};
      let delegationId: any;
      _data = this.delegue;
      console.log('_dataaaaaa', _data);
      delegationId = this.delegue.id;
      console.log('idddd delegation', delegationId);
      _data.agent = this.form.get('agent')?.value;
      const formdata = this.form.value;
      console.log('bind', formdata);

      const DebutDate = new Date(formdata.date_debut);

      DebutDate.setDate(DebutDate.getDate() + 1);

      const FinDate = new Date(formdata.date_fin);

      FinDate.setDate(FinDate.getDate() + 1);
      const deleguedata = {
        //  dateDebut: formdata.date_debut,
        dateDebut: DebutDate,
        //dateFin: formdata.date_fin,
        dateFin: FinDate,
        id: delegationId,
        remplacant: {
          id: this.delegue.remplacant.id

          // id: this.form.get('agent')?.value.id
        },
        remplace: {
          id: this.data.remplaceId
        }
      };
      console.log('data', data);
      this.delegueService
        .modifierMonDelegue(deleguedata, delegationId)
        .pipe(delay(1000))
        .subscribe({
          next: (result) => {
            console.log(result);
            this.dialogRef.close();
            this.dialog.open(ValidationModalComponent, {
              width: '600px',
              data: {
                title: 'Confirmation',
                content: 'La délégtaion a été modifiée avec succès.'
              }
            });
          },
          error: (error) => {
            this.spinner.hide();
            this.dialog.open(AlerteModalComponent, {
              width: '640px',
              data: {
                title: 'Erreur',
                content: error.error.replace('Validation error:', '')
              }
            });
          }
        });
    }
  }
}
