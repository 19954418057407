import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-confirm-validation-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatDividerModule,
    MatListModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    DragDropModule
  ],
  templateUrl: './confirm-validation-modal.component.html',
  styleUrls: ['./confirm-validation-modal.component.scss']
})
export class ConfirmValidationModalComponent {
  refuse: boolean = false;
  type: string;
  action: string = 'valider';
  motif_val: string = '';
  bt_refuser_disabled: boolean = true;
  title: string = '';
  question: string = '';
  texte_bouton: string = 'oui';
  data: any[] = [];
  nombreDeJour: number;
  motif = new FormControl('');
  anyHasRefused = true;
  motifPostion = 'top';
  @Output() onClickEmit = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dataDiag: any
  ) {}

  ngOnInit() {
    this.type = this.dataDiag.type;
    let action = this.dataDiag.action;
    this.motifPostion = action.includes('masse') ? 'top' : 'bottom';

    if (this.dataDiag.type == 'hs') {
      if (action == 'refuser') {
        this.refuse = true;
        this.title = 'Refus des heures supplémentaires';
        this.question = 'Voulez-vous vraiment refuser la saisie HS ?';
      } else if (action == 'valider' || action == 'repasser_valide') {
        this.title = 'Validation des heures supplémentaires';
        this.question = 'Voulez-vous vraiment valider la saisie HS ?';
        this.texte_bouton = 'Valider';
      } else if (action == 'valider en masse' || action == 'valider en masse with motif') {
        this.title = 'Valider en masse';
        this.question = 'Voulez-vous vraiment valider en masse les HS ?';
        this.texte_bouton = 'Valider';
      } else if (action == 'refuser en masse') {
        this.refuse = true;
        this.title = 'Refuser en masse';
        this.question = 'Voulez-vous vraiment refuser en masse les HS ?';
      }
    } else if (this.dataDiag.type == 'astrainte') {
      if (action == 'refuser') {
        this.refuse = true;
        this.title = 'Refus des astreintes';
        this.question = 'Voulez-vous vraiment refuser la saisie astreinte ?';
      } else if (action == 'valider' || action == 'repasser_valide') {
        this.title = 'Validation des astreintes';
        this.question = 'Voulez-vous vraiment valider la saisie astreinte ?';
        this.texte_bouton = 'Valider';
      } else if (action == 'valider en masse' || action == 'valider en masse with motif') {
        this.title = 'Valider en masse';
        this.question = 'Voulez-vous vraiment valider en masse les astreintes ?';
        this.texte_bouton = 'Valider';
      } else if (action == 'refuser en masse') {
        this.refuse = true;
        this.title = 'Refuser en masse';
        this.question = 'Voulez-vous vraiment refuser en masse les astreintes ?';
      }
    }

    if (!Array.isArray(this.dataDiag.data)) {
      // validation sur une ligne
      this.data.push(this.dataDiag.data);
    }
    // validation de masse
    else {
      this.data = this.dataDiag.data;
    }
    // heures supplémentaires
    if (this.dataDiag.type == 'hs') {
      // si clic sur une seule ligne d'heure sup
      this.data.forEach((element: any) => {
        if (!element.hasOwnProperty('subTable')) {
          element.duree = [element['Durée']];
          element['Nombre d’heures totales'] = 1;
        }
        // si clic sur une ligne agent ou validation de masse
        else if ('duree' in element) {
          element['Nombre d’heures totales'] = element['duree'].length;
        }
      });

      // calcul des durées
      this.data.forEach((element: any) => {
        element.hours = this.toHHss(element.duree.reduce((r: any, elem: any) => r + this.toSeconds(elem), 0));
      });
    }
    // astreintes
    else {
      // si clic sur une seule ligne d'heure sup
      this.data.forEach((element: any) => {
        element.duree = [element['Durée']];
        if (element.hasOwnProperty('subTable')) {
          element['Nombre d’heures totales'] = element['subTable'].length;
        } else {
          element['Nombre d’heures totales'] = 1;
        }
      });
    }

    // si il y a un ou plusieurs refus le motif est obligatoire
    if (this.dataDiag.action && !this.dataDiag.action.includes('refuser')) {
      this.data = this.data.map((element: any) => {
        return { ...element, hasRefused: this.hasRefused(element) };
      });
      this.anyHasRefused = this.data.some((element: any) => element.hasRefused);
      this.anyHasRefused ? this.motif.setValidators([Validators.required]) : this.motif.setValidators([]);
      this.motif.updateValueAndValidity();
    }
  }

  hasRefused(_el: any): boolean {
    if (_el.subTable?.length) {
      return _el.subTable.some((elm: any) => elm.Statut?.code === 'REFUSEES');
    }
    return _el.Statut?.code === 'REFUSEES' || false;
  }

  verifMotif() {
    this.bt_refuser_disabled = this.motif_val == '' ? true : false;
  }

  toSeconds = (str: any) => {
    str = str.split(':');
    return +str[0] * 60 + +str[1];
  };
  toHHss = (seconds: any) => {
    /* @ts-ignore */
    let minutes = parseInt(seconds / 60);
    seconds = seconds - minutes * 60;
    return (minutes + '').padStart(2, '0') + ':' + (seconds + '').padStart(2, '0');
  };
}
