<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<h2 style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mb-0" mat-dialog-title>
  {{ data.title }}
</h2>
<button mat-dialog-close class="btnClose" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
  <mat-icon>close</mat-icon>
</button>

<mat-dialog-content class="mat-typography pt-0">
  <div style="margin-top: 5%">
    <mat-divider></mat-divider>
    <div class="div-contenu">
      <div>
        <mat-icon
          style="
            color: rgba(35, 163, 23, 0.982);
            transform: scale(2);
            vertical-align: middle;
            margin-top: 10px;
            margin-bottom: 9px;
            margin-right: 20px;
          "
          >check_circle</mat-icon
        >
      </div>
      <div>
        <div>
          {{ data.content }}
        </div>
        <div *ngIf="data?.content2 != '' && data?.content2 != null">
          {{ data.content2 }}
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
</mat-dialog-actions>
