import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Broadcast } from 'src/app/core/services/broadcast.service';

@Injectable({
  providedIn: 'root'
})
export class ValidationSaisiesService {
  module = environment.webService.server + environment.webService.url + environment.webService.api;
  constructor(
    private http: HttpClient,
    private broadcast: Broadcast
  ) {}

  // lignes heures supplémentaires en attente de validation
  getSaisieEnAttente() {
    return this.http.get<any>(this.module + '/heures-supplementaires/a-valider');
  }
  getSaisieEnAttenteWithParams(offset: number, limit: number, filtre: string | null = null) {
    filtre = filtre ?? '';
    let url = this.module + '/heures-supplementaires/a-valider';
    if (filtre != '' && filtre !== undefined && filtre !== null) {
      url = url + '?' + `filterGenerique=${filtre}`;
    }
    const params = new HttpParams().set('page', offset).set('size', limit);
    return this.http.get<any>(url, { params });
  }

  // lignes heures supplémaentaires déjà validées
  getSaisieValider() {
    return this.http.get<any>(this.module + '/heures-supplementaires/validees');
  }

  getSaisieValiderWithParams(offset: number, limit: number, filtre: string | null = null) {
    filtre = filtre ?? '';
    let url = this.module + '/heures-supplementaires/validees';
    if (filtre != '' && filtre !== undefined && filtre !== null) {
      url = url + '?' + `filterGenerique=${filtre}`;
    }
    const params = new HttpParams().set('page', offset).set('size', limit);
    return this.http.get<any>(url, { params });
  }

  // lignes heures supplémaentaires déjà validées
  getSaisieRefuser() {
    return this.http.get<any>(this.module + '/heures-supplementaires/refusees');
  }
  getSaisieRefuserWithParams(offset: number, limit: number, filtre: string | null = null) {
    filtre = filtre ?? '';
    let url = this.module + '/heures-supplementaires/refusees';
    if (filtre != '' && filtre !== undefined && filtre !== null) {
      url = url + '?' + `filterGenerique=${filtre}`;
    }
    const params = new HttpParams().set('page', offset).set('size', limit);
    return this.http.get<any>(url, { params });
  }
  // lignes quelque soit l'état
  getSaisieRecap() {
    return this.http.get<any>(this.module + '/heures-supplementaires/all');
  }
  getSaisieRecapWithParams(offset: number, limit: number, filtre: string | null = null) {
    filtre = filtre ?? '';
    let url = this.module + '/heures-supplementaires/all';
    if (filtre != '' && filtre !== undefined && filtre !== null) {
      url = url + '?' + `filterGenerique=${filtre}`;
    }
    const params = new HttpParams().set('page', offset).set('size', limit);
    return this.http.get<any>(url, { params });
  }

  // validation HS
  validerSaisie(data: any) {
    let obj = this.http.put<any>(this.module + '/heures-supplementaires/valider-bulk', data);
    setTimeout(() => {
      this.broadcast.broadcastEvent('reloadNotification');
    }, 1000);
    return obj;
  }
  // refus HS
  refuserSaisie(data: any) {
    let obj = this.http.put<any>(this.module + '/heures-supplementaires/refuser-bulk', data);
    setTimeout(() => {
      this.broadcast.broadcastEvent('reloadNotification');
    }, 1000);
    return obj;
  }

  // détail de la validation
  getValidationDetail(id: number) {
    return this.http.get<any>(this.module + '/heures-supplementaires/' + id + '/validations-refus');
  }
  getValidationDetailById(id: number) {
    return this.http.get<any>(this.module + '/heures-supplementaires/' + id);
  }
  getAstraintesWithParams(offset: number, limit: number, filtre: string | null = null) {
    filtre = filtre ?? '';
    let url = this.module + '/astreintes/all';
    if (filtre != '' && filtre !== undefined && filtre !== null) {
      url = url + '?' + `filterGenerique=${filtre}`;
    }
    const params = new HttpParams().set('page', offset).set('size', limit);
    return this.http.get<any>(url, { params });
  }
  getAvaliderAstrainteWithParams(offset: number, limit: number, filtre: string | null = null) {
    filtre = filtre ?? '';
    let url = this.module + '/astreintes/a-valider';
    if (filtre != '' && filtre !== undefined && filtre !== null) {
      url = url + '?' + `filterGenerique=${filtre}`;
    }
    const params = new HttpParams().set('page', offset).set('size', limit);
    return this.http.get<any>(url, { params });
  }
  getRefuseAstrainteWithParams(offset: number, limit: number, filtre: string | null = null) {
    filtre = filtre ?? '';
    let url = this.module + '/astreintes/refusees';
    if (filtre != '' && filtre !== undefined && filtre !== null) {
      url = url + '?' + `filterGenerique=${filtre}`;
    }
    const params = new HttpParams().set('page', offset).set('size', limit);
    return this.http.get<any>(url, { params });
  }
  getvalideAstrainteWithParams(offset: number, limit: number, filtre: string | null = null) {
    filtre = filtre ?? '';
    let url = this.module + '/astreintes/validees';
    if (filtre != '' && filtre !== undefined && filtre !== null) {
      url = url + '?' + `filterGenerique=${filtre}`;
    }
    const params = new HttpParams().set('page', offset).set('size', limit);
    return this.http.get<any>(url, { params });
  }

  // validation astreinte
  validerSaisieAstreinte(data: any) {
    let obj = this.http.put<any>(this.module + '/astreintes/valider-bulk', data);
    setTimeout(() => {
      this.broadcast.broadcastEvent('reloadNotification');
    }, 1000);
    return obj;
  }
  // refus astreinte
  refuserSaisieAstrainte(data: any) {
    let obj = this.http.put<any>(this.module + '/astreintes/refuser-bulk', data);
    setTimeout(() => {
      this.broadcast.broadcastEvent('reloadNotification');
    }, 1000);
    return obj;
  }

  // détail de la validation
  getValidationDetailAstreinte(id: number) {
    return this.http.get<any>(this.module + '/astreintes/' + id + '/validations-refus');
  }
  getValidationDetailAstreinteById(id: number) {
    return this.http.get<any>(this.module + '/astreintes/' + id);
  }

  //Saisie à renouveler
  renouvelerHS(id: number) {
    return this.http.put<any>(this.module + '/heures-supplementaires/renouveler/' + id, {});
  }
  renouvelerAstreinte(id: number) {
    return this.http.put<any>(this.module + '/astreintes/renouveler/' + id, {});
  }
}
