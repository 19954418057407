import { ApplicationConfig, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { Router, provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakService } from 'keycloak-angular';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { environment } from 'src/environments/environment';

function initializeKeycloak(keycloak: KeycloakService, router: Router) {
  return () =>
    keycloak
      .init({
        config: {
          url: environment.keycloak.url,
          realm: environment.keycloak.realm,
          clientId: environment.keycloak.clientId
        },
        initOptions: {
          onLoad: 'login-required',
          checkLoginIframe: false
        }
      })
      .then((auth) => {
        if (auth) {
          const isLogin = sessionStorage.getItem('isLoginAction');
          if (!isLogin) {
            sessionStorage.setItem('isLoginAction', 'true');
            router.navigateByUrl(window.location.origin + '/accueil');
          }
        }
      });
}
export const appConfig: ApplicationConfig = {
  providers: [
    KeycloakService,
    KeycloakAngularModule,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500, horizontalPosition: 'right', verticalPosition: 'top' } },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, Router]
    },
    { provide: HTTP_INTERCEPTORS, useClass: KeycloakBearerInterceptor, multi: true },
    provideRouter(routes),
    importProvidersFrom([BrowserAnimationsModule])
  ]
};
