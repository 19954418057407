<div class="background-container">
  <div class="shape">
    <h2>**** PORTAIL DE SAISIES DES HEURES SUPPLEMENTAIRES</h2>
  </div>

  <img class="img-logo" src="assets/logos/logo.png" alt="" />

  <div class="center-card">
    <mat-card>
      <mat-card-title>PORTAIL DE SAISIES DES HEURES SUPPLEMENTAIRES</mat-card-title>
      <mat-card-content>
        <form [formGroup]="form">
          <p>
            <mat-form-field class="mt-5">
              <mat-icon color="primary" matPrefix>mail</mat-icon>
              <input type="text" formControlName="username" matInput placeholder="Saisissez votre identifiant" />
            </mat-form-field>
          </p>

          <p>
            <mat-form-field>
              <mat-icon color="primary" matPrefix>lock_open</mat-icon>
              <input type="password" matInput formControlName="password" placeholder="Saisissez votre mot de passe" />
            </mat-form-field>
          </p>

          <div class="button">
            <button (click)="submit()" color="primary" type="submit" mat-raised-button>Se connecter</button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
