import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatInputModule, MatButtonModule],
  templateUrl: './acces-interdit.component.html',
  styleUrls: ['./acces-interdit.component.scss']
})
export class AccesInterditComponent {
  constructor(
    private keycloak: KeycloakService,
    private router: Router
  ) {}

  logout() {
    sessionStorage.removeItem('isLoginAction');
    this.keycloak.logout('');
  }
}
