import { Component, Inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { take } from 'rxjs';
import { AlerteModalComponent } from 'src/app/shared/alerte-modal/alerte-modal.component';
import { MatSelectModule } from '@angular/material/select';
import { DateTime } from 'luxon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ValidationModalComponent } from 'src/app/shared/validation-modal/validation-modal.component';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { DirectionService } from 'src/app/services/direction.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CustomLuxonDateAdapter } from 'src/app/shared/utils/custom-luxon-date-adapter';
import { MY_FORMATS } from 'src/app/shared/utils/date-pickr-custom-luxon-format';
import { DragDropModule } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-directions-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatRadioModule,
    MatIconModule,
    MatCardModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatAutocompleteModule,
    ValidationModalComponent,
    ConfirmModalComponent,
    MatNativeDateModule,
    MatCheckboxModule,
    DragDropModule
  ],
  templateUrl: './directions-modal.component.html',
  styleUrls: ['./directions-modal.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: DateAdapter, useClass: CustomLuxonDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class DirectionsModalComponent {
  rowData: any = {};
  dateP = new DatePipe('fr-FR');
  action: string = '';
  titre_action: string = 'Ajouter';
  selectedType: string = '';
  dataCampagne: any = '';
  texte_erreur_date: string = '';
  minDateDebutSaisie: Date;
  minDateFinSaisie: Date;
  minDateDebutValidation: Date;
  minDateFinValidation: Date;
  minDateArchivage: Date;
  directionList: any[] = [];
  listDirection: any = [];
  val_direction: any = null;
  selTypeWorkflow: '';
  possibleWorkflows: any = [
    { libelle: 'Assistant vers Directeur', value: 'ASSISTANT_DIRECTEUR' },
    { libelle: 'Assistant vers Responsable puis Directeur', value: 'ASSISTANT_RESPONSABLE_DIRECTEUR' }
  ];

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DirectionsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private directionService: DirectionService
  ) {}

  form: FormGroup = this.formBuilder.group({
    libelle: [{ value: this.data['libelle'], disabled: true }],
    structValidation: [{ value: this.data['structValidation'], disabled: true }],
    typeWorkflow: [this.data['typeWorkflow'], [Validators.required]],
    flagDepassementInterne: [this.data['flagDepassementInterne']],
    flagDepassementExterne: [this.data['flagDepassementExterne']]
  });

  ngOnInit() {
    this.rowData = this.data;
    this.initForm();
  }

  ngAfterViewInit() {}

  // chargement des dates dans les datepicker
  initForm() {
    let _data = this.data;
    console.log('initForm _data:', _data);

    this.selTypeWorkflow = this.data['typeWorkflow'];

    // par défaut : en mode ajout
    this.action = 'ajouter';

    if (Object.keys(_data).length > 0) {
      if (_data['edition']) {
        this.titre_action = 'Modifier';
        this.action = 'modifier';
      } else {
        this.action = 'ajouter';
      }
    }
  }

  fermerModale() {
    // modification
    if (this.data['edition']) {
      this.dialogRef.close({ action: false });
    }
    // création
    else {
      this.confirmQuitterModale();
    }
  }

  confirmQuitterModale() {
    // création uniquement
    if (!this.data['edition']) {
      if (this.form.pristine) {
        const dialogConfirm = this.dialog.open(ConfirmModalComponent, {
          width: '600px',
          data: {
            title: 'Confirmation',
            content1: 'Souhaitez vous abandonner la création de la direction ?',
            content2: 'Les données que vous avez saisies ne seront pas enregistrées.'
          }
        });

        dialogConfirm.afterClosed().subscribe((data) => {
          if (data) {
            this.dialogRef.close({ action: false });
          }
        });
      } else {
        this.dialogRef.close({ action: false });
      }
    }
  }

  validerForm() {
    if (this.action == 'ajouter') {
      //this.ajouterDirection();
    } else {
      this.modifierDirection();
    }
  }

  // Modifier une direction
  modifierDirection() {
    let _data: any = {};

    _data['id'] = this.rowData.id;
    _data['libelle'] = this.form.controls['libelle'].value;
    _data['structValidation'] = this.form.controls['structValidation'].value;
    _data['typeWorkflow'] = this.form.controls['typeWorkflow'].value;
    _data['flagDepassementInterne'] = this.form.controls['flagDepassementInterne'].value;
    _data['flagDepassementExterne'] = this.form.controls['flagDepassementExterne'].value;

    this.directionService
      .modifierDirection(_data, _data['id'])
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          console.log('result ok: ', result);
          this.spinner.hide();
          this.dialogRef.close({ action: true });
          this.dialog.open(ValidationModalComponent, {
            width: '600px',
            data: {
              title: 'Confirmation',
              content: 'La direction a été modifiée avec succès.'
            }
          });
        },
        error: (error) => {
          console.log('error: ', error);
          let erreur_texte = error.error.detail || error.error || error.statusText || error;

          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: 'Erreur lors de la modification de la direction !',
              status: 'STATUS : ' + error.status,
              detail: 'DETAIL : ' + erreur_texte
            }
          });
        }
      });
  }
}
