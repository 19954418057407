<div class="div-nav">
  <span class="material-symbols-outlined icon icon-titre">{{ icon }}</span> <a class="label">{{ label }}</a>
</div>

<div style="display: flex; margin-bottom: -27px" *ngIf="arianeList.length">
  <div class="div-nav">
    <mat-icon
      class="material-symbols-outlined icon-home"
      style="cursor: pointer; margin-top: -52px; margin-left: 48px; padding-left: 8px; font-size: 15px; color: #00000099"
      (click)="redirectHome()"
    >
      home
    </mat-icon>
    <a routerLink="/home"></a>
  </div>
  <div class="div-nav" *ngFor="let arian of arianeList; let i = index">
    <mat-icon style="margin-top: -58px; font-size: 20px">keyboard_arrow_right</mat-icon>
    <a
      *ngIf="i == 1"
      [routerLink]="arian.url"
      style="margin-top: -58px; font-size: 13px; color: #00000099; font-weight: bold; pointer-events: none"
      >{{ arian.label }}</a
    >
    <a *ngIf="i !== 1" [routerLink]="arian.url" class="breadcrumb-link" style="margin-top: -58px; font-size: 13px; color: #00000099">{{
      arian.label
    }}</a>
  </div>
</div>
