import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { take } from 'rxjs';
import { HeuresSupplémentairesService } from 'src/app/services/heures-supplémentaires.service';
import { Broadcast } from 'src/app/core/services/broadcast.service';
import { MatCardModule } from '@angular/material/card';
import { AstreintesService } from 'src/app/services/astreintes.service';
import { DragDropModule } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-delete-modal',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule, MatDividerModule, MatCardModule, DragDropModule],
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent {
  constructor(
    public HS_service: HeuresSupplémentairesService,
    private astreintesService: AstreintesService,
    public dialogRef: MatDialogRef<DeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private broadcast: Broadcast
  ) {}

  delete() {
    if (this?.data?.type == 'hs') {
      this.deleteHs();
    } else if (this.data.type == 'astreinte') {
      this.deleteAstreinte();
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  deleteHs() {
    this.HS_service.DeleteHS(this.data.id)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.broadcast.broadcastEvent({
            broadcastedevent: 'deleteHs'
          });
        },
        error: (error) => {
          this.broadcast.broadcastEvent({
            broadcastedevent: 'deleteHsError'
          });
        }
      });
  }

  deleteAstreinte() {
    console.log('deleteAstreinte');

    this.astreintesService
      .deleteAstreinte(this.data.id)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.broadcast.broadcastEvent({
            broadcastedevent: 'deleteAstreinte'
          });
        },
        error: (error) => {
          this.broadcast.broadcastEvent({
            broadcastedevent: 'deleteAstreinteError'
          });
        }
      });
  }
}
