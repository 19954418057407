import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ConfirmQuestionComponent } from '../../validation/confirm-question/confirm-question.component';
import { AgentSuivantComponent } from 'src/app/shared/agent-suivant/agent-suivant.component';
import { MatChipsModule } from '@angular/material/chips';
import { HeureSupDirectionOrigineFormComponent } from '../heure-sup-direction-origine-form/heure-sup-direction-origine-form.component';
import { HeureSupForm3Component } from '../heure-sup-form-3/heure-sup-form-3.component';
import { SyntheseCreationComponent } from '../synthese-creation/synthese-creation.component';
import { HeuresSupplémentairesService } from 'src/app/services/heures-supplémentaires.service';
import { delay, take } from 'rxjs';
import { CommunicationService } from 'src/app/shared/communication.service';
import { DateTime } from 'luxon';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { FormulaireHSModel, AgentHS, HoraireHS } from 'src/app/models/formulaire-hs.model';
import { ValidationModalComponent } from 'src/app/shared/validation-modal/validation-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AlerteModalComponent } from 'src/app/shared/alerte-modal/alerte-modal.component';
@Component({
  selector: 'app-create-hs-modal',
  standalone: true,
  imports: [
    AgentSuivantComponent,
    CommonModule,
    MatTableModule,
    MatRadioModule,
    MatIconModule,
    MatCardModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatChipsModule,
    HeureSupDirectionOrigineFormComponent,
    HeureSupForm3Component,
    SyntheseCreationComponent,
    MatSnackBarModule,
    DragDropModule
  ],
  templateUrl: './create-hs-modal.component.html',
  styleUrls: ['./create-hs-modal.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: true }
    }
  ]
})
export class CreateHsModalComponent {
  @ViewChild(HeureSupDirectionOrigineFormComponent) childFormEvt!: HeureSupDirectionOrigineFormComponent | null;
  @ViewChild(SyntheseCreationComponent) syntheseComponent!: SyntheseCreationComponent;

  agentsList: any;
  currentIndex = 0;
  selectedAgents: string[] = [];
  selectedOption: string = 'HS_POUR_DIRECTION_ORIGINE';
  selectedNbAgents: number = 1;
  heureInvalide: boolean = false;
  infosFormulaires: FormulaireHSModel[] = [];
  syntheseParAgent: any[];
  previousStepIndex = -1;
  nombreHs = 1;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateHsModalComponent>,
    private HS_service: HeuresSupplémentairesService,
    private notifyService: CommunicationService,
    private spinner: NgxSpinnerService,
    private _snackBar: MatSnackBar
  ) {}

  validate() {
    this.dialog
      .open(ConfirmQuestionComponent, {
        width: '460px'
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {});
  }
  cancelDialog() {
    this.dialogRef.close();
  }
  disabledNext(event: any) {
    if (event) {
      this.heureInvalide = true;
    } else {
      this.heureInvalide = false;
    }
  }
  stepSelChange(event: any) {
    if (event) {
      if (this.childFormEvt) {
        this.infosFormulaires = this.childFormEvt.getCurentInfosFormulaires();
      }
      // Réaffichage du formulaire => on rétablit les données actuelles

      if (event.selectedIndex == 2 /* page 3 synthèse */) {
        // Recalcul de la synthèse par agent
        this.syntheseParAgent = this.getSyntheseByAgent();
      }

      this.previousStepIndex = event.selectedIndex;
    }
  }
  // reinsialise le formulaire lors de changement de type
  purgeData() {
    localStorage.removeItem('event');
    this.infosFormulaires = [];
    if (this.childFormEvt) {
      this.childFormEvt.infosFormulaires = [];
    }
  }
  // @Deprecated
  handleModal() {
    if (this.childFormEvt?.formHsEvenements.valid) {
      let campagneEnCoursStr = sessionStorage.getItem('campagneActuelle');
      let campagneEnCoursId = -1;
      if (campagneEnCoursStr) {
        let campagneEnCours = JSON.parse(campagneEnCoursStr);
        if (campagneEnCours) {
          campagneEnCoursId = campagneEnCours.id;
        }
      }
      if (campagneEnCoursId < 0) {
        this._snackBar.open('Création impossible : pas de campagne en cours', 'OK');
      }

      this.spinner.show();

      const formToJson = this.childFormEvt.formHsEvenements.value;
      const payload: any = [];
      this.childFormEvt.selectedAgents.forEach((agent: any) => {
        formToJson.hoursFormArray.forEach((hs: any) => {
          let dateFormatted = DateTime.fromJSDate(hs.date).toFormat('yyyy-MM-dd');
          payload.push({
            type: this.selectedOption,
            statut: 'A_VALIDER',
            dateRealisation: dateFormatted,
            heureDebut: hs?.heure_debut,
            heureFin: hs?.heure_fin,
            evenement: { id: formToJson.evenement },
            agent: { id: agent?.id },
            directionOrigine: { id: agent?.direction?.id },
            directionOrganisatrice: { id: formToJson.directionId },
            serviceFonctionnel: { id: agent?.serviceFonctionnel?.id },
            motif: formToJson.motif,
            // Campagne en cours
            campagne: { id: campagneEnCoursId }
          });
        });
      });

      this.HS_service.createHSBulk(payload)
        .pipe(delay(1000))
        .subscribe({
          next: (result) => {
            this.spinner.hide();
            this.dialogRef.close();
            this.notifyService.triggerRefresh();
          },
          error: (error) => {
            this.spinner.hide();
            this._snackBar.open('Erreur :(', 'OK');
          }
        });
    }
  }

  // test HS et Hs bulk
  handleHSModal() {
    let campagneEnCoursStr = sessionStorage.getItem('campagneActuelle');
    let campagneEnCoursId = -1;
    if (campagneEnCoursStr) {
      let campagneEnCours = JSON.parse(campagneEnCoursStr);
      if (campagneEnCours) {
        campagneEnCoursId = campagneEnCours.id;
      }
    }
    if (campagneEnCoursId < 0) {
      this._snackBar.open('Création impossible : pas de campagne en cours', 'OK');
    }

    this.spinner.show();

    const heurSuppBody: any = [];

    let nbAgents = 0;
    let nbHs = 0;

    // Parcours des formulaires
    this.infosFormulaires.forEach((currentForm: FormulaireHSModel) => {
      // Parcours des agents
      currentForm.agents.forEach((agent: AgentHS) => {
        nbAgents++;
        const agentData = {
          type: this.selectedOption,
          statut: 'A_VALIDER',
          agent: { id: agent.id },
          directionOrigine: { id: agent.direction.id },
          directionOrganisatrice: {
            id: currentForm.typeHS == 'HS_POUR_DIRECTION_ORIGINE' ? agent.direction.id : currentForm.directionOrganisatriceId
          },
          serviceFonctionnel: { id: agent.serviceFonctionnel.id },
          evenement: currentForm.typeHS == 'HS_POUR_DIRECTION_ORIGINE' ? null : { id: currentForm.evenementId },
          motif: currentForm.motif,
          campagne: { id: campagneEnCoursId }
        };

        currentForm.horaires.forEach((horaire: HoraireHS) => {
          nbHs++;
          let dateFormatted = horaire.dateRealisation.toFormat('yyyy-MM-dd');
          let heurSupp = {
            ...agentData,
            dateRealisation: dateFormatted,
            heureDebut: horaire?.heureDebut + ':00',
            heureFin: horaire?.heureFin + ':00'
          };
          heurSuppBody.push(heurSupp);
        }); // parcours des horaires
      }); // parcours des agents
    }); // parcours des formulaires

    this.HS_service.createHSBulk(heurSuppBody)
      .pipe(delay(1000))
      .subscribe({
        next: (result) => {
          let nombreHs = result.length;
          this.dialogRef.close();
          this.spinner.hide();

          this.dialog.open(ValidationModalComponent, {
            width: '600px',
            data: {
              title: 'Confirmation',
              content:
                'La saisie HS a été enregistrée avec succès  (' +
                nombreHs +
                " demande(s) d'HS créée(s) concernant " +
                nbAgents +
                ' agent(s)).'
            }
          });

          //notify consultation
          this.notifyService.triggerRefresh();
        },
        error: (error) => {
          this.spinner.hide();
          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Erreur',
              content: error.error.replace('Validation error:', '')
            }
          });
        }
      });
  }

  add_agent(selectedAgent: string) {
    if (selectedAgent && !this.selectedAgents.includes(selectedAgent)) this.selectedAgents.push(selectedAgent);
  }

  /**
   * Construit la vue des informations par agent et la passe à l'écran de synthèse
   */
  getSyntheseByAgent(): any[] {
    if (this.infosFormulaires && this.infosFormulaires.length > 0) {
      let agentMap: any = {};

      for (let currentForm of this.infosFormulaires) {
        if (currentForm.agents && currentForm.agents.length > 0) {
          let currentAgents = currentForm.agents;
          for (const currentAgent of currentAgents) {
            let agentId: string = currentAgent.id;
            let prevAgentInfos = agentMap[agentId];
            // agent pas déjà présent
            if (!prevAgentInfos) {
              agentMap[agentId] = {
                agent: currentAgent,
                infos_hs: []
              };
            }
            prevAgentInfos = agentMap[agentId];
            prevAgentInfos.infos_hs.push({
              typeHS: currentForm.typeHS,
              motif: currentForm.motif,
              evenementId: currentForm.evenementId,
              directionOrganisatriceId: currentForm.directionOrganisatriceId,
              horaires: currentForm.horaires
            });
          }
        }
      }

      return Object.values(agentMap);
    } else {
      return [];
    }
  }
}
