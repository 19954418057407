import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { ParametresService } from 'src/app/services/parametres.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-historique-modifications',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatIconModule, MatDialogModule, MatButtonModule, MatDividerModule],
  templateUrl: './historique-modifications.component.html',
  styleUrls: ['./historique-modifications.component.scss']
})
export class HistoriqueModificationsComponent implements OnInit {
  rowData: any;
  dataJSON: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<HistoriqueModificationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private parametreService: ParametresService
  ) {}

  ngOnInit() {
    this.rowData = this.data.data.id;

    this.getHistricalData();
  }

  getHistricalData() {
    let id = this.rowData;

    this.parametreService
      .getHistorique(id)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          console.log('result', result);
          this.dataJSON = result;
        },
        error: (error) => {
          console.log('erreur:', error);
        }
      });
  }
}
