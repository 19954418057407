<app-ariane-nav [label]="arianeLabel" [icon]="arianeIcon" [arianeList]="this.arianeList"></app-ariane-nav>
<mat-divider class="my-3"></mat-divider>
<div class="row mt-3">
  <div class="col">
    <mat-form-field class="mr-5" class="field-filtre">
      <mat-label>Filtrer</mat-label>
      <input matInput #input (keyup)="appDataTable.applyFilter($event)" />
    </mat-form-field>
  </div>
  <div class="col flex-container end align-items-center"></div>
</div>
<app-data-table
  #appDataTable
  [displayedColumns]="ServicesDisplayedColumns"
  [data]="dataTable"
  [expendable]="false"
  [hasView]="false"
  [editable]="true"
  [delatable]="false"
  [filter]="false"
  [length]="dataLength"
  [hasPagination]="true"
  (updatePagination)="updatePagination($event)"
  (clicModalModifier)="openServiceEdit($event)"
  (updateFiltre)="updateFiltre($event)"
  [customFilter]="true"
></app-data-table>
