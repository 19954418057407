import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DataTableComponent } from 'src/app/shared/data-table/data-table.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ValidationModalComponent } from 'src/app/shared/validation-modal/validation-modal.component';
import { ArianeNavComponent } from 'src/app/layout/ariane-nav/ariane-nav.component';
import { ConfirmValidationModalComponent } from './confirm-validation-modal/confirm-validation-modal.component';
import { DetailValidationComponent } from './detail-validation/detail-validation.component';
import { RoleService } from 'src/app/services/role.service';
import { RestrictedPageComponent } from 'src/app/shared/restricted-page/restricted-page.component';
import { ValidationSaisiesService } from 'src/app/services/validation-saisies.service';
import { take, Observable } from 'rxjs';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { SelectionService } from 'src/app/shared/data-table/data-selection.service';
import { AlerteModalComponent } from 'src/app/shared/alerte-modal/alerte-modal.component';
import { CampagneActuService } from '../home-page/campagne-actuelle/campagne-actuelle.service';
import { Duration } from 'luxon';
import { environment } from 'src/environments/environment';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { TabService } from 'src/app/core/services/tab.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-validation',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    DataTableComponent,
    MatDialogModule,
    MatIconModule,
    MatDividerModule,
    MatTabsModule,
    MatCheckboxModule,
    ValidationModalComponent,
    ArianeNavComponent,
    DetailValidationComponent,
    RestrictedPageComponent,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatRadioModule,
    FormsModule
  ],
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss'],
  providers: [
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' }
    }
  ]
})
export class ValidationComponent implements OnInit, OnDestroy {
  pipe = new DatePipe('fr-FR');
  arianeLabel: string = 'Validation des saisies HS / Astreintes';
  arianeIcon: string = 'task_alt';
  dialogRef: any;
  userRoles: string[] = [];
  dataJSON: any[] = [];
  dataEnAttente: any[] = [];
  dataValider: any[] = [];
  dataRefuser: any[] = [];
  dataRecap: any[] = [];
  IDaValider: any[] = [];
  IDaValMotif: any[] = [];
  astrainteData: any[] = [];
  astrainteDataAvalider: any = [];
  astrainteInvalide: any = [];
  astraintevalide: any = [];
  actifValidation: boolean = false;
  //actifRefus: boolean = false;
  aValiderLenght: number;
  validerLenght: number;
  refuseesLenght: number;
  allLenght: number;
  aValiderAstLenght: number;
  validerAstLenght: number;
  refuseesAstLenght: number;
  allAstLenght: number;
  // Pour activer la validation et refus de masse des astreintes :
  actifAstValidation: boolean = false;
  //actifAstRefus: boolean = false;
  isHs = true;
  //cp_active_a$: Observable<any> = of('');
  filterValue: string = '';

  loading = false;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(DataTableComponent) EnAttenteDataTable!: DataTableComponent;
  _selection_hav!: Observable<any[]>;
  partial: boolean = false;
  partialId: any = [];
  dateDebut: any;
  dateFin: any;
  condition: boolean;
  //le ' ' represente le button afficher tous les subTables
  validDisplayedColumns: string[] = [
    'Sélectionner',
    'Collaborateur',
    'Direction origine',
    'Nombre d’heures totales',
    'Nombre d’heures validées',
    'Nombre d’heures en attente de validation',
    'Actions',
    ' '
  ];

  subValidDisplayedColumns: string[] = [
    'Sélectionner',
    // 'DIR. Origine',
    'Service Origine',
    'DIR. Évènement',
    'Motif HS',
    'Date',
    'Heure début',
    'Heure fin',
    'Durée',
    'Statut',
    'Actions'
  ];

  validCellStyleCss: any = {
    Sélectionner: { 'text-align': 'left', 'padding-left': '18px' },

    'Nombre d’heures totales': { 'text-align': 'center', 'padding-right': '30px' },
    'Nombre d’heures validées': { 'text-align': 'center', 'padding-right': '30px' },
    'Nombre d’heures en attente de validation': { 'text-align': 'center', 'padding-right': '30px' },

    'Nombre de jours à valider': { 'text-align': 'center', 'padding-right': '30px' },
    'Nombre de jours refusés': { 'text-align': 'center', 'padding-right': '30px' },
    'Nombre de jours total': { 'text-align': 'center', 'padding-right': '30px' }
  };
  astraintesDisplayedColumns: string[] = [
    'Sélectionner',
    'Collaborateur',
    'Direction origine',
    'Nombre de jours à valider',
    'Nombre de jours refusés',
    'Nombre de jours total',
    'Actions',
    ' '
  ];
  subTableDisplayedColumns: string[] = [
    'Sélectionner',
    //'DIR. Origine',
    'Service Origine',
    'DIR. Évènement',
    'Motif HS',
    'Date de creation',
    'Date de debut',
    'Date de fin',
    'Statut',
    'Actions'
  ];
  astreintesSubTableDisplayedColumns: string[] = [
    'Sélectionner',
    //'DIR. Origine',
    'Service Origine',
    'Date de création',
    'Date de début',
    'Date de fin',
    'Nombre',
    'Statut',
    'Actions'
  ];
  idsTovalidate: any[] = [];

  constructor(
    public dialog: MatDialog,
    private roleService: RoleService,
    private ValidationService: ValidationSaisiesService,
    private detectorRef: ChangeDetectorRef,
    private selectionService: SelectionService,
    private campagneActuService: CampagneActuService,
    private tabService: TabService,
    private route: ActivatedRoute
  ) {
    this.setCampagneActive();
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      let paramIsHs = params.get('isHs');
      // Si le query param est null, on ne fait rien
      if (paramIsHs === null) {
        return;
      }
      this.isHs = params.get('isHs') == 'true' ? true : false;
      this.detectorRef.markForCheck();
    });
    this.dateDebut = localStorage.getItem('dateDebutSaisie');
    this.dateFin = localStorage.getItem('dateFinSaisie');
    this.campagneActuService.getCampagneActuelle().subscribe((data) => {
      /**
       * DHESA-484 : feature flag pour validation pendant la saisie
       */
      this.condition = data.type == 'VALIDATION_EN_COURS' || (data.type == 'SAISIE_EN_COURS' && environment.features.validation_en_saisie);
    });

    this.selectionService.resetSelection();

    this.roleService.getRole().subscribe((roles) => {
      this.userRoles = roles;
    });

    this.getListeAttente();
    this.getListeValider();
    this.getListeRefuser();
    this.getListeRecap();
    this.getAstrainte();
    this.getInvalideAstrainte();
    this.getAvaliderAstrainte();
    this.getValiderAstrainte();
  }

  ngOnDestroy() {
    this.selectionService.resetSelection();
  }

  setCampagneActive() {
    // active la validation en massse si (pas de campagne en cours & statut!=SAISIE ou VALIDATION)
    this.campagneActuService.getCampagneActuelle();

    this.campagneActuService.active_a$.subscribe((value) => {
      // activation du bouton validation en masse
      this.actifValidation = value;

      console.log('campagne actifValidation ? ' + this.actifValidation);
      // On veut masquer ça si la campagne n'est pas active
      if (value != undefined && value == false) {
        // activation de la colonne action
        this.validDisplayedColumns.splice(this.validDisplayedColumns.indexOf('Sélectionner'), 1);
        this.validDisplayedColumns.splice(this.validDisplayedColumns.indexOf('Actions'), 1);
        this.subValidDisplayedColumns.splice(this.subValidDisplayedColumns.indexOf('Sélectionner'), 1);
        this.subValidDisplayedColumns.splice(this.subValidDisplayedColumns.indexOf('Actions'), 1);

        this.astraintesDisplayedColumns.splice(this.astraintesDisplayedColumns.indexOf('Sélectionner'), 1);
        this.astraintesDisplayedColumns.splice(this.astraintesDisplayedColumns.indexOf('Actions'), 1);
        this.astreintesSubTableDisplayedColumns.splice(this.astreintesSubTableDisplayedColumns.indexOf('Sélectionner'), 1);
        this.astreintesSubTableDisplayedColumns.splice(this.astreintesSubTableDisplayedColumns.indexOf('Actions'), 1);
      }
    });
  }

  getListeAttente() {
    this.ValidationService.getSaisieEnAttenteWithParams(environment.pagination.offset, environment.pagination.limit)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.dataJSON = result.results;
          this.dataEnAttente = this.transformData('attente');

          this.aValiderLenght = result.totalRecords;
          this.actifValidation = result.totalRecords == 0 ? false : true;
          this.detectorRef.markForCheck();
        },
        error: (error) => {
          console.log('erreur:', error);
        }
      });
  }

  getListeValider() {
    this.ValidationService.getSaisieValiderWithParams(environment.pagination.offset, environment.pagination.limit)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.dataJSON = result.results;
          this.dataValider = this.transformData('valider');
          this.detectorRef.markForCheck();
          this.validerLenght = result.totalRecords;
        },
        error: (error) => {
          console.log('erreur:', error);
        }
      });
  }

  getListeRefuser() {
    this.ValidationService.getSaisieRefuserWithParams(environment.pagination.offset, environment.pagination.limit)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.dataJSON = result.results;
          this.dataRefuser = this.transformData('refuser');
          this.detectorRef.markForCheck();
          this.refuseesLenght = result.totalRecords;
        },
        error: (error) => {
          console.log('erreur:', error);
        }
      });
  }

  getListeRecap() {
    this.ValidationService.getSaisieRecapWithParams(environment.pagination.offset, environment.pagination.limit)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.dataJSON = result.results;
          this.dataRecap = [...this.dataEnAttente, ...this.dataValider, ...this.dataRefuser]; // temporaire
          this.dataRecap = this.transformData('recap');
          this.detectorRef.markForCheck();
        },
        error: (error) => {
          console.log('erreur:', error);
        }
      });
  }

  refreshListe() {
    this.getListeAttente();
    this.getListeValider();
    this.getListeRefuser();
    this.getListeRecap();
    this.getAstrainte();
    this.getInvalideAstrainte();
    this.getAvaliderAstrainte();
    this.getValiderAstrainte();
  }

  transformData(typeListe: string, result: any = null) {
    let dataValid: any[] = [];

    let roles = this.userRoles;
    let profil_admin = roles.includes('dhesa-admin');
    let profil_directeur = roles.includes('dhesa-directeur');
    let profil_dg = roles.includes('dhesa-dg');
    let profil_responsable = roles.includes('dhesa-responsable');
    let profil_assistant_responsable = roles.includes('dhesa-assistant-responsable');
    if (result !== null) {
      this.dataJSON = result;
    }
    for (let key in this.dataJSON) {
      let dataJ = this.dataJSON[key];
      let agent = dataJ?.agentDTO;
      let heure_sup = dataJ?.listHeuresSupplementaires;
      let _data: any = {};

      _data['id'] = agent?.id;
      _data['Collaborateur'] = agent?.nom + ' ' + agent?.prenom;
      // au cas ou on a besoin d'affichier tous les subtables on ajoute cette propriété
      _data['isExpanded'] = false;
      _data['Direction origine'] = heure_sup[0]?.directionOrigine?.libelle;
      let nbreHeuresAValider: number = dataJ?.nbreHeuresAValider;
      let nbreHeuresValidees: number = dataJ?.nbreHeuresValidees;
      let nbreMinsAValider: number = dataJ?.nbreMinsAValider;
      let nbreMinsValidees: number = dataJ?.nbreMinsValidees;
      let nbreHeuresTotal: number = dataJ?.nbreHeuresTotales;
      let nbreMinsTotal: number = dataJ?.nbreMinsTotales;

      let totalHeures = nbreHeuresTotal;
      let totalMin = nbreMinsTotal;

      _data['Nombre d’heures totales'] = totalHeures + ':' + totalMin.toString().padStart(2, '0');
      _data['Nombre d’heures validées'] = nbreHeuresValidees + ':' + nbreMinsValidees.toString().padStart(2, '0');
      _data['Nombre d’heures en attente de validation'] = nbreHeuresAValider + ':' + nbreMinsAValider.toString().padStart(2, '0');

      let isSubTableValider = false;
      // activation du bouton valider pour la ligne agent
      _data['valider_hidden'] = true;
      if (
        this.condition &&
        (profil_admin ||
          (this.condition && profil_responsable && typeListe == 'attente') ||
          (this.condition && profil_assistant_responsable && typeListe == 'attente') ||
          (this.condition && profil_directeur && typeListe == 'attente') ||
          (this.condition && profil_dg && (typeListe == 'valider' || typeListe == 'refuser')))
      ) {
        _data['valider_hidden'] = false;
      }

      _data['subTable'] = [];
      _data['duree'] = [];
      // sous table
      for (let i in heure_sup) {
        let hs = heure_sup[i];

        let _subTable: any = {};
        _subTable['Sélectionner'] = false;
        _subTable['Service Origine'] = hs?.serviceFonctionnel?.libelle;
        _subTable['DIR. Évènement'] = hs?.directionOrganisatrice?.libelle;
        _subTable['Motif HS'] = hs?.motif;
        _subTable['Date'] = this.pipe.transform(hs?.dateRealisation, 'shortDate');
        _subTable['Heure début'] = hs?.heureDebut.slice(0, -3);

        // arrondit les secondes en minutes
        // dataJ.heureFin = '23:59:59'; // pour test
        let _heure_fin = hs?.heureFin.split(':').map((a: any) => Number(a));
        if (_heure_fin[2] != undefined && _heure_fin[2] > 30) {
          _heure_fin[2] = 0;
          if (_heure_fin[1] == 59) {
            _heure_fin[1] = 0;
            _heure_fin[0] = _heure_fin[0] == 23 ? 0 : _heure_fin[0] + 1;
          } else {
            _heure_fin[1] += 1;
          }
        }
        let d_heure_fin = Duration.fromObject({ hours: _heure_fin[0], minutes: _heure_fin[1], seconds: _heure_fin[2] });
        _subTable['Heure fin'] = d_heure_fin.toFormat('hh:mm');

        let _duree: any = hs?.duree.split(':');
        let hr = _duree[0].toString().length == 1 ? '0' + _duree[0] : _duree[0];
        let min = _duree[1].toString().length == 1 ? '0' + _duree[1] : _duree[1];
        _subTable['Durée'] = hr + ':' + min;
        _data['duree'].push(_subTable['Durée']);

        let status_code = hs?.statut;
        let status_text = {};
        if (status_code == 'A_VALIDER') {
          status_text = { class: 'badge-gray', text: 'Avis non renseigné', code: status_code, object: true };
        } else if (status_code == 'VALIDEES') {
          status_text = { class: 'badge-green-directeur', text: 'Avis favorable - Directeur', code: status_code, object: true };
        } else if (status_code == 'REFUSEES') {
          status_text = { class: 'badge-red', text: 'Avis défavorable - Directeur', code: status_code, object: true };
        } else if (status_code == 'VALIDEES_N2') {
          status_text = { class: 'badge-green-dga', text: 'Avis favorable - DG', code: status_code, object: true };
        } else if (status_code == 'REFUSEES_N2') {
          status_text = { class: 'badge-red', text: 'Avis défavorable - DG', code: status_code, object: true };
        } else if (status_code == 'SAISIE_A_RENOUVELER') {
          status_text = { class: 'badge-gray', text: 'Saisie à renouveler', code: status_code, object: true };
        } else if (status_code == 'A_VALIDER_RESPONSABLE') {
          status_text = { class: 'badge-gray', text: 'Avis non renseigné (responsable)', code: status_code, object: true };
        } else if (status_code == 'VALIDEES_RESPONSABLE') {
          status_text = { class: 'badge-green-directeur', text: 'Avis favorable - Responsable', code: status_code, object: true };
        } else if (status_code == 'REFUSEES_RESPONSABLE') {
          status_text = { class: 'badge-red', text: 'Avis défavorable - Responsable', code: status_code, object: true };
        }
        _subTable['Statut'] = status_text;

        _subTable['id'] = hs['id'];
        _subTable['action'] = '';
        _subTable['checked'] = false;

        // activation du bouton valider pour la ligne HS
        _subTable['valider_hidden'] = true;

        if (
          (this.condition &&
            profil_admin &&
            (status_code == 'A_VALIDER' ||
              status_code == 'VALIDEES' ||
              status_code == 'REFUSEES' ||
              status_code == 'A_VALIDER_RESPONSABLE' ||
              status_code == 'VALIDEES_RESPONSABLE' ||
              status_code == 'REFUSEES_RESPONSABLE')) ||
          (this.condition && (profil_responsable || profil_assistant_responsable) && status_code == 'A_VALIDER_RESPONSABLE') ||
          (this.condition &&
            profil_directeur &&
            (status_code == 'VALIDEES_RESPONSABLE' || status_code == 'REFUSEES_RESPONSABLE' || status_code == 'A_VALIDER')) ||
          (this.condition && profil_dg && (status_code == 'VALIDEES' || status_code == 'REFUSEES'))
        ) {
          _subTable['valider_hidden'] = false;
        } else {
          isSubTableValider = true;
        }

        // pour la modale de détail
        _subTable['Collaborateur'] = agent?.nom + ' ' + agent?.prenom;

        _data['subTable'].push(_subTable);
      }
      _data['valider_hidden'] = isSubTableValider;
      dataValid.push(_data);
    }

    return dataValid;
  }

  // validation d'une ligne ou de toutes les lignes d'un agent
  validerSaisie(select_key?: string, motif?: string | null, enMasse?: boolean) {
    let _data: any = [];
    let _data_ar: any = {};

    // valider en masse
    if (enMasse) {
      if (select_key) {
        _data = this.idsTovalidate;
      }
      // valider une ligne agent ou une saisie
    } else {
      if (this.partial) {
        _data = this.partialId;
      } else {
        _data = this.IDaValider;
      }
    }

    for (let id of _data) {
      _data_ar[id] = motif;
    }

    this.ValidationService.validerSaisie(_data_ar)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.openConfirmValidationModal();
          this.selectionService.resetSelection();
          this.refreshListe();
        },
        error: (error) => {
          console.log('error:', error);
          let erreur_texte = error.error || error.statusText || error.error.detail || error;

          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: 'Opération invalide, erreur de réponse serveur.',
              //erreur: error.message,
              status: 'STATUS: ' + error.status,
              detail: 'DETAIL: ' + erreur_texte
            }
          });
        }
      });
  }

  // refus d'une ligne ou de toutes les lignes d'un agent
  refuserSaisie(motif: string, select_key?: string, enMasse?: boolean) {
    let _data: any = [];
    let _data_ar: any = {};

    // valider en masse
    if (enMasse) {
      if (select_key) {
        _data = this.selectionService.getData;
        _data = _data[select_key] || [];
      }
      // valider une ligne agent ou une saisie
    } else {
      if (this.partial) {
        _data = this.partialId;
      } else {
        _data = this.IDaValider;
      }
    }

    for (let id of _data) {
      _data_ar[id] = motif;
    }
    console.log('data à refuser', _data_ar);

    this.ValidationService.refuserSaisie(_data_ar)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          console.log('result', result);
          this.openConfirmValidationModal(false, true);
          this.selectionService.resetSelection();
          this.refreshListe();
        },
        error: (error) => {
          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: 'Opération invalide, erreur de réponse serveur.',
              //erreur: error.message,
              status: 'STATUS: ' + error.status,
              detail: 'DETAIL: ' + error.error.detail
            }
          });
        }
      });
  }

  // validation d'une ligne ou de toutes les lignes d'un agent
  validerSaisieAstreinte(select_key?: string, motif?: string | null, enMasse?: boolean) {
    let _data: any = [];
    let _data_ar: any = {};

    // valider en masse
    if (enMasse) {
      if (select_key) {
        _data = this.idsTovalidate;
      }
      // valider une ligne agent ou une saisie
    } else {
      if (this.partial) {
        _data = this.partialId;
      } else {
        _data = this.IDaValider;
      }
    }

    for (let id of _data) {
      _data_ar[id] = motif;
    }

    this.ValidationService.validerSaisieAstreinte(_data_ar)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.openConfirmValidationModal(true);
          this.selectionService.resetSelection();
          this.refreshListe();
        },
        error: (error) => {
          console.log('error:', error);
          let erreur_texte = error.error || error.statusText || error.error.detail || error;

          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: 'Opération invalide, erreur de réponse serveur.',
              status: 'STATUS: ' + error.status,
              detail: 'DETAIL: ' + erreur_texte
            }
          });
        }
      });
  }

  refuserSaisieAstreinte(motif: string, select_key?: string, enMasse?: boolean) {
    console.log('refuserSaisieAstreinte');
    let _data: any = [];
    let _data_ar: any = {};

    // valider en masse
    if (enMasse) {
      if (select_key) {
        _data = this.selectionService.getData;
        _data = _data[select_key] || [];
      }
      // valider une ligne agent ou une saisie
    } else {
      if (this.partial) {
        _data = this.partialId;
      } else {
        _data = this.IDaValider;
      }
    }

    for (let id of _data) {
      _data_ar[id] = motif;
    }

    this.ValidationService.refuserSaisieAstrainte(_data_ar)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.openConfirmValidationModal(true, true);
          this.selectionService.resetSelection();
          this.refreshListe();
        },
        error: (error) => {
          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: 'Opération invalide, erreur de réponse serveur.',
              //erreur: error.message,
              status: 'STATUS: ' + error.status,
              detail: 'DETAIL: ' + error.error.detail
            }
          });
        }
      });
  }
  openConfirmValidationModal(isAstreinte?: boolean, isRefus?: boolean) {
    let action = isRefus ? 'refusée' : 'validée';
    let type = isAstreinte ? 'Astreinte' : 'HS';

    this.dialog.open(ValidationModalComponent, {
      width: '480px',
      data: {
        title: 'Confirmation',
        content: `La saisie ${type} a été ${action} avec succès.`
      }
    });
  }

  refuserEnMasse(select_key: string) {
    this.validerEnMasse('refuser en masse', select_key);
  }

  // validation de toutes les cases cochées dans la liste
  validerEnMasse(action: string, select_key: string) {
    let _selection: any = this.selectionService.getData;
    let content = action == 'valider en masse' ? 'valider en masse' : 'refuser en masse';
    if (!_selection?.listeAvalider?.length) {
      this.showEmptyAlert(content);
      return;
    }
    let selectedDatas = this.getSelectedData(this.dataEnAttente, _selection, select_key);

    // si on est sur une validation en masse, on filtre les éléments refusés
    if (action === 'valider en masse') {
      let [filteredResult, allRemoved] = this.filterAndAlertForRefusedItems(selectedDatas);
      selectedDatas = filteredResult;
      // Show alert if all elements were removed because everything is refused
      if (allRemoved) {
        this.dialog.open(AlerteModalComponent, {
          width: '640px',
          data: {
            title: 'Alerte',
            content: `Vous ne pouvez pas valider en masse les hs refusées par un directeur`
          }
        });
        return;
      }
      selectedDatas = this.calculateTotalHoursAndDuree(selectedDatas);
    }

    const dialogRef = this.dialog.open(ConfirmValidationModalComponent, {
      width: '640px',
      data: {
        type: 'hs',
        action: action,
        data: selectedDatas
      }
    });
    this.idsTovalidate = selectedDatas.flatMap((item) => item.subTable.map((subItem: any) => subItem.id));

    dialogRef.afterClosed().subscribe((result) => {
      if (Array.isArray(result)) {
        // demande la validation
        if (result[0] == 'valider') {
          this.validerSaisie(select_key, result[1], true);
        }
        // refus
        else if (result[0] == 'refuser') {
          this.refuserSaisie(result[1], select_key, true);
        }
      }
      this.partial = false;
    });
  }

  // retourne la liste des éléments à valider
  getSelectedData(listToFormat: any[], _selection: any, select_key: string): any[] {
    return listToFormat
      .map((elm) => {
        let subTable = elm.subTable.filter((sub: any) => _selection[select_key].includes(sub.id));
        return { ...elm, subTable };
      })
      .filter((elm) => elm.subTable.length > 0);
  }

  //filter refused items and return the filtered array and a boolean indicating if all elements were removed
  filterAndAlertForRefusedItems(array: any[]): [any[], boolean] {
    // Counter to track how many elements are removed
    let removedCount = 0;

    // Iterate over the array and filter each subtable
    const result = array.filter((item) => {
      item.subTable = item.subTable.filter((subItem: any) => subItem.Statut.code !== 'REFUSEES');
      // Check if the subtable is now empty and mark removed as true if so
      if (item.subTable.length === 0) {
        removedCount++;
        return false; // Remove the element from the array
      }
      return true;
    });

    // Check if all subTables were empty and set removed accordingly
    let allRemoved = removedCount === array.length;

    return [result, allRemoved];
  }

  calculateTotalHoursAndDuree(array: any[], type = ' hs'): any[] {
    // Iterate over the array and calculate the total hours and duree
    return array.map((item) => {
      item['Nombre d’heures totales'] = item.subTable.length;
      if (type === ' hs') {
        item['duree'] = item.subTable.map((subItem: any) => subItem['Durée']);
      }
      if (type === 'astreinte') {
        item['Nombre de jours à valider'] = item['Nombre de jours à valider'] - item['Nombre de jours refusés'];
      }

      return item;
    });
  }

  showEmptyAlert(action = ''): void {
    this.dialog.open(AlerteModalComponent, {
      width: '640px',
      data: {
        title: 'Alerte',
        content: `Vous devez faire une sélection avant de ${action} !`
      }
    });
  }

  refuserEnMasseAstreinte(select_key: string) {
    this.validerEnMasseAstreinte('refuser en masse', select_key);
  }

  // validation de toutes les cases cochées dans la liste astreintes
  validerEnMasseAstreinte(action: string, select_key: string) {
    let _selection: any = this.selectionService.getData;
    let content = action == 'valider en masse' ? 'valider en masse' : 'refuser en masse';
    if (!_selection?.[select_key]?.length) {
      this.showEmptyAlert(content);
      return;
    }
    let selectedDatas = this.getSelectedData(this.astrainteDataAvalider, _selection, select_key);

    // si on est sur une validation en masse, on filtre les éléments refusés
    if (action === 'valider en masse') {
      let [filteredResult, allRemoved] = this.filterAndAlertForRefusedItems(selectedDatas);
      selectedDatas = filteredResult;
      // Show alert if all elements were removed because everything is refused
      if (allRemoved) {
        this.dialog.open(AlerteModalComponent, {
          width: '640px',
          data: {
            title: 'Alerte',
            content: `Vous ne pouvez pas valider en masse les astreintes refusées par un directeur`
          }
        });
        return;
      }
      selectedDatas = this.calculateTotalHoursAndDuree(selectedDatas, 'astreinte');
      this.idsTovalidate = selectedDatas.flatMap((item) => item.subTable.map((subItem: any) => subItem.id));
    }

    const dialogRef = this.dialog.open(ConfirmValidationModalComponent, {
      width: '640px',
      data: {
        type: 'astrainte',
        action: action,
        data: selectedDatas
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (Array.isArray(result)) {
        // demande la validation
        if (result[0] == 'valider') {
          this.validerSaisieAstreinte(select_key, result[1], true);
        }
        // refus
        else if (result[0] == 'refuser') {
          this.refuserSaisieAstreinte(result[1], select_key, true);
        }
      }
      this.partial = false;
    });
  }

  // vérification des status d'une ligne agent
  verifStatutLigne(_data: any) {
    let statut_refuse = false;
    let _statut: any = [];
    let _id: any[] = [];
    this.IDaValMotif = [];

    if (_data['subTable'] != undefined && _data['subTable'] != null && _data['subTable'].length > 0) {
      let _subTable = _data['subTable'];
      _id = _subTable.map((ligne: any) => ligne.id);
      _statut = _subTable.map((ligne: any) => ligne?.Statut?.code);

      let a = 0;
      if (_statut.length > 0) {
        _statut.every((statut: any) => {
          if (statut === 'REFUSEES') {
            statut_refuse = true;
            this.IDaValMotif.push(_id[a]);
          }
          a++;
          return true;
        });
      }
    } else {
      console.log('pas de subtable');
    }

    return statut_refuse;
  }

  // recherche des ID de ligne
  /**
   * Construit la liste des éléments (HS, astreinte) sur lesquels effectuer l'opération
   * (validation ou refus)
   */
  extractIDSubtable(_data: any) {
    let _id: any[] = [];
    this.IDaValider = [];

    // recherche des ID de toutes les lignes
    if (_data['subTable'] != undefined && _data['subTable'] != null && _data['subTable'].length > 0) {
      let _subTable = _data['subTable'];
      _id = _subTable.map((ligne: any) => ligne.id);
      this.IDaValider = _id;
    }
    // recherche l'ID de la ligne
    else {
      this.IDaValider.push(_data['id']);
    }
  }

  // modale de confirmation pour HS et astreintes
  openDialog(event: any, select_key?: string, action?: string, type?: string) {
    console.log('openDialog event', event);
    /**
     * Il existe 2 structure possible pour event :
     * - si rien n'est sélectionné dans la sous-table, il s'agit de la ligne entière de la table
     * - si une ou plusieurs sous-lignes sont sélectionnées, on ne reçoit que les ID des HS/astreintes de ces sous-lignes
     */

    this.extractIDSubtable(event);

    /**
     * Calcul des objets JSON à traiter
     * Pour une ligne HS/astreinte, on ne garde que les sous-lignes à traiter
     * dans la partie subtable
     */
    let _data: any[] = [];
    if (
      (event['listeAvalider'] && event['listeAvalider'].lenght !== 0) ||
      (event['listeAstAvalider'] && event['listeAstAvalider'].lenght !== 0)
    ) {
      _data = this.mergePartial(this.partialUpdate(event));
      this.partial = true;
    } else if (event instanceof Object) {
      _data = event;
    }

    // vérification des status pour afficher le motif
    /**
     * On parcourt toutes les lignes à traiter pour savoir si on impose
     * de saisir un motif de validation
     */

    // si clic sur la ligne agent sinon c'est une sélection de lignes
    let _data_li = !Array.isArray(_data) && 'subTable' in _data ? _data['subTable'] : _data;

    // uniquement pour l'action valider, vérifie si le motif est nécessaire
    if (action == 'valider') {
      let _self = this;
      if (_data_li && _data_li.length > 0) {
        _data_li.forEach(function (lineHSAst) {
          let statut_refuse = _self.verifStatutLigne(lineHSAst);

          // si status "Avis défavorable - Directeur" : demande le motif
          if (lineHSAst?.Statut?.code == 'REFUSEES' || statut_refuse) {
            action = 'repasser_valide';
          }
        });
      }
    }

    const dialogRef = this.dialog.open(ConfirmValidationModalComponent, {
      width: '540px',
      data: {
        data: _data,
        action: action,
        type: type
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('afterClosed result:', result);

      if (Array.isArray(result)) {
        // demande la validation
        if (result[0] == 'valider' && result[2] != 'astrainte') {
          console.log('result', result);
          this.validerSaisie(select_key, result[1]);
        } else if (result[0] == 'valider' && result[2] == 'astrainte') {
          this.validerSaisieAstreinte(select_key, result[1]);
        }
        // refus
        else if (result[0] == 'refuser' && result[2] != 'astrainte') {
          this.refuserSaisie(result[1], select_key);
        } else if (result[0] == 'refuser' && result[2] == 'astrainte') {
          this.refuserSaisieAstreinte(result[1], select_key);
        }

        this.selectionService.resetSelection(select_key);
      }
      this.partial = false;
    });
  }

  viewDetailsValidation(id: number) {
    console.log('viewDetailsValidation id:' + id);

    this.dialog.open(DetailValidationComponent, {
      data: { type: 'hs', id: id },
      width: '960px',
      maxHeight: '800px'
    });
  }

  updatePaginationAvalider(event: any) {
    if (event) {
      this.ValidationService.getSaisieEnAttenteWithParams(event.offset, event.limit, event.filterValue)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.dataJSON = result.results;
            this.aValiderLenght = result.totalRecords;
            this.dataEnAttente = this.transformData('attente');
            this.detectorRef.markForCheck();
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    } else {
      this.ValidationService.getSaisieEnAttenteWithParams(environment.pagination.offset, environment.pagination.limit)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.dataJSON = result.results;
            this.aValiderLenght = result.totalRecords;
            this.dataEnAttente = this.transformData('attente');
            this.detectorRef.markForCheck();
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    }
  }
  updatePaginationValider(event: any) {
    if (event) {
      this.ValidationService.getSaisieValiderWithParams(event.offset, event.limit, event.filterValue)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.dataJSON = result.results;
            this.dataValider = this.transformData('valider');
            this.detectorRef.markForCheck();
            this.validerLenght = result.totalRecords;
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    } else {
      this.ValidationService.getSaisieValiderWithParams(environment.pagination.offset, environment.pagination.limit)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.dataJSON = result.results;
            this.dataValider = this.transformData('valider');
            this.detectorRef.markForCheck();
            this.validerLenght = result.totalRecords;
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    }
  }
  updatePaginationRefusee(event: any) {
    if (event) {
      this.ValidationService.getSaisieRefuserWithParams(event.offset, event.limit, event.filterValue)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.dataJSON = result.results;
            this.dataRefuser = this.transformData('refuser');
            this.detectorRef.markForCheck();
            this.refuseesLenght = result.totalRecords;
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    } else {
      this.ValidationService.getSaisieRefuserWithParams(environment.pagination.offset, environment.pagination.limit)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.dataJSON = result.results;
            this.dataRefuser = this.transformData('refuser');
            this.detectorRef.markForCheck();
            this.refuseesLenght = result.totalRecords;
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    }
  }
  updatePaginationAll(event: any) {
    if (event) {
      this.ValidationService.getSaisieRecapWithParams(event.offset, event.limit, event.filterValue)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.dataJSON = result.results;
            this.dataRecap = this.transformData('recap');
            this.detectorRef.markForCheck();
            this.allLenght = result.totalRecords;
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    } else {
      this.ValidationService.getSaisieRecapWithParams(environment.pagination.offset, environment.pagination.limit)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.dataJSON = result.results;
            this.dataRecap = this.transformData('recap');
            this.detectorRef.markForCheck();
            this.allLenght = result.totalRecords;
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    }
  }

  getAstrainte() {
    this.ValidationService.getAstraintesWithParams(environment.pagination.offset, environment.pagination.limit)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.allAstLenght = result.totalRecords;
          this.astrainteData = this.transformAstraintesData(result.results, 'recap');
        },
        error: (error) => {
          console.log('erreur:', error);
        }
      });
  }
  getAvaliderAstrainte() {
    this.ValidationService.getAvaliderAstrainteWithParams(environment.pagination.offset, environment.pagination.limit)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.aValiderAstLenght = result.totalRecords;
          this.actifAstValidation = result.totalRecords == 0 ? false : true;
          this.astrainteDataAvalider = this.transformAstraintesData(result.results, 'attente');
        },
        error: (error) => {
          console.log('erreur:', error);
        }
      });
  }

  getInvalideAstrainte() {
    this.ValidationService.getRefuseAstrainteWithParams(environment.pagination.offset, environment.pagination.limit)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.refuseesAstLenght = result.totalRecords;
          this.astrainteInvalide = this.transformAstraintesData(result.results, 'refuser');
        },
        error: (error) => {
          console.log('erreur:', error);
        }
      });
  }

  getValiderAstrainte() {
    this.ValidationService.getvalideAstrainteWithParams(environment.pagination.offset, environment.pagination.limit)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.validerAstLenght = result.totalRecords;
          this.astraintevalide = this.transformAstraintesData(result.results, 'valider');
        },
        error: (error) => {
          console.log('erreur:', error);
        }
      });
  }

  transformAstraintesData(result: any, typeListe: string) {
    let roles = this.userRoles;
    let profil_admin = roles.includes('dhesa-admin');
    let profil_directeur = roles.includes('dhesa-directeur');
    let profil_dg = roles.includes('dhesa-dg');
    let profil_responsable = roles.includes('dhesa-responsable');
    let profil_assistant_responsable = roles.includes('dhesa-assistant-responsable');

    let data: any[] = [];
    for (let key in result) {
      let dataJ = result[key];
      let _data: any = {};
      let astraintes = dataJ?.listAstreintes;
      let agent = dataJ?.agentDTO;
      _data['id'] = agent?.id;
      _data['Collaborateur'] = agent?.nom + ' ' + agent?.prenom;
      _data['Direction origine'] = astraintes[0]?.directionOrigine?.libelle;
      _data['Nombre de jours à valider'] = dataJ?.nbreAValider;
      _data['Nombre de jours refusés'] = dataJ?.nbreRefusees;
      _data['Nombre de jours total'] = dataJ?.nbreTotales;

      let isSubTableValider = false;
      // activation du bouton valider pour la ligne agent
      _data['valider_hidden'] = true;
      if (
        this.condition &&
        (profil_admin ||
          (this.condition && profil_responsable && typeListe == 'attente') ||
          (this.condition && profil_assistant_responsable && typeListe == 'attente') ||
          (this.condition && profil_directeur && typeListe == 'attente') ||
          (this.condition && profil_dg && (typeListe == 'valider' || typeListe == 'refuser')))
      ) {
        _data['valider_hidden'] = false;
      }

      _data['subTable'] = [];

      for (let astreinte in astraintes) {
        let ast = astraintes[astreinte];
        let _subTable: any = {};

        _subTable['Sélectionner'] = true;
        _subTable['Service Origine'] = ast?.directionOrigine?.libelle;
        _subTable['DIR. Évènement'] = ast?.evenement?.libelle;
        _subTable['Motif'] = ast?.motif ? ast?.motif : '';
        _subTable['Date de création'] = this.pipe.transform(ast?.dateCreation, 'shortDate');
        _subTable['Date de début'] = this.pipe.transform(ast?.dateDebut, 'shortDate');
        _subTable['Date de fin'] = this.pipe.transform(ast?.dateFin, 'shortDate');
        _subTable['Nombre'] = ast?.nombre;

        let status_code = ast?.statut;
        let status_text = {};
        if (status_code == 'A_VALIDER') {
          status_text = { class: 'badge-gray', text: 'Avis non renseigné', code: status_code, object: true };
        } else if (status_code == 'VALIDEES') {
          status_text = { class: 'badge-green-directeur', text: 'Avis favorable - Directeur', code: status_code, object: true };
        } else if (status_code == 'REFUSEES') {
          status_text = { class: 'badge-red', text: 'Avis défavorable - Directeur', code: status_code, object: true };
        } else if (status_code == 'VALIDEES_N2') {
          status_text = { class: 'badge-green-dga', text: 'Avis favorable - DG', code: status_code, object: true };
        } else if (status_code == 'REFUSEES_N2') {
          status_text = { class: 'badge-red', text: 'Avis défavorable - DG', code: status_code, object: true };
        } else if (status_code == 'SAISIE_A_RENOUVELER') {
          status_text = { class: 'badge-gray', text: 'Saisie à renouveler', code: status_code, object: true };
        } else if (status_code == 'A_VALIDER_RESPONSABLE') {
          status_text = { class: 'badge-gray', text: 'Avis non renseigné (responsable)', code: status_code, object: true };
        } else if (status_code == 'VALIDEES_RESPONSABLE') {
          status_text = { class: 'badge-green-directeur', text: 'Avis favorable - Responsable', code: status_code, object: true };
        } else if (status_code == 'REFUSEES_RESPONSABLE') {
          status_text = { class: 'badge-red', text: 'Avis défavorable - Responsable', code: status_code, object: true };
        }
        _subTable['Statut'] = status_text;

        _subTable['id'] = ast['id'];
        _subTable['action'] = '';
        _subTable['checked'] = false;

        // activation du bouton valider pour la ligne HS
        _subTable['valider_hidden'] = true;

        if (
          (profil_admin &&
            this.condition &&
            (status_code == 'A_VALIDER' ||
              status_code == 'VALIDEES' ||
              status_code == 'REFUSEES' ||
              status_code == 'A_VALIDER_RESPONSABLE' ||
              status_code == 'VALIDEES_RESPONSABLE' ||
              status_code == 'REFUSEES_RESPONSABLE')) ||
          (this.condition && (profil_responsable || profil_assistant_responsable) && status_code == 'A_VALIDER_RESPONSABLE') ||
          (this.condition &&
            profil_directeur &&
            (status_code == 'VALIDEES_RESPONSABLE' || status_code == 'REFUSEES_RESPONSABLE' || status_code == 'A_VALIDER')) ||
          (this.condition && profil_dg && (status_code == 'VALIDEES' || status_code == 'REFUSEES'))
        ) {
          _subTable['valider_hidden'] = false;
        } else {
          isSubTableValider = true;
        }

        _subTable['Collaborateur'] = agent?.nom + ' ' + agent?.prenom;
        _data['subTable'].push(_subTable);
      }
      _data['valider_hidden'] = isSubTableValider;
      data.push(_data);
    }
    return data;
  }

  viewDetailsValidationAstreinte(id: number) {
    this.dialog.open(DetailValidationComponent, {
      data: { type: 'astreinte', id: id },
      width: '960px',
      maxHeight: '800px'
    });
  }

  updatePaginationAstAvalider(event: any) {
    if (event) {
      this.ValidationService.getAvaliderAstrainteWithParams(event.offset, event.limit, event.filterValue)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.aValiderAstLenght = result.totalRecords;
            this.astrainteDataAvalider = this.transformAstraintesData(result.results, 'attente');
            this.detectorRef.markForCheck();
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    } else {
      this.ValidationService.getAvaliderAstrainteWithParams(environment.pagination.offset, environment.pagination.limit)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.aValiderAstLenght = result.totalRecords;
            this.astrainteDataAvalider = this.transformAstraintesData(result.results, 'attente');
            this.detectorRef.markForCheck();
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    }
  }
  updatePaginationAstValider(event: any) {
    if (event) {
      this.ValidationService.getvalideAstrainteWithParams(event.offset, event.limit, event.filterValue)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.validerAstLenght = result.totalRecords;
            this.astraintevalide = this.transformAstraintesData(result.results, 'valider');
            this.detectorRef.markForCheck();
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    } else {
      this.ValidationService.getvalideAstrainteWithParams(environment.pagination.offset, environment.pagination.limit)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.validerAstLenght = result.totalRecords;
            this.astraintevalide = this.transformAstraintesData(result.results, 'valider');
            this.detectorRef.markForCheck();
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    }
  }
  updatePaginationAstRefusee(event: any) {
    if (event) {
      this.ValidationService.getRefuseAstrainteWithParams(event.offset, event.limit, event.filterValue)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.refuseesAstLenght = result.totalRecords;
            this.astrainteInvalide = this.transformAstraintesData(result.results, 'refuser');
            this.detectorRef.markForCheck();
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    } else {
      this.ValidationService.getRefuseAstrainteWithParams(environment.pagination.offset, environment.pagination.limit)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.refuseesAstLenght = result.totalRecords;
            this.astrainteInvalide = this.transformAstraintesData(result.results, 'refuser');
            this.detectorRef.markForCheck();
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    }
  }
  updatePaginationAstAll(event: any) {
    if (event) {
      this.ValidationService.getAstraintesWithParams(event.offset, event.limit, event.filterValue)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.allAstLenght = result.totalRecords;
            this.astrainteData = this.transformAstraintesData(result.results, 'recap');
            this.detectorRef.markForCheck();
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    } else {
      this.ValidationService.getAstraintesWithParams(environment.pagination.offset, environment.pagination.limit)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.allAstLenght = result.totalRecords;
            this.astrainteData = this.transformAstraintesData(result.results, 'recap');
            this.detectorRef.markForCheck();
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    }
  }

  //récuperation les sous element de astrainte // hs selectionner
  partialUpdate(event: any) {
    let _data: any[] = []; // Initialize _data as an empty array
    if (event['listeAvalider'] && event['listeAvalider'].lenght !== 0) {
      event['listeAvalider'].forEach((elem: any) => {
        this.dataEnAttente.forEach((aValider: any) => {
          aValider.subTable.forEach((subElement: any) => {
            if (subElement.id == elem) {
              _data.push(subElement);
            }
          });
        });
      });
    }
    if (event['listeAstAvalider'] && event['listeAstAvalider'].lenght !== 0) {
      event['listeAstAvalider'].forEach((elem: any) => {
        this.astrainteDataAvalider.forEach((aValider: any) => {
          aValider.subTable.forEach((subElement: any) => {
            if (subElement.id == elem) {
              _data.push(subElement);
            }
          });
        });
      });
    }
    this.partialId = _data.map((o) => o.id);

    return JSON.parse(JSON.stringify(_data));
  }
  mergePartial(data: any) {
    console.log('partialdata', data);
    //@ts-ignore
    let _data = [];
    // split resault into multiple arrays groupped by Collaborateur
    //@ts-ignore
    const groupedPartial = data.reduce((result, currentObject) => {
      const existingGroup = result.find((group: any) => group[0].Collaborateur === currentObject.Collaborateur);

      if (existingGroup) {
        existingGroup.push(currentObject);
      } else {
        result.push([currentObject]);
      }

      return result;
    }, []);
    // on prend le 1ér objet de chaque tableau et on le transforme en un seul avec le format nécessaire
    groupedPartial.forEach((partials: any) => {
      partials[0].subTable = [];
      partials[0]['duree'] = [];
      partials.forEach((subPartials: any) => {
        partials[0].subTable.push(subPartials);
        partials[0]['duree'].push(subPartials['Durée']);
      });
      _data.push(partials[0]);
    });
    //@ts-ignore
    return _data;
  }
  removeDuplicatesByKey(array: any, key: string) {
    const seen = new Set();
    return array.filter((item: any) => {
      const value = item[key];
      if (!seen.has(value)) {
        seen.add(value);
        return true;
      }
      return false;
    });
  }
  validateSingleItem(event: any, type: string) {
    const id = event.id;

    console.log('validateSingleItem : ', event);

    /**
     * Est-ce qu'on doit forcer la demande d'un motif
     */
    let action = 'valider';
    if (event?.Statut?.code == 'REFUSEES') {
      action = 'repasser_valide';
    }

    if (type == 'astrainte') {
      const dialogRef = this.dialog.open(ConfirmValidationModalComponent, {
        width: '540px',
        data: {
          data: event,
          action: action,
          type: type
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.selectionService.resetSelection();

        if (Array.isArray(result)) {
          let payload = { [id]: result[1] };

          this.ValidationService.validerSaisieAstreinte(payload)
            .pipe(take(1))
            .subscribe({
              next: (result) => {
                this.openConfirmValidationModal(true);
                this.selectionService.resetSelection();
                this.refreshListe();
              },
              error: (error) => {}
            });
        }
        this.partial = false;
      });
    } else {
      const dialogRef = this.dialog.open(ConfirmValidationModalComponent, {
        width: '540px',
        data: {
          data: event,
          action: action,
          type: type
        }
      });
      this.selectionService.resetSelection();
      dialogRef.afterClosed().subscribe((result) => {
        console.log(result);
        if (Array.isArray(result)) {
          let payload = { [id]: result[1] };

          this.ValidationService.validerSaisie(payload)
            .pipe(take(1))
            .subscribe({
              next: (result) => {
                this.openConfirmValidationModal();
                this.selectionService.resetSelection();
                this.refreshListe();
              },
              error: (error) => {}
            });
        }
        this.partial = false;
      });
    }
  }

  refuseSingleItem(event: any, type: string) {
    const id = event.id;

    if (type == 'astrainte') {
      const dialogRef = this.dialog.open(ConfirmValidationModalComponent, {
        width: '540px',
        data: {
          data: event,
          action: 'refuser',
          type: type,
          displayMotif: true
        }
      });
      this.selectionService.resetSelection();
      dialogRef.afterClosed().subscribe((result) => {
        console.log(result);
        if (Array.isArray(result)) {
          let payload = { [id]: result[1] };

          this.ValidationService.refuserSaisieAstrainte(payload)
            .pipe(take(1))
            .subscribe({
              next: (result) => {
                this.openConfirmValidationModal(true, true);
                this.selectionService.resetSelection();
                this.refreshListe();
              },
              error: (error) => {}
            });
        }
        this.partial = false;
      });
    } else {
      const dialogRef = this.dialog.open(ConfirmValidationModalComponent, {
        width: '540px',
        data: {
          data: event,
          action: 'refuser',
          type: type,
          displayMotif: true
        }
      });
      this.selectionService.resetSelection();
      dialogRef.afterClosed().subscribe((result) => {
        console.log(result);
        if (Array.isArray(result)) {
          let payload = { [id]: result[1] };

          this.ValidationService.refuserSaisie(payload)
            .pipe(take(1))
            .subscribe({
              next: (result) => {
                this.openConfirmValidationModal(false, true);
                this.selectionService.resetSelection();
                this.refreshListe();
              },
              error: (error) => {}
            });
        }
        this.partial = false;
      });
    }
  }

  updateFiltreHsEnAttent(value: any) {
    this.filterValue = value.filterValue;
    this.updatePaginationAvalider(value);
  }

  updateFiltreHsValidees(value: any) {
    this.filterValue = value.filterValue;
    this.updatePaginationValider(value);
  }
  updateFiltreHsRefusee(value: any) {
    this.filterValue = value.filterValue;
    this.updatePaginationRefusee(value);
  }
  updateFiltreHsAll(value: any) {
    this.filterValue = value.filterValue;
    this.updatePaginationAll(value);
  }
  updateFiltreAstEnAttent(value: any) {
    this.filterValue = value.filterValue;
    this.updatePaginationAstAvalider(value);
  }
  updateFiltreAstValider(value: any) {
    this.filterValue = value.filterValue;
    this.updatePaginationAstValider(value);
  }
  updateFiltreAstRefusee(value: any) {
    this.filterValue = value.filterValue;
    this.updatePaginationAstRefusee(value);
  }
  updateFiltreAstAll(value: any) {
    this.filterValue = value.filterValue;
    this.updatePaginationAstAll(value);
  }
}
