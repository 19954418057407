import { Component, Inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { take } from 'rxjs';
import { AlerteModalComponent } from 'src/app/shared/alerte-modal/alerte-modal.component';
import { MatSelectModule } from '@angular/material/select';
import { DateTime } from 'luxon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ValidationModalComponent } from 'src/app/shared/validation-modal/validation-modal.component';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { DirectionService } from 'src/app/services/direction.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { JoursFeriesService } from 'src/app/services/jours-feries.service';
import { CustomLuxonDateAdapter } from 'src/app/shared/utils/custom-luxon-date-adapter';
import { MY_FORMATS } from 'src/app/shared/utils/date-pickr-custom-luxon-format';
import { DragDropModule } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-campagne-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatRadioModule,
    MatIconModule,
    MatCardModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatAutocompleteModule,
    ValidationModalComponent,
    ConfirmModalComponent,
    MatNativeDateModule,
    MatCheckboxModule,
    DragDropModule
  ],
  templateUrl: './jours-feries-modal.component.html',
  styleUrls: ['./jours-feries-modal.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: DateAdapter, useClass: CustomLuxonDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class JoursFeriesModalComponent {
  rowData: any = {};
  dateP = new DatePipe('fr-FR');
  action: string = '';
  titre_action: string = 'Ajouter';
  selectedType: string = '';
  dataCampagne: any = '';
  texte_erreur_date: string = '';
  minDateDebutSaisie: Date;
  minDateFinSaisie: Date;
  minDateDebutValidation: Date;
  minDateFinValidation: Date;
  minDateArchivage: Date;
  directionList: any[] = [];
  listDirection: any = [];
  val_direction: any = null;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<JoursFeriesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private joursFeriesService: JoursFeriesService,
    private directionService: DirectionService
  ) {}

  form: FormGroup = this.formBuilder.group({
    libelle: [this.data['libelle'], [Validators.required]],
    dateJourFerier: ['', [Validators.required]],
    annee: [this.data['annee']]
  });

  ngOnInit() {
    this.rowData = this.data;
    this.initForm();
  }

  ngAfterViewInit() {
    // initialise les vérification de champs après la création des champs
    this.form.controls['dateJourFerier'].addValidators([verifDateJour(this)]);
  }

  // chargement des dates dans les datepicker
  initForm() {
    let _data = this.data;
    console.log('initForm _data:', _data);

    // par défaut : en mode ajout
    this.action = 'ajouter';

    if (Object.keys(_data).length > 0) {
      if (_data['edition']) {
        this.titre_action = 'Modifier';
        this.action = 'modifier';
      } else {
        this.action = 'ajouter';
      }
    }

    if (_data['dateJourFerier'] != null) {
      let date_d = _data['dateJourFerier'];
      let dateJour = new Date(date_d).toISOString();
      this.form.controls['dateJourFerier'].setValue(dateJour);
    }
  }

  fermerModale() {
    // modification
    if (this.data['edition']) {
      this.dialogRef.close({ action: false });
    }
    // création
    else {
      this.confirmQuitterModale();
    }
  }

  confirmQuitterModale() {
    // création uniquement
    if (!this.data['edition']) {
      if (this.form.pristine) {
        const dialogConfirm = this.dialog.open(ConfirmModalComponent, {
          width: '600px',
          data: {
            title: 'Confirmation',
            content1: 'Souhaitez vous abandonner la création du jour férié ?',
            content2: 'Les données que vous avez saisies ne seront pas enregistrées.'
          }
        });

        dialogConfirm.afterClosed().subscribe((data) => {
          if (data) {
            this.dialogRef.close({ action: false });
          }
        });
      } else {
        this.dialogRef.close({ action: false });
      }
    }
  }

  validerForm() {
    if (this.action == 'ajouter') {
      this.ajouterJourFerie();
    } else {
      this.modifierJourFerie();
    }
  }

  // Ajouter un cjour férié
  ajouterJourFerie() {
    let _data: any = {};

    _data['libelle'] = this.form.controls['libelle'].value;

    let dateJourFerier = this.form.controls['dateJourFerier'].value;
    let dateJourFerier_dt = DateTime.fromJSDate(dateJourFerier).isValid
      ? DateTime.fromJSDate(dateJourFerier)
      : DateTime.fromISO(dateJourFerier);
    if (dateJourFerier_dt.isValid) {
      _data['dateJourFerier'] = dateJourFerier_dt.toFormat('yyyy-MM-dd') + 'T00:00:00';
    }

    this.joursFeriesService
      .ajouterJourFerie(_data)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          console.log('result ok: ', result);
          this.spinner.hide();
          this.dialogRef.close({ action: true });
          this.dialog.open(ValidationModalComponent, {
            width: '600px',
            data: {
              title: 'Confirmation',
              content: 'Le jour férié a été créé avec succès.'
            }
          });
        },
        error: (error) => {
          console.log('error:', error);

          let erreur_texte = error.error.detail || error.error || error.statusText || error;

          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: 'Erreur lors de la création du jour férié !',
              status: 'STATUS: ' + error.status,
              detail: 'DETAIL: ' + erreur_texte
            }
          });
        }
      });
  }

  // Modifier une campagne
  modifierJourFerie() {
    let _data: any = {};

    _data['id'] = this.rowData.id;
    _data['libelle'] = this.form.controls['libelle'].value;

    let dateJourFerier = this.form.controls['dateJourFerier'].value;
    let dateJourFerier_dt = DateTime.fromJSDate(dateJourFerier).isValid
      ? DateTime.fromJSDate(dateJourFerier)
      : DateTime.fromISO(dateJourFerier);
    if (dateJourFerier_dt.isValid) {
      _data['dateJourFerier'] = dateJourFerier_dt.toFormat('yyyy-MM-dd') + 'T00:00:00';
    }

    this.joursFeriesService
      .modifierJourFerie(_data, _data['id'])
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          console.log('result ok: ', result);
          this.spinner.hide();
          this.dialogRef.close({ action: true });
          this.dialog.open(ValidationModalComponent, {
            width: '600px',
            data: {
              title: 'Confirmation',
              content: 'Le jour férié a été modifié avec succès.'
            }
          });
        },
        error: (error) => {
          console.log('error: ', error);
          let erreur_texte = error.error.detail || error.error || error.statusText || error;

          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: 'Erreur lors de la modification du jour férié !',
              status: 'STATUS : ' + error.status,
              detail: 'DETAIL : ' + erreur_texte
            }
          });
        }
      });
  }
}

// vérification de la date du jour
export function verifDateJour(_this: any): ValidatorFn {
  return (control: AbstractControl): null | ValidationErrors => {
    let c_dateJourFerier = _this.form.controls['dateJourFerier'].value;
    let dateJourFerier: any = '';
    if (c_dateJourFerier) {
      let date = new Date(c_dateJourFerier);
      dateJourFerier = DateTime.fromJSDate(date);
    }

    let date_j = DateTime.fromJSDate(new Date());

    // la date du jour férié doit être supérieure à la date du jour
    if (dateJourFerier < date_j) {
      return { erreurDateJour: control.value };
    }

    return null;
  };
}
