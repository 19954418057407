import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EvenementsService {
  module = environment.webService.server + environment.webService.url + environment.webService.api;
  constructor(private http: HttpClient) {}

  // get all the evenements
  getAllEvenements() {
    return this.http.get<any>(this.module + '/evenements/all');
  }

  /**
   * Ce endpoint n'est appelé que depuis la section Administration
   * Il doit aussi renvoyer les évènements non actifs pour pouvoir les réactiver
   */
  getEvenementsWithParams(offset: number, limit: number, filtre: string | null = null) {
    console.log('filtre param', filtre);
    filtre = filtre ?? '';
    let url = this.module + '/evenements';
    if (filtre != ' ' && filtre !== undefined && filtre !== null) {
      url = url + '?' + `filterGenerique=${filtre}`;
    }
    const params = new HttpParams().set('page', offset).set('size', limit);
    return this.http.get<any>(url, { params });
  }

  // création évènement
  ajouterEvenement(data: any) {
    return this.http.post<any>(this.module + '/evenements', data);
  }

  // Modification évènement
  modifierEvenement(data: any, id: number) {
    return this.http.put<any>(this.module + '/evenements/' + id, data);
  }
}
